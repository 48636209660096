import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, GetEmployeeRequest, GetStudentListRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer, Views, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../utils/ApiConnection'
import ReactSwitch from 'react-switch';


const Scheduled_Activites = () => {
    const dispatch = useDispatch()
    const { id, pros } = useParams()
    console.log(id)

    const [search, setsearch] = useState("")
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [AvilScheduleList, setAvilScheduleList] = useState([])
    const [ScheduleDay, setScheduleDay] = useState(null)

    const [remainderdate1, setremainderdate1] = useState(null)
    const [communicationtype1, setcommunicationtype1] = useState("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const [FilterResult, setFilterResult] = useState([])

    let navigate = useNavigate()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var StudentActivityList = fetchacompanyReducer?.GetStudentActivityResponse?.results
    var StudentActivityView = fetchacompanyReducer?.FetchStudentActivityResponse?.results[0]
    console.log(fetchacompanyReducer.GetStudentActivityResponse)
    console.log("StudentId", id)

    const GetSchedule = async (e) => {
        try {
          const response = await ApiConnection.get(`activities_crud/?server_type=production&student_id=${id}`);
          if (response?.status === 200) {
            const avilScheduleList = response?.data?.results.map((item) => {
              const startSeconds = item?.start_time;
              const endSeconds = item?.end_time;
              const startHours = Math.floor(startSeconds / 3600);
              const startMinutes = Math.floor((startSeconds % 3600) / 60);
              const endHours = Math.floor(endSeconds / 3600);
              const endMinutes = Math.floor((endSeconds % 3600) / 60);
      
              return {
                ...item,
                start_time_formatted: `${startHours}:${startMinutes}`,
                end_time_formatted: `${endHours}:${endMinutes}`,
              };
            });
            // console.log('start_time_formatted', item)

            setAvilScheduleList(avilScheduleList);
          }
        } catch (e) {
          // Handle error
        }
      };

    useEffect(() => {
        GetSchedule()

    }, [])


    return (
        <div className='module_list'>
        <Loader visible={fetchacompanyReducer.isloading} />
            <div className='mb-4'>
          <h3> Activites </h3>
        </div>

            <div className='table-responsive'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th>Activity Name</th>
                            <th>Start Date</th>
                            <th>Start Time</th>
                            <th>End Date</th>
                            <th>End Time</th>
                            <th>Communication Type</th>

                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    {AvilScheduleList && AvilScheduleList.length > 0 ?

                    <tbody>
                        {AvilScheduleList && AvilScheduleList.map((item, i) => {
                            return (
                                <tr>
                                    <td>{item?.title}</td>
                                    <td>{item?.start_date}</td>
                                    <td>{item?.start_time_formatted}</td>
                                    <td>{item?.end_date}</td>
                                    <td>{item?.end_time_formatted}</td>
                                    <td>{item?.comunication_type}</td>

                                </tr>
                            )
                        })}
                    </tbody>
                                      :
                                      <h6 className='text-center'>No data found</h6>
                                  }  
                </table>
                
            </div>


        </div>
    )
}

export default Scheduled_Activites