import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetStudenttestRequest, GetExistingTestRequest, GetStudentTestListRequest, GetExistingQuestionRequest, AssignScheduleRequest, GetstudentRequest, DownloadQuestionRequest, GetLearningPlanListsRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';

const StudentAssessment = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [time, settime] = useState("")
    const [assessmentdate, setassessmentdate] = useState(null)
    const [starttime, setstarttime] = useState("")
    const [endtime, setendtime] = useState("")
    const [show, setShow] = useState(false);
    const [editrowId, seteditrowId] = useState(null)
    const [TestId, setTestId] = useState("");
    const handleClose = () => setShow(false);
    const [show1, setShow1] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [StudentList , setStudentList] = useState([])
    const handleClose1 = () => setShow1(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var testList = fetchacompanyReducer?.GetStudenttestResponse?.results
    var ExistingTest = fetchacompanyReducer?.GetStudentTestListResponse?.results
    var studentList = fetchacompanyReducer?.GetstudentResponse?.results


    console.log(fetchacompanyReducer.GetStudenttestResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id } = useParams()

//     selector: (row) => (
//         row.is_exam_taken != '0' ?
//            <button className='formBtn studentassignbtn' onClick={()=>DownloadHandle(row.test_id)}>Print</button>
//            :
//            <span>N/A</span>
//    ),

    const columns = [
        {
            name: 'Title',
            selector: row => (
            <div style={{width: '110rem'}}>                
                <NavLink onClick={()=>DownloadHandle(row.test_id)}><i className="fa-solid fa-eye"></i></NavLink> &nbsp;
                <span>{row.test_name}</span>
                </div>
            ),
            sortable: true,
        },
        {
            name: 'Group',
            selector: row => row.group_name,
        },
        {
            name: 'Date Taken',
            selector: row => row.date_of_exam_taken,
        }, 
        {
            name: 'Level',
            selector: row => row.level,
        },
        // {
        //     name: 'Assign',
        //     selector: (row) => (
        //         row.is_exam_taken != '0' ?
        //             <button className='formBtn studentassignbtn' onClick={row.is_has_question != '0' ? () => handleShow1(row?.test_id): () => AssignHandle()}>Assign</button>
        //         :
        //         <span>Assigned</span>
        //     ),
        // },
        {
            name: 'AnswerSheet',
            selector: (row) => (
                 row.is_exam_taken != '0' ?
                 <NavLink to={`/${userinfo?.company_slug}/students/assessmentanswer/${row?.test_id}`} className='formBtn studentassignbtn'>Print</NavLink>
                    // <button className='formBtn studentassignbtn' onClick={()=>DownloadHandle(row.test_id)}>Print</button>
                    :
                    <span>N/A</span>
            ),
        },
        {
            name: 'Chart',
            selector: (row) => (
                 row.is_exam_taken != '0' ?
                    // <button className='formBtn studentassignbtn' onClick={handleChart()}>Chart</button>
                    <NavLink to={`/${userinfo?.company_slug}/students/assessmentchart/${row.test_id}/${id}`} className='formBtn studentassignbtn'>Chart</NavLink>
                    :
                    <span>N/A</span>
            ),
        },
        // {
        //     name: 'Compare',
        //     selector: (row) => (
        //         row.is_exam_taken != '0' ?
        //            ''
        //            :
        //            <span>N/A</span>
        //    ),
        // },
        {
            name: 'Select',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                   <>
                   <input type="checkbox"/>
                   </>
                   :
                   <span>N/A</span>
           ),
        },
        {
            name: 'Score (%)',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                <span>{row.marks_obtained}</span>
                :
                <span>N/A</span>
           ),
        },
        {
            name: 'Results',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                <NavLink to={`/${userinfo?.company_slug}/students/assessmentresult/${row?.test_id}/${id}`} className='formBtn studentassignbtn'>Results</NavLink>
                :
                <span>N/A</span>
           ),
        },
        {
            name: 'Answer Key',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                <NavLink to={`/${userinfo?.company_slug}/students/assessmentanswer/${row?.test_id}`} className='formBtn studentassignbtn'>Answer</NavLink>

                :
                <span>N/A</span>
           ),
        },
        // {
        //     name: 'Learning Path',
        //     selector: (row) => (
        //         row.is_exam_taken != '0' && row.is_lp_taken == '0' ?
        //         // <button className='formBtn studentassignbtn' onClick={()=>AddLearningPath(row.test_id,row.student_id)}>Add LP</button>
        //         <NavLink to={`/${userinfo?.company_slug}/students/StudentLearningpath/${row?.test_id}/${id}`} className='formBtn studentassignbtn'>Add LP</NavLink>
        //         :
        //         <NavLink to={`/${userinfo?.company_slug}/students/ViewStudentLearningpath/${row?.test_id}/${id}`} className='formBtn studentassignbtn'>View LP</NavLink>
        //         ),
        // },
        // {
        //     name: 'Delete',
        //     selector: (row) => (
        //             row.is_exam_taken != '0' ?
        //             <button className='table-btn'><i className="fa-solid fa-trash"></i></button>
        //             :
        //             <span>N/A</span>
        //     ),
        // },
    ];

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(GetStudenttestRequest(obj))
    }, [])

    useEffect(() => {
        const result = testList && testList.filter(data => {
            return data.test_name?.toLowerCase().match(Namesearch?.toLowerCase())
        })
        setFilterResult(result)
    }, [Namesearch])

    useEffect(() => {

        setFilterResult(testList)
    }, [fetchacompanyReducer?.GetStudenttestResponse])

    useEffect(() => {
       
        setStudentList(studentList)

    }, [fetchacompanyReducer?.GetstudentResponse])
    
    const handleShow = () => {
        // setaddrowId(tid)
         setShow(true)
         let obj ={
            id:id
        }
         dispatch(GetStudentTestListRequest(obj))
    };

    const handleChange = (e, data) =>{
        const {name, checked} = e.target
        if (checked) {
            // if cheked and selectall checkbox add all fileds to selectedList
            if (name === "allSelect") {
              setSelectedUser(StudentList);
            } else {
              // if cheked and specific checkbox add specific field to selectedList
              setSelectedUser([...selectedUser, data]);
            }
          } else {
            // if uncheked and selectall checkbox add remove all fileds from selectedList
            if (name === "allSelect") {
              setSelectedUser([]);
            } else {
              // if uncheked and specific checkbox remove specific field from selectedList
              let tempuser = selectedUser.filter((item) => item.id !== data.id);
              setSelectedUser(tempuser);
            }
          }
    }
    

    const handleShow1 = (testId) => {
        console.log("qwerty  Test Id", testId)
        setTestId(testId)
        setShow1(true)
        dispatch(GetstudentRequest())
    };

    const DownloadHandle = (testId)=>{
        dispatch(DownloadQuestionRequest(testId))
    }

    const submitLP = () => {

    };

    const StartTimeHandle = (e) => {
        setstarttime(e.target.value)

        settime(e.target.value);
    }

    const EndTimeHandle = (e) => {
        settime(e.target.value)

        const start = new Date(`1970-01-01T${starttime}`);
        const durationInMinutes = parseInt(e.target.value, 10);
        const end = new Date(start.getTime() + durationInMinutes * 60000);
        const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
        setendtime(formattedEndtime);
    }

    const AddIndSchduleHandle = () => {

        if (TestId == '') {
            Swal.fire({
                icon: "error",
                text: 'Test Name should be must',
            });
        } else if (assessmentdate == null) {
            Swal.fire({
                icon: "error",
                text: 'Schedule Date should be must',
            });
        } else if (starttime == '') {
            Swal.fire({
                icon: "error",
                text: 'Start Time should be must',
            });
        } else if (endtime == '') {
            Swal.fire({
                icon: "error",
                text: 'End Time should be must',
            });
        } else if (time == '') {
            Swal.fire({
                icon: "error",
                text: 'Time should be must',
            });
        } else {

            var Temp = []
            selectedUser&&selectedUser.map((item)=>{
                var dataitem =  Number(item.id);
                Temp.push(dataitem);
            })


            let obj = {
                "test_id": Number(TestId),
                "student_id": [Number(id)],
                "test_date": moment(assessmentdate).format("YYYY-MM-DD"),
                "test_time": Number(time),
                "start_time": starttime,
                "end_time": endtime
            }

            dispatch(AssignScheduleRequest({ obj: obj, TestId: TestId, student_id: id, navigate: navigate }))
        }
    }

    // const AssignHandle = () => {
    //     Swal.fire({
    //         title: 'No Questions In This Assessment',
    //         text: "Please Add Questions First to Assign!",
    //         icon: 'warning',
    //     })
    // }

    return (
        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h5>Assessment</h5>
            <div className='row form-style mb-4 align-items-center'>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <input type='text' className='form-control' placeholder='Assessment Name'
                            value={Namesearch}
                            onChange={(e) => setNamesearch(e.target.value)}
                        />
                    </div>
                </div>
                {/* <div className='button-part mb-4' style={{marginBottom: '15px'}}>
                    <ul>
                        <li><button className='formBtn' onClick={handleShow}>Add Assessment</button></li>
                        <li><button className='formBtn' >Compare</button></li>
                    </ul>
        </div> */}
            </div>

            <a id="DownloadQuestion" target='_blank'></a>

            <div className='table-responsive'>
                <DataTable
                    columns={columns}
                    data={FilterResult}
                    pagination
                    highlightOnHover
                />
            </div>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Assessment Assign</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>
                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Student</label>
                            <Dropdown className='custom-dropdown-checkbox'>
                            <Dropdown.Toggle variant="success">
                                {selectedUser.length > 0 ? 'select ' + selectedUser.length : 'select student'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <ul>
                                    <li>
                                        <input type="checkbox" name="allSelect" checked={selectedUser?.length === StudentList?.length}
                                         onChange={(e) => handleChange(e, StudentList)}
                                        />
                                        <label>All Select</label>
                                    </li>
                                    {StudentList&&StudentList.map((list, i) =>{
                                        return (
                                            <li>
                                                <input type="checkbox" name={list?.first_name+list?.last_name+i} id={i} onChange={(e) => handleChange(e, list)}
                                                checked={selectedUser.some((item) => item?.id === list.id)}
                                                />
                                                <label htmlFor={i}>{list?.first_name} {list?.last_name}</label>
                                            </li>
                                        )
                                    })}
                                    
                                   
                                </ul>
                            </Dropdown.Menu>
                            </Dropdown>
                             
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Schedule Date</label>
                                <DatePicker selected={assessmentdate} onChange={(date) => { setassessmentdate(date) }} className='form-control'
                                    placeholderText="Schedule Date"
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Schedule Time</label>
                                <input type='time' className='form-control' placeholder='Start Time'
                                    value={starttime}
                                    onChange={StartTimeHandle}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Duration</label>
                                {/* <input type='number' className='form-control' placeholder='Duration (In Minutes)'
                                value={time} onChange={EndTimeHandle}
                            /> */}
                                <select className='form-control' value={time}
                                    onChange={EndTimeHandle}>
                                    <option value="">--Duration--</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                    <option value="150">150</option>
                                    <option value="180">180</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>End Time</label>
                                <input type='text' className='form-control' placeholder='End Time'
                                    value={endtime}
                                    onChange={(e) => setendtime(e.target.value)}
                                    readOnly />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddIndSchduleHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Add Assessment</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

          <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Assessment <span class="asterisk">*</span></label>
                                <select className='form-control' value={TestId} onChange={(e) => { setTestId(e.target.value)}}>
                                <option>--select--</option>
                                    {ExistingTest&&ExistingTest.map((list, index) =>{
                                        
                                        return (
                                            <option key={index} value={list?.id}>{list?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Schedule Date <span class="asterisk">*</span></label>
                                <DatePicker selected={assessmentdate} onChange={(date) => { setassessmentdate(date) }} className='form-control'
                                    placeholderText="Schedule Date"
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Schedule Time <span class="asterisk">*</span></label>
                                <input type='time' className='form-control' placeholder='Start Time'
                                    value={starttime}
                                    onChange={StartTimeHandle}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Duration <span class="asterisk">*</span></label>
                                {/* <input type='number' className='form-control' placeholder='Duration (In Minutes)'
                                value={time} onChange={EndTimeHandle}
                            /> */}
                                <select className='form-control' value={time}
                                    onChange={EndTimeHandle}>
                                    <option value="">--Duration--</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                    <option value="150">150</option>
                                    <option value="180">180</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>End Time <span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='End Time'
                                    value={endtime}
                                    onChange={(e) => setendtime(e.target.value)}
                                    readOnly />
                            </div>
                        </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={AddIndSchduleHandle}>Add</button>

        </Modal.Footer>
      </Modal>


        </div>

    )
}

export default StudentAssessment