import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Loader from '../utils/Loader';
import { useSelector, useDispatch } from 'react-redux'
import { LoginRequest } from '../redux/reducer/FetchAdminDataReducer';

const Login = () => {
    const [email, setemail] = useState('')
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const fetchReducer = useSelector((state) => state.FetchAdminDataReducer)

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };


      const dispatch = useDispatch()
      let navigate = useNavigate();


    const LoginHandler = () =>{

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (reg.test(email) === false) {
            Swal.fire({
                icon: "error",
                text: 'Email should be proper',
              });
        } else if(password == ''){
            Swal.fire({
                icon: "error",
                text: 'Password should be mandatory',
              });
        } else {

            let obj = {
                "email": email,
                "password":password,
                "company_slug": ""
            }

            dispatch(LoginRequest({obj:obj,navigate:navigate}))
        }
        
    }



  return (
    <div className='login-layout'>
    <Loader visible={fetchReducer.isloading}  />
            <div className='login-box'>
                <div className='form-group'>
                    <label>Email</label>
                    <input type='email'
                    value={email}
                    onChange={(e)=>setemail(e.target.value)}
                    className='form-control' placeholder='Enter mail-id'  />
                    <span><i className="fa-solid fa-envelope"></i></span>
                </div>
                <div className='form-group mb-5'>
                    <label>Password</label>
                    <input type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    className='form-control' placeholder='******'  />
                    <span><button onClick={togglePasswordVisibility}>
                    {!showPassword ?  <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}
                       
                    </button></span>
                </div>
                <div className='form-group'>
                    <ul className='bottom-navigation'>
                        <li> <NavLink to="/forgot-password">Forgot Password</NavLink></li>
                        <li>  <button className='theme-button' onClick={LoginHandler}>Login</button></li>
                    </ul>

              
                   
                </div>
            </div>
    </div>
  )
}

export default Login