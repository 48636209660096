import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddTrainingRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const AddTraining = () => {
    const [trainingname, settrainingname] = useState("")
    const [trainingduration, settrainingduration] = useState("")
    const [trainingdescription, settrainingdescription] = useState("")
    const [trainingvideolink, settrainingvideolink] = useState("")
    const [trainingyoutubelink, settrainingyoutubelink] = useState("")
    const [trainingexamid, settrainingexamid] = useState("")
    const [trainingexamurl, settrainingexamurl] = useState("")
    const [trainingpassmarks, settrainingpassmarks] = useState("")
    const [trainingtotalmarks, settrainingtotalmarks] = useState("")
    const [trainingdocument, settrainingdocument] = useState(null)

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetTrainingResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    function handleChange(event) {
        settrainingdocument(event.target.files[0])
      }

    const SubmitHandle = () => {
        if (trainingname == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Name should be must',
            });
        } else if (trainingduration == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Duration should be must',
            });
        } else if (trainingdescription == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Description should be must',
            });
        } else if (trainingvideolink == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Video Link should be must',
            });
        } else if (trainingyoutubelink == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Youtube Link should be must',
            });
        } else if (trainingexamid == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Exam ID should be must',
            });
        } else if (trainingexamurl == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Exam URL should be must',
            });
        } else if (trainingpassmarks == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Pass Marks should be must',
            });
        } else if (trainingtotalmarks == '') {
            Swal.fire({
                icon: "error",
                text: 'Training Total Marks should be must',
            });
        } else {

            const FormData = require('form-data');
            let data = new FormData();
            data.append('training_name', trainingname);
            data.append('training_duration', trainingduration);
            data.append('training_description', trainingdescription);
            data.append('training_video_link', trainingvideolink);
            data.append('training_youtube_link', trainingyoutubelink);
            data.append('exam_id', trainingexamid);
            data.append('exam_url', trainingexamurl);
            data.append('pass_mark', trainingpassmarks);
            data.append('total_mark', trainingtotalmarks);
            data.append('file', trainingdocument);

            dispatch(AddTrainingRequest({ data: data, navigate: navigate }))

        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Add Training</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-4'>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Name <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Name'
                                value={trainingname}
                                onChange={(e) => settrainingname(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Duration <span class="asterisk">*</span></label>
                            <input type='number' className='form-control' placeholder='Training Duration'
                                value={trainingduration}
                                onChange={(e) => settrainingduration(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Description <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Description'
                                value={trainingdescription}
                                onChange={(e) => settrainingdescription(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Video Link <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Video Link'
                                value={trainingvideolink}
                                onChange={(e) => settrainingvideolink(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Youtube Link <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Youtube Link'
                                value={trainingyoutubelink}
                                onChange={(e) => settrainingyoutubelink(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Exam ID <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Exam ID'
                                value={trainingexamid}
                                onChange={(e) => settrainingexamid(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Exam URL <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Exam URL'
                                value={trainingexamurl}
                                onChange={(e) => settrainingexamurl(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Pass Marks <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Pass Marks'
                                value={trainingpassmarks}
                                onChange={(e) => settrainingpassmarks(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Enter Training Total Marks <span class="asterisk">*</span></label>
                            <input type='text' className='form-control' placeholder='Training Total Marks'
                                value={trainingtotalmarks}
                                onChange={(e) => settrainingtotalmarks(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                        <label className='selectlabel'>Upload document</label>
                        <input type="file" className='form-control' onChange={handleChange}/>
                        </div>
                    </div>


                </div>
                <button className='formBtn' onClick={SubmitHandle}>Save</button>
            </div>

        </div>
    )
}

export default AddTraining