import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetTitleRequest, DeleteTitleRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";

const ViewTitle_CP = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var groupList = fetchacompanyReducer?.GetTitleResponse?.results

    console.log(fetchacompanyReducer.GetTitleResponse)

    const dispatch = useDispatch()
    const [filter, setFilter] = useState('');

    useEffect(() => {
        dispatch(GetTitleRequest())
    }, [])

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const filteredGroupList = groupList?.filter(item =>
        item?.name.toLowerCase().includes(filter.toLowerCase())
    );

    useEffect(() => {
        dispatch(GetTitleRequest())
    }, [fetchacompanyReducer?.DeleteTitleResponse])


    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteTitleRequest(id))

                if (fetchacompanyReducer?.DeleteTitleResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }





    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Title Management</h3>
                </div>

                <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <input className='form-control'
                                type="text"
                                style={{ width: '107%' }}
                                placeholder="Search Title"
                                value={filter}
                                onChange={handleFilterChange}
                            />
                        </li>
                        <li className='ml-3'>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/settings/Title_CP/AddTitle_CP`}><i className="fa-solid fa-plus"></i> Add Title</NavLink>
                        </li>

                    </ul>
                </div>

                {filteredGroupList && filteredGroupList.length > 0 ? (
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredGroupList.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item?.name}</td>
                                        <td>
                                            <NavLink to={`/${userinfo?.company_slug}/settings/Title_CP/EditTitle_CP/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                            <button onClick={() => DeleteHandle(item.id)}><i className="fa-solid fa-trash"></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    </div>
                ) : (
                    <h6>No data found</h6>
                )}
            </div>

        </div>
    )
}

export default ViewTitle_CP