import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { DeletestudentRequest, GetGradeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../utils/ApiConnection'


const PurchaseRegistration = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [subject, setsubject] = useState("");
    const [recurring, setrecurring] = useState("");
    const [subjectList, setsubjectList] = useState([])
    const [studentList, setstudentList] = useState([])
    const [recurringList, setrecurringList] = useState([])
    const [PurchaseList, setPurchaseList] = useState([])
    const [serviceList, setserviceList] = useState([])
    const [servicename, setservicename] = useState("");
    const [studentname, setstudentname] = useState("");
    const [sublevel, setsublevel] = useState("");
    const [sessionpermnth, setsessionpermnthname] = useState("");
    const [firstmnthsession, setfirstmnthsession] = useState("");
    const [regfee, setregfee] = useState("");
    const [assessmentfee, setassessmentfee] = useState("");
    const [monthlyrecurring, setmonthlyrecurring] = useState("");
    const [firstmnthprorate, setfirstmnthprorate] = useState("");
    const [enddate, setenddate] = useState(null)
    const [startdate, setstartdate] = useState(null)
    const [subject1, setsubject1] = useState("");
    const [recurring1, setrecurring1] = useState("");
    const [servicename1, setservicename1] = useState("");
    const [studentname1, setstudentname1] = useState("");
    const [sublevel1, setsublevel1] = useState("");
    const [sessionpermnth1, setsessionpermnthname1] = useState("");
    const [firstmnthsession1, setfirstmnthsession1] = useState("");
    const [regfee1, setregfee1] = useState("");
    const [assessmentfee1, setassessmentfee1] = useState("");
    const [monthlyrecurring1, setmonthlyrecurring1] = useState("");
    const [firstmnthprorate1, setfirstmnthprorate1] = useState("");
    const [enddate1, setenddate1] = useState(null)
    const [startdate1, setstartdate1] = useState(null)
    const [docId, setdocId] = useState(null);

    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var gradeList = fetchacompanyReducer?.GetGradeResponse?.results

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const GetSubject = async () => {
        let obj = {
            name: '',
            group_id: ''
        }
        try {
            const response = await ApiConnection.get(`subjects-crud/?server_type=production&name=${obj.name}&group_id=${obj.group_id}`)
            if (response?.status == 200) {
                console.log("get subject", response.data)
                setsubjectList(response?.data?.results)
            }
        } catch (e) { }
    }

    const GetService = async () => {
        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production`)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
            }
        } catch (e) { }
    }  

    const GetPurchaseListService = async () => {
        try {
            const response = await ApiConnection.get(`purchase_reg_crud/?server_type=production`)
            if (response?.status == 200) {
                setPurchaseList(response?.data?.results)
            }
        } catch (e) { }
    }

    const GetPricing = async () => {
        try {
            const response = await ApiConnection.get(`pricing_crud/?server_type=production`)
            if (response?.status == 200) {
                setrecurringList(response?.data?.results)
            }
        } catch (e) { }
    }  

    const GetStudent = async () => {
        try {
            const response = await ApiConnection.get(`student-crud/?server_type=production`)
            if (response?.status == 200) {
                setstudentList(response?.data?.results)
            }
        } catch (e) { }
    }

    const SubmitPurchaseHandle = async () => {
        if (studentname == '') {
            Swal.fire({
                icon: "error",
                text: 'Student Name should be must',

            });
        } else if (subject == '') {
            Swal.fire({
                icon: "error",
                text: 'Subject should be must',
            });
        } else {

            let obj = {
                "subject_id": subject,
                "service_id": servicename,
                "student_id": studentname,
                "sublevel": sublevel,
                "sessionpermnth": sessionpermnth,
                "firstmnthprorate": firstmnthprorate,
                "startdate": startdate == null ? '' : moment(startdate).format("YYYY-MM-DD"),
                "enddate": enddate == null ? '' : moment(enddate).format("YYYY-MM-DD"),
                "recurring_id": recurring,
                "firstmnthsession": firstmnthsession,
                "assessment_fee": assessmentfee,
                "reg_fee": regfee,
                "monthly_recurring": monthlyrecurring,

            }

            try {
                const response = await ApiConnection.post(`purchase_reg_crud/?server_type=production`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetPurchaseListService()
                    setShow(false)
                }
            } catch (e) { }
        }
    }

    const UpdatePurchaseHandle = async () => {
        if (studentname1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Student Name should be must',

            });
        } else if (subject1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Subject should be must',
            });
        } else {

            let obj2 = {
                id: docId,
            }

            let obj = {
                "subject_id": subject1,
                "service_id": servicename1,
                "student_id": studentname1,
                "sublevel": sublevel1,
                "sessionpermnth": sessionpermnth1,
                "firstmnthprorate": firstmnthprorate1,
                "startdate": startdate1 == null ? '' : moment(startdate1).format("YYYY-MM-DD"),
                "enddate": enddate1 == null ? '' : moment(enddate1).format("YYYY-MM-DD"),
                "recurring_id": recurring1,
                "firstmnthsession": firstmnthsession1,
                "assessment_fee": assessmentfee1,
                "reg_fee": regfee1,
                "monthly_recurring": monthlyrecurring1,

            }

            try {
                const response = await ApiConnection.put(`purchase_reg_crud/?server_type=production&id=${docId}&method=edit`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetPurchaseListService()
                    setShow(false)
                }
            } catch (e) { }
        }
    }


    useEffect(() => {
        GetSubject()
        GetService()
        GetStudent()
        GetPricing()
        GetPurchaseListService()
    }, [])

    const SubjectHandle = (e) => {
        setsubject(e.target.value)
    }

    const ServiceHandle = (e) => {
        setservicename(e.target.value)
    }

    const StudentHandle = (e) => {
        setstudentname(e.target.value)
    }
    
    const SubjectHandle1 = (e) => {
        setsubject1(e.target.value)
    }

    const ServiceHandle1 = (e) => {
        setservicename1(e.target.value)
    }

    const StudentHandle1 = (e) => {
        setstudentname1(e.target.value)
    }


    const RecurringHandle = (e) => {
        setrecurring(e.target.value)
    }

    const RecurringHandle1 = (e) => {
        setrecurring1(e.target.value)
    }

    const columns = [
        {
            name: 'Student Name',
            selector: row => row.student_first_name + ' ' + row.student_last_name,
            sortable: true,
        },
        {
            name: 'Subject',
            selector: row => row.subject_name,
            sortable: true,
        },
        {
          name: 'Subject Level',
          selector: row => row.subject_level,
          sortable: true,
        },
      {
        name: 'Session Per Month',
        selector: row => row.session_per_months,
        sortable: true,
    },
    {
        name: 'First Months Sessions',
        selector: row => row.first_month_session,
        sortable: true,
    },
    {
        name: 'Start Date',
        selector: row => moment(row.start_date).format("YYYY-MM-DD"),
        sortable: true,
    },
    {
        name: 'End Date',
        selector: row =>  moment(row.end_date).format("YYYY-MM-DD"),
        sortable: true,
    },
    {
        name: 'Recurring',
        selector: row => row.recurring,
        sortable: true,
    },
    {
        name: 'Registration Fee',
        selector: row => row.registration_fee,
        sortable: true,
    },
    {
        name: 'First Month Prorate',
        selector: row => row.first_month_prorate,
        sortable: true,
    },
    {
        name: 'Monthly Recurring',
        selector: row => row.monthly_recurring,
        sortable: true,
    },
    {
        name: 'Action',
        selector: (row) => (
            <>
            <button onClick={()=>handleShow1(row.id)} className='formBtn1'><i className="fa-solid fa-pen-to-square"></i></button>
            </>
          ),
        sortable: true,
    },
    ];

    useEffect(()=>{
        dispatch(GetGradeRequest())
    },[])

    useEffect(()=>{
      const result = PurchaseList && PurchaseList.filter(data =>{
        return data.subject_name.toLowerCase().match(search.toLowerCase())
      })
      setFilterResult(PurchaseList)
    },[search])

    useEffect(()=>{

      setFilterResult(PurchaseList)
    },[fetchacompanyReducer?.GetGradeResponse])

    const handleShow = () => {
        setShow(true)
    };

    const handleShow1 = async (id) => {
        setdocId(id)
        setShow1(true)
        
        try {
            const response = await ApiConnection.get(`purchase_reg_crud/?server_type=production&id=${id}`)
            if (response?.status == 200) {
                setPurchaseList(response?.data?.results)
                setstudentname1(response.data.results[0].student_id);
                setsubject1(response.data.results[0].subject_id);
                setsublevel1(response.data.results[0].subject_level);
                setservicename1(response.data.results[0].service_id);
                setsessionpermnthname1(response.data.results[0].session_per_months);
                setfirstmnthsession1(response.data.results[0].first_month_session);
                setstartdate1(moment(response.data.results[0].start_date).format("YYYY-MM-DD"));
                setenddate1(moment(response.data.results[0].end_date).format("YYYY-MM-DD"));
                setrecurring1(response.data.results[0].recurring);
                setregfee1(response.data.results[0].registration_fee);
                setassessmentfee1(response.data.results[0].assessment_fee);
                setfirstmnthprorate1(response.data.results[0].first_month_prorate);
                setmonthlyrecurring1(response.data.results[0].monthly_recurring);

            }
        } catch (e) { }
    };

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Purchase Registration </h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-68px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <a id="Download" download></a>

                <div className='module_list-top form-style mt-3'>
          <div className='form-group m-0 w-25'>
          <button className='formBtn1' onClick={handleShow}><i className="fa-solid fa-plus"></i> Pick a Student</button>
            </div>
            <div className='form-group m-0 w-25'>
          <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/PendingRegistration`}> Pending Registration List</NavLink>
            </div>
     </div>
       

      <div className='table-responsive'>
          <DataTable
            columns={columns}
            data={FilterResult}
            pagination
            highlightOnHover
        />
      <span style={{fontSize: '12px'}}>* Prices shown are card Prices. E-check payers get a discount.</span>
      </div>
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Purchase Registration</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                    <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Student </label>
                                <select className='form-control' value={studentname}
                                    onChange={StudentHandle}>
                                    <option value="">--Select Student---</option>
                                    {studentList && studentList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.first_name} {item?.last_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Subject </label>
                                <select className='form-control' value={subject}
                                    onChange={SubjectHandle}>
                                    <option value="">--Select Subject---</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Subject Level</label>
                                <input type='text' className='form-control' placeholder='Subject Level' 
                                    value={sublevel} onChange={(e) => { setsublevel(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Service </label>
                                <select className='form-control' value={servicename}
                                    onChange={ServiceHandle}>
                                    <option value="">--Select Service---</option>
                                    {serviceList && serviceList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Session Per Months</label>
                                <input type='text' className='form-control' placeholder='Session Per Months' 
                                    value={sessionpermnth} onChange={(e) => { setsessionpermnthname(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>First Month's Session</label>
                                <input type='text' className='form-control' placeholder='First Months Session' 
                                    value={firstmnthsession} onChange={(e) => { setfirstmnthsession(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Start Date</label>
                                <input type='date' className='form-control' placeholder='Start Date' 
                                    value={startdate} onChange={(e) => { setstartdate(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>End Date</label>
                                <input type='date' className='form-control' placeholder='End Date' 
                                    value={enddate} onChange={(e) => { setenddate(e.target.value) }}  />
                            </div>
                        </div>

                        {/* <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Recurring</label>
                            <select className='form-control' value={recurring}
                                    onChange={RecurringHandle}>
                                    <option value="">--Select Recurring---</option>
                                    {recurringList && recurringList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.recurring}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div> */}

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Registration Fee</label>
                                <input type='text' className='form-control' placeholder='Registration Fee' 
                                    value={regfee} onChange={(e) => { setregfee(e.target.value) }}  />
                            </div>
                        </div>
                        
                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Assessment Fee</label>
                                <input type='text' className='form-control' placeholder='Assessment Fee' 
                                    value={assessmentfee} onChange={(e) => { setassessmentfee(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>First Month Prorate</label>
                                <input type='text' className='form-control' placeholder='First Month Prorate' 
                                    value={firstmnthprorate} onChange={(e) => { setfirstmnthprorate(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Monthly Recurring</label>
                                <input type='text' className='form-control' placeholder='Monthly Recurring' 
                                    value={monthlyrecurring} onChange={(e) => { setmonthlyrecurring(e.target.value) }}  />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={SubmitPurchaseHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Update Purchase Registration</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                    <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Student </label>
                                <select className='form-control' value={studentname1}
                                    onChange={StudentHandle1}>
                                    <option value="">--Select Student---</option>
                                    {studentList && studentList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.first_name} {item?.last_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Subject </label>
                                <select className='form-control' value={subject1}
                                    onChange={SubjectHandle1}>
                                    <option value="">--Select Subject---</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Subject Level</label>
                                <input type='text' className='form-control' placeholder='Subject Level' 
                                    value={sublevel1} onChange={(e) => { setsublevel1(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Service </label>
                                <select className='form-control' value={servicename1}
                                    onChange={ServiceHandle1}>
                                    <option value="">--Select Service---</option>
                                    {serviceList && serviceList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Session Per Months</label>
                                <input type='text' className='form-control' placeholder='Session Per Months' 
                                    value={sessionpermnth1} onChange={(e) => { setsessionpermnthname1(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>First Month's Session</label>
                                <input type='text' className='form-control' placeholder='First Months Session' 
                                    value={firstmnthsession1} onChange={(e) => { setfirstmnthsession1(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Start Date</label>
                                <input type='date' className='form-control' placeholder='Start Date' 
                                    value={startdate1} onChange={(e) => { setstartdate1(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>End Date</label>
                                <input type='date' className='form-control' placeholder='End Date' 
                                    value={enddate1} onChange={(e) => { setenddate1(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Recurring</label>
                            <select className='form-control' value={recurring1}
                                    onChange={RecurringHandle1}>
                                    <option value="">--Select Recurring---</option>
                                    {recurringList && recurringList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.recurring}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Registration Fee</label>
                                <input type='text' className='form-control' placeholder='Registration Fee' 
                                    value={regfee1} onChange={(e) => { setregfee1(e.target.value) }}  />
                            </div>
                        </div>
                        
                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Assessment Fee</label>
                                <input type='text' className='form-control' placeholder='Assessment Fee' 
                                    value={assessmentfee1} onChange={(e) => { setassessmentfee1(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>First Month Prorate</label>
                                <input type='text' className='form-control' placeholder='First Month Prorate' 
                                    value={firstmnthprorate1} onChange={(e) => { setfirstmnthprorate1(e.target.value) }}  />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Monthly Recurring</label>
                                <input type='text' className='form-control' placeholder='Monthly Recurring' 
                                    value={monthlyrecurring1} onChange={(e) => { setmonthlyrecurring1(e.target.value) }}  />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdatePurchaseHandle}>Update</button>

                </Modal.Footer>
            </Modal>
        </div>

        
    )
}

export default PurchaseRegistration