import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { AddDocumentRequest, DeleteDocumentRequest, FetchDocumentRequest, GetDocumentRequest, UpdateDocumentRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const Documents = ({ id, etype }) => {

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [editrowId, seteditrowId] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const [documentname, setdocumentname] = useState("")
  const [document, setdocument] = useState("")
  const [documentname1, setdocumentname1] = useState("")
  const [document1, setdocument1] = useState("")
  const [FilterResult, setFilterResult] = useState([])
  const [docId, setdocId] = useState(null);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);



  let navigate = useNavigate()


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var documentList = fetchacompanyReducer?.GetDocumentResponse?.results
  var empdocumentList = fetchacompanyReducer?.GetDocumentResponse?.results
  var empdocumentView = fetchacompanyReducer?.FetchDocumentResponse?.results[0]


  console.log(fetchacompanyReducer?.FetchDocumentResponse)
  console.log(empdocumentView)
  console.log("employee id", id)

  const dispatch = useDispatch()

  useEffect(() => {
    let obj = {
      'employee_id': id,
      'student_id': '',
  }
    dispatch(FetchDocumentRequest(obj))
  }, [])

  useEffect(() => {
    var editinfo = fetchacompanyReducer?.FetchDocumentResponse?.results[0]
    console.log(editinfo)
    setdocumentname1(editinfo?.document_name)
    setdocument1(editinfo?.file_url)
  }, [fetchacompanyReducer?.FetchDocumentResponse])

  // function handleChange(event) {
  //   setdocument(event.target.files[0])
  // }

  const handleChange = (event) => {
    var file = event.target.files[0];
    setdocument(file);
  };

  function handleChange1(event) {
    setdocument1(event.target.files[0])
  }

  const columns = [
    {
      name: 'Document Name',
      selector: row => row.document_name,
      sortable: true,
    },
    {
      name: 'Created By',
      selector: row => row.employee_first_name + " " + row.employee_last_name,
    },
    {
      name: 'Last Modified',
      selector: row => row.last_modified_date_only,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink onClick={() => handleShow2(row?.id)} className="mr-3"><i className="fa-solid fa-eye"></i></NavLink>
          <NavLink onClick={() => handleShow1(row?.id)} className="mr-2"><i className="fa-solid fa-pen-to-square"></i></NavLink>
          {/* <button className='table-btn' onClick={() => handleShow1(row?.id)}><i className="fa-solid fa-pen-to-square"></i></button> */}
          <button onClick={() => DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = empdocumentList && empdocumentList.filter(data => {
      return data?.document_name.toLowerCase().match(search.toLowerCase())
    })
    setFilterResult(result)
  }, [search])

  const handleShow1 = (tid) => {
    setdocId(tid)
    setShow1(true)
    let obj = {
      'employee_id': '',
      'student_id': id,
  }
    dispatch(FetchDocumentRequest({obj:obj, rowId: tid, id: id }))
  };

  const handleShow2 = (tid) => {
    console.log("Documents RowId",tid)
    setdocId(tid)
    setShow2(true)
    let obj = {
      'employee_id': '',
      'student_id': id,
  }
    dispatch(FetchDocumentRequest({obj:obj, rowId: tid, id: id }))
  };

  useEffect(() => {
    setShow1(false)
    let obj = {
      'employee_id': '',
      'student_id': id,
  }
    dispatch(FetchDocumentRequest(obj))
  }, [fetchacompanyReducer?.UpdateDocumentResponse])

  useEffect(() => {

    setFilterResult(empdocumentList)
  }, [fetchacompanyReducer?.GetDocumentResponse])

  useEffect(() => {

    let obj = {
      'employee_id': id,
      'student_id': '',
    }
    dispatch(GetDocumentRequest(obj))
    console.log("dispatch(GetDocumentRequest(obj)", obj)
  }, [])

  useEffect(() => {
    let obj = {
      'employee_id': id,
      'student_id': '',
    }
    dispatch(GetDocumentRequest(obj))
  }, [fetchacompanyReducer?.DeleteDocumentResponse])


  const DeleteHandle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteDocumentRequest(id))

        if (fetchacompanyReducer?.DeleteDocumentResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const AddHandle = () => {

    if (documentname == '') {
      Swal.fire({
        icon: "error",
        text: 'Document Name should be must',

      });
    } else if (document == '') {
      Swal.fire({
        icon: "error",
        text: 'File should be must',
      });
    } else {

      const FormData = require('form-data');
      let data = new FormData();
      data.append('employee_id', id);
      data.append('document_name', documentname);
      data.append('file', document);

      dispatch(AddDocumentRequest({ data: data, navigate: navigate }))
    }
  }

  const UpdateHandle = () => {

    if (documentname1 == '') {
      Swal.fire({
        icon: "error",
        text: 'Document Name should be must',

      });
    } else if (document1 == '') {
      Swal.fire({
        icon: "error",
        text: 'File should be must',
      });
    } else {

      const FormData = require('form-data');
      let data = new FormData();
      data.append('employee_id', id);
      data.append('document_name', documentname1);
      data.append('file', document1);

      dispatch(UpdateDocumentRequest({ data: data, id: docId, navigate: navigate }))

    }
  }

  useEffect(() => {

    setFilterResult(empdocumentList)
  }, [fetchacompanyReducer?.GetDocumentResponse])



  return (

    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Documents</h5>


      <div className='row form-style mt-3'>
        <div className='col-lg-3'>
          <div className='form-group m-0'>
            <input type='text' className='form-control' placeholder='Documents Name'
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </div>
        </div>
        <div className='col-lg-3'>
          <button className='formBtn' onClick={handleShow}>Add Documents</button>
          {/* <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Training/AddTraining`}><i className="fa-solid fa-plus"></i> Add Training</NavLink> */}
        </div>
      </div>

      <div className='table-responsive'>
        <DataTable
          columns={columns}
          data={FilterResult}
          pagination
          highlightOnHover
          noDataComponent=""
          />
          {FilterResult?.length === 0 && (
                  <table className='custom-table'>
                      <thead>
                          <tr>
                              {columns.map((column, index) => (
                                  <th key={index}>{column.name}</th>
                              ))}
                          </tr>
                      </thead>
                      <tbody>
                          <tr>
                              <td colSpan={columns.length} style={{ textAlign: 'center' }}>There are no records to display</td>
                          </tr>
                      </tbody>
                  </table>
              )}
            </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Add Documents</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='selectlabel'>Document Name</label>
                <input type='text' className='form-control' placeholder='Document Name'
                  value={documentname}
                  onChange={(e) => setdocumentname(e.target.value)}
                />
              </div>
            </div>

            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='selectlabel'>Upload document</label>
                <input type="file" className='form-control' onChange={handleChange} />
                {/* <img src={trainingdocument} /> */}
              </div>
            </div>


          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={AddHandle}>Add</button>

        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header >
          <Modal.Title>Edit Documents</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>
            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='selectlabel'>Document Name</label>
                <input type='text' className='form-control' placeholder='Document Name'
                  value={documentname1}
                  onChange={(e) => setdocumentname1(e.target.value)}
                />
              </div>
            </div>

            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='selectlabel'>Upload document</label>
                <input type="file" className='form-control' onChange={handleChange1} />
                {/* <img src={trainingdocument} /> */}
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={UpdateHandle}>Update</button>

        </Modal.Footer>
      </Modal>


      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header >
          <Modal.Title>Documents details</Modal.Title>
          <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>
            <div className='view-page'>
              <div className='row'>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Document Name:</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empdocumentView?.document_name}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Created By:</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empdocumentView?.employee_first_name} {empdocumentView?.employee_last_name}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Last Modified:</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empdocumentView?.last_modified_date_only}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Uploaded File:</label>
                      </div>
                      <div className='col-lg-6'>
                        <b><a href={empdocumentView?.file_url} target='_blank'>Download</a></b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/students`}><i className="fa-solid fa-angle-left"></i> Back to Activities</NavLink>
                        </li>

                    </ul>
                </div> */}


          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Documents