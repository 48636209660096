import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import { GetStudentAssesmentRequest, SubmitExamRequest, TakeexamRequest } from '../redux/reducer/FetchStudentDataReducer'
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'bootstrap';
import moment from "moment";
import ApiConnection from '../utils/ApiConnection'

const AttendanceList = () => {
    const [show, setShow] = useState(false);
    const [coinearned, setcoinearned] = useState("");
    const [coinasoftoday, setcoinasoftoday] = useState("");
    const [moneyasoftoday, setmoneyasoftoday] = useState("");
    const [coinredeemed, setcoinredeemed] = useState("");
    const [remainingcoin, setremainingcoin] = useState("");
    const [remainingmoneyvalue, setremainingmoneyvalue] = useState("");
    const [EnrollmentList, setEnrollmentList] = useState([])
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const handleClose = () => setShow(false);
    const handleShow1 = () => setShow1(true);

  const fetchstudentReducer = useSelector((state) => state.FetchStudentDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))

  const dispatch = useDispatch()
  const { id } = useParams()
  let navigate = useNavigate()

  useEffect(() => {
      dispatch(GetStudentAssesmentRequest())
  }, [])

  const GetEnrollmentList = async () => {

    try {
        const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${userinfo?.user_id}`)
        // const response = await ApiConnection.get(`stripe-payment-list/`)
        if (response?.status == 200) {
            console.log("get Enrollment", response.data.results)
            setEnrollmentList(response?.data?.results)
        }
    } catch (e) { }
}

useEffect(() => {
    GetEnrollmentList();
}, [])

  const CancelHandle = async (srvid) => {
    Swal.fire({
       title: 'Are you sure?',
       text: "You want to cancel this Service!",
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, Cancel it!'
   }).then(async (result) => {
       if (result.isConfirmed) {
           const response = await ApiConnection.post(`service-subscription-cancel/?server_type=production&student_id=${id}&service_id=${srvid}`)
           console.log(srvid, id)
           if (response?.status == 200) {
               Swal.fire(
                   'Cancelled!',
                   'Your Service has been Cancelled.',
                   'success'
               )
               GetEnrollmentList();
           }

       }
   })
};


  return (
        <div className='common-layout'>
            <Loader visible={fetchstudentReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Rewards </h3>
                </div>

                <div className='row form-style mb-4 d-flex'>

<div className='col-lg-6 '>
    <div className='form-group'>
        <div className='row align-items-left'>
            <div className='col-lg-6'>
                <label className='d-block text-right mt-1'>Add Coins </label>
            </div>
            <div className='col-lg-6'>
                <input type='number' className='form-control' value={coinearned}
                onChange={(e) => { setcoinearned(e.target.value) }} placeholder='Coin Earned in Session'/>
            </div>
            {/* <div className='col-lg-3'>
<i className="fa-solid fa-pen-to-square"></i>
</div> */}
        </div>
    </div>
</div>

<div className='col-lg-6 '>
    <div className='form-group'>
        <div className='row align-items-left'>
            <div className='col-lg-6'>
                <label className='d-block text-right mt-1'>Coins Redeemed in Session</label>
            </div>
            <div className='col-lg-6'>
                <input type='number' className='form-control' value={coinredeemed}
                onChange={(e) => { setcoinredeemed(e.target.value) }} placeholder='Coins Redeemed in Session'/>
            </div>
        </div>
    </div>
</div>

<div className='col-lg-6 '>
    <div className='form-group'>
        <div className='row align-items-left'>
            <div className='col-lg-6'>
                <label className='d-block text-right mt-1'>Total Coins as of Today</label>                                                        </div>
            <div className='col-lg-6'>
                <input type='number' className='form-control' value={coinasoftoday}
                onChange={(e) => { setcoinasoftoday(e.target.value) }} placeholder='Total Coins as of Today'/>
            </div>
        </div>
    </div>
</div>

<div className='col-lg-6 '>
    <div className='form-group'>
        <div className='row align-items-left'>
            <div className='col-lg-6'>
                <label className='d-block text-right mt-1'>Remaining Coins</label>
            </div>
            <div className='col-lg-6'>
                <input type='number' className='form-control' value={remainingcoin}
                onChange={(e) => { setremainingcoin(e.target.value) }} placeholder='Remaining Coins'/>
            </div>
        </div>
    </div>
</div>

<div className='col-lg-6 '>
    <div className='form-group'>
        <div className='row align-items-left'>
            <div className='col-lg-6'>
                <label className='d-block text-right mt-1'>Total Money as of Today</label>
            </div>
            <div className='col-lg-6'>
                <input type='number' className='form-control' value={moneyasoftoday}
                onChange={(e) => { setmoneyasoftoday(e.target.value) }} placeholder='Total Money as of Today'/>
            </div>
        </div>
    </div>
</div>

<div className='col-lg-6 '>
    <div className='form-group'>
        <div className='row align-items-left'>
            <div className='col-lg-6'>
                <label className='d-block text-right mt-1'>Remaining Money Value</label>
            </div>
            <div className='col-lg-6'>
                <input type='number' className='form-control' value={remainingmoneyvalue}
                onChange={(e) => { setremainingmoneyvalue(e.target.value) }} placeholder='Remaining Money as of Today'/>
            </div>
        </div>
    </div>
</div>

</div>


            </div>
        </div>
  )
}

export default AttendanceList