import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddTitleRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import ReactSwitch from 'react-switch';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../../utils/ApiConnection'


const AddRolePermission = () => {
    const [tname, settname] = useState("")
    const [checked, setChecked] = useState({});
    const [usertype, setusertype] = useState("");

    const [Sidemenus, setSidemenus] = useState([])

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetTitleResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    // const Sidemenus = [
    //     {
    //         id: "1",
    //         menuname: "Home",
    //         Permissiondetails: {
    //             view: 1,
    //             add: 1,
    //             edit: 0,
    //             delete: 1,
    //             export: 1,
    //         }
    //     },
    //     {
    //         id: "2",
    //         menuname: "Employees",
    //         empmenu: [
    //             {
    //                 id: "28",
    //                 empname: "Location",
    //             },
    //             {
    //                 id: "29",
    //                 empname: "Virtual Location",
    //             },
    //             {
    //                 id: "30",
    //                 empname: "Training",
    //             },
    //             {
    //                 id: "31",
    //                 empname: "Class/Subject",
    //             },
    //             {
    //                 id: "32",
    //                 empname: "Attendance",
    //             },
    //             {
    //                 id: "33",
    //                 empname: "Document",
    //             },
    //             {
    //                 id: "34",
    //                 empname: "Activities",
    //             },
    //             {
    //                 id: "35",
    //                 empname: "Schedule",
    //             }
    //         ]
    //     },
    //     {
    //         id: "3",
    //         menuname: "Prospects",
    //         empmenu: [
    //             {
    //                 id: "44",
    //                 empname: "Guardians",
    //             },
    //             {
    //                 id: "45",
    //                 empname: "Students",
    //             }
    //         ]
    //     },
    //     {
    //         id: "4",
    //         menuname: "Customers",
    //     },
    //     {
    //         id: "5",
    //         menuname: "Student",
    //         empmenu: [
    //             {
    //                 id: "36",
    //                 empname: "Assessments",
    //             },
    //             {
    //                 id: "37",
    //                 empname: "Subscriptions",
    //             },
    //             {
    //                 id: "38",
    //                 empname: "Attendance",
    //             },
    //             {
    //                 id: "39",
    //                 empname: "Rewards",
    //             },
    //             {
    //                 id: "40",
    //                 empname: "Documents",
    //             },
    //             {
    //                 id: "41",
    //                 empname: "Activities",
    //             },
    //             {
    //                 id: "42",
    //                 empname: "Teacher",
    //             },
    //             {
    //                 id: "43",
    //                 empname: "Schedule",
    //             }
    //         ]
    //     },
    //     {
    //         id: "6",
    //         menuname: "Curriculam",
    //         submenu: [
    //             {
    //                 id: "17",
    //                 name: "Group",
    //             },
    //             {
    //                 id: "18",
    //                 name: "Grade",
    //             },
    //             {
    //                 id: "19",
    //                 name: "Subject",
    //             },
    //             {
    //                 id: "20",
    //                 name: "Syllabus",
    //                 childmenu: [
    //                     {
    //                         id: "26",
    //                         name: "Module",
    //                     },
    //                     {
    //                         id: "27",
    //                         name: "Topic",
    //                     },
    //                 ]
    //             }
    //         ]
    //     },
    //     {
    //         id: "7",
    //         menuname: "Service & Pricing",
    //         empmenu: [
    //             {
    //                 id: "46",
    //                 empname: "Service & Pricing Setup",
    //             },
    //             {
    //                 id: "47",
    //                 empname: "Purchase Registration",
    //             },
    //             {
    //                 id: "48",
    //                 empname: "Membership Setup",
    //             }
    //         ]
    //     },
    //     {
    //         id: "8",
    //         menuname: "Payment",
    //     },
    //     {
    //         id: "9",
    //         menuname: "Tasks",
    //     },
    //     {
    //         id: "10",
    //         menuname: "Documents",
    //     },
    //     {
    //         id: "11",
    //         menuname: "Schedule",
    //     },
    //     {
    //         id: "12",
    //         menuname: "Reports",
    //     },
    //     {
    //         id: "13",
    //         menuname: "Locations",
    //     },
    //     {
    //         id: "14",
    //         menuname: "Settings",
    //         submenu: [
    //             {
    //                 id: "21",
    //                 name: "Title",
    //             },
    //             {
    //                 id: "22",
    //                 name: "Background Check...",
    //             },
    //             {
    //                 id: "23",
    //                 name: "Module Type",
    //             },
    //             {
    //                 id: "24",
    //                 name: "Profile",
    //             },
    //             {
    //                 id: "25",
    //                 name: "Email Templ...",
    //             },
    //         ]
    //     },
    //     {
    //         id: "15",
    //         menuname: "Training",
    //     },
    //     {
    //         id: "16",
    //         menuname: "Assessment",
    //     },
    // ]

    useEffect(() => {
        GetTermsofServices()
    }, [])

    const GetTermsofServices = async () => {
        try {
            const response = await ApiConnection.get(`users/menu-submenu-tabmenu-crud/api/?server_type=production&user_type=${userinfo?.user_type}`)
            console.log(response)
            if (response?.status == 200) {
                setSidemenus(response?.data?.results)
                // console.log(Sidemenus);
            }
        } catch (e) { }
    }

    const handleChange = (event, itemId, permission) => {
        setChecked(prevState => ({
          ...prevState,
          [itemId]: {
            ...prevState[itemId],
            [permission]: event.target.checked,
          },
        }));
      };
    
      const convertBooleanToNumber = (obj) => {
        const newObj = {};
        for (const key in obj) {
          newObj[key] = obj[key] ? 1 : 0;
        }
        return newObj;
      };
    
      const ensureAllPermissions = (permissions) => {
        const allPermissions = {
          is_add: 0,
          is_view: 0,
          is_export: 0,
          is_delete: 0,
          is_edit: 0,
          ...permissions,
        };
        return convertBooleanToNumber(allPermissions);
      };
    
      const handleSubmit = async (event) => {
        event.preventDefault();

        if(usertype == ''){
            Swal.fire({
                icon: "error",
                text: 'UserType should be must',
              });
            } else {
    
        const dataToSend = Sidemenus.map(item => ({
          ...item,
          menu_user_permission_list: ensureAllPermissions(checked[item.id] || item.menu_user_permission_list),
          tab_menu_list: item.tab_menu_list.map(tab => ({
            ...tab,
            tab_menu_user_permission_list: ensureAllPermissions(checked[tab.tab_menu_id] || tab.tab_menu_user_permission_list),
          })),
          sub_menu_list: item.sub_menu_list.map(sub => ({
            ...sub,
            sub_menu_user_permission_list: ensureAllPermissions(checked[sub.sub_menu_id] || sub.sub_menu_user_permission_list),
            sub_tab_menu_list: sub.sub_tab_menu_list.map(subTab => ({
              ...subTab,
              sub_tab_menu_user_permission_list: ensureAllPermissions(checked[subTab.tab_menu_id] || subTab.sub_tab_menu_user_permission_list),
            })),
          })),
        }));

        let obj = {
            user_type: usertype,
            results: dataToSend,
          };
    
        try {
            const response = await ApiConnection.post(`users/menu-submenu-tabmenu-crud/api/?server_type=production`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
                // GetService()
            }
        } catch (error) {
          console.error('Error:', error);
        }
    }
      };


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Role Permission Management</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '907px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <div className='row form-style mb-1'>
                    <div className='col-lg-12'>
                        <div className='form-group'>
                        {/* <form onSubmit={handleSubmit}> */}
                        <div className='col-lg-3 main-div'>
                            <div className='form-group'>
                                <label className='selectlabel' style={{fontWeight:'500'}}>Usertype</label>
                                    <select className='form-control' value={usertype}
                                    onChange={(e) => { setusertype(e.target.value) }}>
                                    <option value="">--Select User Type---</option>
                                    <option value="COMPANY">Company</option>
                                    <option value="EMPLOYEE">Employee</option>
                                    <option value="STUDENT">Student</option>
                                    <option value="PROSPECT">Prospect</option>
                                    <option value="GUARDIAN">Guardian</option>
                                </select>
                            </div>
                        </div>
                        <Accordion defaultActiveKey="0" className='view-accordian'>
        {Sidemenus.map((item, index) => (
          <Accordion.Item eventKey={index.toString()} id={index.toString()} key={item?.id}>
            <Accordion.Header style={{ backgroundColor: '#abe2fa', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
              <i className="fas fa-chalkboard-teacher"></i>
              <span className='ml-2'>{item?.menu_name}</span>
            </Accordion.Header>
            <Accordion.Body>
              <div key={item?.id} className='menu-item'>
                <div className='checkbox-row d-flex'>
                  {Object.entries(item?.menu_user_permission_list).map(([permission, value]) => (
                    <div className='col-lg-1' key={permission}>
                      <div className='form-group d-flex align-item-center'>
                        <input
                          type='checkbox'
                          checked={checked[item?.id]?.[permission] || value}
                          onChange={(e) => handleChange(e, item?.id, permission)}
                        />
                        <label className='selectlabel ml-2 mt-1'>{permission.charAt(3).toUpperCase() + permission.slice(4)}</label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {checked[item.id]?.is_view && (
                <>
                  {item.tab_menu_list.length > 0 && (
                    <Accordion defaultActiveKey="0" className='view-accordian' style={{ width: '90%', marginLeft: '90px', marginTop: '-20px', marginBottom: '25px' }}>
                      {item.tab_menu_list.map((tab, tabindex) => (
                        <Accordion.Item eventKey={tabindex.toString()} id={tabindex.toString()} key={tab?.tab_menu_id}>
                          <Accordion.Header style={{ backgroundColor: 'rgb(248 250 171)', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
                            <i className="fas fa-chalkboard-teacher"></i>
                            <span className='ml-2'>{tab?.tab_menu_name}</span>
                          </Accordion.Header>
                          <Accordion.Body style={{ marginBottom: '-20px' }}>
                            <div key={tab?.tab_menu_id} className='menu-item'>
                              <div className='checkbox-row d-flex'>
                                {Object.entries(tab?.tab_menu_user_permission_list).map(([permission, value]) => (
                                  <div className='col-lg-1' key={permission}>
                                    <div className='form-group d-flex align-item-center'>
                                      <input
                                        type='checkbox'
                                        checked={checked[tab.tab_menu_id]?.[permission] || value}
                                        onChange={(e) => handleChange(e, tab.tab_menu_id, permission)}
                                      />
                                      <label className='selectlabel ml-2 mt-1'>{permission.charAt(3).toUpperCase() + permission.slice(4)}</label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                  {item.sub_menu_list.length > 0 && (
                    <Accordion defaultActiveKey="0" className='view-accordian' style={{ width: '90%', marginLeft: '90px', marginTop: '-20px', marginBottom: '25px' }}>
                      {item.sub_menu_list.map((sub, subindex) => (
                        <Accordion.Item eventKey={subindex.toString()} id={subindex.toString()} key={sub?.sub_menu_id}>
                          <Accordion.Header style={{ backgroundColor: 'rgb(248 250 171)', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
                            <i className="fas fa-chalkboard-teacher"></i>
                            <span className='ml-2'>{sub?.sub_menu_name}</span>
                          </Accordion.Header>
                          <Accordion.Body style={{ marginBottom: '-20px' }}>
                            <div key={sub?.sub_menu_id} className='menu-item'>
                              <div className='checkbox-row d-flex'>
                                {Object.entries(sub?.sub_menu_user_permission_list).map(([permission, value]) => (
                                  <div className='col-lg-1' key={permission}>
                                    <div className='form-group d-flex align-item-center'>
                                      <input
                                        type='checkbox'
                                        checked={checked[sub?.sub_menu_id]?.[permission] || value}
                                        onChange={(e) => handleChange(e, sub?.sub_menu_id, permission)}
                                      />
                                      <label className='selectlabel ml-2 mt-1'>{permission.charAt(3).toUpperCase() + permission.slice(4)}</label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              {sub.sub_tab_menu_list.length > 0 && checked[sub.id]?.is_view && (
                                <Accordion defaultActiveKey="0" className='view-accordian' style={{ width: '90%', marginLeft: '90px', marginTop: '-20px', marginBottom: '25px' }}>
                                  {sub.sub_tab_menu_list.map((subTab, subTabIndex) => (
                                    <Accordion.Item eventKey={subTabIndex.toString()} id={subTabIndex.toString()} key={subTab?.tab_menu_id}>
                                      <Accordion.Header style={{ backgroundColor: 'rgb(255 227 202)', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'>
                                        <i className="fas fa-chalkboard-teacher"></i>
                                        <span className='ml-2'>{subTab?.tab_menu_name}</span>
                                      </Accordion.Header>
                                      <Accordion.Body style={{ marginBottom: '-20px' }}>
                                        <div key={subTab?.tab_menu_id} className='menu-item'>
                                          <div className='checkbox-row d-flex'>
                                            {Object.entries(subTab?.sub_tab_menu_user_permission_list).map(([permission, value]) => (
                                              <div className='col-lg-1' key={permission}>
                                                <div className='form-group d-flex align-item-center'>
                                                  <input
                                                    type='checkbox'
                                                    checked={checked[subTab?.tab_menu_id]?.[permission] || value}
                                                    onChange={(e) => handleChange(e, subTab?.tab_menu_id, permission)}
                                                  />
                                                  <label className='selectlabel ml-2 mt-1'>{permission.charAt(3).toUpperCase() + permission.slice(4)}</label>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  ))}
                                </Accordion>
                              )}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  )}
                </>
              )}
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    {/* <button className='formBtn' type="submit">Submit</button>
    </form> */}
                        </div>
                    </div>
                </div>
                {/* <button className='formBtn float-right mb-2' onClick={handleSubmit}>Save</button> */}
            </div>

        </div >
    )
}

export default AddRolePermission