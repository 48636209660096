import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { DeleteattendanceRequest, UpdateattendanceRequest, AddattendanceRequest, GetattendanceRequest, FetchattendanceRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ApiConnection from '../../utils/ApiConnection'

const EmployeeAttendance = ({ etype }) => {

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [editrowId, seteditrowId] = useState(null)
  const [trainingmodule, settrainingmodule] = useState("")
  const [trainingmodule1, settrainingmodule1] = useState("")
  const [clockin, setclockin] = useState("")
  const [clockin1, setclockin1] = useState("")
  const [clockout, setclockout] = useState("")
  const [clockout1, setclockout1] = useState("")
  const [systemin, setsystemin] = useState("")
  const [systemin1, setsystemin1] = useState("")
  const [systemout, setsystemout] = useState("")
  const [systemout1, setsystemout1] = useState("")
  const [punchdate, setpunchdate] = useState("")
  const [punchdate1, setpunchdate1] = useState("")
  const [remarks, setremarks] = useState("")
  const [remarks1, setremarks1] = useState("")
  const [attendanceList, setattendanceList] = useState([])


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const [FilterResult, setFilterResult] = useState([])

  let navigate = useNavigate()
  const { id } = useParams()


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  // var attendanceList = fetchacompanyReducer?.GetattendanceResponse?.results
  var attendanceView = fetchacompanyReducer?.FetchattendanceResponse?.results[0]


  console.log(fetchacompanyReducer?.FetchattendanceResponse)
  console.log(attendanceView)
  // console.log(attendanceList)
  console.log("employee id", id)

  const dispatch = useDispatch()

  useEffect(() => {
    var editinfo = fetchacompanyReducer?.FetchattendanceResponse?.results[0]
    console.log(editinfo)

    const convertedTime = () => {
      const hours = Math.floor(clockin1 / 3600000);
      console.log("hours", hours)
      const minutes = Math.floor((clockin1 % 3600000) / 60);
      setclockin1(`${hours} hours ${minutes} minutes`);
    }

    setclockin1(editinfo?.login_time)
    setclockout1(editinfo?.logout_time)
  }, [fetchacompanyReducer?.FetchattendanceResponse])

  const handleShow1 = async (tid,did) => {
    seteditrowId(tid)
    setShow1(true)
    try {
      const response = await ApiConnection.get(`attendance-crud/?server_type=production&employee_id=${id}&id=${tid}&attendance_date=${did}`);
      if (response?.status === 200) {
        console.log(response?.data?.results[0]?.login_time);
        setpunchdate1(response?.data?.results[0]?.attendance_date)
        const loginTime = response?.data?.results[0]?.login_time;
        const hours = Math.floor(loginTime / 3600);
        const minutes = Math.floor((loginTime % 3600) / 60); // Corrected to 60000 for minutes
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
        setclockin1(formattedTime);
        const logoutTime = response?.data?.results[0]?.logout_time;
        const hours1 = Math.floor(logoutTime / 3600);
        const minutes1 = Math.floor((logoutTime % 3600) / 60); // Corrected to 60000 for minutes
        const formattedTime1 = `${String(hours1).padStart(2, '0')}:${String(minutes1).padStart(2, '0')}`;
        setclockout1(formattedTime1)
        setremarks1(response?.data?.results[0]?.remarks)
      }
  } catch (e) {}  
};

  const DeleteHandle = (tid,did) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {

        try {
          const response = await ApiConnection.put(`attendance-crud/?server_type=production&employee_id=${id}&id=${tid}&attendance_date=${did}&method=delete`);
          if (response?.status == 200) {
              Swal.fire({
                  icon: "success",
                  text: response?.data?.msg,
              });
              GetTeacherAttendance()
            }
      } catch (e) { } 
        if (fetchacompanyReducer?.DeleteattendanceResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const AddHandle = async () => {

    if (clockin == '') {
      Swal.fire({
        icon: "error",
        text: 'Clock In should be must',
      });
    } else if (clockout <= clockin) {
        Swal.fire({
          icon: "error",
          text: 'Clock Out should be greater than Clock In',
        });
    } else {
      let obj = {
        "attendance_date": punchdate,
        "login_time": clockin,
        "logout_time": clockout,
        "remarks": remarks,
      }

      try {
        const response = await ApiConnection.post(`attendance-crud/?server_type=production&employee_id=${id}`, obj);
        if (response?.status == 200) {
            Swal.fire({
                icon: "success",
                text: response?.data?.msg,
            });
            setShow(false)
            GetTeacherAttendance()
          }
    } catch (e) { }    }
  }

  const UpdateHandle = async () => {

    if (clockout1 == '') {
      Swal.fire({
        icon: "error",
        text: 'Clock Out should be must',
      });
    } else if (clockout1 <= clockin1) {
      Swal.fire({
        icon: "error",
        text: 'Clock Out should be greater than Clock In',
      });
    } else {

      let obj = {
        "attendance_date": punchdate1,
        "login_time": clockin1,
        "logout_time": clockout1,
        "remarks": remarks1,
      }

      try {
        const response = await ApiConnection.put(`attendance-crud/?server_type=production&employee_id=${id}&id=${editrowId}&method=edit`, obj);
        if (response?.status == 200) {
            Swal.fire({
                icon: "success",
                text: response?.data?.msg,
            });
            setShow1(false)
            GetTeacherAttendance()
          }
    } catch (e) { } 
    }
  }

  useEffect(() => {
    GetTeacherAttendance()
}, [])

  const GetTeacherAttendance = async () => {
    try {
        const response = await ApiConnection.get(`attendance-crud/?server_type=production&employee_id=${id}`);
        if (response?.status === 200) {
          setattendanceList(response?.data?.results);
        }
    } catch (e) {
    }
}

  useEffect(() => {

    setFilterResult(attendanceList)
  }, [fetchacompanyReducer?.GetattendanceResponse])



  return (

    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Attendance List</h5>
      <hr></hr>

      {/* <div className='row form-style mt-3'>
        <div className='col-lg-3'>
          <div className='form-group m-0'>
            <input type='text' className='form-control' placeholder='Attendance Name'
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </div>
        </div>
      </div> */}
                      
      {userinfo?.user_type == "COMPANY" &&
      <div className='col-lg-4'>
        <button className='formBtn mb-4' onClick={handleShow}>Add Attendance</button>
      </div>
      }

        <div className='table-responsive'>
          <table className='custom-table'>
            <thead>
              <tr className='text-center'>
                <th>Date</th>
                <th>Clock In</th>
                <th>Clock Out</th>
                <th>Remarks</th>
                {userinfo?.user_type == "COMPANY" &&
                <th>Action</th>
                }
              </tr>
            </thead>
            {attendanceList && attendanceList.length > 0 ?
            <tbody>
{attendanceList.filter(item => item?.time && item?.time.length > 0).map((item, i) => (
                  <tr key={i}>
                      <td className='text-center'>{moment(new Date(item?.attendance_date)).format("DD-MM-YYYY")}</td>
                      <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                        return (
                          <span className='timeseparate'>{list?.login_time}</span>
                        )
                      })}</td>
                      <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                        return (
                          <span className='timeseparate'>{list?.logout_time}</span>
                        )
                      })}</td>
                      <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                        return (
                          <span className='timeseparate'>{list?.remarks}</span>
                        )
                      })}</td>
                      {userinfo?.user_type == "COMPANY" &&
                      <td className='text-center'>{item?.time && item?.time.map((list, index) => {
                        return (
                          <span className='timeseparate'>
                            <NavLink className='mr-2' onClick={() => handleShow1(list?.id, item?.attendance_date)}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                            <button onClick={() => DeleteHandle(list?.id, item?.attendance_date)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
                        </span>
                        )
                      })}
                      </td>
                      }
                  </tr>
                )
              )}
            </tbody>
            :
            <tbody>
            <tr>
                <td colSpan={5} style={{ textAlign: 'center' }}>There are no records to display</td>
            </tr>
        </tbody>          }    
          </table>
        </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Add Attendance Information</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>
          <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Date</label>
                <input type='date' className='form-control' value={punchdate}
                  onChange={(e) => setpunchdate(e.target.value)} placeholder='Punch Date' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock In Time</label>
                <input type='time' className='form-control' value={clockin}
                  onChange={(e) => setclockin(e.target.value)} placeholder='Clock In Time' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock Out Time</label>
                <input type='time' className='form-control' value={clockout}
                  onChange={(e) => setclockout(e.target.value)} placeholder='Clock Out Time' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Remarks</label>
                <input type='text' className='form-control' value={remarks}
                  onChange={(e) => setremarks(e.target.value)} placeholder='Remarks' />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={AddHandle}>Add</button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header >
          <Modal.Title>Edit Attendance Information</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>
          <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Date</label>
                <input type='date' className='form-control' value={punchdate1}
                  onChange={(e) => setpunchdate1(e.target.value)} placeholder='Punch Date' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock In Time</label>
                <input type='time' className='form-control' value={clockin1}
                  onChange={(e) => setclockin1(e.target.value)} placeholder='Clock In Time' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Clock Out Time</label>
                <input type='time' className='form-control' value={clockout1}
                  onChange={(e) => setclockout1(e.target.value)} placeholder='Clock Out Time' />
              </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Remarks</label>
                <input type='text' className='form-control' value={remarks1}
                  onChange={(e) => setremarks1(e.target.value)} placeholder='Remarks' />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={UpdateHandle}>Update</button>

        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default EmployeeAttendance