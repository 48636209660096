import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, NavLink, useLocation } from 'react-router-dom'
import { ViewstudentRequest, FetchstudentRequest, FetchSchoolRequest, GetTeacherRequest, GetLearningPlanRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import moment from "moment";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import StudentAssessment from './StudentAssessment';
import StudentEnrollment from './StudentEnrollment';
import StudentAttendance from './studentAttendance';
import StudentRewards from './studentRewards';
import StudentTeacher from './StudentTeacher';
import StudentDocuments from './StudentDocuments';
import StudentActivities from './StudentActivities';
import StudentSchedule from './scheduleList'


const ViewStudentsDetails = () => {
    const [key, setKey] = useState('assessments');
    const [detailsrowId, setdetailsrowId] = useState(null)
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const { id, pros } = useParams()
    const location = useLocation()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var viewdata = fetchacompanyReducer?.FetchstudentResponse?.results[0]
    var schoolList = fetchacompanyReducer?.FetchSchoolResponse?.results[0]
    var learningpathlist = fetchacompanyReducer?.GetLearningPlanResponse?.results[0]

    console.log(fetchacompanyReducer.GetLearningPlanResponse?.results)
    console.log("Viewlearningpathlist", learningpathlist)


    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchstudentRequest(obj))
    }, [])

    useEffect(() => {
        dispatch(GetTeacherRequest())
      }, [])

    const handleShow2 = (id) => {
        console.log(id)
        setdetailsrowId(id)
        setShow2(true)
        dispatch(FetchSchoolRequest({ id: id }))
    };


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Student Management</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-68px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                {/* <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h6>Enrollment Status  : <b style={{color: 'greenyellow'}}>Pre-Enrolled</b> </h6>
            <div className='d-flex' style={{marginTop: '40px'}}>
            <div className='col-lg-4'>
                            <div className='form-group'>
                                    <div className='col-lg-12'>
                                        <label className='d-block'>Enrollment Length of Stay : 0</label>
                                        
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                    <div className='col-lg-10'>
                                        <label className='d-block'>Student Length of Stay : 0</label>
                                 
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                    <div className='col-lg-12'>
                                        <label className='d-block'>Enrollment Contract of Stay : 0</label>
                                   
                                </div>
                            </div>
                        </div>
            </div>
            </div> */}

            <hr></hr>

                <div className='view-page'>
                    <div className='form-group'>
                        <h6>Student information</h6>
                    </div>
                    <div className='form-group'>
                        <hr />
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>First Name:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.first_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Last Name:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.last_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Email:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.email}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Username:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.first_name.toLowerCase()}.{viewdata?.last_name.toLowerCase()}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Enrollment Status:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.enrollment_status}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Gender:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.gender}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Grade:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.grade_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>School Year:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.school_year}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Date of Birth:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{moment(viewdata?.date_of_birth).format('DD-MM-YYYY')}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Description:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.student_description}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Medical Information:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.medical_information}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Students Notes:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.students_notes}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Notes from Center Director:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.notes_from_center_director}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>School Subject Matter:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.school_subject_matter}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>School:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.school_name}  -  </b>
                                        <NavLink onClick={() => handleShow2(viewdata?.school_id)} className="mr-2"><u>View Details</u></NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <Modal show={show2} onHide={handleClose2} size="lg">
                    <Modal.Header >
                        <Modal.Title>School details</Modal.Title>
                        <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row form-style mb-4'>

                            <div className='view-page'>

                                <div className='row'>
                                    <div className='col-lg-6'>
                                        <div className='form-group'>
                                            <div className='row align-items-center'>
                                                <div className='col-lg-6'>
                                                    <label className='d-block text-right'>School Name :</label>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <b>{schoolList?.name}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className='form-group'>
                                            <div className='row align-items-center'>
                                                <div className='col-lg-6'>
                                                    <label className='d-block text-right'>Phone No. :</label>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <b>{schoolList?.phone_no}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className='form-group'>
                                            <div className='row align-items-center'>
                                                <div className='col-lg-6'>
                                                    <label className='d-block text-right'>Email :</label>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <b>{schoolList?.email}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className='form-group'>
                                            <div className='row align-items-center'>
                                                <div className='col-lg-6'>
                                                    <label className='d-block text-right'>Address :</label>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <b>{schoolList?.address}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-6'>
                                        <div className='form-group'>
                                            <div className='row align-items-center'>
                                                <div className='col-lg-6'>
                                                    <label className='d-block text-right'>Contact Person :</label>
                                                </div>
                                                <div className='col-lg-6'>
                                                    <b>{schoolList?.contact_person_name}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>

                        </div>
                    </Modal.Body>
                </Modal>

                <div className='form-group mt-5'>
                    <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="assessments" title="Assessments">
                            <StudentAssessment id={id} />
                        </Tab>
                        <Tab eventKey="enrollments" title="Subscriptions">
                            <StudentEnrollment id={id} />
                        </Tab>                         
                        <Tab eventKey="attendance" title="Attendance">
                            <StudentAttendance id={id} />
                        </Tab>         
                        <Tab eventKey="studentdigitalrewards" title="Rewards">
                            <StudentRewards id={id} />
                        </Tab> 
                        <Tab eventKey="documents" title="Documents">
                            <StudentDocuments id={id} />
                        </Tab>
                        <Tab eventKey="activities" title="Activities">
                            <StudentActivities id={id} />
                        </Tab>                 
                        <Tab eventKey="teacher" title="Teacher">
                            <StudentTeacher id={id} />
                        </Tab>
                        <Tab eventKey="schedule" title="Schedule">
                            <StudentSchedule id={id} />
                        </Tab>
                    </Tabs>
                </div>

            </div>

        </div>
    )
}

export default ViewStudentsDetails