import React from 'react'

const AdminDashboard = () => {
  return (
    <div className='common-layout'>
        <div className='innerlayout p-3'>
                 Dashboard
        </div>
    </div>
  )
}

export default AdminDashboard