import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, GetEmployeeRequest, GetStudentListRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer, Views, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../utils/ApiConnection'
import ReactSwitch from 'react-switch';


const Schedule_List = () => {

  const localizer = momentLocalizer(moment)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var studentteacherList = fetchacompanyReducer?.GetTeacherResponse?.results
  var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
  var SubList = fetchacompanyReducer?.GetSubjectResponse?.results
  var EmployeeList = fetchacompanyReducer?.GetEmployeeResponse?.results
  const locationReducer = useSelector((state) => state.LocationSlice)
  let locationId = localStorage.getItem("locationId");

  const { id } = useParams()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const timeSlots = [
    '08:00 - 08:30',
    '08:30 - 09:00',
    '09:00 - 09:30',
    '09:30 - 10:00',
    '10:00 - 10:30',
    '10:30 - 11:00',
    '11:00 - 11:30',
    '11:30 - 12:00',
    '12:00 - 12:30',
    '12:30 - 01:00',
    '01:00 - 01:30',
    '01:30 - 02:00',
    '02:00 - 02:30',
    '02:30 - 03:00',
    '03:00 - 03:30',
    '03:30 - 04:00',
  ];

  const events = [
    {
      title: 'Math',
      start: new Date(2024, 4, 20, 10, 0),
      end: new Date(2024, 4, 20, 12, 0),
      hexColor: 'green',
    },
    {
      title: 'History',
      start: new Date(2024, 4, 22, 11, 0),
      end: new Date(2024, 4, 22, 15, 0),
      hexColor: 'red',
    },
  ];

  const [grade, setgrade] = useState("")
  const [teacher, setteacher] = useState("")
  const [lpList, setlpList] = useState("")
  const [show1, setShow1] = useState(false);
  const [checked, setChecked] = useState(false);

  const [serviceList, setserviceList] = useState([])
  const [AvilScheduleList, setAvilScheduleList] = useState([])
  const [EndTimeList, setEndTimeList] = useState([])
  const [scheduleList, setscheduleList] = useState([])
  const [time, settime] = useState(null)
  const [studentList, setstudentList] = useState([])
  const [student, setstudent] = useState("")
  const [repeattype, setrepeattype] = useState("")
  const [employeeName, setemployeeName] = useState("")
  const [ScheduleDay, setScheduleDay] = useState(null)
  const [service, setservice] = useState("")
  const [myEvents, setEvents] = useState(events)
  const [startTime, setStartTime] = useState('');
  const [TstartTime, setTstartTime] = useState('');
  const [TendTime, setTendTime] = useState('');
  const [empid, setempid] = useState('');
  const [endTime, setEndTime] = useState('');
  const [assigndate, setassigndate] = useState(null)
  const [MinstartTime, setMinStartTime] = useState('');
  const [MaxendTime, setMaxEndTime] = useState('');
  const [selectedsubject, setselectedsubject] = useState([]);
  const [isrepeat, setisrepeat] = useState(false);


  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleChange = (val) => {
    setChecked(val);
  };

  const DayList = [
    {
      id: "1",
      dayname: "sunday",
    },
    {
      id: "2",
      dayname: "monday",
    },
    {
      id: "3",
      dayname: "tuesday",
    },
    {
      id: "4",
      dayname: "wednesday",
    },
    {
      id: "5",
      dayname: "thrusday",
    },
    {
      id: "6",
      dayname: "friday",
    },
    {
      id: "7",
      dayname: "saturday",
    },
  ]

  const ModuleHandle = (e, data) => {
    console.log(data)
    const { name, checked } = e.target
    if (checked) {
      if (name === "allSelect") {
        setselectedsubject(DayList);
      } else {
        setselectedsubject([...selectedsubject, data]);
      }
    } else {
      if (name === "allSelect") {
        setselectedsubject([]);
      } else {
        let tempuser = DayList.filter((item) => item.id !== data.id);
        setselectedsubject(tempuser);
      }
    }
  }

  useEffect(() => {
    GetSchedule();
    GetScheduledetails();
    GetStudent()
    GetService();
  }, [])

  const handleShow1 = (e, timeSlot, stime, etime, employee_id, tstime, tetime) => {
    console.log(stime, etime, employee_id, tstime, tetime)
    console.log(e, timeSlot)
    const startTimeFromTableCell = timeSlot;
    const startTime = startTimeFromTableCell.split(" - ")[0];
    setStartTime(stime);
    setTstartTime(tstime);
    setTendTime(tetime);
    setempid(employee_id);
    const start = new Date(`1970-01-01T${startTime}`);
    const durationInMinutes = 30;
    console.log(durationInMinutes)

    const end = new Date(start.getTime() + durationInMinutes * 60000);
    const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
    console.log(formattedEndtime)
    setEndTime(etime);
    setShow1(true);
    dispatch(GetStudentListRequest())
  };

  const GetStudent = async () => {
    try {
      const response = await ApiConnection.get(`student-crud/?server_type=production`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }


  const StudentServiceHandle = async () => {

    try {
      const response = await ApiConnection.get(`student_service-crud/?server_type=production`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  const DayHandle = async (e) => {
    console.log(e);
    var val = e.target.value
    setScheduleDay(val)

    let obj = {
      'date': val,
      'service_id': service,
    }
    try {
      const response = await ApiConnection.post(`employee-time-schedule-crud/?server_type=production&method="list"`, obj);

      if (response?.status === 200) {
        const serviceDuration = response?.data.results[0].service_durations;
        const formattedEndTime = convertMinutesToTime(serviceDuration);

        const newEndTime = addTimes(formattedEndTime, startTime);
        console.log(newEndTime)

        setEndTime(newEndTime);
      }
    } catch (e) {
    }
    }

  const ServiceHandle = async (e) => {
    console.log(e);
    var val = e.target.value
    setservice(val)

    let obj = {
      'date': ScheduleDay,
      'service_id': val,
    }
    try {
      const response = await ApiConnection.post(`employee-time-schedule-crud/?server_type=production&method=list`, obj);

      if (response?.status === 200) {
        setAvilScheduleList(response?.data?.results);

        const minStartTime = response?.data?.results[0].min_start_time;
        const minEndTime = response?.data?.results[0].max_end_time;

        const [startHour, startMinute] = minStartTime.split(":").map(Number);
        const [endHour, endMinute] = minEndTime.split(":").map(Number);

        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        console.log(startTime, endTime)

        setMinStartTime(startHour);
        setMaxEndTime(endHour);
      }
    } catch (e) {
    }
  }

  const handleClose = () => {
    setShow1(false);
  }

  const SubmitScheduleHandle = async () => {

    var Temp = []
    selectedsubject && selectedsubject.map((item) => {
      var dataitem = Number(item.id);
      Temp.push(dataitem);
    })

    let obj = {
      "student_id": student,
      "service_id": service,
      "stud_start_time": startTime,
      "stud_end_time": endTime,
      "teacher_start_time": TstartTime,
      "teacher_end_time": TendTime,
      "is_repeat": "1",
      "repeat_type": repeattype,
      "week_days": Temp,
      "schedule_date": ScheduleDay,
    }

    try {
      const response = await ApiConnection.post(`student-service-schedule-crud/?server_type=production&employee_id=${empid}`, obj)
      console.log(response);
      if (response?.data.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Schedule created successfully!",
        });
      } else if (response?.data.status === 400) {
        Swal.fire({
          icon: "error",
          text: response?.data.msg,
        });
      }
    } catch (e) {
    }
  };

  const GetScheduledetails = async () => {
    try {
      const response = await ApiConnection.get(`student-service-schedule-crud/?server_type=production`)
      if (response?.status == 200) {
        console.log(response)
        setscheduleList(response?.data?.results)
      }
    } catch (e) { }
  }


  const GetService = async () => {

    try {
      const response = await ApiConnection.get(`service_crud/?server_type=production`)
      if (response?.status == 200) {
        setserviceList(response?.data?.results)
      }
    } catch (e) { }
  }

  const convertMinutesToTime = (serviceDuration) => {
    const totalMinutes = parseInt(serviceDuration, 10);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  const studentHandle = async (e) => {
    console.log('studentHandle',e)
    const val = e.target.value;
    setstudent(val);

    try {
      const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${id}`)
      if (response?.status == 200) {
        console.log('student-admission-crud',response)
        setserviceList(response?.data?.results)
      }
    } catch (e) { }
  }


  const ScheduledurationHandle = async (e) => {
    const val = e.target.value;
    setservice(val);

    try {
      const response = await ApiConnection.get(
        `service-durations-details/?server_type=production&service_id=${val}`
      );

      if (response?.status === 200) {
        const serviceDuration = response?.data.results[0].service_durations;
        const formattedEndTime = convertMinutesToTime(serviceDuration);

        const newEndTime = addTimes(formattedEndTime, startTime);
        console.log(newEndTime)

        setEndTime(newEndTime);
      }
    } catch (e) {
    }
  };

  const addTimes = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const totalMinutes = hours1 * 60 + minutes1 + hours2 * 60 + minutes2;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;

    const formattedHours = newHours.toString().padStart(2, '0');
    const formattedMinutes = newMinutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  )

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  )

  function generateTimeIntervals() {
    const startTime = MinstartTime * 60;
    const endTime = MaxendTime * 60;
    console.log(startTime, endTime)

    const intervals = [];
    for (let i = startTime; i < endTime; i += 30) {
      const startHour = Math.floor(i / 60);
      const startMinute = i % 60;
      const endHour = Math.floor((i + 30) / 60);
      const endMinute = (i + 30) % 60;

      const formattedStart = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
      const formattedEnd = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
      const interval = `${formattedStart} - ${formattedEnd}`;
      intervals.push(interval);
    }

    return intervals;
  }

  const timeIntervals = generateTimeIntervals();
  console.log(timeIntervals);

  const GetSchedule = async (e) => {

    try {
      console.log(e);
      const val = e.target.value;
      setScheduleDay(val)
      const response = await ApiConnection.get(
        `employee-time-schedule-crud/?server_type=production&date=${val}`
      );
      if (response?.status === 200) {
        console.log(response);
        setAvilScheduleList(response?.data?.results[0]);

        const minStartTime = response?.data?.results[0].min_start_time;
        const minEndTime = response?.data?.results[0].max_end_time;

        const [startHour, startMinute] = minStartTime.split(":").map(Number);
        const [endHour, endMinute] = minEndTime.split(":").map(Number);

        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        console.log(startTime, endTime)

        setMinStartTime(startHour);
        setMaxEndTime(endHour);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='module_list'>
    <Loader visible={fetchacompanyReducer.isloading} />
    <h5>Schedule</h5>
    <div className='row form-style'>
        <div className='col-lg-4'>
            <NavLink to={`/${userinfo?.company_slug}/Test/Addschedule`} className='formBtn mb-1' style={{width: '68%'}}>Add Schedule</NavLink>
        </div>
    </div>

    <div className='table-responsive'>
        <table className='custom-table'>
            <thead>
                <tr>
                    <th>Schedule Date</th>
                    <th>Day Name</th>
                    <th>Schedule Time</th>
                    <th>Action</th>
                </tr>
            </thead>
            {scheduleList && scheduleList.length > 0 ?

            <tbody>
                {scheduleList && scheduleList.map((item, i) => {
                    return (
                        <tr>
                            <td>{moment(item?.schedule_date).format('YYYY-MM-DD')}</td>
                            <td>{moment(item?.schedule_date).format('dddd')}</td>
                            <td>
                            {item?.time_details_lists && item?.time_details_lists.map((avilitem, i) => {
                                return (
                                    <tr className='rowspan-2'>{avilitem?.std_service_start_time} - {avilitem?.std_service_end_time}
                                        {/* <button onClick={() => DeleteHandle(avilitem?.id, avilitem?.service_id)} style={{color: 'red'}}>X</button> */}
                                    </tr>
                                )
                            })}
                            </td>
                            <td>
                                <NavLink><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                <button><i className="fa-solid fa-trash"></i></button>
                            </td>
                        </tr>
                    )
                })}
            </tbody>
            :
            <h6 className='text-center'>There are no records to display</h6>
        }  
        </table>
        
    </div>


</div>
  )

}

export default Schedule_List