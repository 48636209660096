import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  FetchModuleRequest,
  GetTopicRequest,
  GetAllGroupListRequest,
  GetSyllabusRequest,
  AddTopicRequest,
  DeleteTopicRequest,
  FetchTopicRequest,
  UpdateTopicRequest,
  GetModuleRequest
} from "../../../redux/reducer/FetchCompanyDataReducer";
import Loader from "../../../utils/Loader";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import DataTable from 'react-data-table-component';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const ViewModule = () => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [gname, setgname] = useState("");
  const [description, setdescription] = useState("");
  const [tname, settname] = useState("");
  const [code, setcode] = useState("");
  const [tno, settno] = useState("");
  const [origin, setorigin] = useState("");
  const [days, setdays] = useState("");
  const [hworks, sethworks] = useState("");
  const [syllabusId, setsyllabusId] = useState("");
  const [teachersfile, setteachersfile] = useState("");
  const [studentfile, setstudentfile] = useState("");

  const [gname1, setgname1] = useState("");
  const [description1, setdescription1] = useState("");
  const [tname1, settname1] = useState("");
  const [code1, setcode1] = useState("");
  const [tno1, settno1] = useState("");
  const [origin1, setorigin1] = useState("");
  const [days1, setdays1] = useState("");
  const [hworks1, sethworks1] = useState("");
  const [syllabusId1, setsyllabusId1] = useState("");
  const [teachersfile1, setteachersfile1] = useState("");
  const [studentfile1, setstudentfile1] = useState("");
  const [topicId, settopicId] = useState(null);

  const [sgroup, setsgroup] = useState("")
  const [sylname, setsylname] = useState("")
  const [mname, setmname] = useState("")
  const [search, setsearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])

  const fetchacompanyReducer = useSelector(
    (state) => state.FetchCompanyDataReducer
  );
  const Viewdata = fetchacompanyReducer?.FetchModuleResponse?.results[0];
  const TopicList = fetchacompanyReducer?.GetTopicResponse?.results;
  const groupList = fetchacompanyReducer?.GetAllGroupListResponse?.results;
  const SyllabusList = fetchacompanyReducer?.GetSyllabusResponse?.results;
  const ModuleList = fetchacompanyReducer?.GetModuleResponse?.results
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const columns = [
    {
        name: 'Topic Name',
        selector: row => row.name,
        sortable: true,
    },
    {
        name: 'Topic Code',
        selector: row => row.code,
        sortable: true,
    },
    {
      name: 'Topic Number',
      selector: row => row.number,
      sortable: true,
  },
  {
    name: 'Group Name',
    selector: row => row.group_name,
    sortable: true,
},
{
  name: 'Module Name',
  selector: row => row.modules_name,
  sortable: true,
},
{
  name: 'Syllabus Name',
  selector: row => row.syllabus_name,
  sortable: true,
},
  {
    name: 'Action',
    selector: (row) => (
      <>
         
                       <NavLink to={`/${userinfo?.company_slug}/curriculum/view-topic/${row?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                        <button className='table-btn' onClick={()=>handleShow1(row?.id)}><i className="fa-solid fa-pen-to-square"></i></button>
                       
                         <button className='table-btn' onClick={()=>DeleteHandle(row?.id)}><i className="fa-solid fa-trash"></i></button>
      
      </>
    ),
},
];

useEffect(()=>{
  const result = TopicList && TopicList.filter(data =>{
    return data.name.toLowerCase().match(search.toLowerCase())
  })
  setFilterResult(result)
},[search])



  useEffect(() => {

    let obj = {
      'name':'',
      'module_id': id,
      'group_id':'',
      'syllabus_id':''
    }
    let obj1= {
      'name':'',
      'grade_id':'',
      'group_id':'',
      'subject_id':''
    }

    let obj2 = {
      'name':'',
      'group_id':'',
      'syllabus_id': id
  }

  dispatch(GetModuleRequest(obj2))

    dispatch(GetTopicRequest(obj));
    dispatch(FetchModuleRequest(id));
    dispatch(GetAllGroupListRequest());
    dispatch(GetSyllabusRequest(obj1));
  }, []);

  console.log(fetchacompanyReducer?.GetTopicResponse);

  const teachersFileHandle = (e) => {
    var file = e.target.files[0];
    setteachersfile(file);
  };
  const StudentFileHandle = (e) => {
    var file = e.target.files[0];
    setstudentfile(file);
  };

  const teachersFileHandle1 = (e) => {
    var file = e.target.files[0];
    setteachersfile1(file);
  };
  const StudentFileHandle1 = (e) => {
    var file = e.target.files[0];
    setstudentfile1(file);
  };

  useEffect(()=>{
    let obj = {
      'name':'',
      'module_id': id,
      'group_id':'',
      'syllabus_id':''
    }
     dispatch(GetTopicRequest(obj));
     setShow(false)
 },[fetchacompanyReducer?.AddTopicResponse])

 useEffect(()=>{
  let obj = {
    'name':'',
    'module_id': id,
    'group_id':'',
    'syllabus_id':''
  }
     dispatch(GetTopicRequest(obj))
 },[fetchacompanyReducer?.DeleteTopicResponse])

  const AddTopic = () => {
    if (gname == "") {
      Swal.fire({
        icon: "error",
        text: "Group name should be must",
      });
    } else if (code == "") {
      Swal.fire({
        icon: "error",
        text: "Topic code should be must",
      });
    } else if (syllabusId == "") {
      Swal.fire({
        icon: "error",
        text: "Syllabus should be must",
      });
    } else if (tname == "") {
     Swal.fire({
       icon: "error",
       text: "Topic Name should be must",
     });
   } else if (tno == "") {
     Swal.fire({
       icon: "error",
       text: "Number should be must",
     });
   }  else {

     const FormData = require('form-data');
     let data = new FormData();
     data.append('module_id', id);
     data.append('group_id', Number(gname));
     data.append('syllabus_id', Number(syllabusId));
     data.append('code', code);
     data.append('name', tname);
     data.append('number', Number(tno));
     data.append('status', 'active');
     data.append('class_origin', origin);
     data.append('coverage_days', days);
     data.append('teacher_file', teachersfile);
     data.append('student_file', studentfile);
     data.append('home_works', hworks);
     data.append('description', description);

        dispatch(AddTopicRequest(data));

    }
  };

  const handleShow1 = (id) =>{
     dispatch(FetchTopicRequest(id))
     setShow1(true)
     settopicId(id)
 };

 const handleClose1 = () => {
  setShow1(false)
  // setgname1('')
  // setsyllabusId1('')
  // settname1('')
  // setcode1('')
  // settno1('')
  // setorigin1('')
  // setdays1('')
  // sethworks1('')
  // setdescription1('')
 };

  const DeleteHandle = (id)=>{
     Swal.fire({
         title: 'Are you sure?',
         text: "You won't be able to revert this!",
         icon: 'warning',
         showCancelButton: true,
         confirmButtonColor: '#3085d6',
         cancelButtonColor: '#d33',
         confirmButtonText: 'Yes, delete it!'
       }).then((result) => {
         if (result.isConfirmed) {
             dispatch(DeleteTopicRequest(id))

             if(fetchacompanyReducer?.DeleteTopicResponse){
                 Swal.fire(
                     'Deleted!',
                     'Your file has been deleted.',
                     'success'
                   )
             }
          
         }
       })
 }

 useEffect(()=>{
  var editinfo = fetchacompanyReducer?.FetchTopicResponse?.results[0]
  console.log('editinfo', editinfo)
  setgname1(editinfo?.group_id)
  setsyllabusId1(editinfo?.syllabus_id)
  settname1(editinfo?.name)
  setcode1(editinfo?.code)
  settno1(editinfo?.number)
  setorigin1(editinfo?.class_origin)
  setdays1(editinfo?.coverage_days)
  sethworks1(editinfo?.home_works)
  setdescription1(editinfo?.description)
},[fetchacompanyReducer?.FetchTopicResponse])

useEffect(()=>{
  const Viewdata = fetchacompanyReducer?.FetchModuleResponse?.results[0];
  setgname(Viewdata?.group_id)
  setsyllabusId(Viewdata?.syllabus_id)


},[fetchacompanyReducer?.FetchModuleResponse])

useEffect(()=>{
  let obj = {
    'name':'',
    'module_id': id,
    'group_id':'',
    'syllabus_id':''
  }
  dispatch(GetTopicRequest(obj))
  setShow1(false)
},[fetchacompanyReducer?.UpdateTopicResponse])

const UpdateTopic = () => {

  if (gname1 == "") {
    Swal.fire({
      icon: "error",
      text: "Group name should be must",
    });
  } else if (code1 == "") {
    Swal.fire({
      icon: "error",
      text: "Topic code should be must",
    });
  } else if (syllabusId1 == "") {
    Swal.fire({
      icon: "error",
      text: "Syllabus should be must",
    });
  } else if (tname1 == "") {
   Swal.fire({
     icon: "error",
     text: "Topic Name should be must",
   });
 } else if (tno1 == "") {
   Swal.fire({
     icon: "error",
     text: "Number should be must",
   });
 }  else {

   const FormData = require('form-data');
   let data = new FormData();
   data.append('module_id', id);
   data.append('group_id', Number(gname1));
   data.append('syllabus_id', Number(syllabusId1));
   data.append('code', code1);
   data.append('name', tname1);
   data.append('number', Number(tno1));
   data.append('status', 'active');
   data.append('class_origin', origin1);
   data.append('coverage_days', days1);
   data.append('teacher_file', teachersfile1);
   data.append('student_file', studentfile1);
   data.append('home_works', hworks1);
   data.append('description', description1);

      dispatch(UpdateTopicRequest({obj:data, id:topicId}));

  }
};


const GroupHandle = (e)=>{
  setsgroup(e.target.value)
  var val = e.target.value
  console.log(val)

  let obj = {
    'name':search,
    'module_id':mname,
    'group_id':val,
    'syllabus_id':sylname
  }

  dispatch(GetTopicRequest(obj))
}

const SyllabusHandle = (e)=>{
  setsylname(e.target.value)
  var val = e.target.value
  console.log(val)

  let obj = {
    'name':search,
    'module_id':mname,
    'group_id':sgroup,
    'syllabus_id':val
  }

  dispatch(GetTopicRequest(obj))
}

const ModuleHandle = (e)=>{
  setmname(e.target.value)
  var val = e.target.value
  console.log(val)

  let obj = {
    'name':search,
    'module_id':val,
    'group_id':sgroup,
    'syllabus_id':sylname
  }

  dispatch(GetTopicRequest(obj))
}

const editorRef = useRef(null);

const editorConfiguration = {
    // toolbar: ['bold', 'italic', 'heading', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', 'fontSize', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', 'mediaEmbed', 'undo', 'redo'],
    toolbar: [],
    contentsLangDirection: 'ltr'
};

const changeTemplateHandle = (editor) => {
  const data = editor.getData();
  sethworks(data);
};

const changeTemplateHandle2 = (editor) => {
  const data = editor.getData();
  sethworks1(data);
};

useEffect(()=>{

setFilterResult(TopicList)
},[fetchacompanyReducer?.GetTopicResponse])

const ResetHandle = ()=>{
  setsearch("")
  setsgroup("")
  setmname('')
  setsylname('')
  let obj = {
    'name':'',
    'module_id': id,
    'group_id':'',
    'syllabus_id':''
  }

   dispatch(GetTopicRequest(obj))
}



  return (
    <div className="common-layout">
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className="innerlayout p-3">
        <div className="mb-4">
          <h3> View Module</h3>
        </div>
        <div className='button-part mb-4' style={{marginLeft:'910px', marginTop:'-50px'}}>
          <ul>
            <li>
            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
              {/* <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/curriculum/syllabus`}><i className="fa-solid fa-angle-left"></i> Back to syllabus</NavLink> */}
            </li>
           
          </ul>
      </div>

        <div className="row view-page">
          <div className="col-lg-6">
            <div className="form-group">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <label className="d-block text-right">Module Name:</label>
                </div>
                <div className="col-lg-6">
                  <b>{Viewdata?.name}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <label className="d-block text-right">Module Code:</label>
                </div>
                <div className="col-lg-6">
                  <b>{Viewdata?.code}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <label className="d-block text-right">Module Number:</label>
                </div>
                <div className="col-lg-6">
                  <b>{Viewdata?.number}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <label className="d-block text-right">Group Name:</label>
                </div>
                <div className="col-lg-6">
                  <b>{Viewdata?.group_name}</b>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <label className="d-block text-right">Tags:</label>
                </div>
                <div className="col-lg-6">
                  <b>{Viewdata?.tags}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="module_list">
        <h5>Topics</h5>
        
        <div className='row form-style mt-3'>
        <div className='col-lg-3'>
        <div className='form-group m-0'>
                <label>Module Name</label>
            <input type='text' className='form-control' placeholder='Enter Module'
            value={search}
            onChange={(e)=>setsearch(e.target.value)}
            />
                </div>
        </div>
     
                <div className='col-lg-3'>
           <div className='form-group'>
           <label>Select Group</label>
              <select className='form-control'  value={sgroup}
               onChange={(e)=>GroupHandle(e)}>
                <option value="">--All---</option>
                {groupList&&groupList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.name}</option>
                    )
                })}
              </select>
           </div>
       </div>
       <div className='col-lg-3'>
           <div className='form-group'>
           <label>Select Syllabus</label>
              <select className='form-control'  value={sylname}
               onChange={(e)=>SyllabusHandle(e)}>
                <option value="">--All---</option>
                {SyllabusList&&SyllabusList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.name}</option>
                    )
                })}
              </select>
           </div>
       </div>
       <div className='col-lg-3'>
           <div className='form-group'>
           <label>Select Module</label>
              <select className='form-control'  value={mname}
               onChange={(e)=>ModuleHandle(e)}>
                <option value="">--All---</option>
                {ModuleList&&ModuleList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.name}</option>
                    )
                })}
              </select>
           </div>
       </div>
       <div className='col-lg-4'>
                <div className='form-group'>
            
                    <div className='d-flex'>
                 
                    <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
                </div>
                </div>
            </div>
        
     </div>
     <div className='row mb-3'>
                <div className='col-lg-3'>
                <button className="formBtn" onClick={handleShow}>
              Add Topics
            </button>
                </div>
        </div>

            <div className="table-responsive">

          <DataTable
                  columns={columns}
                  data={FilterResult}
                  pagination
                  highlightOnHover
                />

            </div>
         
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header>
          <Modal.Title>Add Topic</Modal.Title>
          <button onClick={handleClose} className="btn btn-sm btn-warning">
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row form-style mb-4">
            {/* <div className="col-lg-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={gname}
                  onChange={(e) => setgname(e.target.value)}
                >
                  <option value="">--Select Group---</option>
                  {groupList &&
                    groupList.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div> */}
            {/* <div className="col-lg-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={syllabusId}
                  onChange={(e) => setsyllabusId(e.target.value)}
                >
                  <option value="">--Select Syllabus---</option>
                  {SyllabusList &&
                    SyllabusList.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div> */}

            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic Name <span class="asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic Name"
                  value={tname}
                  onChange={(e) => settname(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic Code <span class="asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic Code"
                  value={code}
                  onChange={(e) => setcode(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic No. <span class="asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic No"
                  value={tno}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => settno(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Class Origin</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Class origin"
                  value={origin}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setorigin(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic coverage days</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic coverage days"
                  value={days}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setdays(e.target.value)}
                />
              </div>
            </div>

            {/* <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Home Works</label>
                <textarea
                  className="form-control"
                  placeholder="Home Works"
                  value={hworks}
                  onChange={(e) => sethworks(e.target.value)}
                ></textarea>
              </div>
            </div> */}            

            <div className="col-lg-6">
              <div className="form-group">
                <label className='selectlabel'>Teachers File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={teachersFileHandle}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label className='selectlabel'>Student File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={StudentFileHandle}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Description</label>
                <textarea
                  className="form-control"
                  placeholder="Write Description"
                  value={description}
                  onChange={(e) => setdescription(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-12">
            <div className="form-group">
            <label className='selectlabel'>Enter Home Works</label>
            <CKEditor
                                editor={Editor}
                                config={editorConfiguration}
                                data={hworks}
                                onReady={editor => {
                                    editorRef.current = editor;
                                }}
                                onChange={(event, editor) => changeTemplateHandle(editor)}
                            />
            </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="formBtn" onClick={AddTopic}>
            Add Topic
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header>
          <Modal.Title>Edit Topic</Modal.Title>
          <button onClick={handleClose1} className="btn btn-sm btn-warning">
            <i class="fa-solid fa-circle-xmark"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="row form-style mb-4">
            {/* <div className="col-lg-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={gname1}
                  onChange={(e) => setgname1(e.target.value)}
                >
                  <option value="">--Select Group---</option>
                  {groupList &&
                    groupList.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div> */}
            {/* <div className="col-lg-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={syllabusId1}
                  onChange={(e) => setsyllabusId1(e.target.value)}
                >
                  <option value="">--Select Syllabus---</option>
                  {SyllabusList &&
                    SyllabusList.map((item, i) => {
                      return (
                        <option key={i} value={item?.id}>
                          {item?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div> */}

            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic Name <span class="asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic Name"
                  value={tname1}
                  onChange={(e) => settname1(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic Code <span class="asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic Code"
                  value={code1}
                  onChange={(e) => setcode1(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic No. <span class="asterisk">*</span></label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic No"
                  value={tno1}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => settno1(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Class Origin</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Class origin"
                  value={origin1}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setorigin1(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Topic coverage days</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Topic coverage days"
                  value={days1}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  onChange={(e) => setdays1(e.target.value)}
                />
              </div>
            </div>

            {/* <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Home Works</label>
                <textarea
                  className="form-control"
                  placeholder="Home Works"
                  value={hworks1}
                  onChange={(e) => sethworks1(e.target.value)}
                ></textarea>
              </div>
            </div> */}
            <div className="col-lg-6">
              <div className="form-group">
                <label>Teachers File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={teachersFileHandle1}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>Student File</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={StudentFileHandle1}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
              <label className='selectlabel'>Enter Description</label>
                <textarea
                  className="form-control"
                  placeholder="Write Description"
                  value={description1}
                  onChange={(e) => setdescription1(e.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="col-lg-12">
            <label className='selectlabel'>Enter Home Works</label>
            <CKEditor
                                editor={Editor}
                                config={editorConfiguration}
                                data={hworks1}
                                onReady={editor => {
                                    editorRef.current = editor;
                                }}
                                onChange={(event, editor) => changeTemplateHandle2(editor)}
                            />
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="formBtn" onClick={UpdateTopic}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ViewModule;
