import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    locationList:[],
    changeStatus:false,
  };

  export const LocationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        LocationAction: (state, action) => {
        state.locationList = action.payload;
        state.changeStatus = true
    },
}
  })

  export const { LocationAction } = LocationSlice.actions

  export default LocationSlice.reducer;