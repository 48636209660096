import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { UpdateSubjectRequest, FetchSubjectRequest, GetAllGroupListRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";

const EditSubject = () => {
    
    const [gname, setgname] = useState("")
    const [sname, setsname] = useState("")
    const [code, setcode] = useState("")
    const [description, setdescription] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  
    const groupList = fetchacompanyReducer?.GetAllGroupListResponse?.results

    console.log(fetchacompanyReducer.FetchSubjectResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const {id} = useParams()



    useEffect(()=>{
        let obj ={
            id:id
        }
        dispatch(FetchSubjectRequest(obj))
    },[])

    useEffect(()=>{
        dispatch(GetAllGroupListRequest())
    },[])

    useEffect(()=>{
        var editdata = fetchacompanyReducer.FetchSubjectResponse
        setsname(editdata?.subject_name)
        setcode(editdata?.subject_code)
        setgname(editdata?.group_id)
        setdescription(editdata?.subject_description)

    },[fetchacompanyReducer?.FetchSubjectResponse])



    const SubmitHandle = () =>{
        if(gname == ''){
            Swal.fire({
                icon: "error",
                text: 'Grade name should be must',
              });
            } else  if(code == ''){
                Swal.fire({
                    icon: "error",
                    text: 'Code should be must',
                  });
                } else  if(description == ''){
                    Swal.fire({
                        icon: "error",
                        text: 'Description should be must',
                      });
                    } else {
    
                        let obj =  {
                            "subject_name": sname,
                            "group_id": gname,
                            "subject_code": code,
                            "subject_description": description,
                            "status": "active"
                        }
    
                        dispatch(UpdateSubjectRequest({obj:obj,id:id, navigate:navigate}))
    
                    }
    }


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
    <div className='innerlayout p-3'>
    <div className='mb-4'>
      <h3> Edit Subject</h3>
      </div>
      <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
      <div className='row form-style mb-4'>
      <div className='col-lg-4'>
           <div className='form-group'>
              <select className='form-control'  value={gname}
               onChange={(e)=>setgname(e.target.value)}>
                <option value="">--Group Name---</option>
                {groupList&&groupList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.name}</option>
                    )
                })}
              </select>
           </div>
       </div>
       <div className='col-lg-4'>
           <div className='form-group'>
               <input type='text' className='form-control' placeholder='Subject Name'
               value={sname}
               onChange={(e)=>setsname(e.target.value)}
               />
           </div>
       </div>
       <div className='col-lg-4'>
           <div className='form-group'>
               <input type='text' className='form-control' placeholder='Code'
               value={code}
               onChange={(e)=>setcode(e.target.value)}
               />
           </div>
       </div>

      <div className='col-lg-12'>
           <div className='form-group'>
              <textarea className='form-control' placeholder='Write Description'
              value={description}
              onChange={(e)=>setdescription(e.target.value)}
              >

              </textarea>
           </div>
       </div>
  
      
   </div>
   <button className='formBtn' onClick={SubmitHandle}>Update</button>
    </div>

  </div>
  )
}

export default EditSubject