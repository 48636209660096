import { all } from "redux-saga/effects";
import FetchAdminDataSaga from './FetchAdminDataSaga';
import FetchCompanyDataSaga from './FetchCompanyDataSaga';
import FetchStudentDataSaga from './FetchStudentDataSaga';



const combinedSaga = [

    ...FetchAdminDataSaga, ...FetchCompanyDataSaga, ...FetchStudentDataSaga
  
  ];


  export default function* RootSaga(){

    yield all(combinedSaga);
  
  }