import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetGradeRequest, GetSubjectRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../../utils/ApiConnection'

const EmployeeClassSubject = () => {

    const [search, setsearch] = useState("")
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [editrowId, seteditrowId] = useState(null)
    const [detailsrowId, setdetailsrowId] = useState(null)
    const [show2, setShow2] = useState(false);
    const [subclass, setsubclass] = useState("")
    const [subject, setsubject] = useState("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const [FilterResult, setFilterResult] = useState([])
    const [selectedsubject, setselectedsubject] = useState([]);
    const [teacherSub, setteacherSub] = useState([])
    // const [traininfo, settraininfo] = useState([]);

    const [traininfo, settraininfo] = useState([{
        subclass: '',
        subject: [],
    }]);
    console.log(traininfo, selectedsubject)

    let navigate = useNavigate()
    const { id } = useParams()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var GradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var SubjectList = fetchacompanyReducer?.GetSubjectResponse?.results

    const dispatch = useDispatch()

    const handleShow1 = async (tid) => {
        seteditrowId(tid);
        setShow1(true);
        try {
            const response = await ApiConnection.get(`employee-teacher/class-subject-crud/?server_type=production&employee_id=${id}&id=${tid}`);
            const editinfo = response?.data?.results[0];
            if (editinfo) {
                settraininfo(editinfo.traininfo || [{
                    subclass: editinfo?.class_name,
                    subject: editinfo?.subjects_list || [],
                }]);
                setselectedsubject(editinfo.subjects_list || []);
            }
        } catch (e) {
            console.error(e);
        }
    };

    useEffect(() => {
        let obj = {
            'name': '',
            'group_id': '',
        }
        dispatch(GetGradeRequest(id))
        dispatch(GetSubjectRequest(obj,id))
    }, [])

    const AddMore = () => {
        const rawinput = {
            subclass: '',
            subject: [],
        };
        settraininfo([...traininfo, rawinput]);
    };

    const DeleteHandle = (index) => {
        var newValue = [...traininfo];
        newValue.splice(index, 1);
        settraininfo(newValue);
    };

    const AddMoreHandle = (value, key, index) => {
        var newValue = [...traininfo];
        newValue[index][key] = value;
        settraininfo(newValue);
    };

    const ModuleHandle = (e, data, index) => {
        const { name, checked } = e.target;
        let newTrainInfo = [...traininfo];
        if (name === "allSelect") {
            if (checked) {
                newTrainInfo[index].subject = SubjectList;
            } else {
                newTrainInfo[index].subject = [];
            }
        } else {
            if (checked) {
                newTrainInfo[index].subject = [...newTrainInfo[index].subject, data];
            } else {
                newTrainInfo[index].subject = newTrainInfo[index].subject.filter((item) => item.id !== data.id);
            }
        }
        settraininfo(newTrainInfo);
    };

    useEffect(() => {
        GetTeacherSubject()
    }, [])

    const GetTeacherSubject = async () => {
        try {
            const response = await ApiConnection.get(`employee-teacher/class-subject-crud/?server_type=production&employee_id=${id}`);
            if (response?.status === 200) {
                setteacherSub(response?.data?.results);
            }
        } catch (e) {
        }
    }

    const AddHandle = async () => {
        let arr = traininfo.map((element) => ({
            "class_id": element.subclass,
            "subject_id": element.subject.map((item) => item.id),
        }));

        let obj = {
            "teacher_subject": arr,
        };

        try {
            const response = await ApiConnection.post(`employee-teacher/class-subject-crud/?server_type=production&employee_id=${id}`, obj);
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
                GetTeacherSubject();
                setShow(false);
            }
        } catch (e) { }
    };

    const UpdateHandle = async () => {

        let arr = traininfo.map((element) => ({
            "class_id": element.subclass,
            "subject_id": element.subject.map((item) => item.id),
        }));

        let obj = {
            "teacher_subject": arr,
        };

        try {
            const response = await ApiConnection.put(`employee-teacher/class-subject-crud/?server_type=production&employee_id=${id}&id=${editrowId}&method=edit`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
                GetTeacherSubject();
                setShow1(false)
            }
        } catch (e) { }
    }

    const DeleteSubjectHandle = async (delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`employee-teacher/class-subject-crud/?server_type=production&employee_id=${id}&class_id=${delid}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        GetTeacherSubject();
                    }
                }
            })
        } catch (e) { }
    }


    return (

        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h5>Class/Subject</h5>

            <div className='row form-style mt-3 mb-1'>
                {/* <div className='col-lg-3'>
                    <div className='form-group m-0'>
                        <input type='text' className='form-control' placeholder='Subject Name'
                            value={search}
                            onChange={(e) => setsearch(e.target.value)}
                        />
                    </div>
                </div> */}
                <div className='col-lg-3'>
                    <button className='formBtn' style={{ width: '110%' }} onClick={handleShow}>Add Class/Subject</button>
                </div>
            </div>

            <div className='table-responsive'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th>Class</th>
                            <th>Subjects</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {teacherSub.length > 0 ? (
                        <tbody>
                            {teacherSub && teacherSub.map((data, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{data?.class_name}</td>
                                        <td>
                                            {data?.subjects_list && data?.subjects_list.map((item, i) => {
                                                return (
                                                    <span>{item?.subject_name},</span>
                                                )
                                            })}
                                        </td>
                                        <td>
                                            <button><i className="fa-solid fa-pen-to-square" onClick={() => handleShow1(data?.id)} ></i></button>
                                            <button><i className="fa-solid fa-trash" onClick={() => DeleteSubjectHandle(data?.class_id)}></i></button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    ) : (
                        <tbody>
                        <tr>
                          <td colSpan="3" className="text-center">There are no records to display</td>
                        </tr>
                      </tbody>
                    )}
                </table>
            </div>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Assign Class & Subject</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    {traininfo.map((titem, index) => {
                        return (
                            <div className='row form-style mb-4' key={index}>

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <label className='selectlabel'>Select Class <span class="asterisk">*</span></label>
                                        <select className='form-control' value={titem?.subclass}
                                            onChange={(e) => AddMoreHandle(e.target.value, 'subclass', index)}>
                                            <option value="">--Select Grade--</option>
                                            {GradeList && GradeList.map((item, i) => {
                                                return (
                                                    <option key={i} value={item?.id} >{item?.grade_name}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <Dropdown className='custom-dropdown-checkbox' style={{ marginTop: '26px' }}>
                                            <Dropdown.Toggle variant="success">
                                                {titem.subject.length > 0 ? 'select ' + titem.subject.length : 'select subject'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <ul>
                                                    <li>
                                                        <input type="checkbox" name="allSelect" checked={titem.subject?.length === SubjectList?.length}
                                                            onChange={(e) => ModuleHandle(e, SubjectList, index)}
                                                        />
                                                        <label>All Select</label>
                                                    </li>
                                                    {SubjectList && SubjectList.map((list, i) => {
                                                        return (
                                                            <li key={i}>
                                                                <input type="checkbox" name={list?.subject_name + i} id={i} onChange={(e) => ModuleHandle(e, list, index)}
                                                                    checked={titem.subject.some((item) => item?.id === list.id)}
                                                                />
                                                                <label htmlFor={i}>{list?.subject_name}</label>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </div>

                                <div className='col-lg-2' style={{ marginTop: '30px' }}>
                                    <button className='btn btn-primary' onClick={AddMore}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>

                                <div className='col-lg-1' style={{ marginTop: '30px', marginLeft: '-4.5rem' }}>
                                    {index > 0 &&
                                        <div className='form-group'>
                                            <button className='btn btn-sm btn-danger' onClick={() => DeleteHandle(index)}>
                                                <i className="fa-solid fa-trash-can"></i>
                                            </button>
                                        </div>
                                    }
                                </div>


                            </div>
                        )
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Assign Class & Subject</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    {traininfo.map((titem, index) => {
                        return (
                            <div className='row form-style mb-4' key={index}>

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <label className='selectlabel'>Select Class <span class="asterisk">*</span></label>
                                        {/* <select className='form-control' value={titem?.subclass}
                                            onChange={(e) => AddMoreHandle(e.target.value, 'subclass', index)}>
                                            <option value="">--Select Grade--</option>
                                            {GradeList && GradeList.map((item, i) => {
                                                return (
                                                    <option key={i} value={item?.id} >{item?.grade_name}</option>
                                                )
                                            })}
                                        </select> */}
                                        <input type='text' disabled className='form-control' placeholder='Grade Name'
                                            value={titem?.subclass}
                                            />
                                    </div>
                                </div>

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <Dropdown className='custom-dropdown-checkbox' style={{ marginTop: '26px' }}>
                                            <Dropdown.Toggle variant="success">
                                                {titem.subject.length > 0 ? 'select ' + titem.subject.length : 'select subject'}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <ul>
                                                    <li>
                                                        <input type="checkbox" name="allSelect" checked={titem.subject?.length === SubjectList?.length}
                                                            onChange={(e) => ModuleHandle(e, SubjectList, index)}
                                                        />
                                                        <label>All Select</label>
                                                    </li>
                                                    {SubjectList && SubjectList.map((list, i) => (
                                                        <li key={i}>
                                                            <input type="checkbox" name={list?.subject_name + i} id={i} onChange={(e) => ModuleHandle(e, list, index)}
                                                                checked={titem.subject.some((item) => item?.id === list.id)}
                                                            />
                                                            <label htmlFor={i}>{list?.subject_name}</label>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                </div>

                                <div className='col-lg-2' style={{ marginTop: '30px' }}>
                                    <button className='btn btn-primary' onClick={AddMore}>
                                        <i className="fa-solid fa-plus"></i>
                                    </button>
                                </div>

                                <div className='col-lg-1' style={{ marginTop: '30px', marginLeft: '-4.5rem' }}>
                                    {index > 0 &&
                                        <div className='form-group'>
                                            <button className='btn btn-sm btn-danger' onClick={() => DeleteHandle(index)}>
                                                <i className="fa-solid fa-trash-can"></i>
                                            </button>
                                        </div>
                                    }
                                </div>


                            </div>
                        )
                    })}
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdateHandle}>Update</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2} size="lg">
                <Modal.Header >
                    <Modal.Title>Assigned Class & Subject</Modal.Title>
                    <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='view-page'>

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='form-group'>
                                        <div className='row align-items-center'>
                                            <div className='col-lg-6'>
                                                <label className='d-block text-right'>Class/Subject :</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <b></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default EmployeeClassSubject