import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetTitleRequest, DeleteTitleRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import ApiConnection from '../utils/ApiConnection'
import moment, { months } from 'moment';


const ScheduleList = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetTitleResponse)

    const dispatch = useDispatch()

    const [AvilScheduleList, setAvilScheduleList] = useState([])
    const [ScheduleDay, setScheduleDay] = useState(null)

    const GetSchedule = async (e) => {
        try {
            const response = await ApiConnection.get(
                `student-service-schedule-crud/?server_type=production&student_id=${userinfo?.user_id}`
            );
            if (response?.status === 200) {
                console.log('student-service-schedule-crud',response);
                setAvilScheduleList(response?.data?.results);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        GetSchedule()

    }, [])




    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Schedule Management</h3>
                </div>

                {/* <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/settings/Title_CP/AddTitle_CP`}><i className="fa-solid fa-plus"></i> Add Title</NavLink>
                        </li>

                    </ul>
                </div> */}

            <div className='row form-style'>
                <div className='col-lg-3'>
                    <div className='form-group'>
                        <label className='selectlabel'>Select Date<span class="asterisk">*</span></label>
                        <input type='date' value={ScheduleDay} onChange={(e) => GetSchedule(e)} className='form-control'
                            placeholderText="Date Scheduled"
                        />
                    </div>
                </div>
            </div>

                    <div className='table-responsive'>
                        <table className='custom-table'>
                        <thead>
                        <tr>
                            <th>Schedule Date</th>
                            <th>Day Name</th>
                            <th>Schedule Time / Teacher</th>
                            {/* <th>Action</th> */}
                        </tr>
                    </thead>
                    {AvilScheduleList && AvilScheduleList.length > 0 ?
                    <tbody>
                        {AvilScheduleList && AvilScheduleList.map((item, i) => {
                            return (
                                <tr>
                                    <td>{moment(item?.schedule_date).format('YYYY-MM-DD')}</td>
                                    <td>{moment(item?.schedule_date).format('dddd')}</td>
                                    <td>
                                    {item?.time_details_lists && item?.time_details_lists.map((avilitem, i) => {
                                        return (
                                            <tr className='rowspan-2'>{avilitem?.std_service_start_time} - {avilitem?.std_service_end_time} ({avilitem?.emp_first_name})</tr>
                                        )
                                    })}
                                    </td>
                                    {/* <td>
                                        <NavLink><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                        <button><i className="fa-solid fa-trash"></i></button>
                                    </td> */}
                                </tr>
                            )
                        })} 
                    </tbody>
                :
                <td colSpan="4" className='text-center'>No data found</td>
            }  
                        </table>
                    </div>



            </div>

        </div>
    )
}

export default ScheduleList