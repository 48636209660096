import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, GetEmployeeRequest, GetStudentListRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer, Views, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../utils/ApiConnection'
import ReactSwitch from 'react-switch';


const Schedule_TrailSession = () => {

  const localizer = momentLocalizer(moment)
  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var studentteacherList = fetchacompanyReducer?.GetTeacherResponse?.results
  var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
  var SubList = fetchacompanyReducer?.GetSubjectResponse?.results
  var EmployeeList = fetchacompanyReducer?.GetEmployeeResponse?.results
  const locationReducer = useSelector((state) => state.LocationSlice)
  let locationId = localStorage.getItem("locationId");
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))

  const { id } = useParams()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const timeSlots = [
    '08:00 - 08:30',
    '08:30 - 09:00',
    '09:00 - 09:30',
    '09:30 - 10:00',
    '10:00 - 10:30',
    '10:30 - 11:00',
    '11:00 - 11:30',
    '11:30 - 12:00',
    '12:00 - 12:30',
    '12:30 - 01:00',
    '01:00 - 01:30',
    '01:30 - 02:00',
    '02:00 - 02:30',
    '02:30 - 03:00',
    '03:00 - 03:30',
    '03:30 - 04:00',
  ];

  const events = [
    {
      title: 'Math',
      start: new Date(2024, 4, 20, 10, 0),
      end: new Date(2024, 4, 20, 12, 0),
      hexColor: 'green',
    },
    {
      title: 'History',
      start: new Date(2024, 4, 22, 11, 0),
      end: new Date(2024, 4, 22, 15, 0),
      hexColor: 'red',
    },
  ];

  const [grade, setgrade] = useState("")
  const [teacher, setteacher] = useState("")
  const [lpList, setlpList] = useState("")
  const [show1, setShow1] = useState(false);
  const [checked, setChecked] = useState(false);

  const [serviceList, setserviceList] = useState([])
  const [AvilScheduleList, setAvilScheduleList] = useState([])
  const [EndTimeList, setEndTimeList] = useState([])
  const [scheduleList, setscheduleList] = useState([])
  const [time, settime] = useState(null)
  const [studentList, setstudentList] = useState([])
  const [student, setstudent] = useState("")
  const [repeattype, setrepeattype] = useState("")
  const [employeeName, setemployeeName] = useState("")
  const [ScheduleDay, setScheduleDay] = useState(null)
  const [service, setservice] = useState("")
  const [myEvents, setEvents] = useState(events)
  const [startTime, setStartTime] = useState('');
  const [TstartTime, setTstartTime] = useState('');
  const [TendTime, setTendTime] = useState('');
  const [empid, setempid] = useState('');
  const [endTime, setEndTime] = useState('');
  const [assigndate, setassigndate] = useState(null)
  const [MinstartTime, setMinStartTime] = useState('');
  const [MaxendTime, setMaxEndTime] = useState('');
  const [selectedsubject, setselectedsubject] = useState([]);
  const [isrepeat, setisrepeat] = useState(false);


  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleChange = (val) => {
    setChecked(val);
  };

  const DayList = [
    {
      id: "1",
      dayname: "sunday",
    },
    {
      id: "2",
      dayname: "monday",
    },
    {
      id: "3",
      dayname: "tuesday",
    },
    {
      id: "4",
      dayname: "wednesday",
    },
    {
      id: "5",
      dayname: "thrusday",
    },
    {
      id: "6",
      dayname: "friday",
    },
    {
      id: "7",
      dayname: "saturday",
    },
  ]

  const ModuleHandle = (e, data) => {
    console.log(data)
    const { name, checked } = e.target
    if (checked) {
      if (name === "allSelect") {
        setselectedsubject(DayList);
      } else {
        setselectedsubject([...selectedsubject, data]);
      }
    } else {
      if (name === "allSelect") {
        setselectedsubject([]);
      } else {
        let tempuser = DayList.filter((item) => item.id !== data.id);
        setselectedsubject(tempuser);
      }
    }
  }

  useEffect(() => {
    GetSchedule();
    GetScheduledetails();
    GetStudent()
    // GetService();
  }, [])

  const handleShow1 = (e, timeSlot, stime, etime, employee_id, tstime, tetime) => {
    console.log(stime, etime, employee_id, tstime, tetime)
    console.log(e, timeSlot)
    const startTimeFromTableCell = timeSlot;
    const startTime = startTimeFromTableCell.split(" - ")[0];
    setStartTime(stime);
    setTstartTime(tstime);
    setTendTime(tetime);
    setempid(employee_id);
    const start = new Date(`1970-01-01T${startTime}`);
    const durationInMinutes = 30;
    console.log(durationInMinutes)

    const end = new Date(start.getTime() + durationInMinutes * 60000);
    const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
    console.log(formattedEndtime)
    setEndTime(etime);
    setShow1(true);
    dispatch(GetStudentListRequest())
  };

  const GetStudent = async () => {
    try {
      const response = await ApiConnection.get(`student-crud/?server_type=production`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

    const StudentDayHandle = async (e) => {
    console.log(e);
    var val = e.target.value
    setScheduleDay(val)
    
    try {
      const response = await ApiConnection.get(`student-crud/?server_type=production&schedule_date=${val}`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  const StudentServiceHandle = async () => {

    try {
      const response = await ApiConnection.get(`student_service-crud/?server_type=production`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  const DayHandle = async (e) => {
    console.log(e);
    var val = e.target.value
    setScheduleDay(val)

    let obj = {
      'date': val,
      'service_id': service,
    }
    try {
      const response = await ApiConnection.post(`employee-time-schedule-crud/?server_type=production&method="list"`, obj);

      if (response?.status === 200) {
        const serviceDuration = response?.data.results[0].service_durations;
        const formattedEndTime = convertMinutesToTime(serviceDuration);

        const newEndTime = addTimes(formattedEndTime, startTime);
        console.log(newEndTime)

        setEndTime(newEndTime);
      }
    } catch (e) {
    }
    }

  const ServiceHandle = async (e) => {
    console.log(e);
    var val = e.target.value
    setservice(val)

    let obj = {
      'date': ScheduleDay,
      'service_id': val,
    }
    try {
      const response = await ApiConnection.post(`employee-time-schedule-crud/?server_type=production&method=list`, obj);

      if (response?.status === 200) {
        setAvilScheduleList(response?.data?.results);

        const minStartTime = response?.data?.results[0].min_start_time;
        const minEndTime = response?.data?.results[0].max_end_time;

        const [startHour, startMinute] = minStartTime.split(":").map(Number);
        const [endHour, endMinute] = minEndTime.split(":").map(Number);

        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        console.log(startTime, endTime)

        setMinStartTime(startHour);
        setMaxEndTime(endHour);
      }
    } catch (e) {
    }
  }

  const handleClose = () => {
    setShow1(false);
  }

  const SubmitScheduleHandle = async () => {

    var Temp = []
    selectedsubject && selectedsubject.map((item) => {
      var dataitem = Number(item.id);
      Temp.push(dataitem);
    })

    let obj = {
      "student_id": student,
      "service_id": service,
      "stud_start_time": startTime,
      "stud_end_time": endTime,
      "teacher_start_time": TstartTime,
      "teacher_end_time": TendTime,
      "is_repeat": "1",
      "repeat_type": repeattype,
      "week_days": Temp,
      "schedule_date": ScheduleDay,
    }

    try {
      const response = await ApiConnection.post(`student-service-schedule-crud/?server_type=production&employee_id=${empid}`, obj)
      console.log(response);
      if (response?.data.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Schedule created successfully!",
        });
      } else if (response?.data.status === 400) {
        Swal.fire({
          icon: "error",
          text: response?.data.msg,
        });
      }
    } catch (e) {
    }
  };

  const GetScheduledetails = async () => {
    try {
      const response = await ApiConnection.get(`student-service-schedule-crud/?server_type=production`)
      if (response?.status == 200) {
        console.log(response)
        setscheduleList(response?.data?.results)
      }
    } catch (e) { }
  }


  const GetService = async () => {

    try {
      const response = await ApiConnection.get(`service_crud/?server_type=production`)
      if (response?.status == 200) {
        setserviceList(response?.data?.results)
      }
    } catch (e) { }
  }

  const convertMinutesToTime = (serviceDuration) => {
    const totalMinutes = parseInt(serviceDuration, 10);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  const studentHandle = async (e) => {
    console.log('studentHandle',e)
    const val = e.target.value;
    setstudent(val);

    try {
      const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${63}`)
      if (response?.status == 200) {
        console.log('student-admission-crud',response)
        setserviceList(response?.data?.results)
      }
    } catch (e) { }
  }


  const ScheduledurationHandle = async (e) => {
    const val = e.target.value;
    setservice(val);

    try {
      const response = await ApiConnection.get(
        `service-durations-details/?server_type=production&service_id=${val}`
      );

      if (response?.status === 200) {
        const serviceDuration = response?.data.results[0].service_durations;
        const formattedEndTime = convertMinutesToTime(serviceDuration);

        const newEndTime = addTimes(formattedEndTime, startTime);
        console.log(newEndTime)

        setEndTime(newEndTime);
      }
    } catch (e) {
    }
  };

  const addTimes = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const totalMinutes = hours1 * 60 + minutes1 + hours2 * 60 + minutes2;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;

    const formattedHours = newHours.toString().padStart(2, '0');
    const formattedMinutes = newMinutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  )

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  )

  function generateTimeIntervals() {
    const startTime = MinstartTime * 60;
    const endTime = MaxendTime * 60;
    console.log(startTime, endTime)

    const intervals = [];
    for (let i = startTime; i < endTime; i += 30) {
      const startHour = Math.floor(i / 60);
      const startMinute = i % 60;
      const endHour = Math.floor((i + 30) / 60);
      const endMinute = (i + 30) % 60;

      const formattedStart = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
      const formattedEnd = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
      const interval = `${formattedStart} - ${formattedEnd}`;
      intervals.push(interval);
    }

    return intervals;
  }

  const timeIntervals = generateTimeIntervals();
  console.log(timeIntervals);

  const GetSchedule = async (e) => {

    try {
      console.log(e);
      const val = e.target.value;
      setScheduleDay(val)
      const response = await ApiConnection.get(
        `employee-time-schedule-crud/?server_type=production&date=${val}`
      );
      if (response?.status === 200) {
        console.log(response);
        setAvilScheduleList(response?.data?.results);

        const minStartTime = response?.data?.results[0].min_start_time;
        const minEndTime = response?.data?.results[0].max_end_time;

        const [startHour, startMinute] = minStartTime.split(":").map(Number);
        const [endHour, endMinute] = minEndTime.split(":").map(Number);

        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        console.log(startTime, endTime)

        setMinStartTime(startHour);
        setMaxEndTime(endHour);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3>Trail Schedule Management</h3>
        </div>

        <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
          <ul>
            <li>
              <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
            </li>
          </ul>
        </div>

        <div className='row form-style mb-4'>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Date<span class="asterisk">*</span></label>
              <input type='date' value={ScheduleDay} onChange={(e) => StudentDayHandle(e)} className='form-control'
                placeholderText="Date Scheduled"
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Student<span class="asterisk">*</span></label>
              <select className='form-control' value={student}
                onChange={(e) => studentHandle(e)}>
                <option value="">--Select Student--</option>
                {studentList && studentList.map((item, i) => {
                  return (
                    <option key={i} value={item?.student_id} >{item?.student_first_name} {item?.student_last_name}</option>
                  )

                })}
              </select>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Service<span class="asterisk">*</span></label>
              <select className='form-control' value={service} onChange={(e) => ServiceHandle(e)}>
                <option value="">--Select Service--</option>
                {serviceList && serviceList.map((item, i) => (
                  <option key={i} value={item?.service_id}>{item?.service_name}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className='row form-style mb-4 align-items-center'>

          <div className='mt-2 ml-4' style={{ border: '1px solid #c3c3c3', width: '96%' }}>
            <div className='table-responsive'>
              <table className='custom-table'>
                <thead style={{ backgroundColor: 'rgb(97, 115, 141)' }}>
                  <tr className='text-center' style={{ minWidth: '200px' }}>
                    <th style={{ width: '50px', color: 'white' }}>Time</th>
                    {/* Add teacher names as headers */}
                    {AvilScheduleList.map((teacher) => (
                      <th key={teacher.employee_id} style={{ width: '100px', color: 'white' }}>
                        {teacher.employee_first_name} {teacher.employee_last_name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {timeIntervals.map((timeSlot, rowIndex) => (
                    <tr key={timeSlot}>
                      <td style={{ backgroundColor: '#E6E6FA' }}>{timeSlot}</td>
                      {AvilScheduleList.map((teacher, colIndex) => {
                        const timeDetails = teacher.time_details_list || [];
                        const currentSlot = timeDetails.find((time) => {
                          return time.start_time <= timeSlot && time.end_time >= timeSlot;
                        });

                        if (currentSlot) {
                          const rowspan = currentSlot.end_time - currentSlot.start_time + 1;
                          return (
                            <td
                              key={`${teacher.employee_id}-${timeSlot}`}
                              rowSpan={rowspan}
                              onClick={(e) => handleShow1(e, timeSlot, currentSlot?.start_time, currentSlot?.end_time, teacher?.employee_id, currentSlot?.start_time, currentSlot?.end_time )}
                              style={{ backgroundColor: currentSlot.is_avail === '1' ? '#98FF98' : '#ffff00bd', borderRight: currentSlot.is_avail === '1' ? '1px solid #a7a3a3' : '1px solid #ccc;' }}
                            >
                              {/* ({teacher.employee_id}) {teacher.employee_first_name} */}
                              <br />
                              {/* {currentSlot.start_time}-{currentSlot.end_time}, {currentSlot.student_list[0]?.id} */}   
                              <span style={{ fontSize: "10px" }}>
                                  <button style={{ cursor: 'default' }}><h6 style={{fontSize: '10px', color: 'blue'}}>{currentSlot.student_list[0]?.student_name}</h6> </button>
                              </span>
                              <span style={{ fontSize: "10px" }}>
                              <button style={{ cursor: 'default' }}><h6 style={{fontSize: '10px', color: 'blue'}}>{currentSlot.student_list[1]?.student_name}</h6> </button>
                              </span>
                              <span style={{ fontSize: "10px" }}>
                              <button style={{ cursor: 'default' }}><h6 style={{fontSize: '10px', color: 'blue'}}>{currentSlot.student_list[2]?.student_name}</h6> </button>
                              </span>
                              <span style={{ fontSize: "10px" }}>
                              <button style={{ cursor: 'default' }}><h6 style={{fontSize: '10px', color: 'blue'}}>{currentSlot.student_list[3]?.student_name}</h6> </button>
                              </span>
                              {/* {currentSlot.student_list[0]?.student_name},{currentSlot.student_list[1]?.student_name},{currentSlot.student_list[2]?.student_name},{currentSlot.student_list[3]?.student_name} <i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: 'red' }}></i> */}
                            </td>
                          );
                        } else {
                          return (
                            <td key={`${teacher.employee_id}-${timeSlot}`}>
                              {/* ({teacher.employee_id}) {teacher.employee_first_name} */}
                            </td>
                          );
                        }
                      })}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show1} size="lg">
        <Modal.Header >
          <Modal.Title>Add Schedule</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style'>

            {/* <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Select Student<span class="asterisk">*</span></label>
                <select className='form-control' value={student}
                  onChange={(e) => setstudent(e.target.value)}>
                  <option value="">--Select Student--</option>
                  {studentList && studentList.map((item, i) => {
                    return (
                      <option key={i} value={item?.id} >{item?.first_name} {item?.last_name}</option>
                    )

                  })}
                </select>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Select Service<span className="asterisk">*</span></label>
                <select className='form-control' value={service} onChange={(e) => ScheduledurationHandle(e)}>
                  <option value="">--Select Service--</option>
                  {serviceList && serviceList.map((item, i) => (
                    <option key={i} value={item?.id}>{item?.name}</option>
                  ))}
                </select>
              </div>
            </div> */}

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Start Time<span class="asterisk">*</span></label>
                <select className='form-control' value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}>
                  <option value="">--Start Time---</option>
                  <option value="08:00">08:00 AM</option>
                  <option value="08:30">08:30 AM</option>
                  <option value="09:00">09:00 AM</option>
                  <option value="09:30">09:30 AM</option>
                  <option value="10:00">10:00 AM</option>
                  <option value="10:30">10:30 AM</option>
                  <option value="11:00">11:00 AM</option>
                  <option value="11:30">11:30 AM</option>
                  <option value="12:00">12:00 PM</option>
                  <option value="12:30">12:30 PM</option>
                  <option value="01:00">01:00 PM</option>
                  <option value="01:30">01:30 PM</option>
                  <option value="02:00">02:00 PM</option>
                  <option value="02:30">02:30 PM</option>
                  <option value="03:00">03:00 PM</option>
                  <option value="03:30">03:30 PM</option>
                  <option value="04:00">04:00 PM</option>
                  <option value="04:30">04:30 PM</option>
                  <option value="05:00">05:00 PM</option>
                  <option value="05:30">05:30 PM</option>
                  <option value="06:00">06:00 PM</option>
                  <option value="06:30">06:30 PM</option>
                  <option value="07:00">07:00 PM</option>
                  <option value="07:30">07:00 PM</option>
                  <option value="08:00">07:00 PM</option>
                </select>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>End Time<span class="asterisk">*</span></label>
                <select className='form-control' value={endTime} onChange={(e) => setEndTime(e.target.value)}>

                  <option value="">--End Time---</option>
                  <option value="08:00">08:00 AM</option>
                  <option value="08:30">08:30 AM</option>
                  <option value="09:00">09:00 AM</option>
                  <option value="09:30">09:30 AM</option>
                  <option value="10:00">10:00 AM</option>
                  <option value="10:30">10:30 AM</option>
                  <option value="11:00">11:00 AM</option>
                  <option value="11:30">11:30 AM</option>
                  <option value="12:00">12:00 PM</option>
                  <option value="12:30">12:30 PM</option>
                  <option value="01:00">01:00 PM</option>
                  <option value="01:30">01:30 PM</option>
                  <option value="02:00">02:00 PM</option>
                  <option value="02:30">02:30 PM</option>
                  <option value="03:00">03:00 PM</option>
                  <option value="03:30">03:30 PM</option>
                  <option value="04:00">04:00 PM</option>
                  <option value="04:30">04:30 PM</option>
                  <option value="05:00">05:00 PM</option>
                  <option value="05:30">05:30 PM</option>
                  <option value="06:00">06:00 PM</option>
                  <option value="06:30">06:30 PM</option>
                  <option value="07:00">07:00 PM</option>
                  <option value="07:30">07:00 PM</option>
                  <option value="08:00">07:00 PM</option>
                </select>
              </div>
            </div>

            <div className='col-lg-6 mt-2'>
              <div className='form-group d-flex align-item-center'>
                <ReactSwitch checked={checked} onChange={handleChange} />
                <label className='selectlabel ml-2 mt-1' style={{ fontSize: '14px' }}>Repeat</label>
              </div>
            </div>

            <div className='col-lg-6'>
            </div>

            {checked === true && (
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='selectlabel'>Repeat Type<span class="asterisk">*</span></label>
                  <select className='form-control' style={{ fontSize: '11px', paddingLeft: '10px' }} value={repeattype}
                    onChange={(e) => setrepeattype(e.target.value)}>
                    <option value="">Select Type</option>
                    <option value="daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                  </select>
                </div>
              </div>
            )}

            {repeattype === "Weekly" && checked === true && (
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='selectlabel'>Week Days<span class="asterisk">*</span></label>
                  <Dropdown className='custom-dropdown-checkbox'>
                    <Dropdown.Toggle variant="success" style={{ fontSize: '11px', paddingLeft: '10px' }}>Select Days
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <ul>
                        <li>
                          <input type="checkbox" name="allSelect" checked={selectedsubject?.length === DayList?.length}
                            onChange={(e) => ModuleHandle(e, DayList, 'dayname')}
                          />
                          <label>All Select</label>
                        </li>
                        {DayList && DayList.map((list, i) => {
                          return (
                            <li>
                              <input type="checkbox" name={list?.dayname + i} id={i} onChange={(e) => ModuleHandle(e, list, 'dayname')}

                              />
                              <label htmlFor={i}>{list?.dayname}</label>
                            </li>
                          )
                        })}

                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={SubmitScheduleHandle}>Save</button>
        </Modal.Footer>
      </Modal>

    </div>
  )

}

export default Schedule_TrailSession