import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetSubjectRequest, DeleteSubjectRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';

const SubjectList = () => {
  const [sname, setsname] = useState("")
  const [sgroup, setsgroup] = useState("")
  const [search, setsearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var SubList = fetchacompanyReducer?.GetSubjectResponse?.results
    var groupList = fetchacompanyReducer?.GetGroupResponse?.results
    console.log(fetchacompanyReducer.GetSubjectResponse)

    const dispatch = useDispatch()

    const columns = [
      {
          name: 'Subject Name',
          selector: row => row.subject_name,
          sortable: true,
      },
      {
          name: 'Subject Code',
          selector: row => row.subject_code,
          sortable: true,
      },
      {
        name: 'Group Name',
        selector: row => row.group_name,
        sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.subject_description,
  },
    {
      name: 'Action',
      selector: (row) => (
        <>
         <NavLink to={`/${userinfo?.company_slug}/curriculum/subject/edit-subject/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
        <button onClick={()=>DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
        </>
      ),
  },
  ];

  useEffect(()=>{
    const result = SubList && SubList.filter(data =>{
      return data.subject_name.toLowerCase().match(search.toLowerCase())
    })
    setFilterResult(result)
  },[search])


    useEffect(()=>{
      let obj= {
        'name':'',
        'group_id':'',
      }
        dispatch(GetSubjectRequest(obj))
    },[])

    useEffect(()=>{

      setFilterResult(SubList)
    },[fetchacompanyReducer?.GetSubjectResponse])

useEffect(()=>{
  let obj= {
    'name':'',
    'group_id':'',
  }
    dispatch(GetSubjectRequest(obj))
},[fetchacompanyReducer?.DeleteSubjectResponse])


    const DeleteHandle = (id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteSubjectRequest(id))

                if(fetchacompanyReducer?.DeleteSubjectResponse){
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      )
                }
             
            }
          })
    }

    const GroupHandle = (e)=>{
      setsgroup(e.target.value)
      var val = e.target.value
      console.log(val)

      let obj= {
        'name':search,
        'group_id':val,
      }
  
      dispatch(GetSubjectRequest(obj))
    }
  useEffect(()=>{

    setFilterResult(SubList)
  },[fetchacompanyReducer?.GetSubjectResponse])

  



  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
      <div className='innerlayout p-3'>
      <div className='mb-4'>
        <h3> Subject Management</h3>
        </div>
        <div className='row form-style mb-4 align-items-center'>
            <div className='col-lg-3'>
                <div className='form-group'>
                    <label>Subject Name</label>
                    <input type='text' className='form-control' placeholder='Subject Name'
                    value={search}
                    onChange={(e)=>setsearch(e.target.value)}
                    />
                </div>
            </div>
           
           
            <div className='col-lg-3'>
           <div className='form-group'>
           <label>Select Group</label>
              <select className='form-control'  value={sgroup}
               onChange={(e)=>GroupHandle(e)}>
                <option value="">--All---</option>
                {groupList&&groupList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.name}</option>
                    )
                })}
              </select>
           </div>
       </div>
     
        </div>
        <div className='row mb-3'>
                <div className='col-lg-3'>
                <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/curriculum/subject/add-subject`}><i className="fa-solid fa-plus"></i> Add Subject</NavLink>

                </div>
        </div>
 
      <div className='table-responsive'>

      <DataTable
        columns={columns}
        data={FilterResult}
        pagination
        highlightOnHover
       />



      </div>
      </div>
  </div>
  )
}

export default SubjectList