import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { GettestRequest, DeletetestRequest, AssignScheduleRequest, GetstudentRequest, GetGradeRequest, GetSubjectRequest, AssignGroupScheduleRequest, GetGradeStudentRequest, GettestfilterRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import ReactTooltip from "react-tooltip";

const ViewAssessment = ({ id, etype }) => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [show1, setShow1] = useState(false);
    const [editrowId, seteditrowId] = useState(null)
    const [show, setShow] = useState(false);
    const [addrowId, setaddrowId] = useState(null)
    const [studentname, setstudentname] = useState("")
    const [time, settime] = useState("")
    const [time1, settime1] = useState("")
    const [assessmentdate, setassessmentdate] = useState(null)
    const [assessmentdate1, setassessmentdate1] = useState(null)
    const [starttime, setstarttime] = useState("")
    const [starttime1, setstarttime1] = useState("")
    const [endtime, setendtime] = useState("")
    const [endtime1, setendtime1] = useState("")
    const [subname, setsubname] = useState("")
    const [sgroup, setsgroup] = useState("")
    const [sgrade, setsgrade] = useState("")
    const handleClose1 = () => setShow1(false);
    const handleClose = () => setShow(false);
    const [status, setstatus] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var testList = fetchacompanyReducer?.GettestfilterResponse?.results
    var studentList = fetchacompanyReducer?.GetstudentResponse?.results
    var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var subList = fetchacompanyReducer?.GetSubjectResponse?.results
    var GradeStdList = fetchacompanyReducer?.GetGradeStudentResponse?.results

    const [StudentList , setStudentList] = useState([])
    const [selectedUser, setSelectedUser] = useState([]);

    const [GradeStudentList , setGradeStudentList] = useState([])
    const [GradeselectedUser, setGradeselectedUser] = useState([]);


    console.log(selectedUser)

    const dispatch = useDispatch()
    let navigate = useNavigate()


    const columns = [
        {
            name: 'Assessment Type',
            selector: row => row.test_type,
            sortable: true,
        },
        {
            name: 'Assessment Name/Code',
            selector: row => row.name + ' (' + row.code + ')',
            sortable: true,
        },
        {
            name: 'Grade',
            selector: row => row.grade_name,
            sortable: true,
        }, {
            name: 'Subject',
            selector: row => row.subject_name,
            sortable: true,
        },
        {
            name: 'Assign',
            selector: (row) => (
                <>
                    {row.test_type != 'group' ? <button className='formBtn assignbtn' onClick={row.is_has_question != '0' ? () => handleShow1(row?.id,row?.grade_id): () => AssignHandle()} style={{opacity:row.is_has_question != '0' ? "1" : '0.6'}}>Assign Now</button>
                    :
                    <button className='formBtn assignbtn' onClick={row.is_has_question != '0' ? () => handleShow(row?.id,row?.grade_id): () => AssignHandle()} style={{opacity:row.is_has_question != '0' ? "1" : '0.6'}}>Schedule Now</button>  
                    } 
                </>
            ),
        },

        {
            name: 'Action',
            selector: (row) => (
                <>
                    <NavLink to={`/${userinfo?.company_slug}/Test/Assessmentdetails/${row?.id}`}><i className="fa-solid fa-eye" style={{ marginRight: '8px' }}></i></NavLink>
                    <NavLink to={`/${userinfo?.company_slug}/Test/Editassessment/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                    <button onClick={() => DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
                </>
            ),
        },
    ];

    useEffect(() => {
        let obj = {
            'name': '',
            'grade_id': '',
            'subject_id': '',
            'test_type': ''
        }
        dispatch(GettestfilterRequest(obj))
    }, [])

    // useEffect(() => {
    //     dispatch(GetstudentRequest())
    // }, [])

    // useEffect(() => {
    //     let obj = {
    //         'grade_id': id,
    //     }
    //     dispatch(GetGradeStudentRequest(obj))
    // }, [])

    useEffect(() => {

        let obj1 = {
            'name': '',
            'group_id': '',
        }

        dispatch(GetGradeRequest())
        dispatch(GetSubjectRequest(obj1))
    }, [])

    const GradeHandle = (e) => {
        var val = e.target.value
        setsgrade(val)
        console.log(val)

        let obj = {
            'name': search,
            'grade_id': val,
            'subject_id': subname,
            'test_type': status
        }

        dispatch(GettestfilterRequest(obj))
        console.log(fetchacompanyReducer?.GettestfilterResponse)
    }

    const AssessmentHandle = (e) => {
        var val = e.target.value
        setstatus(val)
        console.log(val)

        let obj = {
            'name': search,
            'grade_id': sgrade,
            'subject_id': subname,
            'test_type': val
        }

        dispatch(GettestfilterRequest(obj))
        console.log(fetchacompanyReducer?.GettestfilterResponse)
    }

    const SubjectHandle = (e) => {
        var val = e.target.value
        setsubname(val)
        console.log(val)

        let obj = {
            'name': search,
            'grade_id': sgrade,
            'subject_id': val,
            'test_type': status
        }

        dispatch(GettestfilterRequest(obj))
    }

    useEffect(() => {
        const result = testList && testList.filter(data => {
            return data.name?.toLowerCase().match(Namesearch?.toLowerCase())
        })
        setFilterResult(result)
    }, [Namesearch])

    useEffect(() => {
        dispatch(GettestRequest())
    }, [fetchacompanyReducer?.DeletetestResponse])

    useEffect(() => {
       
        setStudentList(studentList)

    }, [fetchacompanyReducer?.GetstudentResponse])

    useEffect(() => {
       
        setGradeStudentList(GradeStdList)

    }, [fetchacompanyReducer?.GetGradeStudentResponse])

    useEffect(() => {

        setFilterResult(testList)
    }, [fetchacompanyReducer?.GettestfilterResponse])

    const handleShow1 = (tid) => {
        seteditrowId(tid)
        setShow1(true)
        dispatch(GetstudentRequest(tid))
    };

    useEffect(() => {
        setShow1(false)
    }, [fetchacompanyReducer?.AssignScheduleResponse])

    useEffect(() => {
        setShow(false)
    }, [fetchacompanyReducer?.AssignGroupScheduleResponse])

    const handleShow = (tid,grade_id) => {
        console.log(tid,grade_id)
        setaddrowId(tid,grade_id)
        setShow(true)
        dispatch(GetGradeStudentRequest({tid:tid,grade_id:grade_id}))
    };

    const StartTimeHandle = (e) => {
        setstarttime(e.target.value)

        settime(e.target.value);
    }

    const StartTimeHandle1 = (e) => {
        setstarttime1(e.target.value)

        settime1(e.target.value);
    }

    

    const EndTimeHandle = (e) => {
        settime(e.target.value)

        const start = new Date(`1970-01-01T${starttime}`);
        const durationInMinutes = parseInt(e.target.value, 10);
        const end = new Date(start.getTime() + durationInMinutes * 60000);
        const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
        setendtime(formattedEndtime);
    }

    const EndTimeHandle1 = (e) => {
        settime1(e.target.value)

        const start = new Date(`1970-01-01T${starttime1}`);
        const durationInMinutes = parseInt(e.target.value, 10);
        const end = new Date(start.getTime() + durationInMinutes * 60000);
        const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
        setendtime1(formattedEndtime);
    }

    

    const AddIndSchduleHandle = () => {

        if (selectedUser.length < 0) {
            Swal.fire({
                icon: "error",
                text: 'Student Name should be must',
            });
        } else if (starttime == '') {
            Swal.fire({
                icon: "error",
                text: 'Start Time should be must',
            });
        } else if (endtime == '') {
            Swal.fire({
                icon: "error",
                text: 'End Time should be must',
            });
        } else if (time == '') {
            Swal.fire({
                icon: "error",
                text: 'Time should be must',
            });
        } else {

            var Temp = []
            selectedUser&&selectedUser.map((item)=>{
                var dataitem =  Number(item.id);
                Temp.push(dataitem);
            })


            let obj = {
                "student_id": Temp,
                "test_date": moment(assessmentdate).format("YYYY-MM-DD"),
                "test_time": Number(time),
                "start_time": starttime,
                "end_time": endtime
            }

            dispatch(AssignScheduleRequest({ obj: obj, TestId: editrowId, navigate: navigate }))
        }
    }

    const AddGroupSchduleHandle = () => {

        if (GradeselectedUser.length < 0) {
            Swal.fire({
                icon: "error",
                text: 'Student Name should be must',
            });
        } else if (starttime1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Start Time should be must',
            });
        } else if (endtime1 == '') {
            Swal.fire({
                icon: "error",
                text: 'End Time should be must',
            });
        } else if (time1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Time should be must',
            });
        } else {

            var Temp = []
            GradeselectedUser&&GradeselectedUser.map((item)=>{
                var dataitem =  Number(item.student_user_id);
                Temp.push(dataitem);
            })

            let obj = {
                "student_id": Temp,
                "test_date": moment(assessmentdate1).format("YYYY-MM-DD"),
                "test_time": Number(time1),
                "start_time": starttime1,
                "end_time": endtime1
            }

            dispatch(AssignGroupScheduleRequest({ obj: obj, TestId: addrowId, navigate: navigate }))
        }
    }

    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeletetestRequest(id))

                if (fetchacompanyReducer?.DeletetestRequest) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }

    const AssignHandle = () => {
        Swal.fire({
            title: 'No Questions In This Assessment',
            text: "Please Add Questions First to Assign!",
            icon: 'warning',
        })
    }

    const handleChange = (e, data) =>{
        const {name, checked} = e.target
        if (checked) {
            // if cheked and selectall checkbox add all fileds to selectedList
            if (name === "allSelect") {
              setSelectedUser(StudentList);
            } else {
              // if cheked and specific checkbox add specific field to selectedList
              setSelectedUser([...selectedUser, data]);
            }
          } else {
            // if uncheked and selectall checkbox add remove all fileds from selectedList
            if (name === "allSelect") {
              setSelectedUser([]);
            } else {
              // if uncheked and specific checkbox remove specific field from selectedList
              let tempuser = selectedUser.filter((item) => item.id !== data.id);
              setSelectedUser(tempuser);
            }
          }
    }

    const GradehandleChange = (e, data) =>{
        const {name, checked} = e.target
        if (checked) {
            // if cheked and selectall checkbox add all fileds to selectedList
            if (name === "allSelect") {
                setGradeselectedUser(GradeStdList);
            } else {
              // if cheked and specific checkbox add specific field to selectedList
              setGradeselectedUser([...GradeselectedUser, data]);
            }
          } else {
            // if uncheked and selectall checkbox add remove all fileds from selectedList
            if (name === "allSelect") {
              setGradeselectedUser([]);
            } else {
              // if uncheked and specific checkbox remove specific field from selectedList
              let tempuser = GradeselectedUser.filter((item) => item.id !== data.id);
              setGradeselectedUser(tempuser);
            }
          }
    }


    







    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Assessment Managemenet</h3>
                </div>
                {/* <NavLink className='formBtn1 testadd' to={`/${userinfo?.company_slug}/Test/Viewtest`}><i className="fa-solid fa-plus"></i> Test</NavLink> */}


                <div className='row form-style mb-4 align-items-center'>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label>Assessment Name</label>
                            <input type='text' className='form-control' placeholder='Assessment Name'
                                value={Namesearch}
                                onChange={(e) => setNamesearch(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label>Assessment Type</label>
                            {/* <input type='text' className='form-control' placeholder='Assessment Type'
                                value={search}
                                onChange={(e) => setsearch(e.target.value)}
                            /> */}
                            <select className='form-control' value={sgrade}
                                onChange={(e)=>AssessmentHandle(e)}>
                                <option value="">All</option>
                                <option value="Individual">Individual</option>
                                <option value="Group">Group</option>
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label>Select Grade</label>
                            <select className='form-control' value={sgrade}
                                onChange={(e) => GradeHandle(e)}>
                                <option value="">All</option>
                                {gradeList && gradeList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id}>{item?.grade_name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label>Select Subject</label>
                            <select className='form-control' value={subname}
                                onChange={(e) => SubjectHandle(e)}>
                                <option value="">All</option>
                                {subList && subList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id}>{item?.subject_name}</option>
                                    )
                                })}
                            </select>
                        </div>
                    </div>
                </div>

                <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Test/Addassessment`}><i className="fa-solid fa-plus"></i> Add Assessment</NavLink>
                        </li>

                    </ul>
                </div>

                <div className='table-responsive'>
                    <DataTable
                        columns={columns}
                        data={FilterResult}
                        pagination
                        highlightOnHover
                    />

                </div>


            </div>
            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Assessment Assign</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Student</label>
                            <Dropdown className='custom-dropdown-checkbox'>
                            <Dropdown.Toggle variant="success">
                                {selectedUser.length > 0 ? 'select ' + selectedUser.length : 'select student'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <ul>
                                    <li>
                                        <input type="checkbox" name="allSelect" checked={selectedUser?.length === StudentList?.length}
                                         onChange={(e) => handleChange(e, StudentList)}
                                        />
                                        <label>All Select</label>
                                    </li>
                                    {StudentList&&StudentList.map((list, i) =>{
                                        return (
                                            <li>
                                                <input type="checkbox" name={list?.first_name+list?.last_name+i} id={i} onChange={(e) => handleChange(e, list)}
                                                checked={selectedUser.some((item) => item?.id === list.id)}
                                                />
                                                <label htmlFor={i}>{list?.first_name} {list?.last_name}</label>
                                            </li>
                                        )
                                    })}
                                    
                                   
                                </ul>
                            </Dropdown.Menu>
                            </Dropdown>
                             
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Schedule Date</label>
                                <DatePicker selected={assessmentdate} onChange={(date) => { setassessmentdate(date) }} className='form-control'
                                    placeholderText="Schedule Date"
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Schedule Time</label>
                                <input type='time' className='form-control' placeholder='Start Time'
                                    value={starttime}
                                    onChange={StartTimeHandle}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>Duration</label>
                                {/* <input type='number' className='form-control' placeholder='Duration (In Minutes)'
                                value={time} onChange={EndTimeHandle}
                            /> */}
                                <select className='form-control' value={time}
                                    onChange={EndTimeHandle}>
                                    <option value="">--Duration--</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                    <option value="150">150</option>
                                    <option value="180">180</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <label>End Time</label>
                                <input type='text' className='form-control' placeholder='End Time'
                                    value={endtime}
                                    onChange={(e) => setendtime(e.target.value)}
                                    readOnly />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddIndSchduleHandle}>Add</button>

                </Modal.Footer>
            </Modal>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Schedule Assign</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                            <Dropdown className='custom-dropdown-checkbox'>
                            <Dropdown.Toggle variant="success">
                                {GradeselectedUser.length > 0 ? 'select ' + GradeselectedUser.length : 'select student'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <ul>
                                    <li>
                                        <input type="checkbox" name="allSelect" checked={GradeselectedUser?.length === GradeStudentList?.length}
                                         onChange={(e) => GradehandleChange(e, GradeStudentList)}
                                        />
                                        <label>All Select</label>
                                    </li>
                                    {GradeStudentList&&GradeStudentList.map((list, i) =>{
                                        return (
                                            <li>
                                                <input type="checkbox" name={list?.first_name+list?.last_name+i} id={i} onChange={(e) => GradehandleChange(e, list)}
                                                checked={GradeselectedUser.some((item) => item?.id === list.id)}
                                                />
                                                <label htmlFor={i}>{list?.first_name} {list?.last_name}</label>
                                            </li>
                                        )
                                    })}
                                    
                                   
                                </ul>
                            </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <DatePicker selected={assessmentdate1} onChange={(date) => { setassessmentdate1(date) }} className='form-control'
                                    placeholderText="Schedule Date"
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <input type='time' className='form-control' placeholder='Start Time'
                                    value={starttime1}
                                    onChange={StartTimeHandle1}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                {/* <input type='number' className='form-control' placeholder='Duration (In Minutes)'
                                value={time} onChange={EndTimeHandle}
                            /> */}
                                <select className='form-control' value={time1}
                                    onChange={EndTimeHandle1}>
                                    <option value="">--Duration--</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                    <option value="150">150</option>
                                    <option value="180">180</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <input type='text' className='form-control' placeholder='End Time'
                                    value={endtime1}
                                    onChange={(e) => setendtime1(e.target.value)}
                                    readOnly />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddGroupSchduleHandle}>Add</button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ViewAssessment