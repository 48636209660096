import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { DeletestudentRequest, ViewstudentRequest, ExportstudentRequest, GetStudentListRequest, GetGradeRequest, GetTeacherRequest, AddServiceSubjectRequest, AddServicesRequest, AddPricingRequest, AddStaffRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../utils/ApiConnection'
import Dropdown from 'react-bootstrap/Dropdown';
import ReactSwitch from 'react-switch';


const ServiceList = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [subject, setsubject] = useState("");
    const [subject1, setsubject1] = useState("");
    const [grade, setgrade] = useState("");
    const [grade1, setgrade1] = useState("");
    const [name, setname] = useState("");
    const [price, setprice] = useState("");
    const [name1, setname1] = useState("");
    const [price1, setprice1] = useState("");
    const [servicename, setservicename] = useState("");
    const [servicename1, setservicename1] = useState("");
    const [session, setsession] = useState("");
    const [session1, setsession1] = useState("");
    const [staff, setstaff] = useState("");
    const [recurring, setrecurring] = useState("");
    const [recurring1, setrecurring1] = useState("");
    const [valid, setvalid] = useState("");
    const [valid1, setvalid1] = useState("");
    const [desc, setdesc] = useState("");
    const [desc1, setdesc1] = useState("");
    const [subjectList, setsubjectList] = useState([])
    const [teacherList, setteacherList] = useState([])
    const [TermsofServicesList, setTermsofServicesList] = useState([])
    const [serviceList, setserviceList] = useState([])
    const [staffList, setstaffList] = useState([])
    const [servicePriceList, setservicePriceList] = useState([])
    const [docId, setdocId] = useState(null);
    const [SerId, setSerId] = useState(null);
    const [rejected, setrejected] = useState(true)
    const handleClose = () => setShow(false);
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);
    const handleClose5 = () => setShow5(false);
    const handleClose6 = () => setShow6(false);
    const handleClose7 = () => setShow7(false);
    const [selectedgrade, setselectedgrade] = useState([]);
    const [checked, setChecked] = useState(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var studentList = fetchacompanyReducer?.GetStudentListResponse?.results
    var GradeList = fetchacompanyReducer?.GetGradeResponse?.results

    const dispatch = useDispatch()

    const [traininfo, settraininfo] = useState([{
        label: '',
        assigndate: null,
        duedate: null,
        completedate: null,
        isdisabled: false
    }]);

    const GetSubject = async () => {
        let obj = {
            name: '',
            group_id: ''
        }
        try {
            const response = await ApiConnection.get(`subjects-crud/?server_type=production&name=${obj.name}&group_id=${obj.group_id}`)
            if (response?.status == 200) {
                console.log("get subject", response.data)
                setsubjectList(response?.data?.results)
            }
        } catch (e) { }
    }

    const GetTeacher = async () => {
        try {
            const response = await ApiConnection.get(`employee-crud/?server_type=production`)
            if (response?.status == 200) {
                setteacherList(response?.data?.results)
            }
        } catch (e) { }
    }

    const GetService = async () => {
        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production`)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
            }
        } catch (e) { }
    }

    // const GetSubTeachList = async () => {
    //     try {
    //         const response = await ApiConnection.get(`employee-teacher/subject-wise/?server_type=production&subject_id=${2}`)
    //         if (response?.status == 200) {
    //             setserviceList(response?.data?.results)
    //         }
    //     } catch (e) { }
    // }

    const handleChange = (val) => {
        console.log('setChecked', val)
        setChecked(val);
    };

    const GetTermsofServices = async () => {
        try {
            const response = await ApiConnection.get(`service-terms-condition-crud/?server_type=production`)
            if (response?.status == 200) {
                setTermsofServicesList(response?.data?.results)
            }
        } catch (e) { }
    }

    const GetServiceTeacher = async () => {
        try {
            const response = await ApiConnection.get(`service-staff-crud/?server_type=production`)
            if (response?.status == 200) {
                setstaffList(response?.data?.results)
            }
        } catch (e) { }
    }

    const ServiceFilterHandle = async (e) => {

        var val = e.target.value
        setsubject(val)

        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production&subject_id=${val}`,)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
            }
        } catch (e) { }
    }

    const ServiceNameSeacrhHandle = async (e) => {

        var val = e.target.value
        setservicename(val)

        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production&name=${val}`,)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
            }
        } catch (e) { }
    }

    const SubmitServiceHandle = async () => {
        if (subject == '') {
            Swal.fire({
                icon: "error",
                text: 'Subject should be must',

            });
        } else if (servicename == '') {
            Swal.fire({
                icon: "error",
                text: 'Service Name should be must',
            });
        } else {

            var Temp = []
            selectedgrade && selectedgrade.map((item) => {
                var dataitem = Number(item.id);
                Temp.push(dataitem);
            })

            let obj = {
                "is_free_trial": checked,
                "class_ids": Temp,
                "subject_id": subject,
                "name": servicename
            }

            try {
                const response = await ApiConnection.post(`service_crud/?server_type=production`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetService()
                    setShow(false)
                }
            } catch (e) { }
        }
    }

    useEffect(() => {
        dispatch(GetGradeRequest())
    }, [])

    const AddTermsandCondHandle = async () => {
        if (desc == '') {
            Swal.fire({
                icon: "error",
                text: 'Description should be must',

            });
        } else {

            let obj2 = {
                id: docId
            }

            let obj = {
                "text_content": desc,
            }

            try {
                const response = await ApiConnection.post(`service-terms-condition-crud/?server_type=production&service_id=${obj2.id}`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetService()
                    setShow6(false)
                }
            } catch (e) { }
        }
    }

    const UpdateTermsandCondHandle = async () => {
        if (desc1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Description should be must',

            });
        } else {

            let obj2 = {
                id: docId,
                Sid: SerId
            }

            let obj = {
                "text_content": desc1,
            }

            try {
                const response = await ApiConnection.put(`service-terms-condition-crud/?server_type=production&id=${docId}&service_id=${SerId}&method=edit`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetService()
                    setShow7(false)
                }
            } catch (e) { }
        }
    }

    const SubmitTeacherHandle = async (Tid) => {
        if (staff == '') {
            Swal.fire({
                icon: "error",
                text: 'Staff should be must',

            });
        } else {

            let obj1 = {
                id: docId
            }

            let obj = {
                "employee_id": staff,
            }

            try {
                const response = await ApiConnection.post(`service-staff-crud/?server_type=production&service_id=${docId}`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetService()
                    setShow2(false)
                }
            } catch (e) { }
        }
    }

    const UpdateServiceHandle = async () => {
        if (subject1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Subject should be must',

            });
        } else if (servicename1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Service Name should be must',
            });
        } else {

            let obj2 = {
                id: docId
            }

            var Temp = []
            selectedgrade && selectedgrade.map((item) => {
                var dataitem = Number(item.id);
                Temp.push(dataitem);
            })

            let obj = {
                "class_ids": Temp,
                "subject_id": subject1,
                "name": servicename1
            }

            try {
                const response = await ApiConnection.put(`service_crud/?server_type=production&id=${obj2.id}&method=edit`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetService()
                    setShow4(false)
                }
            } catch (e) { }
        }
    }

    const ServiceStatusHandle = async (id) => {

        let obj2 = {
            id: id
        }

        let obj = {
            "is_active": rejected == false ? 0 : 1,
        }

        try {
            Swal.fire({
                title: 'Are you sure you want change the status?',
                //   text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change the status!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`service-status/?server_type=production&id=${obj2.id}`, obj)
                    if (response?.status == 200) {
                        Swal.fire({
                            icon: "success",
                            text: response?.data?.msg,
                        });
                        GetService()
                    }
                }
            })
        } catch (e) { }
    }

    const PricingStatusHandle = async (id, docId) => {

        let obj2 = {
            id: id
        }


        let obj1 = {
            id: docId
        }

        let obj = {
            "is_active": rejected == false ? 0 : 1,
        }

        try {
            Swal.fire({
                title: 'Are you sure you want change the status?',
                //   text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change the status!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`service-price-status/?server_type=production&id=${obj2.id}&service_id=${docId}`, obj)
                    if (response?.status == 200) {
                        Swal.fire({
                            icon: "success",
                            text: response?.data?.msg,
                        });
                        GetService()
                    }
                }
            })
        } catch (e) { }
    }

    const UpdatePricingHandle = async () => {
        if (name1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Price Name should be must',

            });
        } else if (session1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Session should be must',
            });
        } else if (price1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Price should be must',
            });
        } else if (recurring1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Recurring should be must',
            });
        } else {

            let obj2 = {
                id: docId,
                Sid: SerId
            }

            let obj = {
                "name": name1,
                "total_session": session1,
                "price": price1,
                "recurring": recurring1,
                "valid_days": valid1
            }

            try {
                const response = await ApiConnection.put(`pricing_crud/?server_type=production&id=${obj2.id}&service_id=${obj2.Sid}&method=edit`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetService()
                    setShow5(false)
                }
            } catch (e) { }
        }
    }

    const DeleteHandle = async (id, Delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`pricing_crud/?server_type=production&id=${id}&service_id=${Delid}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        GetService()
                        setShow5(false)
                    }
                }
            })
        } catch (e) { }
    }

    const StaffDeleteHandle = async (id, Delid) => {



        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`service-staff-crud/?server_type=production&id=${id}&service_id=${Delid}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        GetService()
                        //   setShow5(false)
                    }
                }
            })


        } catch (e) { }
    }

    const ServiceTermsDeleteHandle = async (id, Delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`service-terms-condition-crud/?server_type=production&id=${id}&service_id=${Delid}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        GetService()
                        //   setShow5(false)
                    }
                }
            })
        } catch (e) { }
    }

    const SubmitpricingHandle = async () => {
        if (name == '') {
            Swal.fire({
                icon: "error",
                text: 'Name should be must',

            });
        } else if (session == '') {
            Swal.fire({
                icon: "error",
                text: 'Total Session should be must',
            });
        } else if (price == '') {
            Swal.fire({
                icon: "error",
                text: 'Price should be must',
            });
        } else {

            let obj1 = {
                id: docId
            }

            let obj = {
                "name": name,
                "total_session": session,
                "price": price,
                "recurring": recurring,
                "valid_days": valid
            }

            try {
                const response = await ApiConnection.post(`pricing_crud/?server_type=production&service_id=${obj1.id}`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetService()
                    setShow1(false)
                }
            } catch (e) { }
        }
    }


    useEffect(() => {
        GetSubject()
        GetTeacher()
        GetService()
        GetTermsofServices()
        // GetSubTeachList()
    }, [])

    const handleShow = () => {
        setShow(true)
    };

    const handleShow1 = (id) => {
        setdocId(id)
        setShow1(true)
    };

    const handleShow2 = async (id) => {
        setdocId(id)
        setShow2(true)

        try {
            const response = await ApiConnection.get(`service-staff-crud/?server_type=production`)
            setgrade1(response.data.results[0].class_id);
            setsubject1(response.data.results[0].subject_id);

        } catch (e) { }
    };

    const handleShow6 = (id) => {
        setdocId(id)
        setShow6(true)
    };

    const handleShow7 = async (id, Sid) => {
        console.log(id, Sid)
        setdocId(id)
        setSerId(Sid)
        setShow7(true)

        try {
            const response = await ApiConnection.get(`service-terms-condition-crud/?server_type=production&id=${id}&service_id=${Sid}`)
            console.log(response.data)

            setdesc1(response.data.results[0].text_content);

        } catch (e) { }

    };

    const handleShow3 = () => {
        setShow3(true)
    };

    const handleShow4 = async (id) => {
        console.log("handleShow4", id);
        setdocId(id)
        setShow4(true)

        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production&id=${id}`)

            setservicename1(response.data.results[0].name);
            setsubject1(response.data.results[0].subject_id);
            // setgrade1(response.data.results[0].class_id);
            setselectedgrade(GradeList.filter(grade => response.data.results[0].class_id.includes(grade.id)));

        } catch (e) { }
    };

    const handleShow5 = async (id, Sid) => {
        setdocId(id)
        setSerId(Sid)
        setShow5(true)

        let obj2 = {
            id: docId,
            Sid: SerId
        }

        let obj = {
            "name": name1,
            "total_session": session1,
            "price": price1,
            "recurring": recurring1,
            "valid_days": valid1
        }

        try {
            const response = await ApiConnection.get(`pricing_crud/?server_type=production&id=${id}&service_id=${Sid}`, obj)
            console.log(response.data.results[0].name);

            setname1(response.data.results[0].name);
            setsession1(response.data.results[0].total_session);
            setprice1(response.data.results[0].price);
            setrecurring1(response.data.results[0].recurring);


        } catch (e) { }


    };

    const column = [
        {
            "key": "First Name",
            "value": "First_Name",
            "ischecked": false
        },
        {
            "key": "Teacher",
            "value": "Arnab",
            "ischecked": false
        },

    ]


    const [columnName, setColumnName] = useState(column)

    useEffect(() => {
        const result = studentList && studentList.filter(data => {
            return data.first_name?.toLowerCase().match(Namesearch?.toLowerCase())
        })
        setFilterResult(result)
    }, [Namesearch])

    useEffect(() => {
        setFilterResult(studentList)
    }, [fetchacompanyReducer?.GetStudentListResponse])

    useEffect(() => {
        dispatch(GetStudentListRequest())
    }, [])

    useEffect(() => {
        dispatch(GetStudentListRequest())
    }, [fetchacompanyReducer?.DeletestudentResponse])

    const SubjectHandle = (e) => {
        setsubject(e.target.value)
    }

    const SubjectHandle2 = (e) => {
        setsubject1(e.target.value)
    }

    const AddServiceSubjectHandle = () => {

        if (subject == '') {
            Swal.fire({
                icon: "error",
                text: 'Subject should be must',

            });
        } else {

            let obj = {
                "subject_id": subject,
            }
            dispatch(AddServiceSubjectRequest(obj))
        }
    }

    const ModuleHandle = (e, data) => {
        console.log(data)
        const { name, checked } = e.target
        if (checked) {
            if (name === "allSelect") {
                setselectedgrade(GradeList);
            } else {
                setselectedgrade([...selectedgrade, data]);
            }
        } else {
            if (name === "allSelect") {
                setselectedgrade([]);
            } else {
                let tempuser = GradeList.filter((item) => item.id !== data.id);
                setselectedgrade(tempuser);
            }
        }
    }



    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Services & Pricing </h3>
                </div>

                <div className='button-part mb-4' style={{ float: "right", marginTop: "-67px" }}>
                    <ul>
                        <li>
                            <div className='form-group'>
                                <input type='text' className='form-control' placeholder='Service Name...'
                                    onChange={(e) => ServiceNameSeacrhHandle(e)} value={servicename}
                                />
                            </div>
                        </li>
                        <li>
                            <div className='form-group'>
                                <select className='form-control'
                                    onChange={(e) => ServiceFilterHandle(e)}>
                                    <option value="">--Select Subject--</option>
                                    <option value="">All</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </li>
                        <li style={{ marginTop: '-15px' }}>
                            <button className='formBtn1' onClick={handleShow}><i className="fa-solid fa-plus"></i> Add Service</button>
                        </li>
                    </ul>
                </div>
                <a id="Download" download></a>
                <Accordion defaultActiveKey="0" className='view-accordian'>
                    {serviceList.map((item, index) => {
                        return (
                            <Accordion.Item eventKey={parseInt(index)} id={parseInt(index)} className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6 className='d-flex'><input type="checkbox" style={{ marginBottom: '11px', marginRight: '2px' }} onChange={() => ServiceStatusHandle(item?.id)} defaultChecked={rejected} /> {item?.name}
                                        {item?.class_list && item?.class_list.map((data, i) => {
                                            return (
                                                <span key={i} value={data?.id} > &nbsp; {data?.class_name} </span>
                                            )
                                        })}
                                        &nbsp;
                                        <button className='btn btn-primary btn-sm' onClick={() => handleShow4(item?.id)}><i className="fa-solid fa-pen-to-square"></i></button>
                                    </h6>

                                    <ul className='d-flex justify-content-end'>
                                        {/* <li>   ({item?.class_list[0]?.class_name})
                                    <NavLink className='btn btn-primary btn-sm' onClick={handleShow3}><i className="fa-solid fa-plus"></i> Add Subject</NavLink>
                                </li> */}
                                        <li>
                                            <NavLink className='btn btn-primary btn-sm mx-2' onClick={() => handleShow1(item?.id)}><i className="fa-solid fa-plus"></i> Add Pricing</NavLink>
                                        </li>
                                        {/* <li>
                                            <NavLink className='btn btn-primary btn-sm' onClick={() => handleShow2(item?.id)}><i className="fa-solid fa-plus"></i> Add Staff</NavLink>
                                        </li> */}
                                    </ul>
                                </div>

                                <Accordion.Body>
                                    <div className='table-responsive'>
                                        <table className='custom-table'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Total Sessions</th>
                                                    <th>Price</th>
                                                    <th>Monthly Recurring</th>
                                                    <th>Status</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item?.pricing_list && item?.pricing_list.map((data, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{data?.name}</td>
                                                            <td>{data?.total_session}</td>
                                                            <td>{data?.price}</td>
                                                            <td>{data?.recurring}
                                                                {data?.recurring === 'No' && (
                                                                    <span> ({data?.valid_days}) Days </span>
                                                                )}
                                                            </td>
                                                            <td>
                                                                <input type="checkbox" onChange={() => PricingStatusHandle(data?.id, data?.service_id)} defaultChecked={rejected} />                                                            </td>
                                                            <td>
                                                                <button><i className="fa-solid fa-pen-to-square" onClick={() => handleShow5(data?.id, data?.service_id)}></i></button>
                                                                <button><i className="fa-solid fa-trash" onClick={() => DeleteHandle(data?.id, data?.service_id)}></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}



                                            </tbody>

                                        </table>

                                        <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                            <span style={{ fontWeight: "600" }}> Staff Members : </span> <br></br>
                                            {item?.service_staff_lists && item?.service_staff_lists.map((tdata, index) => {
                                                return (
                                                    <span style={{ fontSize: "14px" }}>
                                                        <button className='btn-info' style={{ cursor: 'default' }}>{tdata?.employee_first_name} {tdata?.employee_last_name} </button> &nbsp;
                                                        {/* <i className="fa-solid fa-trash" style={{ cursor: 'pointer' }} onClick={() => StaffDeleteHandle(tdata?.id, tdata?.service_id)}></i> */}
                                                    </span>
                                                )
                                            })}
                                        </div>

                                        <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                            <span style={{ fontWeight: "600" }}> Terms of Services : </span> <br></br>
                                            <div className='d-flex justify-content-end pr-0'>

                                                <ul className='d-flex justify-content-end'>
                                                    <li>
                                                        <NavLink className='btn btn-primary btn-sm mx-2' onClick={() => handleShow6(item?.id)}><i className="fa-solid fa-plus"></i> Add Terms of Services</NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                            {item?.service_terms_conditions_lists && item?.service_terms_conditions_lists.map((Sdata, index) => {
                                                return (
                                                    <p style={{ fontSize: "14px", border: '1px solid #cccdbb', padding: '5px 10px', margin: '5px 0px 5px 0px' }}>
                                                        <button className='btn btn-primary btn-sm' onClick={() => handleShow7(Sdata?.id, Sdata?.service_id)}><i className="fa-solid fa-pen-to-square"></i></button> &nbsp;
                                                        <button className='btn-danger'><i className="fa-solid fa-trash" onClick={() => ServiceTermsDeleteHandle(Sdata?.id, Sdata?.service_id)}></i></button> &nbsp;
                                                        {Sdata?.terms_conditions}

                                                    </p>
                                                )
                                            })}
                                        </div>
                                    </div>

                                </Accordion.Body>
                            </Accordion.Item>
                        )
                    }
                    )}
                </Accordion>






            </div>
            <Modal show={show} onHide={handleClose} size="sm">
                <Modal.Header >
                    <Modal.Title>Add Services</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                    <div className='col-lg-12'>
                            <div className='form-group'>
                                <label>Is Private </label>
                                <ReactSwitch checked={checked} onChange={handleChange} />
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <Dropdown className='custom-dropdown-checkbox' style={{ marginTop: '26px' }}>
                                    <Dropdown.Toggle variant="success">Select Class
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul>
                                            <li>
                                                <input type="checkbox" name="allSelect" checked={selectedgrade?.length === GradeList?.length}
                                                    onChange={(e) => ModuleHandle(e, GradeList, 'grade_name')}
                                                />
                                                <label>All Select</label>
                                            </li>
                                            {GradeList && GradeList.map((list, i) => {
                                                return (
                                                    <li>
                                                        <input type="checkbox" name={list?.grade_name + i} id={i} onChange={(e) => ModuleHandle(e, list, 'grade_name')}

                                                        />
                                                        <label htmlFor={i}>{list?.grade_name}</label>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Subject </label>
                                <select className='form-control' value={subject}
                                    onChange={SubjectHandle}>
                                    <option value="">--Select Subject---</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Service Name</label>
                                <input type='text' className='form-control' placeholder='Service Name' value={servicename}
                                    onChange={(e) => { setservicename(e.target.value) }} />
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={SubmitServiceHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show4} onHide={handleClose4} size="sm">
                <Modal.Header >
                    <Modal.Title>Update Services</Modal.Title>
                    <button onClick={handleClose4} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <Dropdown className='custom-dropdown-checkbox' style={{ marginTop: '26px' }}>
                                    <Dropdown.Toggle variant="success">Select Class
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <ul>
                                            <li>
                                                <input type="checkbox" name="allSelect" checked={selectedgrade?.length === GradeList?.length}
                                                    onChange={(e) => ModuleHandle(e, GradeList, 'grade_name')}
                                                />
                                                <label>All Select</label>
                                            </li>
                                            {GradeList && GradeList.map((list, i) => {
                                                return (
                                                    <li>
                                                        <input type="checkbox" name={list?.grade_name + i} id={i} onChange={(e) => ModuleHandle(e, list, 'grade_name')}

                                                        />
                                                        <label htmlFor={i}>{list?.grade_name}</label>
                                                    </li>
                                                )
                                            })}

                                        </ul>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Subject </label>
                                <select className='form-control' value={subject1}
                                    onChange={SubjectHandle2}>
                                    <option value="">--Select Subject---</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Service Name</label>
                                <input type='text' className='form-control' placeholder='Service Name' value={servicename1}
                                    onChange={(e) => { setservicename1(e.target.value) }} />
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdateServiceHandle}>Update</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Pricing</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price Name</label>
                                <input type='text' className='form-control' placeholder='Name' value={name}
                                    onChange={(e) => { setname(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Total Sessions</label>
                                <input type='text' className='form-control' placeholder='Total Sessions' value={session}
                                    onChange={(e) => { setsession(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price</label>
                                <input type='text' className='form-control' placeholder='Price' value={price}
                                    onChange={(e) => { setprice(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Recurring</label>
                                <select className='form-control' value={recurring}
                                    onChange={(e) => { setrecurring(e.target.value) }}>
                                    <option value="">--Select Recurring---</option>
                                    <option value="month">month</option>
                                    <option value="year">year</option>
                                    <option value="week">week</option>
                                    {/* <option value="day">day</option> */}
                                    <option value="onetime">No</option>
                                </select>
                            </div>
                        </div>

                        {recurring === 'No' && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Valid Days</label>
                                    <input type='number' className='form-control' placeholder='No. of Days' value={valid}
                                        onChange={(e) => { setvalid(e.target.value) }} />
                                </div>
                            </div>
                        )}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={SubmitpricingHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show5} onHide={handleClose5} size="lg">
                <Modal.Header >
                    <Modal.Title>Update Pricing</Modal.Title>
                    <button onClick={handleClose5} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price Name</label>
                                <input type='text' className='form-control' placeholder='Name' value={name1}
                                    onChange={(e) => { setname1(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Total Sessions</label>
                                <input type='text' className='form-control' placeholder='Total Sessions' value={session1}
                                    onChange={(e) => { setsession1(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price</label>
                                <input type='text' className='form-control' placeholder='Price' value={price1}
                                    onChange={(e) => { setprice1(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Repeat</label>
                                <select className='form-control' value={recurring1}
                                    onChange={(e) => { setrecurring1(e.target.value) }}>
                                    <option value="">--Select Recurring---</option>
                                    <option value="month">month</option>
                                    <option value="year">year</option>
                                    <option value="week">week</option>
                                    {/* <option value="day">day</option> */}
                                    <option value="onetime">No</option>
                                </select>
                            </div>
                        </div>

                        {recurring === 'No' && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>Valid Days</label>
                                    <input type='number' className='form-control' placeholder='No. of Days' value={valid1}
                                        onChange={(e) => { setvalid1(e.target.value) }} />
                                </div>
                            </div>
                        )}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdatePricingHandle}>Update</button>

                </Modal.Footer>
            </Modal>

            {/* <Modal show={show5} onHide={handleClose5} size="lg">
                <Modal.Header >
                    <Modal.Title>Update Pricing</Modal.Title>
                    <button onClick={handleClose5} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price Name</label>
                                <input type='text' className='form-control' placeholder='Name' value={name1}
                                    onChange={(e) => { setname1(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Total Sessions</label>
                                <input type='text' className='form-control' placeholder='Total Sessions' value={session1}
                                    onChange={(e) => { setsession1(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price</label>
                                <input type='text' className='form-control' placeholder='Price' value={price1}
                                    onChange={(e) => { setprice1(e.target.value) }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Recurring</label>
                                    <select className='form-control' value={recurring1}
                                    onChange={(e) => { setrecurring1(e.target.value) }}>
                                    <option value="">--Select Recurring---</option>
                                    <option value="Monthly">Monthly</option>
                                    <option value="Yearly">Yearly</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>

                        {recurring === 'No' && (
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Valid Days</label>
                                <input type='number' className='form-control' placeholder='No. of Days' value={valid1}
                                    onChange={(e) => { setvalid1(e.target.value) }} />
                            </div>
                        </div>
                        )}

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdatePricingHandle}>Update</button>

                </Modal.Footer>
            </Modal> */}

            <Modal show={show2} onHide={handleClose2} size="sm">
                <Modal.Header >
                    <Modal.Title>Add Staff</Modal.Title>
                    <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Staff </label>
                                <select className='form-control' value={staff}
                                    onChange={(e) => { setstaff(e.target.value) }}>
                                    <option value="">--Select Staff---</option>
                                    {teacherList && teacherList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.user_id} >{item?.first_name} {item?.last_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={SubmitTeacherHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show3} onHide={handleClose3} size="sm">
                <Modal.Header >
                    <Modal.Title>Add Subject</Modal.Title>
                    <button onClick={handleClose3} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Subject </label>
                                <select className='form-control' value={subject}
                                    onChange={SubjectHandle}>
                                    <option value="">--Select Subject---</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddServiceSubjectHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show6} onHide={handleClose6} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Terms of Services</Modal.Title>
                    <button onClick={handleClose6} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Terms of Services </label>
                                <textarea type='text' className='form-control' placeholder='Enter Your Text Here' value={desc}
                                    onChange={(e) => { setdesc(e.target.value) }} > </textarea>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddTermsandCondHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show7} onHide={handleClose7} size="lg">
                <Modal.Header >
                    <Modal.Title>Update Terms of Services</Modal.Title>
                    <button onClick={handleClose7} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Terms of Services </label>
                                <textarea type='text' className='form-control' placeholder='Enter Your Text Here' value={desc1}
                                    onChange={(e) => { setdesc1(e.target.value) }} > </textarea>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdateTermsandCondHandle}>Update</button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ServiceList