import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { GetLocationRequest, GetCountryRequest, GetStateRequest ,GetTrainingListRequest, GetTitleRequest, GetManagerRequest, GetBackgroundRequest, GetcityRequest, GetstateSuccess, GetcitySuccess, AddemployeeRequest} from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import moment from "moment";
import DatePicker from "react-datepicker";


const AddCustomers = () => {
    const [location, setlocation] = useState("")
    const [salution, setsalution] = useState("")
    const [fname, setfname] = useState("")
    const [lname, setlname] = useState("")
    const [dob, setdob] = useState("")
    const [phone, setphone] = useState("")
    const [mobile, setmobile] = useState("")
    const [email, setemail] = useState("")
    const [othermobile, setothermobile] = useState("")
    const [addressone, setaddressone] = useState("")
    const [addresstwo, setaddresstwo] = useState("")
    const [traininfo, settraininfo] = useState([{
      label:'',
      assigndate:null,
      duedate:null , 
      completedate:null , 
      isdisabled:false
    }]);
  
    const [employmenttype, setemploymenttype] = useState("")
  
  
    
  
    const dispatch = useDispatch()
    let navigate = useNavigate()
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var locationList = fetchacompanyReducer?.GetLocationResponse?.results
    var countryList = fetchacompanyReducer?.GetcountryResponse
    var TitleList = fetchacompanyReducer?.GetTitleResponse?.results
    var managerList = fetchacompanyReducer?.GetManagerResponse?.results
    var backgroundList = fetchacompanyReducer?.GetBackgroundResponse?.results
    var TrainingList = fetchacompanyReducer?.GetTrainingResponse?.results
  
    console.log(backgroundList)
  
    useEffect(()=>{
      dispatch(GetLocationRequest())
  },[])
  
  console.log(fetchacompanyReducer)
  
  const dateHandle = (date, key, index)=>{
      console.log(index)
      var newValue = [...traininfo];
      newValue[index][key] = date
      settraininfo(newValue);
  }
  
  const TrainingHandle = (value,key, index, _is)=>{
      var newValue = [...traininfo];
      newValue[index][key] = value
  
      const _index = newValue.findIndex(item => item.label == value);
  
          console.log(_index)
  
  
      if(_index !== -1){
          newValue[index][_is] = true
      } else   newValue[index][_is] = false
  
      settraininfo(newValue);
  }
  
  const DeleteHandle = (index)=>{
      var newValue = [...traininfo];
      newValue.splice(index, 1);
      settraininfo(newValue);
  }
  
  
  
  const AddHandle = ()=>{
      // var _is = false
      // traininfo.forEach(item=>{
      // TrainingList&&TrainingList.forEach(element=>{
      //         if(item.label == element.id){
      //             return _is = true 
      //          } else return _is = false
      //     })  
      // })
  
      const rawinput = {
          label:'',
          assigndate:null,
          duedate:null , 
          completedate:null , 
          isdisabled:false
      }
  
      settraininfo([...traininfo, rawinput])
  }
  
  const SubmitHandle = ()=>{
  
      let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
  
      if(location == ''){
          Swal.fire({
              icon: "error",
              text: 'Location should be must',
            });
      } else  if (reg.test(email) === false) {
          Swal.fire({
              icon: "error",
              text: 'Email should be proper',
            });
        
     } else if(fname == ''){
      Swal.fire({
          icon: "error",
          text: 'First name should be must',
        });   
  } else if(lname == ''){
      Swal.fire({
          icon: "error",
          text: 'Last name should be must',
        });   
  } else if(mobile == ''){
      Swal.fire({
          icon: "error",
          text: 'Mobile no should be must',
        });   
  }   
     
     
     else {
  
      var arr = []
  
      traininfo.forEach((element, index) =>{
          arr.push({
              "training_id": element.label,
              "start_date":  moment(element.assigndate).format("YYYY-MM-DD"),
              "due_date":   moment(element.duedate).format("YYYY-MM-DD"),
              "completion_date":  moment(element.completedate).format("YYYY-MM-DD"),
          })
      })
  
  
  
  let obj = {
      "location_id": Number(location),
      "first_name": fname,
      "last_name": lname,
      "phone": phone,
      "email": email,
      "mobile_phone": mobile,
      "other_phone": othermobile,
  
     }
  
    console.log(obj)
  
  
    dispatch(AddemployeeRequest({obj:obj, navigate:navigate}))
  
   
  }
  }
  
  
  
    return (
      <div className='common-layout'>
  
  <Loader visible={fetchacompanyReducer.isloading}  />
  
  
      <div className='innerlayout p-3'>
      <div className='mb-4'>
        <h3> Add Customers</h3>
        </div>
        <div className='row form-style mb-4'>
        <div className='col-lg-12 mb-2'>
          <h6>Customers information</h6>
        </div>
        <div className='col-lg-4'>
             <div className='form-group'>
                <select className='form-control'  value={location}
                 onChange={(e)=>setlocation(e.target.value)}>
                  <option value="">--Location Name---</option>
                  {locationList&&locationList.map((item, i)=>{
                      return (
                          <option key={i} value={item?.id}>{item?.location_name}</option>
                      )
                  })}
                </select>
             </div>
         </div>
         <div className='col-lg-4'>
             <div className='form-group'>
                <select className='form-control'  value={location}
                 onChange={(e)=>setlocation(e.target.value)}>
                  <option value="">--Contact Preference---</option>
                  {locationList&&locationList.map((item, i)=>{
                      return (
                          <option key={i} value={item?.id}>Test</option>
                      )
                  })}
                </select>
             </div>
         </div>     
        
         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='First Name'
                 value={fname}
                 onChange={(e)=>setfname(e.target.value)}
                 />
             </div>
         </div>
         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Last Name'
                 value={lname}
                 onChange={(e)=>setlname(e.target.value)}
                 />
             </div>
         </div>
         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Phone'
                 value={phone}
                 onChange={(e)=>setphone(e.target.value)}
                 maxLength="10"
                 minLength="10"
                 onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                  }}}
                 />
             </div>
         </div>
         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Mobile Phone'
                 value={mobile}
                 onChange={(e)=>setmobile(e.target.value)}
                 maxLength="10"
                 minLength="10"
                 onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                  }}}
                 />
             </div>
         </div>
         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Other Phone'
                 value={othermobile}
                 onChange={(e)=>setothermobile(e.target.value)}
                 maxLength="10"
                 minLength="10"
                 onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                  }}}
                 />
             </div>
         </div>
         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='email' className='form-control' placeholder='Email'
                 value={email}
                 onChange={(e)=>setemail(e.target.value)}
                 />
             </div>
         </div>

         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Referral Account'
                 value={lname}
                 onChange={(e)=>setlname(e.target.value)}
                 />
             </div>
         </div>

         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Referral Teacher'
                 value={lname}
                 onChange={(e)=>setlname(e.target.value)}
                 />
             </div>
         </div>

         <div className='col-lg-4'>
           <div className='form-group'>
           <DatePicker selected={dob} onChange={(date) => setdob(date)} maxDate={new Date()} className='form-control'
           placeholderText="Last TriMathon Reg Date"
           />
           </div>
       </div>

         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Emergency Contact'
                 value={lname}
                 onChange={(e)=>setlname(e.target.value)}
                 />
             </div>
         </div>

         <div className='col-lg-4'>
             <div className='form-group'>
                 <input type='text' className='form-control' placeholder='Emergency Phone No.'
                 value={lname}
                 onChange={(e)=>setlname(e.target.value)}
                 />
             </div>
         </div>
        
     </div>
     <button className='formBtn' onClick={SubmitHandle}>Save</button>
      </div>
  </div>
    )
  }

export default AddCustomers