import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { DeletestudentRequest, ViewstudentRequest, ExportstudentRequest, GetStudentListRequest, GetGradeRequest, GetTeacherRequest, AddServiceSubjectRequest, AddServicesRequest, AddPricingRequest, AddStaffRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../../utils/ApiConnection'
import Dropdown from 'react-bootstrap/Dropdown';
import ReactSwitch from 'react-switch';


const LeadInterest = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [LeadInterest, setLeadInterest] = useState([])
    const [show6, setShow6] = useState(false);
    const [show7, setShow7] = useState(false);
    const [docId, setdocId] = useState(null);
    const [SerId, setSerId] = useState(null);
    const handleClose6 = () => setShow6(false);
    const handleClose7 = () => setShow7(false);
    const [desc, setdesc] = useState("");
    const [desc1, setdesc1] = useState("");
    const [filter, setFilter] = useState('');

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    const dispatch = useDispatch()

    const GetLeadInterest = async () => {
        try {
            const response = await ApiConnection.get(`lead/interest-master/?server_type=production`)
            console.log(response)
            if (response?.status == 200) {
                setLeadInterest(response.data.results || []);
            }
        } catch (e) { }
    }

    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    };

    const filteredGroupList = LeadInterest?.filter(item =>
        item?.name.toLowerCase().includes(filter.toLowerCase())
    );

    const AddLeadInterest = async () => {
        if (desc == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospect Interest should be must',

            });
        } else {

            let obj = {
                name: desc
            }

            try {
                const response = await ApiConnection.post(`lead/interest-master/?server_type=production`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetLeadInterest()
                    setShow6(false)
                }
            } catch (e) { }
        }
    }

    const UpdateLeadInterest = async () => {
        if (desc1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Prospect Interest should be must',

            });
        } else {
            let obj2 = {
                id: docId,
                Sid: SerId
            }
            let obj = {
                "text_content": desc1,
            }
            try {
                const response = await ApiConnection.put(`lead/interest-master/?server_type=production&id=${docId}&method=edit`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    GetLeadInterest()
                    setShow7(false)
                }
            } catch (e) { }
        }
    }

    useEffect(() => {
        GetLeadInterest()
    }, [])

    const handleShow6 = (id) => {
        setdocId(id)
        setShow6(true)
    };

    const DeleteHandle = async (id, Delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`lead/interest-master/?server_type=production&id=${id}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        GetLeadInterest()
                    }
                }
            })
        } catch (e) { }
    }

    const handleShow7 = async (id, Sid) => {
        console.log(id, Sid)
        setdocId(id)
        setSerId(Sid)
        setShow7(true)

        try {
            const response = await ApiConnection.get(`lead/interest-master/?server_type=production&id=${id}`)
            console.log(response.data)

            setdesc1(response.data.results[0].name);

        } catch (e) { }

    };

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Prospect Interest Management </h3>
                </div>

                <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <input className='form-control'
                                type="text"
                                style={{ width: '107%' }}
                                placeholder="Search Prospect Interest"
                                value={filter}
                                onChange={handleFilterChange}
                            />
                        </li>
                        <li className='ml-3'>
                            <button className='formBtn1' onClick={handleShow6}><i className="fa-solid fa-plus"></i> Add Prospect Interest</button>
                        </li>
                    </ul>
                </div>

                <a id="Download" download></a>
                {filteredGroupList && filteredGroupList.length > 0 ? (
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Prospect Interest Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Array.isArray(filteredGroupList) && filteredGroupList.map((data, index) => (
                                    <tr key={index}>
                                        <td>{data?.name}</td>
                                        <td>
                                            <button style={{color:'#2497d7'}}><i className="fa-solid fa-pen-to-square" onClick={() => handleShow7(data?.id)}></i></button>
                                            <button><i className="fa-solid fa-trash" onClick={() => DeleteHandle(data?.id)}></i></button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <h6>No data found</h6>
                )}

            </div>

            <Modal show={show6} onHide={handleClose6} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Prospect Interest</Modal.Title>
                    <button onClick={handleClose6} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Prospect Interest <span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Prospect Interest Name' value={desc}
                                    onChange={(e) => { setdesc(e.target.value) }} />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddLeadInterest}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show7} onHide={handleClose7} size="lg">
                <Modal.Header >
                    <Modal.Title>Update Prospect Interest</Modal.Title>
                    <button onClick={handleClose7} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Prospect Interest </label>
                                <textarea type='text' className='form-control' placeholder='Enter Your Text Here' value={desc1}
                                    onChange={(e) => { setdesc1(e.target.value) }} > </textarea>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={UpdateLeadInterest}>Update</button>

                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default LeadInterest