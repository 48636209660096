import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { GetEmployeevirtuallocationRequest, AddEmployeevirtuallocationRequest, FetchEmployeevirtuallocationRequest, DeleteEmployeevirtuallocationRequest, UpdateEmployeevirtuallocationRequest, ViewEmployeeRequest, GetEmployeelocationRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";

const EmployeeVirtualLocation = ({ id, etype }) => {
  const [search, setsearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])
  const [location, setlocation] = useState("")
  const [location1, setlocation1] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [editrowId, seteditrowId] = useState(null)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);

  const dispatch = useDispatch()


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var locationList = fetchacompanyReducer?.GetAssignEmployeelocationResponse?.results
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var getEmployeeLocation = fetchacompanyReducer?.GetEmployeelocationResponse?.results
  var VirtualLocation = fetchacompanyReducer?.GetEmployeelocationResponse?.results?.[0]

  console.log(VirtualLocation)


  useEffect(() => {

    let obj = {
      id: id
    }
    dispatch(GetEmployeelocationRequest(obj))
  }, [])


  return (
    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Assign Virtual Locations</h5>
      {etype == "EMPLOYEE" ?
        <h3>can not assign multiple location for Corporate  employee</h3>
        :
        <>



          <div className='row form-style mt-3'>
            <div className='col-lg-3'>
              <div className='form-group m-0'>
                <input type='text' className='form-control' placeholder='Virtual Location Name'
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
              </div>
            </div>


          </div>
          {getEmployeeLocation && getEmployeeLocation.length > 0 ?
            <div className='table-responsive'>
              <table className='custom-table'>
                <thead>
                  <tr>
                    <th>Virtual Location</th>
                  </tr>
                </thead>

                {/* {unreadMessages.length > 0 &&
        <h2>
          You have {unreadMessages.length} unread messages.
        </h2>
      } */}

                <tbody>
                  {getEmployeeLocation && getEmployeeLocation.map((item, i) => {
                    return (
                      <tr key={i}>
                        {item?.is_virtual_location == 1 &&
                        <td>
                            <span>{item?.location_name}</span>
                        </td>
                        }
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
            :
            <h6>No data found</h6>
          }
        </>
      }

    </div>
  )
}

export default EmployeeVirtualLocation