import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import { GetProspectiveRequest, GetAssessmentAnswerRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Modal from 'react-bootstrap/Modal';

const StudentReportCard = () => {

    const [name, setname] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var AnswerDetails = fetchacompanyReducer?.GetAssessmentAnswerResponse?.results
    var AnswerName = fetchacompanyReducer?.GetAssessmentAnswerResponse?.results[0]?.test_name
    console.log(fetchacompanyReducer?.GetAssessmentAnswerResponse)
    const dispatch = useDispatch()
    const { id } = useParams()
    let navigate = useNavigate()

    useEffect(() => {
        let obj = {
            id: id,
        }
        dispatch(GetAssessmentAnswerRequest(obj))
    }, [])

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Answers Key </h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='form-group'>
                    <hr />
                </div>

                <div className='mb-4'>
                    <h5 className='text-center'><u>{AnswerName}</u> </h5>
                </div>

                <div className='row'>
                    {AnswerDetails && AnswerDetails.map((item, index) => {
                        return (
                            <div className='col-lg-12' key={index}>
                                <div className='form-group'>
                                    <div className='question-details'>
                                        <b className='d-block mb-2'>Question {index + 1}.</b>
                                        <div
                                            dangerouslySetInnerHTML={{ __html: item?.question }}
                                        />
                                        <b className='d-block mb-2'>Answer:</b>
                                        <ul>
                                            <li className={item?.answer == "1" ? "correct" : ''}><span>{item?.answer == "1" ? <i className="fa-solid fa-square-check" style={{backgroundColor: 'green'}}></i> : <i className="fa-regular fa-square"></i>}</span> {item?.option_1}</li>
                                            <li className={item?.answer == "2" ? "correct" : ''}><span>{item?.answer == "2" ? <i className="fa-solid fa-square-check" style={{backgroundColor: 'green'}}></i> : <i className="fa-regular fa-square"></i>}</span> {item?.option_2}</li>
                                            <li className={item?.answer == "3" ? "correct" : ''}><span>{item?.answer == "3" ? <i className="fa-solid fa-square-check" style={{backgroundColor: 'green'}}></i> : <i className="fa-regular fa-square"></i>}</span> {item?.option_3}</li>
                                            <li className={item?.answer == "4" ? "correct" : ''}><span>{item?.answer == "4" ? <i className="fa-solid fa-square-check" style={{backgroundColor: 'green'}}></i> : <i className="fa-regular fa-square"></i>}</span> {item?.option_4}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>

    )
}

export default StudentReportCard