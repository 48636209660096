import React from 'react'
import { IMAGE } from './Theme'



const Loader = (props) => {

  return  props.visible ? (
    <div className='CustomLoader'>
        <div className='CustomLoaderInner'>
            <img src={IMAGE.loader} alt="loader" className='loaaderIcon' />
            <h3>Loading...</h3>
        </div>
    </div>
  )  : null
}

export default Loader