import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { GetExistingTestRequest, GetStudentTestListRequest, GetExistingQuestionRequest, AssignScheduleRequest, GetStudenttestRequest, ViewLearningPlanRequest, GetLearningPlanRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import CreateProgressCheck from './CreateProgressCheck'

const Student_Learningpaths = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [holdduration, setholdduration] = useState("")
    const [remarks, setremarks] = useState("")
    const [enrollstartdate, setenrollstartdate] = useState(null)
    const [enrolloed, setenrolloed] = useState("")
    const [enrollinactivedate, setenrollinactivedate] = useState(null)
    const [enrollrecurring, setenrollrecurring] = useState("")
    const [enrolltype, setenrolltype] = useState("")
    const [enrollmonthlyamount, setenrollmonthlyamount] = useState("")
    const [enrolltotalsession, setenrolltotalsession] = useState("")
    const [enrollremainings, setenrollremainings] = useState("")
    const [enrollholdlength, setenrollholdlength] = useState("")
    const [enrolldelivery, setenrolldelivery] = useState("")

    const [startdate, setstartdate] = useState(null)
    const [enddate, setenddate] = useState(null)
    const [show, setShow] = useState(false);
    const [editrowId, seteditrowId] = useState(null)
    const [TestId, setTestId] = useState("");
    const handleClose = () => setShow(false);
    const [show1, setShow1] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [StudentList, setStudentList] = useState([])
    const handleClose1 = () => setShow1(false);

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var testList = fetchacompanyReducer?.GetLearningPlanResponse?.results
    var ExistingTest = fetchacompanyReducer?.GetStudentTestListResponse?.results

    console.log(fetchacompanyReducer.GetTitleResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id, pros } = useParams()

    const columns = [
        {
            name: 'LP Name',
            selector: row => row.test_name,
            sortable: true,
        },
        {
            name: 'Created Date',
            selector: row => row.created_date,
        },
        {
            name: 'Active',
            selector: (row) => (
                <>
                    <input type="checkbox" />
                </>
            ),
        },
        {
            name: 'Progress Check',
            selector: (row) => (
                <>
                  <div className='d-flex'>
                    <NavLink to={`/${userinfo?.company_slug}/students/CreateProgressCheck/${row?.test_id}/${row?.student_id}/${row?.id}`} className='formBtn studentassignbtn mr-2'>Create</NavLink>
                    {/* <Routes>
                    <Route path = {`/${userinfo?.company_slug}/students/CreateProgressCheck/${row?.test_id}/${row?.student_id}/${row?.id}`} component = {CreateProgressCheck} > Create</Route>
                    </Routes> */}
                    <NavLink to={`/${userinfo?.company_slug}/students/ViewProgressCheck/${row?.test_id}/${row?.student_id}/${row?.id}`}className='formBtn studentassignbtn'>View</NavLink>
                  </div>
                </>
            ),
        },
        {
            name: 'Report',
            selector: (row) => (
                <>
                    <button className='formBtn studentassignbtn'>Report</button>
                </>
            ),
        },
        {
            name: 'Actions',
            selector: (row) => (
                <>
                        <div className='d-flex'>
                            <NavLink to={`/${userinfo?.company_slug}/students/learningplanmanager/${row?.test_id}/${row?.student_id}/${row?.id}`}><i className="fa-solid fa-eye"></i></NavLink>
                            <button className='table-btn'><i className="fa-solid fa-trash"></i></button>
                        </div>
                </>
            ),
        },
    ];

    useEffect(() => {
        const result = testList && testList.filter(data => {
            return data.test_name?.toLowerCase().match(Namesearch?.toLowerCase())
        })
        setFilterResult(result)
    }, [Namesearch])

    useEffect(() => {

        setFilterResult(testList)
    }, [fetchacompanyReducer?.GetLearningPlanResponse])

    
    useEffect(() => {
        let obj = {
            id: id,
            student_id: pros
        }
        dispatch(GetLearningPlanRequest(obj))
      }, [])

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
            <h5>Learning Paths</h5>

            <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-40px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

            <hr></hr>
            <div className='col-lg-8 float-right'>
                <div className='d-flex' style={{ marginTop: '0px', float: 'right' }}>
                    <button className='btn btn-primary mr-3' ><i className="fa-solid fa-video-camera"></i> Learning Path Tutorial</button>
                    <button className='btn btn-primary' ><i className="fa-solid fa-video-camera"></i> Progress Check Tutorial</button>
                </div>
            </div>

            <div className='table-responsive'>
                <DataTable
                    columns={columns}
                    data={FilterResult}
                    pagination
                    highlightOnHover
                />
            </div>
</div>
        </div>

    )
}

export default Student_Learningpaths