import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { ViewEmployeeRequest, GetEmployeelocationRequest, GetTrainingRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DataTable from 'react-data-table-component';
import EmployeeLocation from './EmployeeLocation'
import EmployeeTraining from './EmployeeTraining'
import Accordion from 'react-bootstrap/Accordion';
import EmployeeAttendance from './EmployeeAttendance'
import Documents from './Documents'
import EmployeeActivities from './EmployeeActivities'
import Schedule from './Schedule'
import EmployeeVirtualLocation from './EmployeeVirtualLocation'
import TeacherSchedule from './Schedule'
import EmployeeClassSubject from './EmployeeClassSubject'
import ActivitiesReport from './ActivitiesReport'


const ViewEmployee = () => {
     const [key, setKey] = useState('location');

     const dispatch = useDispatch()
     const { id } = useParams()
     let navigate = useNavigate()

     const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
     var userinfo = JSON.parse(localStorage.getItem('userinfo'))

     var viewdata = fetchacompanyReducer?.ViewEmployeeResponse?.results[0]
     var traningdata = viewdata?.traning
     var getEmployeeLocation = fetchacompanyReducer?.GetEmployeelocationResponse?.results

     console.log(viewdata)


     useEffect(() => {

          let obj = {
               id: id
          }

          dispatch(ViewEmployeeRequest(obj))

     }, [])






     return (
          <div className='common-layout'>
               <Loader visible={fetchacompanyReducer.isloading} />
               <div className='innerlayout p-3'>
                    <div className='mb-3'>
                         <h3> Employee Details</h3>
                    </div>

                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-68px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                    <div className='view-page'>

                         <Accordion defaultActiveKey="0" className='view-accordian'>
                              <Accordion.Item eventKey="0">
                                   <Accordion.Header>Employee information</Accordion.Header>
                                   <Accordion.Body>
                                        <div className='row'>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Salution:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.salutation}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Name:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.first_name} {viewdata?.last_name}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Employee Type:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.employee_type}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Manager:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.manager_first_name} {viewdata?.manager_last_name}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Email:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.email}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Mobile:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.mobile_phone}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Other Phone:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.other_phone}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Date of birth:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.date_of_birth}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Description:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.description}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                        </div>
                                        {/* <div className='form-group'>
                                             <p>{viewdata?.description}</p>
                                        </div> */}
                                   </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="1">
                                   <Accordion.Header>Employeement Details</Accordion.Header>
                                   <Accordion.Body>
                                        <div className='row'>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Employeement Status:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.status}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Backgound check company:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.background_check_name}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Aplication Rejected:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.is_application_rejected == 1 ? "Yes" : "No"} </b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Backgound check date:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.background_check_date}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Employeement type:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.employment_type}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Hire Date:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.hire_date}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                        </div>
                                   </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item eventKey="2">
                                   <Accordion.Header>Address Information</Accordion.Header>
                                   <Accordion.Body>
                                        <div className='row'>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Address 1:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.street_address_1}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Address 2:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.street_address_2}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>City:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.city_name} </b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>State:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.state_name}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Country:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.country_name}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>
                                             <div className='col-lg-6'>
                                                  <div className='form-group'>
                                                       <div className='row align-items-center'>
                                                            <div className='col-lg-6'>
                                                                 <label className='d-block text-right'>Zipcode:</label>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                 <b>{viewdata?.zipcode}</b>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                        </div>
                                   </Accordion.Body>
                              </Accordion.Item>
                         </Accordion>





                         {/* <div className='form-group'>
        <h6>Description</h6>
      </div>
      <div className='form-group'>
        <hr />
      </div> */}


                         <div className='form-group mt-5'>
                         <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
                style={{fontSize: '15px'}}
            >
                <Tab eventKey="location" title="Locations">
                    {key === 'location' && <EmployeeLocation etype={viewdata?.employee_type} id={id} />}
                </Tab>
                <Tab eventKey="vlocation" title="Virtual Location">
                    {key === 'vlocation' && <EmployeeVirtualLocation etype={viewdata?.employee_type} id={id} />}
                </Tab>
                <Tab eventKey="training" title="Training">
                    {key === 'training' && <EmployeeTraining id={id} />}
                </Tab>
                <Tab eventKey="classsubject" title="Class/Subject">
                    {key === 'classsubject' && <EmployeeClassSubject id={id} />}
                </Tab>
                <Tab eventKey="attendance" title="Attendance">
                    {key === 'attendance' && <EmployeeAttendance id={id} />}
                </Tab>
                <Tab eventKey="sctivitie" title="Document">
                    {key === 'sctivitie' && <Documents id={id} />}
                </Tab>
                <Tab eventKey="activities" title="Task">
                    {key === 'activities' && <EmployeeActivities id={id} />}
                </Tab>
                <Tab eventKey="schedule" title="Schedule">
                    {key === 'schedule' && <TeacherSchedule id={id} />}
                </Tab>
                <Tab eventKey="activitie" title="Activities">
                    {key === 'activitie' && <ActivitiesReport id={id} />}
                </Tab>
            </Tabs>
                         </div>



                    </div>



               </div>
          </div>
     )
}

export default ViewEmployee