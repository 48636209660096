import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, GetEmployeeRequest, GetStudentListRequest, GetGradeRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer, Views, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../utils/ApiConnection'
import ReactSwitch from 'react-switch';


const Schedule_Helpdesk = () => {

  const localizer = momentLocalizer(moment)
  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var studentteacherList = fetchacompanyReducer?.GetTeacherResponse?.results
  var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
  var SubList = fetchacompanyReducer?.GetSubjectResponse?.results
  var EmployeeList = fetchacompanyReducer?.GetEmployeeResponse?.results
  var GradeList = fetchacompanyReducer?.GetGradeResponse?.results

  const locationReducer = useSelector((state) => state.LocationSlice)
  let locationId = localStorage.getItem("locationId");

  const { id } = useParams()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const timeSlots = [
    '08:00 - 08:30',
    '08:30 - 09:00',
    '09:00 - 09:30',
    '09:30 - 10:00',
    '10:00 - 10:30',
    '10:30 - 11:00',
    '11:00 - 11:30',
    '11:30 - 12:00',
    '12:00 - 12:30',
    '12:30 - 01:00',
    '01:00 - 01:30',
    '01:30 - 02:00',
    '02:00 - 02:30',
    '02:30 - 03:00',
    '03:00 - 03:30',
    '03:30 - 04:00',
  ];

  const events = [
    {
      title: 'Math',
      start: new Date(2024, 4, 20, 10, 0),
      end: new Date(2024, 4, 20, 12, 0),
      hexColor: 'green',
    },
    {
      title: 'History',
      start: new Date(2024, 4, 22, 11, 0),
      end: new Date(2024, 4, 22, 15, 0),
      hexColor: 'red',
    },
  ];

  const [grade, setgrade] = useState("")
  const [teacher, setteacher] = useState("")
  const [lpList, setlpList] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [checked, setChecked] = useState(false);
  const [termschecked, settermschecked] = useState(false);
  const [serviceList, setserviceList] = useState([])
  const [totalcount, settotalcount] = useState([])
  const [AvilScheduleList, setAvilScheduleList] = useState([])
  const [EndTimeList, setEndTimeList] = useState([])
  const [scheduleList, setscheduleList] = useState([])
  const [time, settime] = useState(null)
  const [studentList, setstudentList] = useState([])
  const [student, setstudent] = useState("")
  const [repeattype, setrepeattype] = useState("")
  const [employeeName, setemployeeName] = useState("")
  const [ScheduleDay, setScheduleDay] = useState(null)
  const [service, setservice] = useState("")
  const [myEvents, setEvents] = useState(events)
  const [startTime, setStartTime] = useState('');
  const [validcheck, setvalidcheck] = useState('');
  const [TstartTime, setTstartTime] = useState('');
  const [TendTime, setTendTime] = useState('');
  const [empid, setempid] = useState('');
  const [endTime, setEndTime] = useState('');
  const [assigndate, setassigndate] = useState(null)
  const [MinstartTime, setMinStartTime] = useState('');
  const [MaxendTime, setMaxEndTime] = useState('');
  const [selectedsubject, setselectedsubject] = useState([]);
  const [isrepeat, setisrepeat] = useState(false);
  const [servicename, setservicename] = useState("");
  const [subjectList, setsubjectList] = useState([])
  const [selectedgrade, setselectedgrade] = useState([]);
  const [subject, setsubject] = useState("");
  const [name, setname] = useState("");
  const [price, setprice] = useState("");
  const [session, setsession] = useState("");
  const [recurring, setrecurring] = useState("");
  const [docId, setdocId] = useState(null);
  const [valid, setvalid] = useState("");
  const [pricename, setpricename] = useState("");
  const [priceList, setpriceList] = useState([]);
  const [achdiscountamountprice, setachdiscountamountprice] = useState("");
  const [Amount1, setAmount1] = useState("");
  const [Amount2, setAmount2] = useState("");
  const [TodaysAmount, setTodaysAmount] = useState("");
  const [paymentmethod, setpaymentmethod] = useState("");
  const [enrollmanualfee, setenrollmanualfee] = useState("");
  const [contactstartdate, setcontactstartdate] = useState("");
  const [contactenddate, setcontactenddate] = useState("");
  const [scheduleduration, setscheduleduration] = useState("");
  const [enrollstartdate, setenrollstartdate] = useState(null)
  const [enrollinactivedate, setenrollinactivedate] = useState(null)
  const [discount, setdiscount] = useState("");
  const [discountAmount, setdiscountAmount] = useState("");
  const [discountPercentage, setdiscountPercentage] = useState("");
  const [achdiscountamount, setachdiscountamount] = useState("");
  const [price1, setprice1] = useState("");
  const [session1, setsession1] = useState("");
  const [recurring1, setrecurring1] = useState("");
  const [discountList, setdiscountList] = useState([])
  const [enrolltype, setenrolltype] = useState("")
  const [enrollmonthlyamount, setenrollmonthlyamount] = useState("")
  const [enrolltotalsession, setenrolltotalsession] = useState("")
  const [enrollremainings, setenrollremainings] = useState("")
  const [enrollregfee, setenrollregfee] = useState("");
  const [enrolldelivery, setenrolldelivery] = useState("")
  const [enrollassessmentfee, setenrollassessmentfee] = useState("");
  const [companyid, setcompanyid] = useState("");
  const [enrollrecurring, setenrollrecurring] = useState("")
  const [name1, setname1] = useState("");

  useEffect(() => {

    var editdata = fetchacompanyReducer?.FetchstudentResponse?.results[0]
    setcompanyid(editdata?.company_id)

    setenrollstartdate(editdata?.startup_fee)
    setenrollinactivedate(editdata?.assessment_fee)
    setenrollrecurring()
    setenrolltype()
    setenrolltotalsession()
    setenrollmonthlyamount(editdata?.cancelation_fee)
    setenrolldelivery()
    setenrollremainings(editdata?.late_fee)
    setenrolltotalsession()
    setenrollmanualfee(editdata?.manual_payment_fee)
    setachdiscountamount(editdata?.ach_discount)
    // setachdiscount(editdata?.ach_discount)
    setscheduleduration(editdata?.service_durations)

    setenrollstartdate(editdata?.startup_fee)
    setenrollinactivedate(editdata?.assessment_fee)
    setenrollrecurring()
    setenrolltype()
    setenrollregfee(editdata?.startup_fee)
    setenrolltotalsession()
    setenrollassessmentfee(editdata?.assessment_fee)
    setenrollmonthlyamount(editdata?.cancelation_fee)
    setenrolldelivery()
    setenrollremainings(editdata?.late_fee)
    setenrolltotalsession()
    setenrollmanualfee(editdata?.manual_payment_fee)
    setachdiscountamount(editdata?.ach_discount)


  }, [fetchacompanyReducer?.FetchstudentResponse])


  const handleSelectSlot = useCallback(
    ({ start, end }) => {
      const title = window.prompt('New Event name')
      if (title) {
        setEvents((prev) => [...prev, { start, end, title }])
      }
    },
    [setEvents]
  )

  const handleClose2 = () => {
    setShow2(false);
  }

  const handleClose3 = () => {
    setShow3(false);
  }

  const handleChange = (val) => {
    setChecked(val);
  };

  const handleChange1 = (val) => {
    settermschecked(val);
  };

  const DayList = [
    {
      id: "1",
      dayname: "sunday",
    },
    {
      id: "2",
      dayname: "monday",
    },
    {
      id: "3",
      dayname: "tuesday",
    },
    {
      id: "4",
      dayname: "wednesday",
    },
    {
      id: "5",
      dayname: "thrusday",
    },
    {
      id: "6",
      dayname: "friday",
    },
    {
      id: "7",
      dayname: "saturday",
    },
  ]

  const ModuleHandle = (e, data) => {
    console.log(data)
    const { name, checked } = e.target
    if (checked) {
      if (name === "allSelect") {
        setselectedsubject(DayList);
      } else {
        setselectedsubject([...selectedsubject, data]);
      }
    } else {
      if (name === "allSelect") {
        setselectedsubject([]);
      } else {
        let tempuser = DayList.filter((item) => item.id !== data.id);
        setselectedsubject(tempuser);
      }
    }
  }

  const GetDiscount = async (e) => {

    try {
      const response = await ApiConnection.get(`membership-discount-crud/?server_type=production`)
      if (response?.status == 200) {
        setdiscountList(response?.data?.results)

      }
    } catch (e) { }

  }

  useEffect(() => {
    GetSchedule();
    GetScheduledetails();
    GetStudent()
    GetDiscount();
  }, [])

  useEffect(() => {
    dispatch(GetGradeRequest())
    GetSubject()
  }, [])

  const handleShow1 = (e, timeSlot, stime, etime, employee_id, tstime, tetime, validity) => {
    console.log('validity', validity)
    const startTimeFromTableCell = timeSlot;
    const startTime = startTimeFromTableCell.split(" - ")[0];
    setvalidcheck(validity);
    setStartTime(stime);
    setTstartTime(tstime);
    setTendTime(tetime);
    setempid(employee_id);
    const start = new Date(`1970-01-01T${startTime}`);
    const durationInMinutes = 30;
    console.log(durationInMinutes)

    const end = new Date(start.getTime() + durationInMinutes * 60000);
    const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
    console.log(formattedEndtime)
    setEndTime(etime);
    setShow1(true);
    dispatch(GetStudentListRequest())
  };

  const GetStudent = async () => {
    try {
      const response = await ApiConnection.get(`student-crud/?server_type=production`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  const StudentDayHandle = async (e) => {
    var val = e.target.value
    setScheduleDay(val)

    // try {
    //   const response = await ApiConnection.get(`student-crud/?server_type=production&schedule_date=${val}`)
    //   if (response?.status == 200) {
    //     setstudentList(response?.data?.results)
    //   }
    // } catch (e) { }
  }

  const GetStudentService = async (e) => {

    console.log(e);
    var val = e.target.value
    setScheduleDay(val)

    try {
      const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${val}`)
      if (response?.status == 200) {
        // setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }


  const StudentServiceHandle = async () => {

    try {
      const response = await ApiConnection.get(`student_service-crud/?server_type=production`)
      if (response?.status == 200) {
        setstudentList(response?.data?.results)
      }
    } catch (e) { }
  }

  const AssignServiceHandle = async (e) => {
    console.log("ServiceHandle", e)
    var val = e.target.value
    setservicename(val)
    try {
      const response = await ApiConnection.get(`pricing_crud/?server_type=production&service_id=${val}`)
      console.log(response.data.results[0].name);
      setpriceList(response?.data?.results)
      console.log("priceList", priceList)

      setsession1("");
      setprice1("");
      setrecurring1("");


    } catch (e) { }
  }

  const serviceinfoSubmit = async () => {
    try {

      if (subject == '') {
        Swal.fire({
          icon: "error",
          text: 'subject should be must',
        });
      } else if (servicename == '') {
        Swal.fire({
          icon: "error",
          text: 'service name should be must',
        });
      } else if (pricename == '') {
        Swal.fire({
          icon: "error",
          text: 'Price name should be must',
        });
      } else if (session1 == '') {
        Swal.fire({
          icon: "error",
          text: 'Total session should be must',
        });
      } else if (price1 == '') {
        Swal.fire({
          icon: "error",
          text: 'price should be must',
        });
      } else if (recurring1 == '') {
        Swal.fire({
          icon: "error",
          text: 'recurring should be must',
        });
      } else if (contactstartdate == '') {
        Swal.fire({
          icon: "error",
          text: 'recurring start date should be must',
        });
      } else if (enrollstartdate == '') {
        Swal.fire({
          icon: "error",
          text: 'enroll start date should be must',
        });
      } else if (enrollinactivedate == '') {
        Swal.fire({
          icon: "error",
          text: 'enroll inactive date should be must',
        });
      } else if (enrollrecurring == '') {
        Swal.fire({
          icon: "error",
          text: 'enroll recurring should be must',
        });
        // } else if (enrolltype == '') {
        //     Swal.fire({
        //         icon: "error",
        //         text: 'Prorated First Month Price should be must',
        //     });
      } else if (enrolltotalsession == '') {
        Swal.fire({
          icon: "error",
          text: 'enroll total session should be must',
        });
      } else if (enrollmanualfee == '') {
        Swal.fire({
          icon: "error",
          text: 'enroll manual fee should be must',
        });
      } else if (enrollremainings == '') {
        Swal.fire({
          icon: "error",
          text: 'enroll remainings should be must',
        });
      } else if (enrolldelivery == '') {
        Swal.fire({
          icon: "error",
          text: 'enroll delivery should be must',
        });
      } else {
        let obj = {
          // "student_id": id,
          "company_id": companyid,
          "subject_id": subject,
          "service_id": servicename,
          "pricing_id": pricename,
          "total_session": session1,
          "price": price1,
          "recurring": recurring1,
          "startup_fee": enrollstartdate,
          "registration_fee": price1 === 0 ? '' : enrollregfee,
          "assessment_fee": enrollassessmentfee,
          "monthly_recurring_fee": price1,
          "contract_start_date": contactstartdate,
          "contract_end_date": contactenddate,
          "discount_id": discount,
          "dicount_amount": discountAmount,
          "discount_percentage": discountPercentage,
          "prorated_first_month": enrolltype,
          "prorated_first_session": enrolltotalsession,
          "account_closing_fee": enrollmonthlyamount,
          "manual_fee": enrollmanualfee,
          "ach_discount": achdiscountamount,
          "late_payment_fee": enrollremainings,
          "todays_total_fee": TodaysAmount,
          "service_durations": scheduleduration

        }

        const response = await ApiConnection.post(`admission/service-info/add/?server_type=production&student_id=${student}`, obj);
        if (response?.status == 200) {
          Swal.fire({
            icon: "success",
            text: response?.data?.msg,
          });
          setShow2(false)
          try {
            const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${student}`)
            if (response?.status == 200) {
              console.log('student-admission-crud', response.data.total_stats[0].total_student_active_service)
              settotalcount(response.data.total_stats[0].total_student_active_service)
              setserviceList(response?.data?.results)
            }
          } catch (e) { }                // if (obj.price !== "0") {
          //     navigate(`/${userinfo?.company_slug}/students/PaymentLink/${obj.service_id}/${obj.company_id}/${id}`)
          // } else {
          //     setShow(false)
          //     setShow3(true)
          //     // navigate(`/${userinfo?.company_slug}/students/PaymentLink/${obj.service_id}/${obj.company_id}/${id}`)
          // }
        }
        // setContent(5);
      }
    } catch (e) { }
  }

  const DayHandle = async (e) => {
    console.log(e);
    var val = e.target.value
    setScheduleDay(val)

    let obj = {
      'date': val,
      'service_id': service,
    }
    try {
      const response = await ApiConnection.post(`employee-time-schedule-crud/?server_type=production&method="list"`, obj);

      if (response?.status === 200) {
        const serviceDuration = response?.data.results[0].service_durations;
        const formattedEndTime = convertMinutesToTime(serviceDuration);

        const newEndTime = addTimes(formattedEndTime, startTime);
        console.log(newEndTime)

        setEndTime(newEndTime);
      }
    } catch (e) {
    }
  }

  // const handleShow2 = (event) => {
  //   setservice(event.target.value)
  //  const selectValue = event.target.value
  //  if (selectValue === 'addservice') {
  //   setShow1(true);
  //  }
  // };

  const SubjectHandle = (e) => {
    setsubject(e.target.value)
  }

  const ServiceSubjectHandle = async (e) => {
    var val = e.target.value
    setsubject(val)
    try {
      const response = await ApiConnection.get(`service_crud/?server_type=production&subject_id=${val}`,)
      if (response?.status == 200) {
        setserviceList(response?.data?.results)
        setname1("");
        setsession1("");
        setprice1("");
        setrecurring1("");
      }
    } catch (e) { }
  }

  const ServiceHandle = async (e) => {
    console.log(e);

    const selectValue = e.target.value
    if (selectValue === 'addservice') {
      setShow2(true);
    } else {
      console.log(e);
      var val = e.target.value
      setservice(val)

      let obj = {
        'date': ScheduleDay,
        'service_id': val,
        'student_id': student
      }
      try {
        const response = await ApiConnection.post(`employee-time-schedule-crud/?server_type=production&method=list`, obj);

        if (response?.status === 200) {
          setAvilScheduleList(response?.data?.results);

          const minStartTime = response?.data?.results[0].min_start_time;
          const minEndTime = response?.data?.results[0].max_end_time;

          const [startHour, startMinute] = minStartTime.split(":").map(Number);
          const [endHour, endMinute] = minEndTime.split(":").map(Number);

          const startTime = startHour * 60 + startMinute;
          const endTime = endHour * 60 + endMinute;
          console.log(startTime, endTime)

          setMinStartTime(startHour);
          setMaxEndTime(endHour);
        }
      } catch (e) {
      }
    }
  }

  const handleClose = () => {
    setShow1(false);
  }

  const GetService = async () => {
    try {
      const response = await ApiConnection.get(`service_crud/?server_type=production`)
      if (response?.status == 200) {
        setserviceList(response?.data?.results)
      }
    } catch (e) { }
  }

  const ModuleHandle2 = (e, data) => {
    console.log(data)
    const { name, checked } = e.target
    if (checked) {
      if (name === "allSelect") {
        setselectedgrade(GradeList);
      } else {
        setselectedgrade([...selectedgrade, data]);
      }
    } else {
      if (name === "allSelect") {
        setselectedgrade([]);
      } else {
        let tempuser = GradeList.filter((item) => item.id !== data.id);
        setselectedgrade(tempuser);
      }
    }
  }

  const PriceHandle = async (e) => {
    console.log("PriceHandle", e)
    var val = e.target.value
    setpricename(val)
    console.log("PriceHandleVal", val)

    try {
      const response = await ApiConnection.get(`pricing_crud/?server_type=production&id=${val}`)
      console.log(response);

      setsession1(response.data.results[0].total_session);
      setprice1(response.data.results[0].price);
      setrecurring1(response.data.results[0].recurring);


    } catch (e) { }
  }

  useEffect(() => {

    var editdata = fetchacompanyReducer?.FetchstudentResponse?.results[0]
    setgrade(editdata?.grade_id)
    setteacher(editdata?.school_teacher_id)

    setcompanyid(editdata?.company_id)

    setenrollstartdate(editdata?.startup_fee)
    setenrollinactivedate(editdata?.assessment_fee)
    setenrollrecurring()
    setenrolltype()
    setenrolltotalsession()
    setenrollmonthlyamount(editdata?.cancelation_fee)
    setenrolldelivery()
    setenrollremainings(editdata?.late_fee)
    setenrolltotalsession()
    setenrollmanualfee(editdata?.manual_payment_fee)
    setachdiscountamount(editdata?.ach_discount)
    setscheduleduration(editdata?.service_durations)

    setenrollstartdate(editdata?.startup_fee)
    setenrollinactivedate(editdata?.assessment_fee)
    setenrollrecurring()
    setenrolltype()
    setenrollregfee(editdata?.startup_fee)
    setenrolltotalsession()
    setenrollassessmentfee(editdata?.assessment_fee)
    setenrollmonthlyamount(editdata?.cancelation_fee)
    setenrolldelivery()
    setenrollremainings(editdata?.late_fee)
    setenrolltotalsession()
    setenrollmanualfee(editdata?.manual_payment_fee)
    setachdiscountamount(editdata?.ach_discount)

  }, [fetchacompanyReducer?.FetchstudentResponse])


  const ProratedamountHandle = (value) => {
    setenrolltotalsession(value)
    const newAmount = Math.floor(price1 / session1 * value);
    setenrolltype(newAmount);
    const newAmount1 = Math.floor(newAmount + enrollregfee + enrollassessmentfee);
    setAmount1(newAmount1);
    const discounttotal = Math.floor(newAmount1 - discountAmount);
    setAmount2(discounttotal)
    const AchdiscountPrice = Math.floor(TodaysAmount * achdiscountamount) / 100;
    setachdiscountamountprice(AchdiscountPrice)
    const Achdiscounttotal = Math.floor(discounttotal - AchdiscountPrice);
    setTodaysAmount(Achdiscounttotal)
  }

  const DiscountHandle = async (e) => {
    console.log("ServiceHandle", e)
    var val = e.target.value
    setdiscount(val)
    try {
      const response = await ApiConnection.get(`membership-discount-crud/?server_type=production&id=${val}`)
      console.log(response.data.results[0].name);

      setdiscountAmount(response.data.results[0].amount);
      setdiscountPercentage(response.data.results[0].is_percentage);

    } catch (e) { }

  }

  const DiscountPercentageAmount = (value) => {
    setdiscountPercentage(value)
    const totalAmount = parseFloat(enrolldelivery) / value;
    console.log("DiscountPercentageAmount", totalAmount)
    const calculatedAmount = enrolldelivery - totalAmount;
    console.log(enrolldelivery, totalAmount);
    console.log(calculatedAmount);
    setachdiscountamount(calculatedAmount);
  }

  const SubmitpricingHandle = async () => {
    if (name == '') {
      Swal.fire({
        icon: "error",
        text: 'Name should be must',

      });
    } else if (session == '') {
      Swal.fire({
        icon: "error",
        text: 'Total Session should be must',
      });
    } else if (price == '') {
      Swal.fire({
        icon: "error",
        text: 'Price should be must',
      });
    } else {

      let obj1 = {
        id: docId
      }

      let obj = {
        "name": name,
        "total_session": session,
        "price": price,
        "recurring": recurring,
        "valid_days": valid
      }

      try {
        const response = await ApiConnection.post(`pricing_crud/?server_type=production&service_id=${obj1.id}`, obj)
        if (response?.status == 200) {
          Swal.fire({
            icon: "success",
            text: response?.data?.msg,
          });
          GetService()
          setShow1(false)
        }
      } catch (e) { }
    }
  }

  const SubmitScheduleHandle = async () => {

    var Temp = []
    selectedsubject && selectedsubject.map((item) => {
      var dataitem = Number(item.id);
      Temp.push(dataitem);
    })

    let obj = {
      "student_id": student,
      "service_id": service,
      "stud_start_time": startTime,
      "stud_end_time": endTime,
      "teacher_start_time": TstartTime,
      "teacher_end_time": TendTime,
      "is_repeat": "1",
      "repeat_type": repeattype,
      "week_days": Temp,
      "schedule_date": ScheduleDay,
    }

    try {
      const response = await ApiConnection.post(`student-service-schedule-crud/?server_type=production&employee_id=${empid}`, obj)
      console.log(response);
      if (response?.data.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Schedule created successfully!",
        });
        GetScheduledetails()
      } else if (response?.data.status === 400) {
        Swal.fire({
          icon: "error",
          text: response?.data.msg,
        });
      }
    } catch (e) {
    }
  };

  const GetScheduledetails = async () => {
    try {
      const response = await ApiConnection.get(`student-service-schedule-crud/?server_type=production`)
      if (response?.status == 200) {
        console.log(response)
        setscheduleList(response?.data?.results)
      }
    } catch (e) { }
  }


  // const GetService = async () => {

  //   try {
  //     const response = await ApiConnection.get(`service_crud/?server_type=production`)
  //     if (response?.status == 200) {
  //       setserviceList(response?.data?.results)
  //     }
  //   } catch (e) { }
  // }

  const convertMinutesToTime = (serviceDuration) => {
    const totalMinutes = parseInt(serviceDuration, 10);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}`;
  };

  const studentHandle = async (e) => {
    console.log('studentHandle', e)
    const val = e.target.value;
    setstudent(val);

    try {
      const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${val}`)
      if (response?.status == 200) {
        console.log('student-admission-crud', response.data.total_stats[0].total_student_active_service)
        settotalcount(response.data.total_stats[0].total_student_active_service)
        setserviceList(response?.data?.results)
      }
    } catch (e) { }
  }

  const SubmitServiceHandle = async () => {
    if (subject == '') {
      Swal.fire({
        icon: "error",
        text: 'Subject should be must',

      });
    } else if (servicename == '') {
      Swal.fire({
        icon: "error",
        text: 'Service Name should be must',
      });
    } else {

      var Temp = []
      selectedgrade && selectedgrade.map((item) => {
        var dataitem = Number(item.id);
        Temp.push(dataitem);
      })

      let obj = {
        "class_ids": Temp,
        "subject_id": subject,
        "name": servicename
      }

      try {
        const response = await ApiConnection.post(`service_crud/?server_type=production`, obj)
        if (response?.status == 200) {
          Swal.fire({
            icon: "success",
            text: response?.data?.msg,
          });
          // GetService()
          setdocId(response?.data?.service_id)
          setShow2(false)
          setShow3(true)
        }
      } catch (e) { }
    }
  }


  const ScheduledurationHandle = async (e) => {
    const val = e.target.value;
    setservice(val);

    try {
      const response = await ApiConnection.get(
        `service-durations-details/?server_type=production&service_id=${val}`
      );

      if (response?.status === 200) {
        const serviceDuration = response?.data.results[0].service_durations;
        const formattedEndTime = convertMinutesToTime(serviceDuration);

        const newEndTime = addTimes(formattedEndTime, startTime);
        console.log(newEndTime)

        setEndTime(newEndTime);
      }
    } catch (e) {
    }
  };

  const addTimes = (time1, time2) => {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const totalMinutes = hours1 * 60 + minutes1 + hours2 * 60 + minutes2;
    const newHours = Math.floor(totalMinutes / 60);
    const newMinutes = totalMinutes % 60;

    const formattedHours = newHours.toString().padStart(2, '0');
    const formattedMinutes = newMinutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  };

  const handleSelectEvent = useCallback(
    (event) => window.alert(event.title),
    []
  )

  const { defaultDate, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(2015, 3, 12),
      scrollToTime: new Date(1970, 1, 1, 6),
    }),
    []
  )

  const GetSubject = async () => {
    let obj = {
      name: '',
      group_id: ''
    }
    try {
      const response = await ApiConnection.get(`subjects-crud/?server_type=production&name=${obj.name}&group_id=${obj.group_id}`)
      if (response?.status == 200) {
        console.log("get subject", response.data)
        setsubjectList(response?.data?.results)
      }
    } catch (e) { }
  }

  function generateTimeIntervals() {
    const startTime = MinstartTime * 60;
    const endTime = MaxendTime * 60;
    console.log(startTime, endTime)

    const intervals = [];
    for (let i = startTime; i < endTime; i += 30) {
      const startHour = Math.floor(i / 60);
      const startMinute = i % 60;
      const endHour = Math.floor((i + 30) / 60);
      const endMinute = (i + 30) % 60;

      const formattedStart = `${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`;
      const formattedEnd = `${endHour.toString().padStart(2, '0')}:${endMinute.toString().padStart(2, '0')}`;
      const interval = `${formattedStart} - ${formattedEnd}`;
      intervals.push(interval);
    }

    return intervals;
  }

  const timeIntervals = generateTimeIntervals();
  console.log(timeIntervals);

  const GetSchedule = async (e) => {

    try {
      console.log(e);
      const val = e.target.value;
      setScheduleDay(val)
      const response = await ApiConnection.get(
        `employee-time-schedule-crud/?server_type=production&date=${val}`
      );
      if (response?.status === 200) {
        setAvilScheduleList(response?.data?.results);

        const minStartTime = response?.data?.results[0].min_start_time;
        const minEndTime = response?.data?.results[0].max_end_time;

        const [startHour, startMinute] = minStartTime.split(":").map(Number);
        const [endHour, endMinute] = minEndTime.split(":").map(Number);

        const startTime = startHour * 60 + startMinute;
        const endTime = endHour * 60 + endMinute;
        console.log(startTime, endTime)

        setMinStartTime(startHour);
        setMaxEndTime(endHour);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3>PC Helpdesk Schedule</h3>
        </div>

        <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
          <ul>
            <li>
              <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
            </li>
          </ul>
        </div>

        <div className='row form-style mb-4'>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Date<span class="asterisk">*</span></label>
              <input type='date' value={ScheduleDay} onChange={(e) => StudentDayHandle(e)} className='form-control'
                placeholderText="Date Scheduled"
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Student<span class="asterisk">*</span></label>
              <select
                className='form-control'
                value={student}
                onChange={(e) => studentHandle(e)}
              >
                <option value="">--Select Student--</option>
                {studentList && studentList.map((item, i) => {
                  return (
                    <option key={i} value={item?.id} >{item?.first_name} {item?.last_name}</option>
                  )
                })}
              </select>
            {/* <Multiselect
            options={studentList} 
            displayValue="first_name" 
            placeholder="select Student"
            /> */}
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label className='selectlabel'>Select Service<span class="asterisk">*</span></label>
              <select className='form-control' value={service} onChange={(e) => ServiceHandle(e)}>
                <option value="" disabled>--Select Service--</option>
                {/* {totalcount === 0 && ( */}
                <option value="addservice" style={{ color: 'blue' }} >Purchase New Service</option>
                {/* )} */}
                {serviceList && serviceList.map((item, i) => (
                  <option key={i} value={item?.service_id}>{item?.service_name}</option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className='row form-style mb-4 align-items-center'>

          <div className='mt-2 ml-4' style={{ border: '1px solid #c3c3c3', width: '96%' }}>
            <div className='table-responsive'>
              <table className='custom-table'>
                <thead style={{ backgroundColor: 'rgb(97, 115, 141)' }}>
                  <tr className='text-center' style={{ minWidth: '200px' }}>
                    <th style={{ width: '50px', color: 'white' }}>Time</th>
                    {/* Add teacher names as headers */}
                    {AvilScheduleList.map((teacher) => (
                      <th key={teacher.employee_id} style={{ width: '100px', color: 'white' }}>
                        {teacher.employee_first_name} {teacher.employee_last_name}
                      </th>
                    ))}
                  </tr>
                </thead>
                {AvilScheduleList && AvilScheduleList.length > 0 ?
                  <tbody>
                    {timeIntervals.map((timeSlot, rowIndex) => (
                      <tr key={timeSlot}>
                        <td style={{ backgroundColor: '#E6E6FA' }}>{timeSlot}</td>
                        {AvilScheduleList.map((teacher, colIndex) => {
                          const timeDetails = teacher.time_details_list || [];
                          const currentSlot = timeDetails.find((time) => {
                            return time.start_time <= timeSlot && time.end_time >= timeSlot;
                          });

                          if (currentSlot) {
                            const rowspan = currentSlot.end_time - currentSlot.start_time + 1;
                            return (
                              <td
                                key={`${teacher.employee_id}-${timeSlot}`}
                                rowSpan={rowspan}
                                onClick={(e) => handleShow1(e, timeSlot, currentSlot?.start_time, currentSlot?.end_time, teacher?.employee_id, currentSlot?.start_time, currentSlot?.end_time, teacher?.std_active_service_count)}
                                style={{ backgroundColor: currentSlot.is_avail === '1' ? '#98FF98' : '#ffff00bd', borderRight: currentSlot.is_avail === '1' ? '1px solid #a7a3a3' : '1px solid #ccc;' }}
                              >
                                {/* ({teacher.employee_id}) {teacher.employee_first_name} */}
                                <br />
                                {/* {currentSlot.start_time}-{currentSlot.end_time}, {currentSlot.student_list[0]?.id} */}
                                <span style={{ fontSize: "10px" }}>
                                  <button style={{ cursor: 'default' }}><h6 style={{ fontSize: '10px', color: 'blue' }}>{currentSlot.student_list[0]?.student_name}</h6> </button>
                                </span>
                                <span style={{ fontSize: "10px" }}>
                                  <button style={{ cursor: 'default' }}><h6 style={{ fontSize: '10px', color: 'blue' }}>{currentSlot.student_list[1]?.student_name}</h6> </button>
                                </span>
                                <span style={{ fontSize: "10px" }}>
                                  <button style={{ cursor: 'default' }}><h6 style={{ fontSize: '10px', color: 'blue' }}>{currentSlot.student_list[2]?.student_name}</h6> </button>
                                </span>
                                <span style={{ fontSize: "10px" }}>
                                  <button style={{ cursor: 'default' }}><h6 style={{ fontSize: '10px', color: 'blue' }}>{currentSlot.student_list[3]?.student_name}</h6> </button>
                                </span>
                                {/* {currentSlot.student_list[0]?.student_name},{currentSlot.student_list[1]?.student_name},{currentSlot.student_list[2]?.student_name},{currentSlot.student_list[3]?.student_name} <i className="fa-solid fa-trash" style={{ cursor: 'pointer', color: 'red' }}></i> */}
                              </td>
                            );
                          } else {
                            return (
                              <td key={`${teacher.employee_id}-${timeSlot}`}>
                                {/* ({teacher.employee_id}) {teacher.employee_first_name} */}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                    {/* } */}
                  </tbody>
                  :
                  <h6 className='text-center' style={{ fontSize: '14px' }}>No data found</h6>
                }
              </table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show1} size="lg">
        <Modal.Header >
          <Modal.Title>Add Schedule</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style'>


            {validcheck === 0 && (
              <div className='col-lg-12 mt-2'>
                <div className='form-group d-flex align-item-center'>
                  {/* <ReactSwitch checked={termschecked} onChange={handleChange1} /> */}
                  <label className='selectlabel ml-2 mt-1' style={{ fontSize: '14px', color: 'red' }}>* Student Does'nt have any Active Service.</label>
                </div>
              </div>
            )}

            {/* {termschecked === true && ( */}
            <>
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='selectlabel'>Start Time<span class="asterisk">*</span></label>
                  <select className='form-control' value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}>
                    <option value="">--Start Time---</option>
                    <option value="08:00">08:00 AM</option>
                    <option value="08:30">08:30 AM</option>
                    <option value="09:00">09:00 AM</option>
                    <option value="09:30">09:30 AM</option>
                    <option value="10:00">10:00 AM</option>
                    <option value="10:30">10:30 AM</option>
                    <option value="11:00">11:00 AM</option>
                    <option value="11:30">11:30 AM</option>
                    <option value="12:00">12:00 PM</option>
                    <option value="12:30">12:30 PM</option>
                    <option value="13:00">01:00 PM</option>
                    <option value="13:30">01:30 PM</option>
                    <option value="14:00">02:00 PM</option>
                    <option value="14:30">02:30 PM</option>
                    <option value="15:00">03:00 PM</option>
                    <option value="15:30">03:30 PM</option>
                    <option value="16:00">04:00 PM</option>
                    <option value="16:30">04:30 PM</option>
                    <option value="17:00">05:00 PM</option>
                    <option value="17:30">05:30 PM</option>
                    <option value="18:00">06:00 PM</option>
                    <option value="18:30">06:30 PM</option>
                    <option value="19:00">07:00 PM</option>
                    <option value="19:30">07:30 PM</option>
                    <option value="20:00">08:00 PM</option>
                  </select>
                </div>
              </div>

              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='selectlabel'>End Time<span class="asterisk">*</span></label>
                  <select className='form-control' value={endTime} onChange={(e) => setEndTime(e.target.value)}>

                    <option value="">--End Time---</option>
                    <option value="08:00">08:00 AM</option>
                    <option value="08:30">08:30 AM</option>
                    <option value="09:00">09:00 AM</option>
                    <option value="09:30">09:30 AM</option>
                    <option value="10:00">10:00 AM</option>
                    <option value="10:30">10:30 AM</option>
                    <option value="11:00">11:00 AM</option>
                    <option value="11:30">11:30 AM</option>
                    <option value="12:00">12:00 PM</option>
                    <option value="12:30">12:30 PM</option>
                    <option value="13:00">01:00 PM</option>
                    <option value="13:30">01:30 PM</option>
                    <option value="14:00">02:00 PM</option>
                    <option value="14:30">02:30 PM</option>
                    <option value="15:00">03:00 PM</option>
                    <option value="15:30">03:30 PM</option>
                    <option value="16:00">04:00 PM</option>
                    <option value="16:30">04:30 PM</option>
                    <option value="17:00">05:00 PM</option>
                    <option value="17:30">05:30 PM</option>
                    <option value="18:00">06:00 PM</option>
                    <option value="18:30">06:30 PM</option>
                    <option value="19:00">07:00 PM</option>
                    <option value="19:30">07:30 PM</option>
                    <option value="20:00">08:00 PM</option>
                  </select>
                </div>
              </div>

              <div className='col-lg-6 mt-2'>
                <div className='form-group d-flex align-item-center'>
                  <ReactSwitch checked={checked} onChange={handleChange} />
                  <label className='selectlabel ml-2 mt-1' style={{ fontSize: '14px' }}>Repeat</label>
                </div>
              </div>

              <div className='col-lg-6'>
              </div>

              {checked === true && (
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='selectlabel'>Repeat Type<span class="asterisk">*</span></label>
                    <select className='form-control' style={{ fontSize: '11px', paddingLeft: '10px' }} value={repeattype}
                      onChange={(e) => setrepeattype(e.target.value)}>
                      <option value="">Select Type</option>
                      <option value="daily">Daily</option>
                      <option value="Weekly">Weekly</option>
                    </select>
                  </div>
                </div>
              )}

              {repeattype === "Weekly" && checked === true && (
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <label className='selectlabel'>Week Days<span class="asterisk">*</span></label>
                    <Dropdown className='custom-dropdown-checkbox'>
                      <Dropdown.Toggle variant="success" style={{ fontSize: '11px', paddingLeft: '10px' }}>Select Days
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <ul>
                          <li>
                            <input type="checkbox" name="allSelect" checked={selectedsubject?.length === DayList?.length}
                              onChange={(e) => ModuleHandle(e, DayList, 'dayname')}
                            />
                            <label>All Select</label>
                          </li>
                          {DayList && DayList.map((list, i) => {
                            return (
                              <li>
                                <input type="checkbox" name={list?.dayname + i} id={i} onChange={(e) => ModuleHandle(e, list, 'dayname')}

                                />
                                <label htmlFor={i}>{list?.dayname}</label>
                              </li>
                            )
                          })}

                        </ul>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
            </>
            {/* )}  */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={SubmitScheduleHandle}>Save</button>
        </Modal.Footer>
      </Modal>

      <Modal show={show2} size="lg">
        <Modal.Header >
          <Modal.Title>Add Service</Modal.Title>
          <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Subject<span class="asterisk">*</span></label>
                <select className='form-control' value={subject}
                  onChange={ServiceSubjectHandle}>
                  <option value="">--Select Subject---</option>
                  {subjectList && subjectList.map((item, i) => {
                    return (
                      <option key={i} value={item?.id} >{item?.subject_name}</option>
                    )

                  })}
                </select>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Service<span class="asterisk">*</span></label>
                <select className='form-control' value={servicename}
                  onChange={(e) => AssignServiceHandle(e)}>
                  <option value="">--Select Services---</option>
                  {serviceList && serviceList.map((item, i) => {
                    return (
                      <option key={i} value={item?.id} >{item?.name}</option>
                    )

                  })}
                </select>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Pricing<span class="asterisk">*</span></label>
                <select className='form-control' value={pricename}
                  onChange={(e) => PriceHandle(e)}>
                  <option value="">--Select Price---</option>
                  {priceList && priceList.map((item, i) => {
                    return (
                      <option key={i} value={item?.id} >{item?.name}</option>
                    )

                  })}
                </select>
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Total Session<span class="asterisk">*</span></label>
                <input type='text' disabled className='form-control' placeholder='Total Session' value={session1}
                  onChange={(e) => { setsession1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Schedule Duration<span class="asterisk">*</span></label>
                {/* <input type='text' className='form-control' placeholder='Schedule Duration' value={scheduleduration}
            onChange={(e) => { setscheduleduration(e.target.value) }}
        /> */}
                <select className='form-control' value={scheduleduration}
                  onChange={(e) => { setscheduleduration(e.target.value) }}>
                  <option value="">--Select Duration---</option>
                  <option value="30">30 min.</option>
                  <option value="60">60 min.</option>
                  <option value="90">90 min.</option>
                  <option value="120">120 min.</option>
                  <option value="150">150 min.</option>
                  <option value="180">180 min.</option>
                  <option value="210">210 min.</option>
                  <option value="240">240 min.</option>


                  {/* {priceList && priceList.map((item, i) => {
                return (
                    <option key={i} value={item?.id} >{item?.name}</option>
                )

            })} */}
                </select>
              </div>
            </div>


            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Price<span class="asterisk">*</span> </label>
                <input type='text' disabled className='form-control' placeholder='Price' value={price1}
                  onChange={(e) => { setprice1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Contract Start Date<span class="asterisk">*</span> </label>
                <input type='date' className='form-control' placeholder='Contract Start Date' value={contactstartdate}
                  onChange={(e) => { setcontactstartdate(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Contract End Date<span class="asterisk">*</span> </label>
                <input type='date' className='form-control' placeholder='Contract End Date' value={contactenddate}
                  onChange={(e) => { setcontactenddate(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Recurring Type<span class="asterisk">*</span> </label>
                <input type='text' disabled className='form-control' placeholder='Recurring Type' value={recurring1}
                  onChange={(e) => { setrecurring1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Registration Fee<span class="asterisk">*</span></label>
                <input type='text' className='form-control' placeholder='Registration Fee '
                  value={enrollstartdate} onChange={(e) => setenrollstartdate(e.target.value)} />
              </div>
            </div>

            <hr></hr>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Prorated First Month Session<span class="asterisk">*</span> </label>
                <input type='text' className='form-control' placeholder='Prorated First Session '
                  value={enrolltotalsession} onChange={(e) => ProratedamountHandle(e.target.value)} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Prorated First Month Price<span class="asterisk">*</span> </label>
                <input type='text' disabled className='form-control' placeholder='Prorated First Month '
                  value={enrolltype} onChange={(e) => setenrolltype(e.target.value)} />

              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Select Discount</label>
                <select className='form-control' value={discount}
                  onChange={(e) => DiscountHandle(e)}>
                  <option value="">--Select Discount---</option>
                  {discountList && discountList.map((item, i) => {
                    return (
                      <option key={i} value={item?.id} >{item?.name}</option>
                    )
                  })}
                </select>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Assessment Fee<span class="asterisk">*</span> </label>
                <input type='text' className='form-control' placeholder='Assessment Fee '
                  value={enrollinactivedate} onChange={(e) => setenrollinactivedate(e.target.value)} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Discount Amount </label>
                <input type='text' disabled className='form-control' placeholder='Amount' value={discountAmount}
                  onChange={(e) => { setdiscountAmount(e.target.value) }}
                />
              </div>
            </div>


            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Sub Total 1<span class="asterisk">*</span> </label>
                <input type='text' disabled className='form-control' placeholder='Sub Total 1'
                  value={Amount1} onChange={(e) => setAmount1(e.target.value)} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Discount Percentage </label>
                <input type='text' disabled className='form-control' placeholder='Percentage' value={discountPercentage}
                  onChange={(e) => { DiscountPercentageAmount(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Discount</label>
                <input type='text' disabled className='form-control' value={discountAmount}
                  onChange={(e) => setenrollstartdate(e.target.value)} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group' style={{ marginTop: '40px', fontWeight: '600' }}>
                <label className='selectlabel'> <u>Monthly Recurring Details</u> </label>
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>

              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Price</label>
                <input type='text' disabled className='form-control' value={price1}
                  onChange={(e) => setenrollstartdate(e.target.value)} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Sub Total 2<span class="asterisk">*</span> </label>
                <input type='text' disabled className='form-control' placeholder='Sub Total 2'
                  value={Amount2} onChange={(e) => setAmount2(e.target.value)} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Discount </label>
                <input type='text' disabled className='form-control' placeholder='Discount' value={discountAmount}
                  onChange={(e) => { setdiscountAmount(e.target.value) }}
                />
              </div>
            </div>

            {/* <div className='col-lg-6'>
    <div className='form-group'>
        <label className='selectlabel'>ACH Discount</label>
        <input type='text' className='form-control' value={achdiscount}
            onChange={(e) => setenrollstartdate(e.target.value)} />
    </div>
</div>

<div className='col-lg-6'>
    <div className='form-group'>
        <label className='selectlabel'>ACH Discount <input disabled style={{ width: '12%' }} value={achdiscount} /> %</label>
        <input type='text' className='form-control' placeholder='ACH Discount' value={achdiscountamount}
            onChange={(e) => { setachdiscountamount(e.target.value) }}
        />
    </div>
</div> */}

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Payment Method</label>
                <select className='form-control' value={paymentmethod}
                  onChange={(e) => setpaymentmethod(e.target.value)}>
                  <option value="" disabled>Payment Method</option>
                  <option value="1">Card</option>
                  <option value="2">Cash</option>
                  <option value="3">Check</option>
                  <option value="4">ACH</option>
                </select>
              </div>
            </div>

            {paymentmethod === '4' && (
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='selectlabel'>ACH Discount</label>
                  <input type='text' disabled className='form-control' value={achdiscountamountprice}
                    onChange={(e) => setenrollstartdate(e.target.value)} />
                </div>
              </div>
            )}

            {paymentmethod === '4' && (
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='selectlabel'>ACH Discount <input disabled style={{ width: '12%' }} value={achdiscountamount} /> %</label>
                  <input type='text' disabled className='form-control' placeholder='ACH Discount' value={achdiscountamountprice}
                    onChange={(e) => { setachdiscountamountprice(e.target.value) }}
                  />
                </div>
              </div>
            )}

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Total (Fututre Recuuring Months)</label>
                <input type='text' disabled className='form-control' value={price1}
                  onChange={(e) => setprice1(e.target.value)} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Today's Total<span class="asterisk">*</span> </label>
                <input type='text' className='form-control' placeholder='Todays Total '
                  value={TodaysAmount} onChange={(e) => setTodaysAmount(e.target.value)} />
              </div>
            </div>



            {/* <div className='col-lg-6'>
    <div className='form-group'>
        <label className='selectlabel'>ACH Discount <input disabled style={{width: '12%'}} value={achdiscount}/> %</label>
        <input type='text' className='form-control' placeholder='ACH Discount' value={achdiscount}
            onChange={(e) => { setachdiscount(e.target.value) }}
        />
    </div>
</div> */}

            <div className='col-lg-6 erolldetails' style={{ maxWidth: '47%' }}>
              <span className='selectlabel'>Notes : </span><br></br>

              <div className='form-group'>
                <label className='selectlabel'>A/C Closing Fee <input disabled value={enrollmonthlyamount} onChange={(e) => setenrollmonthlyamount(e.target.value)} /></label><br></br>
                <label className='selectlabel'>Late Fee <input disabled value={enrollremainings} onChange={(e) => setenrollremainings(e.target.value)} /></label><br></br>
                <label className='selectlabel'>Manual Fee <input disabled value={enrollmanualfee} onChange={(e) => setenrollmanualfee(e.target.value)} /></label><br></br>
              </div>
            </div>




            <div className='col-lg-6'>
              <div className='form-group'>

              </div>
            </div>



          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={serviceinfoSubmit}>Add</button>

        </Modal.Footer>
      </Modal>

      <Modal show={show3} size="lg">
        <Modal.Header >
          <Modal.Title>Add Pricing</Modal.Title>
          <button onClick={handleClose3} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Price Name</label>
                <input type='text' className='form-control' placeholder='Name' value={name}
                  onChange={(e) => { setname(e.target.value) }} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Total Sessions</label>
                <input type='text' className='form-control' placeholder='Total Sessions' value={session}
                  onChange={(e) => { setsession(e.target.value) }} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Price</label>
                <input type='text' className='form-control' placeholder='Price' value={price}
                  onChange={(e) => { setprice(e.target.value) }} />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Recurring</label>
                <select className='form-control' value={recurring}
                  onChange={(e) => { setrecurring(e.target.value) }}>
                  <option value="">--Select Recurring---</option>
                  <option value="month">month</option>
                  <option value="year">year</option>
                  <option value="week">week</option>
                  {/* <option value="day">day</option> */}
                  <option value="onetime">No</option>
                </select>
              </div>
            </div>

            {recurring === 'No' && (
              <div className='col-lg-6'>
                <div className='form-group'>
                  <label className='selectlabel'>Valid Days</label>
                  <input type='number' className='form-control' placeholder='No. of Days' value={valid}
                    onChange={(e) => { setvalid(e.target.value) }} />
                </div>
              </div>
            )}

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={SubmitpricingHandle}>Add</button>

        </Modal.Footer>
      </Modal>

    </div>
  )

}

export default Schedule_Helpdesk