import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetStudenttestRequest, GetExistingTestRequest, GetStudentTestListRequest, GetExistingQuestionRequest, AssignScheduleRequest, GetstudentRequest, DownloadQuestionRequest, GetLearningPlanListsRequest, GetCompareChartRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../../utils/ApiConnection'
import Accordion from 'react-bootstrap/Accordion';

const Student_Assessment = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [time, settime] = useState("")
    const [assessmentdate, setassessmentdate] = useState(null)
    const [starttime, setstarttime] = useState("")
    const [endtime, setendtime] = useState("")
    const [show, setShow] = useState(false);
    const [editrowId, seteditrowId] = useState(null)
    const [TestId, setTestId] = useState("");
    const handleClose = () => setShow(false);
    const [show1, setShow1] = useState(false);
    const [show2, setShow2] = useState(false);
    const [selectedtests, setselectedtests] = useState("")
    const [selectedslug, setselectedslug] = useState("")
    const [selectedUser, setSelectedUser] = useState([]);
    const [StudentList, setStudentList] = useState([])
    const [totalquestion, settotalquestion] = useState([])
    // const [comparechart, setcomparechart] = useState([])
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);



    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var testList = fetchacompanyReducer?.GetStudenttestResponse?.results
    var ExistingTest = fetchacompanyReducer?.GetStudentTestListResponse?.results
    var studentList = fetchacompanyReducer?.GetstudentResponse?.results
    var comparechart = fetchacompanyReducer?.GetCompareChartResponse?.results


    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id } = useParams()

    //     selector: (row) => (
    //         row.is_exam_taken != '0' ?
    //            <button className='formBtn studentassignbtn' onClick={()=>DownloadHandle(row.test_id)}>Print</button>
    //            :
    //            <span>N/A</span>
    //    ),

    const columns = [
        {
            name: 'Title',
            selector: row => (
                <div>
                    <NavLink onClick={() => DownloadHandle(row.test_id)}><i className="fa-solid fa-eye"></i></NavLink> &nbsp;
                    <span>{row.test_name}</span>
                </div>
            ),
            sortable: true,
            width: '260px',
        },
        {
            name: 'Group',
            selector: row => row.group_name,
            width: '100px',
        },
        {
            name: 'Grade',
            selector: row => row.grade_name,
            width: '100px',
        },
        {
            name: 'Date Taken',
            selector: row => row.date_of_exam_taken,
            width: '110px',
        },
        {
            name: 'Test Type',
            selector: row => row.test_type,
            width: '100px',
        },
        {
            name: 'AnswerSheet',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                    <NavLink to={`/${userinfo?.company_slug}/students/assessmentanswer/${row?.test_id}`} className='formBtn studentassignbtn'>Print</NavLink>
                    // <button className='formBtn studentassignbtn' onClick={()=>DownloadHandle(row.test_id)}>Print</button>
                    :
                    <span>N/A</span>
            ),
            width: '120px',
        },
        {
            name: 'Chart',
            selector: (row) => (
                row.is_exam_taken != '0' && row.test_type != 'group' ?
                    // <button className='formBtn studentassignbtn' onClick={handleChart()}>Chart</button>
                    <NavLink to={`/${userinfo?.company_slug}/students/assessmentchart/${row.test_id}/${id}`} className='formBtn studentassignbtn'>Chart</NavLink>
                    :
                    <span>N/A</span>
            ),
            width: '100px',
        },
        // {
        //     name: 'Compare',
        //     selector: (row) => (
        //         row.is_exam_taken != '0' ?
        //            ''
        //            :
        //            <span>N/A</span>
        //    ),
        // },
        {
            name: 'Select',
            selector: (row) => (
                row.is_exam_taken != '0' && row.test_type != 'group' ?
                    <>
                        <input type="checkbox" value={row.id} onChange={(e) => handleCheckboxChange(e, row.test_id, row.slug)} />
                    </>
                    :
                    <span>N/A</span>
            ),
            width: '100px',
        },
        {
            name: 'Level',
            selector: row => row.level,
            width: '120px',
        },
        {
            name: 'Score (%)',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                    <span>{row.total_percantege_obtained}</span>
                    :
                    <span>N/A</span>
            ),
            width: '100px',
        },
        {
            name: 'Results',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                    <NavLink to={`/${userinfo?.company_slug}/students/assessmentresult/${row?.test_id}/${id}`} className='formBtn studentassignbtn'>Results</NavLink>
                    :
                    <span>N/A</span>
            ),
            width: '100px',
        },
        {
            name: 'Answer Key',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                    <NavLink to={`/${userinfo?.company_slug}/students/assessmentanswer/${row?.test_id}`} className='formBtn studentassignbtn'>Answer</NavLink>

                    :
                    <span>N/A</span>
            ),
            width: '110px',
        },
        {
            name: 'Learning Path',
            selector: (row) => {
                if (row.test_type == 'group') {
                    return (
                        <p>N/A</p>
                    );
                } else if (row.is_exam_taken != '0' && row.is_lp_taken == '0') {
                    return (
                        <NavLink to={`/${userinfo?.company_slug}/students/StudentLearningpath/${row?.test_id}/${id}`} className='formBtn studentassignbtn'>
                            Add LP
                        </NavLink>
                    );
                } else if (row.is_exam_taken != '0' && row.is_lp_taken != '0') {
                    return (
                        <NavLink to={`/${userinfo?.company_slug}/students/ViewStudentLearningpath/${row?.test_id}/${id}`} className='formBtn studentassignbtn'>
                            View LP
                        </NavLink>
                    );
                } else {
                    return (
                        <p>N/A</p>
                    );
                }
            },
            width: '120px',
        },
        {
            name: 'Delete',
            selector: (row) => (
                row.is_exam_taken != '0' ?
                    <button className='table-btn'><i className="fa-solid fa-trash"></i></button>
                    :
                    <span>N/A</span>
            ),
            width: '100px',
        },
    ];

    useEffect(() => {

        const totalLength = fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details?.[0]?.total_question_count; let pagArr = []
        for (let i = 0; i < totalLength; i++) {
            pagArr.push(i)
        }
        settotalquestion(pagArr)

    }, [fetchacompanyReducer?.GetCompareChartResponse])

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(GetStudenttestRequest(obj))
    }, [])


    useEffect(() => {
        let obj = {
            id: id

        }
        dispatch(GetStudenttestRequest(obj))
    }, [fetchacompanyReducer?.AssignScheduleRequest])

    useEffect(() => {
        const result = testList && testList.filter(data => {
            return data.test_name?.toLowerCase().match(Namesearch?.toLowerCase())
        })
        setFilterResult(result)
    }, [Namesearch])

    useEffect(() => {

        setFilterResult(testList)
    }, [fetchacompanyReducer?.GetStudenttestResponse])

    useEffect(() => {

        setStudentList(studentList)

    }, [fetchacompanyReducer?.GetstudentResponse])

    const handleShow = () => {
        // setaddrowId(tid)
        setShow(true)
        let obj = {
            id: id
        }
        dispatch(GetStudentTestListRequest(obj))
    };

    const handleChange = (e, data) => {
        const { name, checked } = e.target
        if (checked) {
            // if cheked and selectall checkbox add all fileds to selectedList
            if (name === "allSelect") {
                setSelectedUser(StudentList);
            } else {
                // if cheked and specific checkbox add specific field to selectedList
                setSelectedUser([...selectedUser, data]);
            }
        } else {
            // if uncheked and selectall checkbox add remove all fileds from selectedList
            if (name === "allSelect") {
                setSelectedUser([]);
            } else {
                // if uncheked and specific checkbox remove specific field from selectedList
                let tempuser = selectedUser.filter((item) => item.id !== data.id);
                setSelectedUser(tempuser);
            }
        }
    }


    const handleShow1 = (testId) => {
        setTestId(testId)
        setShow1(true)
        dispatch(GetstudentRequest())
    };

    const handleShow2 = (testId) => {
        setTestId(testId)
        setShow2(true)
        // dispatch(GetstudentRequest())
    };

    const DownloadHandle = (testId) => {
        dispatch(DownloadQuestionRequest(testId))
    }

    const submitLP = () => {

    };

    const handleCheckboxChange = (e, rowId, slug) => {
        if (e.target.checked) {
            // Add the row ID to your selectedTests state (an array)
            setselectedtests((prevSelectedTests) => [...prevSelectedTests, rowId]);
            setselectedslug((prevSelectedSlug) => [...prevSelectedSlug, slug]);
        } else {
            // Remove the row ID from the selectedTests state
            setselectedtests((prevSelectedTests) =>
                prevSelectedTests.filter((id) => id !== rowId)
            );
            setselectedslug((prevSelectedSlug) =>
                prevSelectedSlug.filter((id) => id !== slug)
            );
        }
    };

    const GetCompareChart = async () => {
        let obj = {
            "test_id": selectedtests,
            "slug": selectedslug,
        }

        try {
            const response = await dispatch(GetCompareChartRequest({ obj, id: id }));
            setShow2(true);
            // setcomparechart(response);
            // if (errorMessage?.msg == "All Tests should be the same") {
            //     setShow2(false);
            //     Swal.fire({
            //         icon: 'error',
            //         text: 'All Tests Should be Same.',
            //     });
            // } else if (errorMessage?.status == 200) {
            //     setShow2(true);
            // }  else {
            //     setShow2(true);
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const errorMessage = useSelector((state) => state.FetchCompanyDataReducer.GetCompareChartResponse);

    // try {
    //     const response = await ApiConnection.post(`test/exam-reslut-compare/?server_type=production&student_id=${id}`, obj);
    //     if (response?.data?.status === 400) {
    //         Swal.fire({
    //             icon: 'error',
    //             text: response?.data?.msg,
    //         });
    //     } else if (response?.status === 200) {
    //         setcomparechart(response?.data?.results)
    //         // setShow2(true)

    //         navigate(`/${userinfo?.company_slug}/students/assessmentchart/${2}/${id}`)
    //     }
    // } catch (e) {
    // }

    const StartTimeHandle = (e) => {
        setstarttime(e.target.value)

        settime(e.target.value);
    }

    const EndTimeHandle = (e) => {
        settime(e.target.value)

        const start = new Date(`1970-01-01T${starttime}`);
        const durationInMinutes = parseInt(e.target.value, 10);
        const end = new Date(start.getTime() + durationInMinutes * 60000);
        const formattedEndtime = end.toLocaleTimeString('ru-RU', { hour: '2-digit', minute: '2-digit' });
        setendtime(formattedEndtime);
    }

    const AddIndSchduleHandle = () => {

        if (TestId == '') {
            Swal.fire({
                icon: "error",
                text: 'Test Name should be must',
            });
        } else if (assessmentdate == null) {
            Swal.fire({
                icon: "error",
                text: 'Schedule Date should be must',
            });
        } else if (starttime == '') {
            Swal.fire({
                icon: "error",
                text: 'Start Time should be must',
            });
        } else if (endtime == '') {
            Swal.fire({
                icon: "error",
                text: 'End Time should be must',
            });
        } else if (time == '') {
            Swal.fire({
                icon: "error",
                text: 'Time should be must',
            });
        } else {

            var Temp = []
            selectedUser && selectedUser.map((item) => {
                var dataitem = Number(item.id);
                Temp.push(dataitem);
            })


            let obj = {
                "test_id": Number(TestId),
                "student_id": [Number(id)],
                "test_date": moment(assessmentdate).format("YYYY-MM-DD"),
                "test_time": Number(time),
                "start_time": starttime,
                "end_time": endtime
            }

            dispatch(AssignScheduleRequest({ obj: obj, TestId: TestId, student_id: id, navigate: navigate }))
        }
    }

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(GetStudenttestRequest(obj))
        setShow(false)
    }, [fetchacompanyReducer?.AssignScheduleResponse])

    // const AssignHandle = () => {
    //     Swal.fire({
    //         title: 'No Questions In This Assessment',
    //         text: "Please Add Questions First to Assign!",
    //         icon: 'warning',
    //     })
    // }

    return (
        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h5>Assessment</h5>
            <div className='row form-style mb-4 align-items-center'>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <input type='text' className='form-control' placeholder='Assessment Name'
                            value={Namesearch}
                            onChange={(e) => setNamesearch(e.target.value)}
                        />
                    </div>
                </div>
                <div className='button-part mb-4' style={{ marginBottom: '15px' }}>
                    <ul>
                        <li><button className='formBtn' onClick={handleShow}>Add Assessment</button></li>
                        <li><button className='formBtn' onClick={GetCompareChart}>Compare</button></li>
                    </ul>


                </div>
            </div>

            <a id="DownloadQuestion" target='_blank'></a>

            <div className='table-responsive'>
                <DataTable
                    columns={columns}
                    data={FilterResult}
                    pagination
                    highlightOnHover
                />
            </div>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Assessment Assign</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>
                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Student</label>
                            <Dropdown className='custom-dropdown-checkbox'>
                            <Dropdown.Toggle variant="success">
                                {selectedUser.length > 0 ? 'select ' + selectedUser.length : 'select student'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <ul>
                                    <li>
                                        <input type="checkbox" name="allSelect" checked={selectedUser?.length === StudentList?.length}
                                         onChange={(e) => handleChange(e, StudentList)}
                                        />
                                        <label>All Select</label>
                                    </li>
                                    {StudentList&&StudentList.map((list, i) =>{
                                        return (
                                            <li>
                                                <input type="checkbox" name={list?.first_name+list?.last_name+i} id={i} onChange={(e) => handleChange(e, list)}
                                                checked={selectedUser.some((item) => item?.id === list.id)}
                                                />
                                                <label htmlFor={i}>{list?.first_name} {list?.last_name}</label>
                                            </li>
                                        )
                                    })}
                                    
                                   
                                </ul>
                            </Dropdown.Menu>
                            </Dropdown>
                             
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Schedule Date</label>
                                <DatePicker selected={assessmentdate} onChange={(date) => { setassessmentdate(date) }} className='form-control'
                                    placeholderText="Schedule Date"
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Schedule Time</label>
                                <input type='time' className='form-control' placeholder='Start Time'
                                    value={starttime}
                                    onChange={StartTimeHandle}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Duration</label>
                                <select className='form-control' value={time}
                                    onChange={EndTimeHandle}>
                                    <option value="">--Duration--</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                    <option value="150">150</option>
                                    <option value="180">180</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>End Time</label>
                                <input type='text' className='form-control' placeholder='End Time'
                                    value={endtime}
                                    onChange={(e) => setendtime(e.target.value)}
                                    readOnly />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddIndSchduleHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Assessment</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Select Assessment <span class="asterisk">*</span></label>
                                <select className='form-control' value={TestId} onChange={(e) => { setTestId(e.target.value) }}>
                                    <option>--select--</option>
                                    {ExistingTest && ExistingTest.map((list, index) => {

                                        return (
                                            <option key={index} value={list?.id}>{list?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Schedule Date <span class="asterisk">*</span></label>
                                <DatePicker selected={assessmentdate} onChange={(date) => { setassessmentdate(date) }} className='form-control'
                                    placeholderText="Schedule Date"
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Schedule Time <span class="asterisk">*</span></label>
                                <input type='time' className='form-control' placeholder='Start Time'
                                    value={starttime}
                                    onChange={StartTimeHandle}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Duration <span class="asterisk">*</span></label>
                                <select className='form-control' value={time}
                                    onChange={EndTimeHandle}>
                                    <option value="">--Duration--</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    <option value="120">120</option>
                                    <option value="150">150</option>
                                    <option value="180">180</option>
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>End Time <span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='End Time'
                                    value={endtime}
                                    onChange={(e) => setendtime(e.target.value)}
                                    readOnly />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={AddIndSchduleHandle}>Add</button>

                </Modal.Footer>
            </Modal>


            <Modal show={show2} onHide={handleClose2} size="xl">
                <Modal.Header >
                    <Modal.Title>Compare Chart</Modal.Title>
                    <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    {errorMessage?.status != 400 ?
                        <div className='mb-4'>
                            <div className='view-page' >
                                <div className='row mt-2' style={{ backgroundColor: '#f8f9fa', border: '1px solid #dee2e6', borderRadius: '8px', padding: '15px', width: '99%', marginLeft: '5px' }}>
                                    <div className='col-lg-7'>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <label className='d-block'>Name :</label>
                                                </div>
                                                <div className='col-lg-10'>
                                                    <b>{comparechart?.student_name}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-5'>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-lg-3'>
                                                    <label className='d-block'>Grade :</label>
                                                </div>
                                                <div className='col-lg-9'>
                                                    <b>{comparechart?.grade_name}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='col-lg-12'>
                                        <div className='form-group'>
                                            <div className='row'>
                                                <div className='col-lg-2'>
                                                    <label className='d-block'>Email:</label>
                                                </div>
                                                <div className='col-lg-10'>
                                                    <b>{comparechart?.mobile_phone}</b>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* <hr></hr> */}

                                <div className='d-flex'>
                                    {fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details.map((item, index) => (
                                        <div key={index} className='flex-fill p-2' style={{ width: '50%' }}>
                                            <div className='card'>
                                                <div className='card-header'>
                                                    <h5 style={{ fontSize: '15px', fontWeight: '400' }}>
                                                        <i className="fas fa-laptop-code"></i> {item?.test_name}
                                                    </h5>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='row mt-2'>
                                                        <div className='col-lg-6'>
                                                            <div className='form-group'>
                                                                <div className='row align-items-center'>
                                                                    <div className='col-lg-6'>
                                                                        <label className='d-block text-right'>Group :</label>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <b>{item?.group_name}</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <div className='form-group'>
                                                                <div className='row align-items-center'>
                                                                    <div className='col-lg-6'>
                                                                        <label className='d-block text-right'>Level :</label>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <b>{item?.level}</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <div className='form-group'>
                                                                <div className='row align-items-center'>
                                                                    <div className='col-lg-6'>
                                                                        <label className='d-block text-right'>Date Taken :</label>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <b>{moment(item?.exam_date).format("YYYY-MM-DD")}</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <div className='form-group'>
                                                                <div className='row align-items-center'>
                                                                    <div className='col-lg-6'>
                                                                        <label className='d-block text-right'>Score :</label>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <b style={{ fontSize: '1.2em', color: item?.cp_test !== 0 ? '#28a745' : 'rgb(118, 237, 118)' }}>{item?.total_percantege_obtained} %</b>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {/* <hr></hr> */}

                            </div>

                            <div className='for-suggestion' style={{ border: '2px solid rgb(226 226 226)', borderRadius: '8px', padding: '15px', backgroundColor: '#f8f9fa' }}>
                                <div className='for-suggestion-b' style={{ marginBottom: '10px' }}>
                                    <label style={{ fontWeight: 'bold', color: '#007bff' }}>
                                        {fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details[0]?.test_name}
                                    </label> :
                                    <span style={{ background: 'green', display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }}></span>
                                    <label style={{ marginLeft: '5px' }}>Correct</label>
                                </div>
                                <div className='for-suggestion-b' style={{ marginBottom: '10px' }}>
                                    <span style={{ background: 'orange', display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }}></span>
                                    <label style={{ marginLeft: '5px' }}>Incorrect</label>
                                </div>
                                <div className='for-suggestion-b' style={{ marginBottom: '10px' }}>
                                    <span style={{ background: 'red', display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }}></span>
                                    <label style={{ marginLeft: '5px' }}>Not Attempt</label>
                                </div>
                            </div>

                            <div className='for-suggestion mt-0' style={{ border: '2px solid rgb(226 226 226)', borderRadius: '8px', padding: '15px', backgroundColor: '#f8f9fa', marginTop: '15px' }}>
                                <div className='for-suggestion-b' style={{ marginBottom: '10px' }}>
                                    <label style={{ fontWeight: 'bold', color: '#007bff' }}>
                                        {fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details[1]?.test_name}
                                    </label> :
                                    <span style={{ background: '#76ed76', display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }}></span>
                                    <label style={{ marginLeft: '5px' }}>Correct</label>
                                </div>
                                <div className='for-suggestion-b' style={{ marginBottom: '10px' }}>
                                    <span style={{ background: '#ffcc6f', display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }}></span>
                                    <label style={{ marginLeft: '5px' }}>Incorrect</label>
                                </div>
                                <div className='for-suggestion-b' style={{ marginBottom: '10px' }}>
                                    <span style={{ background: '#ff7a7a', display: 'inline-block', width: '20px', height: '20px', borderRadius: '50%', marginLeft: '5px' }}></span>
                                    <label style={{ marginLeft: '5px' }}>Not Attempt</label>
                                </div>
                            </div>


                            <div className='chart_view' style={{ marginTop: '40px' }} id="PrintDiv">

                                <div className='chart_view_horizental'>
                                    {fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details[0]?.topic_details.map((topic, topicIndex) => (
                                        <React.Fragment key={topicIndex}>
                                            <div className='topic_list' style={{ textAlign: 'center', marginBottom: '5px', top: '5px' }}>
                                                <label style={{ fontWeight: '600' }}>{topic?.topic_name}</label>
                                            </div>
                                            {fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details.map((test, testIndex) => {
                                                const list = test.topic_details[topicIndex];
                                                const correctWidth = (list?.correct_topic?.total / list.total_topic) * 100;
                                                const IncorrectWidth = (list?.incorrect_topic?.total / list.total_topic) * 100;
                                                const notAttemptWidth = (list?.not_attempt_topic?.total / list.total_topic) * 100;
                                                return (
                                                    <div key={test?.test_id} style={{ marginBottom: '5px' }}>
                                                        <div className='chart_bg' style={{ width: '100%' }}>
                                                            <div className='chart_bg_correct' style={{ width: `${correctWidth}%`, backgroundColor: test?.cp_test != 0 ? '#038703' : '#76ed76', height: '25px' }}>
                                                                <div className='hover-info' style={{ width: '15rem' }}>
                                                                    <h5 style={{ color: '#038703', fontWeight: '500' }}>{test?.test_name}</h5>
                                                                    <h5>{list?.topic_name}</h5>
                                                                    <h6>Correct Questions: {list.correct_topic?.question_no}</h6>
                                                                </div>
                                                            </div>
                                                            {list.incorrect_topic?.total > 0 && (
                                                                <div className='chart_bg_correct' style={{ width: `${IncorrectWidth}%`, backgroundColor: test?.cp_test != 0 ? '#f7a205' : '#ffcc6f', height: '25px' }}>
                                                                    <div className='hover-info' style={{ width: '15rem' }}>
                                                                        <h5 style={{ color: '#f7a205', fontWeight: '500' }}>{test?.test_name}</h5>
                                                                        <h5>{list?.topic_name}</h5>
                                                                        <h6>Incorrect Questions: {list.incorrect_topic?.question_no}</h6>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {list.not_attempt_topic?.total > 0 && (
                                                                <div className='chart_bg_correct' style={{ width: `${notAttemptWidth}%`, background: 'red', height: '25px' }}>
                                                                    <div className='hover-info' style={{ width: '15rem' }}>
                                                                        <h5 style={{ color: 'red', fontWeight: '500' }}>{test?.test_name}</h5>
                                                                        <h5>{list?.topic_name}</h5>
                                                                        <h6>Not Attempted Questions: {list.not_attempt_topic?.question_no}</h6>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                            {topicIndex < fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details[0]?.topic_details.length - 1 && (
                                                <hr style={{ margin: '10px 0', border: '1px solid lightgray' }} />
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>


                                <div className='chart_view_verticle'>
                                    <ul>
                                        {totalquestion && totalquestion.map((index) => {
                                            return (
                                                <li style={{ width: `${100 / totalquestion.length}%` }}>{index + 1}</li>
                                            )
                                        })}
                                    </ul>
                                </div>

                                <div className='table-responsive mt-4'>
  <table className='chart-table-border' style={{ fontSize: '12px' }}>
    <thead>
      <tr>
        <th rowSpan="2" style={{ textAlign: 'center' }}>Topics Name</th>
        <th colSpan="2" className='text-center text-success'>Correct</th>
        <th colSpan="2" className='text-center text-warning'>Incorrect</th>
        <th colSpan="2" className='text-center text-danger'>Not Attempt</th>
      </tr>
      <tr>
        <th className='text-center'>Total</th>
        <th className='text-center'>Q.No</th>
        <th className='text-center'>Total</th>
        <th className='text-center'>Q.No</th>
        <th className='text-center'>Total</th>
        <th className='text-center'>Q.No</th>
      </tr>
    </thead>
    <tbody>
      {fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details[0]?.topic_details.map((topic, topicIndex) => (
        <React.Fragment key={topicIndex}>
          {fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details.map((test, testIndex) => {
            const list = test.topic_details[topicIndex];
            return (
              <tr key={`${topicIndex}-${testIndex}`}>
                {testIndex === 0 && (
                  <td rowSpan={fetchacompanyReducer?.GetCompareChartResponse?.results?.test_details.length} style={{ padding: '5px', textAlign: 'center' }}>
                    {topic?.topic_name}
                  </td>
                )}
                <td style={{ backgroundColor: test?.cp_test != 0 ? '#038703' : '#76ed76', padding: '5px', fontSize: '12px' }}>{list?.correct_topic?.total}</td>
                <td style={{ backgroundColor: test?.cp_test != 0 ? '#038703' : '#76ed76', padding: '5px', fontSize: '12px' }}>{list.correct_topic?.question_no}</td>
                <td style={{ backgroundColor: test?.cp_test != 0 ? '#f7a205' : '#ffcc6f', padding: '5px', fontSize: '12px' }}>{list?.incorrect_topic?.total}</td>
                <td style={{ backgroundColor: test?.cp_test != 0 ? '#f7a205' : '#ffcc6f', padding: '5px', fontSize: '12px' }}>{list.incorrect_topic?.question_no}</td>
                <td style={{ backgroundColor: test?.cp_test != 0 ? '#ff0000' : '#ff7a7a', padding: '5px', fontSize: '12px' }}>{list?.not_attempt_topic?.total}</td>
                <td style={{ backgroundColor: test?.cp_test != 0 ? '#ff0000' : '#ff7a7a', padding: '5px', fontSize: '12px' }}>{list.not_attempt_topic?.question_no}</td>
              </tr>
            );
          })}
        </React.Fragment>
      ))}
    </tbody>
  </table>
</div>


                            </div>

                        </div>
                        :
                        <div className='text-center'>
                            <h6>No data found</h6>
                            <h6 style={{ color: 'red' }}>( All Tests should be same )</h6>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>

        </div>

    )
}

export default Student_Assessment