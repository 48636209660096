import { Outlet, Navigate } from 'react-router-dom'

const PublicRoute = () => {
    const token = sessionStorage.getItem('mtb_access_token');
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    return(
        token  ? <Navigate  to={userinfo?.user_type == "ADMIN" ? "/admin/dashboard" 
        : userinfo?.user_type == "PROSPECT" ?  `/${userinfo?.company_slug}/application/dashboard` 
        : userinfo?.user_type == "GUARDIAN" ?  `/${userinfo?.company_slug}/application/dashboard`
        : userinfo?.user_type == "STUDENT" ?  `/${userinfo?.company_slug}/application/dashboard`
        : userinfo?.user_type == "EMPLOYEE" ?  `/${userinfo?.company_slug}/employee/dashboard`
        :  `/${userinfo?.company_slug}/dashboard`}  /> : <Outlet/>
    )
}

export default PublicRoute