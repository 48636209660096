import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import FetchCompanyDataReducer, { AddstudentRequest, GetGradeRequest, AddSchoolRequest, GetSchoolRequest, GetLocationRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Modal from 'react-bootstrap/Modal';
import ApiConnection from '../../utils/ApiConnection'


const AddStudents = () => {

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
  var schoolList = fetchacompanyReducer?.GetSchoolResponse?.results
  var locationList = fetchacompanyReducer?.GetLocationResponse?.results

  console.log(fetchacompanyReducer.GetSchoolResponse)
  console.log(schoolList)

  const {id, pros} = useParams()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const [salution, setsalution] = useState("")
  const [fname, setfname] = useState("")
  const [lname, setlname] = useState("")
  const [gender, setgender] = useState("")
  const [grade, setgrade] = useState("")
  const [email, setemail] = useState("")
  const [deliverytype, setdeliverytype] = useState("")
  const [school, setschool] = useState("")
  const [teacher, setteacher] = useState("")
  const [schoolyear, setschoolyear] = useState("")
  const [dob, setdob] = useState(null)
  const [description, setdescription] = useState("")
  const [schoolsubjectmatter, setschoolsubjectmatter] = useState("")
  const [medicalinformation, setmedicalinformation] = useState("")
  const [studentsnotes, setstudentsnotes] = useState("")
  const [notesfromcenter, setnotesfromcenter] = useState("")
  const [virtuallocation, setvirtuallocation] = useState("")
  const [consenttomediarelase, setconsenttomediarelase] = useState("")
  const [consenttocontactteacher, setconsenttocontactteacher] = useState("")
  const [consenttoleaveunescorted, setconsenttoleaveunescorted] = useState("")
  const [scholarship, setscholarship] = useState("")
  const [schoolwebsite, setschoolwebsite] = useState("")
  const [show1, setShow1] = useState(false);
  // const handleClose = () => setShow1(false);
  const [schoolname, setschoolname] = useState("")
  const [schoolphone, setschoolphone] = useState("")
  const [schoolemail, setschoolemail] = useState("")
  const [schoolcontactperson, setschoolcontactperson] = useState("")
  const [schooladdress, setschooladdress] = useState("")
  // const [customerList, setcustomerList] = useState([])



  useEffect(() => {
    dispatch(GetGradeRequest())
    dispatch(GetSchoolRequest())
    dispatch(GetLocationRequest())
  }, [])

  // useEffect(() => {
  //   CustomerList();
  // }, [])
  
//   const CustomerList = async (e) => {

//     try {
//         const response = await ApiConnection.get(`customers-list/?server_type=production`)
//         console.log(response);
//         if (response?.status == 200) {
//             setcustomerList(response?.data?.results)
//         }
//     } catch (e) { }
// }

  const SubmitSchool = () => {
    if (schoolname == '') {
      Swal.fire({
        icon: "error",
        text: 'School Name should be must',
      });
    }

    else {
      let obj = {
        "name": schoolname,
        "email": schoolemail,
        "address": schooladdress,
        "phone_no": schoolphone,
        "contact_person_name": schoolcontactperson,
        "website": schoolwebsite
      }

      dispatch(AddSchoolRequest({ obj: obj, navigate: navigate }))
    }
  };

  useEffect(() => {
    dispatch(GetSchoolRequest())
    setShow1(false)
}, [fetchacompanyReducer?.AddSchoolResponse])

  const handleShow1 = (event) => {
    setschool(event.target.value)
   const selectValue = event.target.value
   if (selectValue === 'addschool') {
    setShow1(true);
   }
  };

  const handleClose = () => {
    setShow1(false);
    // dispatch(GetSchoolRequest())
  }

  const SubmitHandle = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

    if (fname == '') {
      Swal.fire({
        icon: "error",
        text: 'First Name should be must',
      });
    }
    else if (lname == '') {
      Swal.fire({
        icon: "error",
        text: 'Last Name should be must',
      });
    } else if (gender == '') {
      Swal.fire({
        icon: "error",
        text: 'Gender should be must',
      });
    } else if (grade == '') {
      Swal.fire({
        icon: "error",
        text: 'Grade should be must',
      });
    // } else if (customer == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Customer should be must',
    //   });
    // } else if (school == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'School should be must',
    //   });
    // } else if (teacher == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Teacher should be must',
    //   });
    // } else if (schoolyear == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'School Year should be must',
    //   });
    } else if (virtuallocation == '') {
      Swal.fire({
        icon: "error",
        text: 'Location should be must',
      });
    // } else if (dob == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Date of Birth should be must',
    //   });
    // } else if (description == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Description should be must',
    //   });
    // } else if (schoolsubjectmatter == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Subject Matter should be must',
    //   });
    // } else if (medicalinformation == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Medical Information should be must',
    //   });
    // } else if (studentsnotes == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Students Notes should be must',
    //   });
    // } else if (notesfromcenter == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'Notes from Center should be must',
    //   });
    }

    else {
      let obj = {
        "prospect_id": id,
        "enrollment_status": true,
        "salutation": salution,
        "first_name": fname,
        "last_name": lname,
        "gender": gender,
        "grade_id": grade,
        "email": email,
        "delivery_type": deliverytype,
        // "customer_id": customer,
        "school_id": school,
        // "school_teacher_id": teacher,
        "school_year": schoolyear,
        "date_of_birth": dob ? moment(dob).format("YYYY-MM-DD") : "",
        "student_description": description,
        "school_subject_matter": schoolsubjectmatter,
        "medical_information": medicalinformation,
        "students_notes": studentsnotes,
        "notes_from_center_director": notesfromcenter,
        "virtual_loocation": virtuallocation,
        "consent_media_release": consenttomediarelase == true ? 1 : 0,
        "consent_teacher_contact": consenttocontactteacher == true ? 1 : 0,
        "consent_leave_unescorted": consenttoleaveunescorted == true ? 1 : 0,
        "scholarship": scholarship == true ? 1 : 0,
      }

      dispatch(AddstudentRequest({ obj: obj, user_id: pros, navigate: navigate }))
    }
  }


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3>Add Students</h3>
        </div>
        <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
          <ul>
            <li>
              <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
            </li>
          </ul>
        </div>
        <div className='row form-style mb-4'>
          <div className='col-lg-12 mb-2'>
            <h6>Add Students Details</h6>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Salutation </label>
              <select className='form-control' value={salution}
                onChange={(e) => setsalution(e.target.value)}>
                <option value="">--Salutation---</option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Enter First Name <span class="asterisk">*</span></label>
              <input type='text' className='form-control' placeholder='First Name '
                value={fname}
                onChange={(e) => setfname(e.target.value)}
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Enter Last Name <span class="asterisk">*</span></label>
              <input type='text' className='form-control' placeholder='Last Name '
                value={lname}
                onChange={(e) => setlname(e.target.value)}
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Gender <span class="asterisk">*</span></label>
              <select className='form-control' value={gender}
                onChange={(e) => setgender(e.target.value)}>
                <option value="">-- Select Gender --</option>
                <option>Male</option>
                <option>Female</option>
                <option>Others</option>
              </select>
            </div>
          </div>

          {/* <div className='form-group'>
              <select className='form-control'  value={titlecp}
               onChange={(e)=>settitlecp(e.target.value)}>
                <option value="">--Select Title---</option>
                {TitleList&&TitleList.map((title, i)=>{
                    return (
                        <option key={i} value={title?.id}>{title?.name}</option>
                    )
                })}
              </select>
           </div> */}

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Grade <span class="asterisk">*</span></label>
              <select className='form-control' value={grade}
                onChange={(e) => setgrade(e.target.value)}>
                <option value="">-- Select Grade --</option>
                {gradeList && gradeList.map((grade, i) => {
                  return (
                    <option key={i} value={grade?.id}>{grade?.grade_name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Enter Email</label>

              {/* <label className='selectlabel'>Enter Email <span class="asterisk">*</span></label> */}
              <input type='email' className='form-control' placeholder='Email (Optional)'
                value={email}
                onChange={(e) => { setemail(e.target.value) }}
              />
            </div>
          </div>

          {/* <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Customer <span class="asterisk">*</span></label>
            <select className='form-control' value={customer}
                onChange={(e) => setcustomer(e.target.value)}>
                <option value="">-- Select Customer --</option>
                {customerList && customerList.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.first_name} {item?.last_name}</option>
                  )
                })}
              </select>
            </div>
          </div> */}

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select School Name</label>
              <select className='form-control' value={school}
                onChange={handleShow1}>
                <option value="">-- Select School --</option>
                <option value="addschool" style={{color : 'blue'}}>Add New School</option>
                {schoolList && schoolList.map((school, i) => {
                  return (
                    <option key={i} value={school?.id}>{school?.name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          {/* <div className='col-lg-4'>
            <div className='form-group'>
            <button onClick={() => handleShow1()} className='table-btn' style={{ margin: '35px 0px 0px -10px' }}><i className="fa-solid fa-add"></i></button>
            </div>
          </div> */}

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Enter School Year</label>
              <input type='number' className='form-control' value={schoolyear} min={1000} max={9999}
                onChange={(e) => setschoolyear(e.target.value)} placeholder='School Year'/>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Delivery Type <span class="asterisk">*</span></label>
              <select className='form-control' value={deliverytype}
                onChange={(e) => setdeliverytype(e.target.value)}>
                <option value="">-- Select Delivery Type --</option>
                <option value="inperson">In Person</option>
                <option value="virtual">Virtual</option>
                <option value="hybrid">Hybrid</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Location <span class="asterisk">*</span></label>
              <select className='form-control' value={virtuallocation}
                onChange={(e) => setvirtuallocation(e.target.value)}>
                <option value="">-- Select Location --</option>
                {locationList && locationList.map((item, i) => {
                                    return (
                                        <option key={i} value={item?.id}>{item?.location_name}</option>
                                    )
                                })}
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Enter DOB</label>
              <DatePicker selected={dob} onChange={(dob) => setdob(dob)} className='form-control'
                placeholderText="Date of Birth(mm/dd/yy)"
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <div className="form-group">
            <label className='selectlabel'>Enter Description</label>
              <textarea
                className="form-control"
                placeholder="Description"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className="form-group">
            <label className='selectlabel'>Enter School Subject Matter</label>
              <textarea
                className="form-control"
                placeholder="School Subject Matter"
                value={schoolsubjectmatter}
                onChange={(e) => setschoolsubjectmatter(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className="form-group">
            <label className='selectlabel'>Enter Medical Information</label>
              <textarea
                className="form-control"
                placeholder="Medical Information"
                value={medicalinformation}
                onChange={(e) => setmedicalinformation(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className="form-group">
            <label className='selectlabel'>Enter Student Notes</label>
              <textarea
                className="form-control"
                placeholder="Student Notes"
                value={studentsnotes}
                onChange={(e) => setstudentsnotes(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className="form-group">
            <label className='selectlabel'>Enter Notes for Center Director</label>
              <textarea
                className="form-control"
                placeholder="Notes for Center Director"
                value={notesfromcenter}
                onChange={(e) => setnotesfromcenter(e.target.value)}
              ></textarea>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <input type="checkbox" defaultChecked={consenttomediarelase}
                onChange={() => setconsenttomediarelase(!consenttomediarelase)} /> Consent to Media Release
            </div>
            <div className='form-group'>
              <input type="checkbox" defaultChecked={consenttocontactteacher}
                onChange={() => setconsenttocontactteacher(!consenttocontactteacher)} /> Consent to Contact Teacher
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <input type="checkbox" defaultChecked={consenttoleaveunescorted}
                onChange={() => setconsenttoleaveunescorted(!consenttoleaveunescorted)} /> Consent to Leave Unescorted
            </div>
            <div className='form-group'>
              <input type="checkbox" defaultChecked={scholarship}
                onChange={() => setscholarship(!scholarship)} /> Scholarship
            </div>
          </div>


        </div>
        <button className='formBtn' onClick={SubmitHandle}>Save</button>

      </div>

      <Modal show={show1} size="lg">
        <Modal.Header >
          <Modal.Title>Add School</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

          <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>School Name <span class="asterisk">*</span></label>
                <input type='text' className='form-control' placeholder='School Name'
                  value={schoolname}
                  onChange={(e) => { setschoolname(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Phone No.</label>
                <input type='text' className='form-control' placeholder='Phone No.'
                  value={schoolphone}
                  onChange={(e) => { setschoolphone(e.target.value) }}
                  maxLength="10"
                  minLength="10"
                  onKeyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                      }
                  }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Email</label>
                <input type='text' className='form-control' placeholder='Email'
                  value={schoolemail}
                  onChange={(e) => { setschoolemail(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Contact Person Name</label>
                <input type='text' className='form-control' placeholder='Contact Person Name'
                  value={schoolcontactperson}
                  onChange={(e) => { setschoolcontactperson(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Address</label>
                <input type='text' className='form-control' placeholder='Address'
                  value={schooladdress}
                  onChange={(e) => { setschooladdress(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Website</label>
                <input type='text' className='form-control' placeholder='Website'
                  value={schoolwebsite}
                  onChange={(e) => { setschoolwebsite(e.target.value) }}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={SubmitSchool}>Add</button>

        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default AddStudents