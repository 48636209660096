import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  token: null,
  status: '',
  isloading: false,
  data: {},
  error: {},
  SignInResponse: {},
  GetCompanyResponse: {},
  AddCompanyResponse: {},
  EditCompanyResponse: {},
  UpdateCompanyResponse: {},
  DeleteCompanyResponse: {},
  SendEmaillinkResponse: {}
}

const FetchAdminDataReducer = createSlice({
  name: 'FetchAdminDataReducer',
  initialState,
  reducers: {

    //login
    LoginRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    SignInSuccess(state, action) {
      state.SignInResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    SignInFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get company list
    GetCompanyRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetCompanySuccess(state, action) {
      state.GetCompanyResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetCompanyFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add company 
    AddCompanyRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddCompanySuccess(state, action) {
      state.AddCompanyResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddCompanyFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Edit company 
    EditCompanyRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    EditCompanySuccess(state, action) {
      state.EditCompanyResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    EditCompanyFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //update company 
    UpdateCompanyRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateCompanySuccess(state, action) {
      state.UpdateCompanyResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateCompanyFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //update company 
    DeleteCompanyRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteCompanySuccess(state, action) {
      state.DeleteCompanyResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteCompanyFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Send email link
    SendEmaillinkRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    SendEmaillinkSuccess(state, action) {
      state.SendEmaillinkResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    SendEmaillinkFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Create Password
    CreatePasswordRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    CreatepasswordSuccess(state, action) {
      state.CreatepasswordResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    CreatepasswordFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },






  },
});


export const {

  LoginRequest,
  SignInSuccess,
  SignInFailure,

  GetCompanyRequest,
  GetCompanySuccess,
  GetCompanyFailure,

  AddCompanyRequest,
  AddCompanySuccess,
  AddCompanyFailure,

  EditCompanyRequest,
  EditCompanySuccess,
  EditCompanyFailure,

  UpdateCompanyRequest,
  UpdateCompanySuccess,
  UpdateCompanyFailure,

  DeleteCompanyRequest,
  DeleteCompanySuccess,
  DeleteCompanyFailure,

  SendEmaillinkRequest,
  SendEmaillinkSuccess,
  SendEmaillinkFailure,

  CreatePasswordRequest,
  CreatepasswordSuccess,
  CreatepasswordFailure


} = FetchAdminDataReducer.actions;

export default FetchAdminDataReducer.reducer;