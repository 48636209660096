import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import { GetProspectiveRequest, GetAssessmentResultRequest, GetResultListRequest, UpdateAssessmentResultRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const StudentReportCard = () => {

    const [name, setname] = useState("")
    const [exam_date, setexam_date] = useState('')
    const [reslut, setresult] = useState('')
    const [Newarry, setNewarry] = useState([])
    const [description, setdescription] = useState('')
    const [isoverright, setisoverright] = useState(false)
    const handleClose = () => setShow(false);
    const [show, setShow] = useState(false);


    console.log(Newarry)


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    // var propectList = fetchacompanyReducer?.GetAssessmentResultResponse?.results[0]
    var NewOrder = fetchacompanyReducer?.UpdateAssessmentResultResponse?.results
    var ResultList = fetchacompanyReducer?.GetResultListResponse?.results?.[0]?.exam_details_list
    var StudentList = fetchacompanyReducer?.GetResultListResponse?.results?.[0]


    const dispatch = useDispatch()
    const { id, pros } = useParams()
    let navigate = useNavigate()

    // useEffect(() => {
    //     let obj = {
    //         id: id,
    //         student_id: pros,
    //     }
    //     dispatch(GetAssessmentResultRequest(obj))
    // }, [])

    useEffect(() => {
        let obj = {
            id: id,
            student_id: pros,
        }
        dispatch(GetResultListRequest(obj))
    }, [])

    useEffect(() => {

        setresult(NewOrder)

        let obj = {
            id: id,
            student_id: pros
        }
        dispatch(GetResultListRequest(obj))

    }, [fetchacompanyReducer?.UpdateAssessmentResultResponse])

    useEffect(() => {

        var ResultList = fetchacompanyReducer?.GetResultListResponse?.results?.[0]?.exam_details_list
        console.log(ResultList)

        let TempArray = []

        ResultList && ResultList.forEach(element => {
            TempArray.push({
                qid: element.question_id,
                qno: element.question_number,
                status: element.is_right_answer,
                overright: element.is_overwright == 1 ? true : false,
                excluded: element.is_excluded == 1 ? true : false,
                partial: element.is_partial == 1 ? true : false,
                topic_name: element.topic_name,
                overwright_desc: element.overwright_desc,
                exam_date: element.exam_date,
                is_check_progress: element.is_check_progress,

            })
        });

        setNewarry(TempArray)

    }, [fetchacompanyReducer?.GetResultListResponse])




    const handleradiochange = (value, key, index) => {

        var TempArr = [...Newarry];

        if (value == '0') {
            console.log(value)
            TempArr[index][key] = value
            // TempArr[index]['overright'] = false 
            setNewarry(TempArr);
        } else {
            TempArr[index][key] = value
            setNewarry(TempArr);
        }

    }

    const handlecheckboxchange = (event, key, index) => {

        if (event.target.checked) {
            var TempArr = [...Newarry];
            TempArr[index][key] = true
            setNewarry(TempArr);
            //   if(key == 'overright'){
            //     TempArr[index][key] = true
            //     // TempArr[index]['status'] = '1'
            //     TempArr[index]['is_comment'] = true
            //     setNewarry(TempArr); 

            //   } else {
            //     TempArr[index][key] = true
            //     setNewarry(TempArr); 
            //   }

        } else {
            var TempArr = [...Newarry];
            TempArr[index][key] = false
            TempArr[index]['overwright_desc'] = ''
            // TempArr[index]['status'] = '0'
            setNewarry(TempArr);
        }
    }

    const overwrightRemarks = () => {
        if (description == '') {
            Swal.fire({
                icon: "error",
                text: 'Description should be must',

            });
        } else {

            let obj = {
                "student_id": Number(id),
                "overwrite_reason": description,
            }

            dispatch(UpdateAssessmentResultRequest({ obj: obj, navigate: navigate }))
        }
    }

    const AllcorrectHandle = () => {
        const newData = Newarry.map((data) => ({
            ...data,
            status: 1,
        }));

        setNewarry(newData);
    }

    const objectStyle = {
        backgroundColor: "rgb(215 255 201)",
      };

      const objectStyleValid = {
        backgroundColor: "white",
      };

      const objectStyle1 = {
        backgroundColor: "red",
      };

      const objectStyleValid1 = {
        backgroundColor: "white",
      };

      const isValid = true;

    const AllincorrectHandle = () => {
        const newData = Newarry.map((data) => ({
            ...data,
            status: 0,
        }));

        setNewarry(newData);
    }

    const InputHandlechange = (value, key, index) => {
        var TempArr = [...Newarry];

        if (value == '') {
            TempArr[index][key] = value;
            TempArr[index]['overright'] = false;
            setNewarry(TempArr);
        } else {
            TempArr[index][key] = value;
            TempArr[index]['overright'] = true;
            setNewarry(TempArr);
        }


    }



    const SubmitResult = () => {


        if (exam_date == '') {
            Swal.fire({
                icon: "error",
                text: 'Exam Date should be must',
            });
        } else {

            let TempArr = []

            Newarry.forEach(element => {
                TempArr.push(
                    {
                        "is_right_answer": element.status,
                        "is_excluded": element.excluded == true ? '1' : '0',
                        "is_overwright": element.overright == true ? '1' : '0',
                        "question_id": element.qid,
                        "overwright_desc": element.overwright_desc,
                        "is_partial": element.partial == true ? '1' : '0',
                    }
                )
            })


            let obj = {
                "exam_date": moment(exam_date).format("YYYY-MM-DD"),
                item_list: TempArr
            }

            dispatch(UpdateAssessmentResultRequest({ obj: obj, test_id: id, student_id: pros, navigate: navigate }))
        }
    }

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Assessment Answers </h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='form-group'>
                    <hr />
                </div>

                <div className='mb-4'>
                    <h6> Student Information </h6>
                </div>

                <div className='row'>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Student Name:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <span>{StudentList?.student_first_name} {StudentList?.student_last_name}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Test Taken date:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <span>{moment(StudentList?.exam_date).format("YYYY-MM-DD")}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Grade:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <span>{StudentList?.grade_name} </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Score:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <span>{StudentList?.total_percantege_obtained} %</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <hr></hr>
                <div className='mb-4'>
                    <h6> Answer Details </h6>
                </div>

                <div className='row form-style mb-4 align-items-center'>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Date Taken</label>
                            <DatePicker selected={exam_date} onChange={(date) => setexam_date(date)} className='form-control'
                                placeholderText="Exam date"
                            />
                            {/* <input type='date' className='form-control' placeholder='Search..'
                                value={exam_date} onChange={(date)=> setexam_date(date)}
                            /> */}
                        </div>
                    </div>
                    <div className='button-part' style={{ marginTop: '12px' }}>
                        <ul>
                            {/* <li>
                                <NavLink className='formBtn1'> Partial Credit ON</NavLink>
                            </li> */}
                            <li>
                                <button className='formBtn1' onClick={AllcorrectHandle}><i className="fa-solid fa-file-excel"></i> All Correct </button>
                            </li>
                            <li>
                                <button className='formBtn1' onClick={AllincorrectHandle}><i className="fa-solid fa-file-excel"></i> All Incorrect </button>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th style={{ width: '30px' }}>Q.No.</th>
                                <th>Topic</th>
                                <th>Status</th>
                                <th>Partial</th>
                                <th style={{ width: '200px' }}>Overwrite note</th>
                                <th>Excluded</th>
                            </tr>
                        </thead>
                        {Newarry && Newarry.length > 0 ?
                            <tbody>
                                {Newarry && Newarry.map((item, index) => {
                                    return (
                                        <tr style={item?.is_check_progress === 1 ? objectStyle : objectStyleValid || item?.is_overright_check_progress === 1 ? objectStyleValid1  : objectStyle1}>
                                            <td style={{ width: '30px' }}>{item?.qno}</td>
                                            <td>{item?.topic_name}</td>

                                            <td>
                                                <span>Fail </span><input type='radio' checked={item?.status == 0} onChange={() => handleradiochange(0, 'status', index)} id="fail" name={`status${index}`} value="0" /> &nbsp;&nbsp;&nbsp;&nbsp;
                                                <span>Pass </span><input type='radio' checked={item?.status == 1} onChange={() => handleradiochange(1, 'status', index)} id="pass" name={`status${index}`} value="1" />
                                            </td>
                                            <td>
                                                <span>Partial </span><input type='checkbox' onChange={(e) => handlecheckboxchange(e, 'partial', index)} checked={item.partial == true} />
                                            </td>
                                            <td style={{ width: '200px' }}>
                                                <input type='text' className='form-control' placeholder='Enter reason' value={item.overwright_desc} onChange={(e) => InputHandlechange(e.target.value, 'overwright_desc', index)} style={{ width: '200px', fontSize: '13px' }} />


                                            </td>
                                            <td>
                                                <span>Excluded </span><input type='checkbox' onChange={(e) => handlecheckboxchange(e, 'excluded', index)} checked={item.excluded == true} />
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                            :
                            <h6 className='text-center'>No data found</h6>
                        }
                    </table>
                    <div className='button-part mt-4'>
                        <ul style={{ float: 'right' }}>
                            <li>
                                <NavLink className='formBtn1' onClick={SubmitResult} >Save </NavLink>
                            </li>
                            <li>
                                <NavLink className='formBtn1' >Cancel </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Overwrite Reason</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='selectlabel'>Overwrite Reason</label>
                                <input type='text' className='form-control' placeholder='Overwrite Reason'
                                    value={description}
                                    onChange={(e) => setdescription(e.target.value)}
                                />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={overwrightRemarks}>Add</button>

                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default StudentReportCard