import React from 'react'
import { NavLink } from 'react-router-dom'

const ConfirmationAdded = () => {
  return (
    <div className='common-layout'>
  
            <div className='innerlayout p-3'>
            <div className='mb-4'>
            <h3>Add Confirmation</h3>
            </div>
            <div className='card-box'>
            <ul>
                <li>Company added successfully</li>
                <li>Verify your mail id</li>
            </ul>
                <NavLink to="/admin/companyList" className='formBtn d-inblock'>Go to company list</NavLink>
            </div>
           
            </div>
</div>
  )
}

export default ConfirmationAdded