import axios  from "axios";
import React, { useState, useEffect } from "react";
 
let API_Token = sessionStorage.getItem('mtb_access_token');;
//let API_Token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL2ZjYzExLml0aWZmeWNvbnN1bHRhbnRzLmNvbS9hcGkvbG9naW4iLCJpYXQiOjE3MDQxMTIyMzYsImV4cCI6MTcwNDExNTgzNiwibmJmIjoxNzA0MTEyMjM2LCJqdGkiOiJwcmRLcUNoSTdDQUU3T1g3Iiwic3ViIjoiMSIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJlbWFpbCI6ImZjY2FkbWluQGdtYWlsLmNvbSJ9.Hptjs2YKBwZSFLzY0lbFpIA4kSfeG6_ITjzYt_9UQEs"
 
export const BaseUrl = {
    baseurl: 'https://api.managethebusiness.com'
}

// Staging URL: 'http://43.205.105.142:8000',
// Live URL: 'http://44.226.27.225:8000',

 
const URL = "https://api.managethebusiness.com"
 
export default axios.create({
    baseURL:URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        //'Content-Type': 'multipart/form-data',
        'Authorization': 'Bearer '+API_Token,
    },
   
})