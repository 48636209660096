import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { DeleteattendanceRequest, UpdateattendanceRequest, AddattendanceRequest, GetattendanceRequest, FetchattendanceRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const AttendenceList = ({ id, etype }) => {

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var attendanceList = fetchacompanyReducer?.GetattendanceResponse?.results

  console.log(attendanceList)
  console.log("My Attendance employee id", id)


  const dispatch = useDispatch()
  let navigate = useNavigate()

  useEffect(() => {
    let obj = {
      id: userinfo?.user_id
    }
    dispatch(GetattendanceRequest(obj))
  }, [])


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3> My Attendance</h3>
        </div>

        {attendanceList && attendanceList.length > 0 ?

          <div className='table-responsive'>
            <table className='table attendence-table'>
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Clock In</th>
                  <th>Clock Out</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {attendanceList && attendanceList.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td>{moment(new Date(item?.date)).format("DD-MM-YYYY")}</td>
                      <td className='p-0'>{item?.time && item?.time.map((list, index) => {
                        return (
                          <span className='timeseparate'>{list?.login_time}</span>
                        )
                      })}</td>
                      <td className='p-0'>{item?.time && item?.time.map((list, index) => {
                        return (
                          <span className='timeseparate'>{list?.logout_time}</span>
                        )
                      })}</td>
                      {/* <td>
                        <NavLink className='mr-2' onClick={() => handleShow1(item?.id)}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                      </td> */}
                    </tr>
                  )
                })}

              </tbody>
            </table>

          </div>
          :
          <h6>No data found</h6>
        }

      </div>
    </div>
  )
}

export default AttendenceList