import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  GetLocationRequest,
  GetCountryRequest,
  GetStateRequest,
  GetcityRequest,
  GetInterestRequest,
  UpdatepropectsRequest,
  ViewprospectsRequest,
} from "../../redux/reducer/FetchCompanyDataReducer";
import Loader from "../../utils/Loader";
import Swal from "sweetalert2";
import Multiselect from "multiselect-react-dropdown";
import axios from 'axios'

const EditProspects = () => {
  const [location, setlocation] = useState("");
  const [salution, setsalution] = useState("");
  const [refaccount, setrefaccount] = useState("");
  const [refteacher, setrefteacher] = useState("");
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [gender, setgender] = useState("");
  const [phone, setphone] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [othermobile, setothermobile] = useState("");
  const [prospectstatus, setprospectstatus] = useState("");
  const [rating, setrating] = useState("");
  const [emailout, setemailout] = useState(false);
  const [freetrial, setfreetrial] = useState(false);
  const [addressone, setaddressone] = useState("");
  const [addresstwo, setaddresstwo] = useState("");
  const [description, setdescription] = useState("");
  const [customerdescription, setcustomerdescription] = useState("");
  const [country, setcountry] = useState("");
  const [countryCode, setcountryCode] = useState("+1")
  const [countryCodes, setcountryCodes] = useState([])
  const [countryId, setcountryId] = useState("")

  const [stateList, setstateList] = useState([]);
  const [state, setstate] = useState("");
  const [city, setcity] = useState("");
  const [cityList, setcityList] = useState([]);
  const [zipcode, setzipcode] = useState("");
  const [selectedleads, setselectedleads] = useState([]);
  const [LeadList, setLeadList] = useState([]);
  const [InterestList, setInterestList] = useState([])
  const [defaultLeadList, setdefaultLeadList] = useState([]);
  const [selectedInterest, setselectedInterest] = useState([])
  const [LeadInterest, setLeadInterest] = useState([])
  const [LeadSource, setLeadSource] = useState([])

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { id } = useParams();
  const fetchacompanyReducer = useSelector(
    (state) => state.FetchCompanyDataReducer
  );

  var locationList = fetchacompanyReducer?.GetLocationResponse?.results;
  var countryList = fetchacompanyReducer?.GetcountryResponse;
  var editdata = fetchacompanyReducer.ViewprospectsResponse?.results[0];
  var defaultleadeditdata =
    fetchacompanyReducer.ViewprospectsResponse?.lead_source_result;

  console.log(defaultLeadList);

  useEffect(() => {
    dispatch(GetLocationRequest());
    dispatch(GetCountryRequest());
    dispatch(GetInterestRequest());
    let obj = {
      id: id,
    };

    dispatch(ViewprospectsRequest(obj));
  }, []);

  useEffect(() => {
    setstateList(fetchacompanyReducer?.GetstateResponse);
  }, [fetchacompanyReducer?.GetstateResponse]);

  useEffect(() => {
    setcityList(fetchacompanyReducer?.GetcityResponse);
  }, [fetchacompanyReducer?.GetcityResponse]);

  // useEffect(() => {
  //   let Arr = [];

  //   fetchacompanyReducer?.GetInterestResponse?.results &&
  //     fetchacompanyReducer?.GetInterestResponse?.results.forEach((leads) => {
  //       leads.sources &&
  //         leads.sources.forEach((source) => {
  //           Arr.push({
  //             cat: leads?.interest?.name,
  //             key: source?.name,
  //             id: source?.id,
  //             laed_interest: source?.laed_interest,
  //           });
  //         });
  //     });

  //   setLeadList(Arr);
  // }, [fetchacompanyReducer?.GetInterestResponse]);

  useEffect(() => {
    let Arr = [
    ];
    LeadSource?.forEach(lead => {
      Arr.push({
        cat: lead.name,
        key: lead.name,
        id: lead.id
      });
    });
    setLeadList(Arr);
  }, [LeadSource]);

  useEffect(() => {
    let Arr = [];
    LeadInterest.forEach(lead => {
      Arr.push({
        cat: lead.name,
        key: lead.name,
        id: lead.id
      });
    });
    setInterestList(Arr);
  }, [LeadInterest]);

  useEffect(() => {
    const mobilePhone = editdata?.mobile_phone || "";
    const last10Digits = mobilePhone.slice(-10);
    const countryCode = mobilePhone.slice(0, -10);

    setlocation(editdata?.location_id);
    setsalution(editdata?.salutation);
    setrefaccount(editdata?.referral_account);
    setrefteacher(editdata?.referral_teacher);
    setfname(editdata?.first_name);
    setlname(editdata?.last_name);
    setgender(editdata?.gender);
    setphone(editdata?.phone);
    setmobile(last10Digits);
    setcountryCode(countryCode);

    setemail(editdata?.email);
    setothermobile(editdata?.other_phone);
    setprospectstatus(editdata?.propect_status);
    setrating(editdata?.propect_rating);
    setemailout(editdata?.is_email_opt_out == 1 ? true : false);
    setfreetrial(editdata?.is_free_trial == 1 ? true : false);
    setaddressone(editdata?.street_address_1);
    setaddresstwo(editdata?.street_address_2);
    if (editdata) {
      const selectedCountryData = countryCodes.find(c => c.id === editdata.country_id);
      if (selectedCountryData) {
        setcountry(selectedCountryData.name);
        setcountryId(selectedCountryData.id);
        setcountryCode(selectedCountryData.code);
      }    setstate(editdata?.state_id);
    }
    setcity(editdata?.city_id);
    setzipcode(editdata?.zipcode);
    setdescription(editdata?.description);
    setcustomerdescription(editdata?.customer_description);
    let obj = {
      id: editdata?.country_id,
    };
    dispatch(GetStateRequest(obj));

    let obj1 = {
      id: editdata?.state_id,
    };
    dispatch(GetcityRequest(obj1));

    let Arr = [];

    defaultleadeditdata &&
      defaultleadeditdata.forEach((leads) => {
        Arr.push({
          cat: leads?.lead_interest_name,
          key: leads?.lead_source_name,
          id: leads?.id,
          lead_source_id: leads?.lead_source_id,
        });
      });

    setdefaultLeadList(Arr);
  }, [fetchacompanyReducer?.ViewprospectsResponse]);

  useEffect(() => {
    const fetchCountryCodes = async () => {
        try {
            const response = await axios.get('https://restcountries.com/v3.1/all');
            const countryIdMap = {
                "Afghanistan": 1,
                "Aland Islands": 2,
                "Albania": 3,
                "Algeria": 4,
                "American Samoa": 5,
                "Andorra": 6,
                "Angola": 7,
                "Anguilla": 8,
                "Antarctica": 9,
                "Antigua And Barbuda": 10,
                "Argentina": 11,
                "Armenia": 12,
                "Aruba": 13,
                "Australia": 14,
                "Austria": 15,
                "Azerbaijan": 16,
                "Bahrain": 17,
                "Bangladesh": 18,
                "Barbados": 19,
                "Belarus": 20,
                "Belgium": 21,
                "Belize": 22,
                "Benin": 23,
                "Bermuda": 24,
                "Bhutan": 25,
                "Bolivia": 26,
                "Bonaire, Sint Eustatius and Saba": 27,
                "Bosnia and Herzegovina": 28,
                "Botswana": 29,
                "Bouvet Island": 30,
                "Brazil": 31,
                "British Indian Ocean Territory": 32,
                "Brunei": 33,
                "Bulgaria": 34,
                "Burkina Faso": 35,
                "Burundi": 36,
                "Cambodia": 37,
                "Cameroon": 38,
                "Canada": 39,
                "Cape Verde": 40,
                "Cayman Islands": 41,
                "Central African Republic": 42,
                "Chad": 43,
                "Chile": 44,
                "China": 45,
                "Christmas Island": 46,
                "Cocos (Keeling) Islands": 47,
                "Colombia": 48,
                "Comoros": 49,
                "Congo": 50,
                "Cook Islands": 51,
                "Costa Rica": 52,
                "Cote D'Ivoire (Ivory Coast)": 53,
                "Croatia": 54,
                "Cuba": 55,
                "Curaçao": 56,
                "Cyprus": 57,
                "Czech Republic": 58,
                "Democratic Republic of the Congo": 59,
                "Denmark": 60,
                "Djibouti": 61,
                "Dominica": 62,
                "Dominican Republic": 63,
                "East Timor": 64,
                "Ecuador": 65,
                "Egypt": 66,
                "El Salvador": 67,
                "Equatorial Guinea": 68,
                "Eritrea": 69,
                "Estonia": 70,
                "Falkland Islands": 71,
                "Hondura": 72,
                "Faroe Islands": 73,
                "Fiji Islands": 74,
                "Finland": 75,
                "France": 76,
                "French Guiana": 77,
                "French Polynesia": 78,
                "French Southern Territories": 79,
                "Gabon": 80,
                "Gambia The": 81,
                "Georgia": 82,
                "Germany": 83,
                "Ghana": 84,
                "Gibraltar": 85,
                "Greece": 86,
                "Greenland": 87,
                "Grenada": 88,
                "Guadeloupe": 89,
                "Guam": 90,
                "Guatemala": 91,
                "Guernsey and Alderneyia": 92,
                "Guinea": 93,
                "Guinea-Bissau": 94,
                "Guyana": 95,
                "Haiti": 96,
                "Heard Island and McDonald Islands": 97,
                "Honduras": 98,
                "Hong Kong S.A.R.": 99,
                "Hungary": 100,
                "Iceland": 101,
                "India": 102,
                "Indonesia": 103,
                "Iran": 104,
                "Iraq": 105,
                "Ireland": 106,
                "Israel": 107,
                "Italy": 108,
                "Jamaica": 109,
                "Japan": 110,
                "Jersey": 111,
                "Jordan": 112,
                "Kazakhstan": 113,
                "Kenya": 114,
                "Kiribati": 115,
                "Kosovo": 116,
                "Kuwait": 117,
                "Kyrgyzstan": 118,
                "Laos": 119,
                "Latvia": 120,
                "Lebanon": 121,
                "Lesotho": 122,
                "Liberia ": 123,
                "Libya": 124,
                "Liechtenstein": 125,
                "Lithuania": 126,
                "Luxembourg": 127,
                "Macau S.A.R.": 128,
                "Macedonia": 129,
                "Madagascar": 130,
                "Malawi": 131,
                "Malaysia": 132,
                "Maldives": 133,
                "Mali": 134,
                "Malta": 135,
                "Man (Isle of)": 136,
                "Marshall Islands": 137,
                "Martinique": 138,
                "Mauritania": 139,
                "Mauritius": 140,
                "Mayotte": 141,
                "Mexico": 142,
                "Micronesia": 143,
                "Moldova": 144,
                "Monaco": 145,
                "Mongolia": 146,
                "Montenegro": 147,
                "Montserrat": 148,
                "Morocco": 149,
                "Mozambique": 150,
                "Myanmar": 151,
                "Namibia": 152,
                "Nauru": 153,
                "Nepal": 154,
                "Netherlands": 155,
                "New Caledonia": 156,
                "New Zealand": 157,
                "Nicaragua": 158,
                "Niger": 159,
                "Nigeria": 160,
                "Niue": 161,
                "Norfolk Island": 162,
                "North Korea": 163,
                "Northern Mariana Islands": 164,
                "Norway": 165,
                "Oman": 166,
                "Pakistan": 167,
                "Palau": 168,
                "Palestinian Territory Occupied": 169,
                "Panama": 170,
                "Papua new Guinea": 171,
                "Paraguay": 172,
                "Peru": 173,
                "Philippines": 174,
                "Pitcairn Island": 175,
                "Poland": 176,
                "Portugal": 177,
                "Puerto Rico": 178,
                "Qatar": 179,
                "Reunion": 180,
                "Romania": 181,
                "Russia": 182,
                "Rwanda": 183,
                "Saint Helena": 184,
                "Saint Kitts And Nevis": 185,
                "Saint Lucia": 186,
                "Saint Pierre and Miquelon": 187,
                "Saint Vincent And The Grenadines": 188,
                "Saint-Barthelemy": 189,
                "Saint-Martin (French part)": 190,
                "Samoa": 191,
                "San Marino": 192,
                "Sao Tome and Principe": 193,
                "Saudi Arabia": 194,
                "Senegal": 195,
                "Serbia": 196,
                "Seychelles": 197,
                "Sierra Leone": 198,
                "Singapore": 199,
                "Sint Maarten (Dutch part)": 200,
                "Slovakia": 201,
                "Slovenia": 202,
                "Solomon Islands": 203,
                "Somalia": 204,
                "South Africa": 205,
                "South Georgia": 206,
                "South Korea": 207,
                "South Sudan": 208,
                "Spain": 209,
                "Sri Lanka": 210,
                "Sudan": 211,
                "Suriname": 212,
                "Svalbard And Jan Mayen Islands": 213,
                "Swaziland": 214,
                "Sweden": 215,
                "Switzerland": 216,
                "Syria": 217,
                "Taiwan": 218,
                "Tajikistan": 219,
                "Tanzania": 220,
                "Thailand": 221,
                "The Bahamas": 222,
                "Togo": 223,
                "Tokelau": 224,
                "Tonga": 225,
                "Trinidad And Tobago": 226,
                "Tunisia": 227,
                "Turkey": 228,
                "Turkmenistan": 229,
                "Turks And Caicos Islands": 230,
                "Tuvalu": 231,
                "Uganda": 232,
                "Ukraine": 233,
                "United Arab Emirates": 234,
                "United Kingdom": 235,
                "United States": 236,
                "United States Minor Outlying Islands": 237,
                "Uruguay": 238,
                "Uzbekistan": 239,
                "Vanuatu": 240,
                "Vatican City State (Holy See)": 241,
                "Venezuela": 242,
                "Vietnam": 243,
                "Virgin Islands (British)": 244,
                "Virgin Islands (US)": 245,
                "Wallis And Futuna Islands": 246,
                "Western Sahara": 247,
                "Yemen": 248,
                "Zambia": 249,
                "Zimbabwe": 250
            };
            const codes = response.data.map(country => {
                const predefinedCode = predefinedCountryCodes[country.name.common];
               const code = predefinedCode || `${country.idd.root}${country.idd.suffixes ? country.idd.suffixes[0] : ''}`;
               return {
                   code,
                   name: country.name.common,
                   flag: country.flags.svg,
                   id: countryIdMap[country.name.common] || country.ccn3
               };
           });
           console.log("Testcodes", codes);
           setcountryCodes(codes);
       } catch (e) {
           console.error(e);
       }
   };
   fetchCountryCodes();
}, []);

const predefinedCountryCodes = {
   'United States': '+1',
   'Canada': '+1',
};

const CountryHandle = (e) => {
  const selectedCountry = e.target.value;
  const selectedCountryData = countryCodes.find(c => c.name === selectedCountry);
  if (selectedCountryData) {
    const selectedCountryCode = selectedCountryData.code || '';
    const selectedCountryId = selectedCountryData.id || '';
    let obj = {
      id: selectedCountryId
    };
    setcountryId(selectedCountryId);
    dispatch(GetStateRequest(obj));
    setcountry(selectedCountry);
    console.log('selectedCountryId', selectedCountryId);
    setcountryCode(selectedCountryCode);
  } else {
    console.error('Selected country data not found');
  }
};

  const stateHandle = (e) => {
    setstate(e.target.value);
    let obj = {
      id: e.target.value,
    };
    dispatch(GetcityRequest(obj));
  };

  const SubmitHandle = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

    if (location == "") {
      Swal.fire({
        icon: "error",
        text: "Location should be must",
      });
    } else if (fname == "") {
      Swal.fire({
        icon: "error",
        text: "First name should be must",
      });
    } else if (lname == "") {
      Swal.fire({
        icon: "error",
        text: "Last name should be must",
      });
    } else if (gender == "") {
      Swal.fire({
        icon: "error",
        text: "Gender should be must",
      });
    } else if (mobile == "") {
      Swal.fire({
        icon: "error",
        text: "Mobile no should be must",
      });
    } else if (reg.test(email) === false) {
      Swal.fire({
        icon: "error",
        text: "Email should be proper",
      });
    // } else if(addressone == ''){
    //   Swal.fire({
    //       icon: "error",
    //       text: 'Address should be must',
    //     });
    } else if (country == "") {
      Swal.fire({
        icon: "error",
        text: "country should be must",
      });
    // } else if (state == "") {
    //   Swal.fire({
    //     icon: "error",
    //     text: "State should be must",
    //   });
    // } else if (city == "") {
    //   Swal.fire({
    //     icon: "error",
    //     text: "City should be must",
    //   });
    } else {
      // let leadsarr = [];
      // let defaultleadsarr = [];

      // selectedleads.forEach((leads) => {
      //   leadsarr.push({
      //     id: leads.id,
      //     leadsource_id: leads.lead_source_id,
      //   });
      // });

      // defaultLeadList.forEach((leads) => {
      //   defaultleadsarr.push({
      //     id: leads.id,
      //     leadsource_id: leads.lead_source_id,
      //   });
      // });

      let leadsarr = []
      selectedleads.forEach(leads =>{
          leadsarr.push(leads.id)
      })

      let interestarr = []
      selectedInterest.forEach(interest =>{
          interestarr.push(interest.id)
      })

      let obj = {
        location_id: location,
        referral_account: refaccount,
        salutation: salution,
        referral_teacher: refteacher,
        first_name: fname,
        last_name: lname,
        gender: gender,
        propect_status: prospectstatus,
        propect_rating: rating,
        phone: phone,
        email: email,
        mobile_phone: countryCode + mobile,
        other_phone: othermobile,
        is_email_opt_out: emailout == true ? 1 : 0,
        is_free_trial: freetrial == true ? 1 : 0,
        street_address_1: addressone,
        street_address_2: addresstwo,
        country_id: countryId,
        state_id: state,
        city_id: city,
        description: description,
        customer_description: customerdescription,
        // leadsource_ids: leadsarr.length > 0 ? leadsarr : defaultleadsarr,
        "leadsource_ids": leadsarr,
        "leadinterest_ids": interestarr
      };

      console.log("--------------------------------", obj);

      dispatch(UpdatepropectsRequest({ obj: obj, navigate: navigate, id: id }));
    }
  };

  return (
    <div className="common-layout">
      <Loader visible={fetchacompanyReducer.isloading} />

      <div className="innerlayout p-3">
        <div className="mb-4">
          <h3> Edit Prospects</h3>
        </div>
        <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
        <div className="row form-style mb-4">
          <div className="col-lg-12 mb-2">
            <h6>Prospect Guardian information</h6>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Select Location <span class="asterisk">*</span></label>
              <select
                className="form-control"
                value={location}
                onChange={(e) => setlocation(e.target.value)}
              >
                <option value="">--Location---</option>
                {locationList &&
                  locationList.map((item, i) => {
                    return (
                      <option key={i} value={item?.id}>
                        {item?.location_name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
         
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Refferral Account</label>
              <input
                type="text"
                className="form-control"
                placeholder="Refferral Account"
                value={refaccount}
                onChange={(e) => setrefaccount(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Refferral Teacher</label>
              <input
                type="text"
                className="form-control"
                placeholder="Refferral Teacher"
                value={refteacher}
                onChange={(e) => setrefteacher(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Select Salutation</label>
                     <select className='form-control'  value={salution}
               onChange={(e)=>setsalution(e.target.value)}>
                <option value="">--Salutation---</option>
                <option value="Mr">Mr</option>
                <option value="Ms">Ms</option>
                <option value="Mrs">Mrs</option>
                
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter First Name <span class="asterisk">*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                value={fname}
                onChange={(e) => setfname(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Last Name <span class="asterisk">*</span></label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                value={lname}
                onChange={(e) => setlname(e.target.value)}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Address 1 </label>
              <input
                type="text"
                className="form-control"
                placeholder="Street Address 1"
                value={addressone}
                onChange={(e) => setaddressone(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Address 2</label>
              <input
                type="text"
                className="form-control"
                placeholder="Street Address 2"
                value={addresstwo}
                onChange={(e) => setaddresstwo(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Select Country <span className="asterisk">*</span></label>
                            <select className={`form-control`} value={country} onChange={CountryHandle}>
                                <option value="">--Select country---</option>
                                {countryCodes.sort((a, b) => a.name.localeCompare(b.name)).map((country) => (
          <option key={country.id} value={country.name}>{country.name}</option>
        ))}
                            </select>
                        </div>
                    </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Select State </label>
              <select
                className="form-control"
                value={state}
                onChange={stateHandle}
              >
                <option value="">--Select State---</option>

                {stateList &&
                  stateList.map((list, i) => {
                    return (
                      <option key={i} value={list?.state_id}>
                        {list?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Select City </label>
              <select
                className="form-control"
                value={city}
                onChange={(e) => setcity(e.target.value)}
              >
                <option value="">--Select city---</option>
                {cityList &&
                  cityList.map((list, i) => {
                    return (
                      <option key={i} value={list?.city_id}>
                        {list?.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Select Zipcode</label>
              <input
                type="text"
                className="form-control"
                placeholder="Zipcode"
                value={zipcode}
                onChange={(e) => setzipcode(e.target.value)}
                maxLength="6"
                minLength="6"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
            <label className='selectlabel'>Select Gender <span class="asterisk">*</span></label>
              <select className='form-control' value={gender}
                onChange={(e) => setgender(e.target.value)}>
                <option value="">-- Select Gender --</option>
                <option>Male</option>
                <option>Female</option>
                <option>Others</option>
              </select>
            </div>
          </div>
          <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enter Mobile Number <span class="asterisk">*</span></label>
                            <div className='d-flex'>
                                <div className='col-lg-4'>
                                    <select
                                        className='form-control' style={{ width: '140%', marginLeft: '-15px', fontSize: '12px' }}
                                        value={countryCode} disabled
                                        onChange={(e) => setcountryCode(e.target.value)}
                                    >
                                        {countryCodes.map((country) => (
                                            <option key={country.code} value={country.code}>
                                                {country.code}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className='col-lg-8'>
                                    <input type='text' className={`form-control`} style={{ width: '118%', marginLeft: '-15px' }} placeholder='Mobile Phone'
                                        value={mobile}
                                        onChange={(e) => { setmobile(e.target.value)}}
                                        maxLength="10"
                                        minLength="10"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Phone No.</label>
              <input
                type="text"
                className="form-control"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setphone(e.target.value)}
                maxLength="10"
                minLength="10"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Other Mobile No.</label>
              <input
                type="text"
                className="form-control"
                placeholder="Other Phone"
                value={othermobile}
                onChange={(e) => setothermobile(e.target.value)}
                maxLength="10"
                minLength="10"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Enter Email <span class="asterisk">*</span></label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Select Status</label>
              <select
                className="form-control"
                value={prospectstatus}
                onChange={(e) => setprospectstatus(e.target.value)}
              >
                <option value="">--Prospectus Status---</option>
                <option value="active">Active</option>
                <option value="inactive">In-active</option>
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
            <label className='selectlabel'>Select Rating</label>
              <select
                className="form-control"
                value={rating}
                onChange={(e) => setrating(e.target.value)}
              >
                <option>--Select Rating---</option>
                <option value="1">★</option>
                <option value="2">★ ★</option>
                <option value="3">★ ★ ★</option>
                <option value="4">★ ★ ★ ★</option>
                <option value="5">★ ★ ★ ★ ★</option>
              </select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="form-group">
              <input
                type="checkbox"
                defaultChecked={emailout}
                onChange={() => setemailout(!emailout)}
              />{" "}
              Email Opt Out
            </div>
            {/* <div className="form-group">
              <input
                type="checkbox"
                defaultChecked={freetrial}
                onChange={() => setfreetrial(!freetrial)}
              />{" "}
              Free Trial
            </div> */}
          </div>
          {/* <div className="col-lg-12">
            <hr />
          </div>
          <div className="col-lg-12 mb-2">
            <h6>Address information</h6>
          </div> */}
         
          <div className="col-lg-12">
            <hr />
          </div>
          <div className="col-lg-12 mb-2">
            <h6>Description information</h6>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
            <label className='selectlabel'>Enter Description</label>
              <textarea
                className="form-control"
                placeholder="Write Description"
                value={description}
                onChange={(e) => setdescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form-group">
            <label className='selectlabel'>Enter Customer Comments</label>
              <textarea
                className="form-control"
                placeholder="Customer comments"
                value={customerdescription}
                onChange={(e) => setcustomerdescription(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className='col-lg-12 mb-2'>
        <h6>Prospect Interest</h6>
      </div>
      <div className='col-lg-12'>
      <Multiselect
        displayValue="key"
        // groupBy="cat"
 
  options={InterestList}
  showCheckbox
  onSelect={(selectedInterest)=>{
    setselectedInterest(selectedInterest)
}}
onRemove={(selectedInterest)=>{
    setselectedInterest(selectedInterest)

}}
/>
</div>
<div className='col-lg-12'>
        <hr />
      </div>
       <div className='col-lg-12 mb-2'>
        <h6>Lead Source</h6>
      </div>
      <div className='col-lg-12'>
      <Multiselect
        displayValue="key"
        // groupBy="cat"
 
  options={LeadList}
  showCheckbox
  onSelect={(selectedList)=>{
    setselectedleads(selectedList)
}}
onRemove={(selectedList)=>{
    setselectedleads(selectedList)

}}
/>
          </div>
        </div>
        <button className="formBtn" onClick={SubmitHandle}>
          Save
        </button>
      </div>
    </div>
  );
};

export default EditProspects;
