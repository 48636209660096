import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { GetEmployeelocationRequest, AddEmployeelocationRequest, FetchEmployeelocationRequest, GetAssignEmployeelocationRequest, DeleteEmployeelocationRequest, UpdateEmployeelocationRequest, MakeLocationPrimaryRequest, ViewEmployeeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from "sweetalert2";
import Dropdown from 'react-bootstrap/Dropdown';

const EmployeeLocation = ({ id, etype }) => {
  const [search, setsearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])
  const [location, setlocation] = useState("")
  const [location1, setlocation1] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [date, setdate] = useState(null)
  const [date1, setdate1] = useState(null)
  const [editrowId, seteditrowId] = useState(null)
  const [selectedlocation, setselectedlocation] = useState([]);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);

  const dispatch = useDispatch()


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var locationList = fetchacompanyReducer?.GetAssignEmployeelocationResponse?.results
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))

  var getEmployeeLocation = fetchacompanyReducer?.GetEmployeelocationResponse?.results
  console.log(locationList, getEmployeeLocation)


  console.log("locationList", locationList)


  useEffect(() => {

    let obj = {
      id: id
    }
    dispatch(GetEmployeelocationRequest(obj))
    dispatch(GetAssignEmployeelocationRequest(id))

  }, [])

  const columns = [
    {
      name: 'Location Name',
      selector: row => row.location_name,
      sortable: true,
    },
    {
      name: 'Location Type',
      selector: (row) => (
        row.is_virtual_location == 1 ? "Hybrid Location" : ""
      ),
      sortable: true,
    },
    {
      name: 'Assigned Date',
      selector: row => moment(row.date).format("DD-MM-YYYY")
    },
    {
      name: 'Primary Location',
      selector: (row) => (
        row.is_primary == 1 ? "primary" : <button className='btn btn-sm btn-primary' onClick={() => MakePrimary(row?.id)}>Make Primary</button>
      )
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <button className='table-btn' onClick={() => handleShow1(row?.id)}><i className="fa-solid fa-pen-to-square"></i></button>
          <button className='table-btn' onClick={() => DeleteHandle(row.id, row.is_primary)}><i className="fa-solid fa-trash"></i></button>
        </>
      ),
    },
  ];

  useEffect(()=>{
      const result = getEmployeeLocation && getEmployeeLocation.filter(data =>{
        return data?.location_name?.toLowerCase().match(search.toLowerCase())
      })
      setFilterResult(result)


    },[search])

  useEffect(() => {
    console.log("hitting")

    setShow(false)
    let obj = {
      id: id
    }
    dispatch(GetEmployeelocationRequest(obj))
    dispatch(GetAssignEmployeelocationRequest(id))


    let obj1 = {
      id: id
    }

    dispatch(ViewEmployeeRequest(obj1))

  }, [fetchacompanyReducer?.AddEmployeelocationResponse])

  useEffect(() => {
    let obj = {
      id: id
    }
    dispatch(GetEmployeelocationRequest(obj))
    dispatch(GetAssignEmployeelocationRequest(id))

  }, [fetchacompanyReducer?.DeleteEmployeelocationResponse])

  useEffect(() => {
    let obj = {
      id: id
    }
    dispatch(GetEmployeelocationRequest(obj))
    dispatch(GetAssignEmployeelocationRequest(id))

  }, [fetchacompanyReducer?.MakeLocationPrimaryResponse])


  const AddHandle = () => {

    if (selectedlocation.length < 0) {
      Swal.fire({
        icon: "error",
        text: 'Location should be must',
      });
    } else if (date == '') {
      Swal.fire({
        icon: "error",
        text: 'Date should be must',
      });
    } else {

      var Temp = []
      selectedlocation&&selectedlocation.map((item)=>{
        console.log('locationitem',item)
          var dataitem =  Number(item.id);
          console.log('locationitem',dataitem);
          Temp.push(dataitem);
      })

      let obj = {
        "employee_id": Number(id),
        "location_id": Temp,
        "date": moment(date).format("YYYY-MM-DD"),
      }
      dispatch(AddEmployeelocationRequest(obj))
    }
  }

  const handleChange = (e, data) =>{
    console.log(data)
    const {name, checked} = e.target
    if (checked) {
        // if cheked and selectall checkbox add all fileds to selectedList
        if (name === "allSelect") {
          setselectedlocation(locationList);
        } else {
          // if cheked and specific checkbox add specific field to selectedList
          setselectedlocation([...selectedlocation, data]);
        }
      } else {
        // if uncheked and selectall checkbox add remove all fileds from selectedList
        if (name === "allSelect") {
          setselectedlocation([]);
        } else {
          // if uncheked and specific checkbox remove specific field from selectedList
          let tempuser = locationList.filter((item) => item.id !== data.id);
          console.log(tempuser);
          setselectedlocation(tempuser);
        }
      }
}

  const handleShow1 = (tid) => {
    seteditrowId(tid)
    setShow1(true)
    dispatch(FetchEmployeelocationRequest({ rowId: tid, employee_id: id }))
  };

  useEffect(() => {
    var editinfo = fetchacompanyReducer?.FetchEmployeelocationResponse?.results[0]
    console.log(editinfo?.location_id)
    setlocation1(editinfo?.location_id)
    setdate1(new Date(editinfo?.date == undefined ? new Date() : new Date(editinfo?.date)))

  }, [fetchacompanyReducer?.FetchEmployeelocationResponse])

  const DeleteHandle = (rid, isPrimary) => {
    if (isPrimary === 1) {
      Swal.fire({
        text: "Primary location cannot be deleted.",
        icon: 'error',
      });
      return;
    }  
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteEmployeelocationRequest({ rowId: rid, employee_id: id }))

        if (fetchacompanyReducer?.DeleteEmployeelocationResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const UpdateHandle = () => {

    if (selectedlocation.length < 0) {
      Swal.fire({
        icon: "error",
        text: 'Location should be must',
      });
    } else if (date1 == '') {
      Swal.fire({
        icon: "error",
        text: 'Date should be must',
      });
    } else {

      var Temp = []
      selectedlocation&&selectedlocation.map((item)=>{
        console.log('locationitem',item)
          var dataitem =  Number(item.id);
          console.log('locationitem',dataitem);
          Temp.push(dataitem);
      })

      let obj = {
        "employee_id": Number(id),
        "location_id": Temp,
        "date": moment(date1).format("YYYY-MM-DD"),
      }
      dispatch(UpdateEmployeelocationRequest({ obj: obj, employee_id: id, rowId: editrowId }))
    }
  }

  useEffect(() => {
    setShow1(false)
    let obj = {
      id: id
    }
    dispatch(GetEmployeelocationRequest(obj))
    dispatch(GetAssignEmployeelocationRequest(id))
  }, [fetchacompanyReducer?.UpdateEmployeelocationResponse])

  const MakePrimary = (rid) => {
    Swal.fire({
      title: 'Are you sure make primary?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, make primary!'
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          is_primary: 1
        }
        dispatch(MakeLocationPrimaryRequest({ obj: obj, rowId: rid, employee_id: id }))

        if (fetchacompanyReducer?.MakeLocationPrimaryResponse) {
          Swal.fire(
            'set primary!',
            'Your file has been set.',
            'success'
          )
        }

      }
    })
  }



  return (
    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Locations</h5>
      {etype == "EMPLOYEE" ?
        <h3>can not assign multiple location for Corporate  employee</h3>
        :
        <>



          <div className='row form-style mt-3'>
            <div className='col-lg-3'>
              <div className='form-group m-0'>
                <input type='text' className='form-control' placeholder='Location Name'
                  value={search}
                  onChange={(e) => setsearch(e.target.value)}
                />
              </div>
            </div>
            <div className='col-lg-3'>
              <button className='formBtn' onClick={handleShow}>Assign Location</button>
            </div>

          </div>
          <div className='table-responsive'>

            <DataTable
              columns={columns}
              data={getEmployeeLocation}
              pagination
              highlightOnHover
            />

          </div>
        </>
      }

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Assign Location</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

          <div className='col-lg-6'>
                            <div className='form-group'>
                            <label className='selectlabel'>Select Location Name</label>
                            <Dropdown className='custom-dropdown-checkbox'>
                            <Dropdown.Toggle variant="success">
                                {selectedlocation.length > 0 ? 'select ' + selectedlocation.length : 'select location'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <ul>
                                    <li>
                                        <input type="checkbox" name="allSelect" checked={selectedlocation?.length === locationList?.length}
                                         onChange={(e) => handleChange(e, locationList)}
                                        />
                                        <label>All Select</label>
                                    </li>
                                    {locationList&&locationList.map((list, i) =>{
                                        return (
                                            <li>
                                                <input type="checkbox" name={list?.location_name+i} id={i} onChange={(e) => handleChange(e, list)}
                                                 checked={selectedlocation.some((item) => item?.id === list.id)}
                                                />
                                                <label htmlFor={i}>{list?.location_name} {list?.is_virtual_location == 1 ? "(Hybrid Location)" : ""}</label>
                                            </li>
                                        )
                                    })}
                                    
                                   
                                </ul>
                            </Dropdown.Menu>
                            </Dropdown>
                            </div>
                        </div>


            {/* <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Select Location Name</label>
                <select className='form-control' value={location}
                  onChange={(e) => setlocation(e.target.value)}>
                  <option value="">--Select Location---</option>
                  {locationList && locationList.map((item, i) => {
                    return (
                      <option key={i} value={item?.id}>{item?.location_name} {item?.is_virtual_location == 1 ? "(Hybrid Location)" : ""}</option>
                    )
                  })}
                </select>
              </div>
            </div> */}

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Assigning date</label>
                <DatePicker selected={date} onChange={(date) => setdate(date)} className='form-control'
                  placeholderText="Assigning date"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={AddHandle}>Add</button>

        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header >
          <Modal.Title>Edit Assign Location</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>
            <div className='col-lg-6'>
            <div className='form-group'>
                            <label className='selectlabel'>Select Location Name</label>
                            <Dropdown className='custom-dropdown-checkbox'>
                            <Dropdown.Toggle variant="success">
                                {selectedlocation.length > 0 ? 'select ' + selectedlocation.length : 'select location'}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <ul>
                                    <li>
                                        <input type="checkbox" name="allSelect" checked={selectedlocation?.length === locationList?.length}
                                         onChange={(e) => handleChange(e, locationList)}
                                        />
                                        <label>All Select</label>
                                    </li>
                                    {locationList&&locationList.map((list, i) =>{
                                        return (
                                            <li>
                                                <input type="checkbox" name={list?.location_name+i} id={i} onChange={(e) => handleChange(e, list)}
                                                 checked={selectedlocation.some((item) => item?.id === list.id)}
                                                />
                                                <label htmlFor={i}>{list?.location_name} {list?.is_virtual_location == 1 ? "(Hybrid Location)" : ""}</label>
                                            </li>
                                        )
                                    })}
                                    
                                   
                                </ul>
                            </Dropdown.Menu>
                            </Dropdown>
                            </div>
            </div>
            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Enter Assigning date</label>
                <DatePicker selected={date1} onChange={(date) => setdate1(date)} className='form-control'
                  placeholderText="Assigning date"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={UpdateHandle}>Update</button>

        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default EmployeeLocation