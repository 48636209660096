import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { AddProgressCheckRequest, GetProgressCheckRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';

const CreateProgressCheck = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [holdduration, setholdduration] = useState("")
    const [Newarry, setNewarry ] = useState([])
    const [is_select, setis_select ] = useState(true)

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var ProgressList = fetchacompanyReducer?.GetProgressCheckResponse?.results

    // console.log(fetchacompanyReducer.GetProgressCheckResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id, pros, lpid } = useParams();
    console.log(lpid)

    const columns = [
        {
            name: 'Select',
            selector: (row) => (
                <>
                    <input type="checkbox" />
                </>
            ),
        },
        {
            name: 'Question No.',
            selector: row => ""
        },
        {
            name: 'Score',
            selector: row => "",
        },
        {
            name: 'Assessment',
            selector: row => "",
        },
        {
            name: 'Topic',
            selector: row => "",
        },
        {
            name: 'LP Name',
            selector: row => "",
        },
        {
            name: 'Assignment',
            selector: row => "",
        },
        {
            name: 'Description',
            selector: row => "",
        },
        {
            name: 'Date Assigned',
            selector: row => "",
        },        {
            name: 'Date Completed',
            selector: row => "",
        },
        {
            name: 'Mastered',
            selector: row => "",
        }
    ];

    useEffect(() => {

        var ProgressList = fetchacompanyReducer?.GetProgressCheckResponse?.results
        console.log(fetchacompanyReducer?.GetProgressCheckResponse?.results)
        console.log(ProgressList)

        let TempArray = []

        ProgressList&&ProgressList.forEach(element => {
            TempArray.push({
            
                display_order: element.display_order,
                id: element.id,
                topic_id: element.topic_id,
                learning_path_id: element.learning_path_id,
                company_id: element.company_id,
                student_id: element.student_id,
                test_id: element.test_id,
                question_id: element.question_id,
                question_number: element.question_number,
                exam_id: element.exam_id,
                is_select: "1",
                is_correct: element.is_correct,
                assignment: element.assignment,
                date_assigned: element.date_assigned,
                date_completed: element.date_completed,
                date_last_printed: element.date_last_printed,
                created_at: element.created_at,
                created_by: element.created_by,
                is_active: element.is_active,
                is_edit: element.is_edit,
                last_modified: element.last_modified,
                test_name: element.test_name,
                learning_name: element.learning_name,
                topic_name: element.topic_name,
                topic_number: element.topic_number,
                module_name: element.module_name,
                company_name: element.company_name
          
            })
        });

        setNewarry(TempArray)

    }, [fetchacompanyReducer?.GetProgressCheckResponse])

    useEffect(() => {
        const result = ProgressList && ProgressList.filter(data => {
            return data.test_name?.toLowerCase().match(Namesearch?.toLowerCase())
        })
        setFilterResult(result)
    }, [Namesearch])

    useEffect(() => {

        setFilterResult(ProgressList)
    }, [fetchacompanyReducer?.GetProgressCheckResponse])

    
    useEffect(() => {
        let obj = {
            id: id,
            student_id: pros,
            learning_path_id: lpid
        }
        dispatch(GetProgressCheckRequest(obj))
      }, [])

      const handlecheckboxchange = (event,key, index) => {

        if (event.target.checked) {
           var TempArr = [...Newarry];
           TempArr[index][key] = true
           setNewarry(TempArr); 
         
          } else {
            var TempArr = [...Newarry];
            TempArr[index][key] = false  
            TempArr[index]['overwright_desc'] = ''
            setNewarry(TempArr); 
          }
    } 

    
    const AllcorrectHandle = () => {
        console.log("AllcorrectHandle")
        const newData = Newarry.map((data) => ({
            ...data,
            is_select: '1', 
          }));

        setNewarry(newData);
    }

    
    const SubmitResult = () => {

  
        let TempArr = []

        Newarry.forEach(element =>{
            TempArr.push(
                {
                    "display_order": element.display_order,
                    "id": element.id,
                    "topic_id": element.topic_id,
                    "learning_path_id": element.learning_path_id,
                    "company_id": element.company_id,
                    "student_id": element.student_id,
                    "test_id": element.test_id,
                    "question_id": element.question_id,
                    "question_number": element.question_number,
                    "exam_id": element.exam_id,
                    "is_select": element.is_select == false ? 0 : 1,
                    "is_correct": element.is_correct,
                    "assignment": element.assignment,
                    "date_assigned": element.date_assigned,
                    "date_completed": element.date_completed,
                    "date_last_printed": element.date_last_printed,
                    "created_at": element.created_at,
                    "created_by": element.created_by,
                    "is_active": element.is_active,
                    "is_edit": element.is_edit,
                    "last_modified": element.last_modified,
                    "test_name": element.test_name,
                    "learning_name": element.learning_name,
                    "topic_name": element.topic_name,
                    "topic_number": element.topic_number,
                    "module_name": element.module_name,
                    "company_name": element.company_name
                }
            )
        })


        let obj = {
            question_data_list: TempArr
        }

        dispatch(AddProgressCheckRequest({ obj: obj, test_id:id, student_id:pros, progress_check_id:lpid, navigate: navigate }))
 
}

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
            <h5>Create Progress Check</h5>

            <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-40px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

            <hr></hr>
            <div className='row form-style mb-4 align-items-center'>

                    <div className='button-part' style={{ marginTop: '12px', marginLeft: '10px' }}>
                        <ul>
                            {/* <li>
                                <NavLink className='formBtn1'> Partial Credit ON</NavLink>
                            </li> */}
                            <li>
                                <button className='formBtn1' onClick={AllcorrectHandle} >Select All </button>
                            </li>
                        </ul>
                    </div>
                    </div>

                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th style={{width:'30px'}}>Select</th>
                                    <th>Question No.</th>
                                    <th>Score</th>
                                    <th>Assessment</th>
                                    <th>Topic</th>
                                    <th>LP Name</th>
                                    <th>Assignment</th>
                                    <th>Description</th>
                                    <th>Date Assigned</th>
                                    <th>Date Completed</th>
                                    <th>Mastered</th>
                                </tr>
                            </thead>
                            {ProgressList && ProgressList.length > 0 ?
                                <tbody>
                                    {ProgressList && ProgressList.map((item, index) => {
                                        return (
                                            <tr>
                                                <td style={{width:'30px'}}>
                                                {/* <input type="checkbox" /> */}
                                                <input type='checkbox' onChange={(e)=>handlecheckboxchange(e, 'is_select', index)} defaultChecked={is_select}/>
                                                </td>
                                                <td>{item?.question_number}</td>
                                                <td></td>
                                                <td></td>
                                                <td>{item?.topic_name}</td>
                                                <td>{item?.test_name}</td>
                                                <td>{item?.assignment}</td>
                                                <td></td>
                                                <td style={{width:'150'}}>{moment(item?.date_assigned).format('DD-MM-YYYY')}</td>
                                                <td style={{width:'150'}}>{moment(item?.date_completed).format('DD-MM-YYYY')}</td>
                                                <td></td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                                :
                                <h6 className='text-center'>No data found</h6>
                            }
                        </table>
                        <div className='button-part mt-4'>
                            <ul style={{ float: 'right' }}>
                                <li>
                                    <NavLink className='formBtn1' onClick={SubmitResult}>Save </NavLink>
                                </li>
                                {/* <li>
                                    <NavLink className='formBtn1' >Cancel </NavLink>
                                </li> */}
                            </ul>
                        </div>
                    </div>
</div>
        </div>

    )
}

export default CreateProgressCheck