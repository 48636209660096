import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddBackgroundRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const EnrollCostDetails = () => {
    const [bccname, setbccname] = useState("")
    const [email, setemail] = useState("")
    const [contactname, setcontactname] = useState("")
    const [phone, setphone] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetBackgroundResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const SubmitHandle = () => {
        if (bccname == '') {
            Swal.fire({
                icon: "error",
                text: 'Delivery Method should be must',
            });
        } else if (email == '') {
            Swal.fire({
                icon: "error",
                text: 'Contract Start Date should be must',
            });
        } else if (contactname == '') {
            Swal.fire({
                icon: "error",
                text: 'Contract End Date should be must',
            });
        } else if (phone == '') {
            Swal.fire({
                icon: "error",
                text: 'Phone should be must',
            });
        } else {

            let obj = {
                "bcc_name": bccname,
                "email": email,
                "contact_name": contactname,
                "phone": phone,

            }

            dispatch(AddBackgroundRequest({ obj: obj, navigate: navigate }))

        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Enrollment Details</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Delivery Method</label>
                                <input type='text' className='form-control' placeholder='Delivery Method'
                                    />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Contract Start Date</label>
                                <input type='date' className='form-control' placeholder='Contract Start Date'
                                    />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                            <label className='selectlabel'>Contract End Date</label>
                                <input type='date' className='form-control' placeholder='Contract End Date'
                                    />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Membership Type</label>
                                <input type='text' className='form-control' placeholder='Membership Type'
                                    />
                            </div>
                        </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Session Count </label>
                            <input type='text' className='form-control' placeholder='Session Count'
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Recurs After</label>
                            <input type='text' className='form-control' placeholder='Recurs After'
                            />
                        </div>
                    </div>                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Contract Length</label>
                            <input type='text' className='form-control' placeholder='Contract Length'
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Contract End</label>
                            <input type='text' className='form-control' placeholder='Contract End'
                            />
                        </div>
                    </div>

                </div>
                <button className='btn btn-primary mr-3 mb-4 float-right' onClick={SubmitHandle}>Confirm</button>
            </div>

        </div>
    )
}

export default EnrollCostDetails