import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useParams } from 'react-router-dom'
import { FetchstudentRequest, UpdateattendanceRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment'
import Accordion from 'react-bootstrap/Accordion';
import ReactSwitch from 'react-switch';
import ApiConnection from '../../utils/ApiConnection'


const Digitalworkout = () => {

    const { id, date, pros, pros2 } = useParams()
    console.log(date, pros, pros2);

    const [show, setShow] = useState(false);
    const newDate = new Date();
    const CurrentTime = `${newDate.getHours()}:${newDate.getMinutes()}`
    const [selectedDate, setSelectedDate] = useState(newDate);
    const [checked, setChecked] = useState(false);
    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);
    const [checked4, setChecked4] = useState(false);
    const [duration, setduration] = useState(0);
    const [time, setplanduration] = useState("");
    const [testId, settestId] = useState("");
    const [lpList, setlpList] = useState([]);
    const [RewardList, setRewardList] = useState([]);
    const [SessionDetailsList, setSessionDetailsList] = useState([]);
    const [signnotesList, setsignnotesList] = useState([]);
    const [SessionRatingList, setSessionRatingList] = useState([]);
    const [instructorsign, setinstructorsign] = useState("")
    const [sessiondate, setsessiondate] = useState(new Date(date));
    const [sessionstart, setsessionstart] = useState(pros);
    const [sessionend, setsessionend] = useState(pros2);
    const [studentworkedonassessment, setstudentworkedonassessment] = useState("");
    const [internetconnectivity, setinternetconnectivity] = useState("");
    const [effort, seteffort] = useState("");
    const [accuracy, setaccuracy] = useState("");
    const [confidence, setconfidence] = useState("");
    const [mathitude, setmathitude] = useState("");
    const [difficulty, setdifficulty] = useState("");
    // const [schoolworkplanned, setschoolworkplanned] = useState("");
    // const [schoolworkafter, setschoolworkafter] = useState("");
    // const [schoolworkdesc, setschoolworkdesc] = useState("");
    const [pagegoal, setpagegoal] = useState("");
    const [schoolworkcompleted, setschoolworkcompleted] = useState(false);
    const [schoolworkchecked, setschoolworkchecked] = useState(false);
    const [conceptexplained, setconceptexplained] = useState(false);
    const [respectfulness, setrespectfulness] = useState("");
    const [pagescompleted, setpagescompleted] = useState(0);
    const [coinearned, setcoinearned] = useState(0);
    const [coinasoftoday, setcoinasoftoday] = useState("");
    const [moneyasoftoday, setmoneyasoftoday] = useState("");
    const [coinredeemed, setcoinredeemed] = useState("");
    const [remainingcoin, setremainingcoin] = useState("");
    const [remainingmoneyvalue, setremainingmoneyvalue] = useState("");
    const [notesforcd, setnotesforcd] = useState("");
    const [learningscore, setlearningscore] = useState("");
    const [notesforstd, setnotesforstd] = useState("");
    const [timerActive, setTimerActive] = useState(false);

    const [schoolworkplanned, setschoolworkplanned] = useState(0);
    const [schoolworkafter, setschoolworkafter] = useState(0);
    const [schoolworkdesc, setschoolworkdesc] = useState('');
    const [isSchoolworkCompleted, setIsSchoolworkCompleted] = useState(false);
    const [isSchoolworkChecked, setIsSchoolworkChecked] = useState(false);
    const [isConceptExplained, setIsConceptExplained] = useState(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const teachersList = fetchacompanyReducer?.FetchstudentResponse?.results[0]

    console.log(fetchacompanyReducer.FetchstudentResponse?.results[0]?.teacher_name)

    const dispatch = useDispatch()
    const handleClose = () => setShow(false);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
            if (instructorsign == '') {
                e.preventDefault();
                e.returnValue = "Are you sure you want to leave?";
            }
        };
        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    const handleChange = (val) => {
        setChecked(val);
    };

    const handleChange1 = (val) => {
        setChecked1(val);
    };

    const handleChange2 = (val) => {
        setChecked2(val);
    };

    const handleChange3 = (val) => {
        setChecked3(val);
    };

    const handleChange4 = (val) => {
        setChecked4(val);
    };

    // useEffect(() => {
    //     if (duration > 0) {
    //         const timerId = setInterval(() => {
    //             setduration((prevTime) => prevTime - 1);
    //         }, 60000);

    //         return () => clearInterval(timerId);
    //     }
    // }, [duration]);

    const ModalHandler = (id, duration) => {
        console.log(id, duration)
        setduration(Number(time))
        setShow(true);
        settestId(id)
    }

    useEffect(() => {
        setplanduration(0)
    })

    useEffect(() => {
        GetDigitalRewadrd()
        GetSingatureDetials()
        GetSessionDetails()
    }, [])

    useEffect(() => {
        GetPrevSessionRating()
    }, [])

    useEffect(() => {
        GetLPList()
    }, [])

    useEffect(() => {
        let obj = {
            'id': Number(id),
        }
        dispatch(FetchstudentRequest(obj))
    }, [])

    const GetLPList = async () => {
        try {
            const response = await ApiConnection.get(`test-learningpath-details-list/?server_type=production&student_id=${id}&is_game_plan=${0}`)
            if (response?.status == 200) {
                setlpList(response?.data?.results)
            }
        } catch (e) { }
    }

    const SubmitResult = async (lpid, tid, value, key, innerindex, outerindex) => {

        var arr = [...lpList]

        arr[outerindex].learning_path_topic_details_list[innerindex][key] = value
        setlpList(arr)

        var TempArr = []

        lpList[outerindex].learning_path_topic_details_list.forEach(elm => {
            TempArr.push(
                {
                    "topic_id": elm.topic_id,
                    "start_page_no": elm.start_page_no,
                    "end_page_no": elm.end_page_no,
                    "remarks": elm.remarks,
                    "is_completed": elm.is_completed,
                    "is_mastered": elm.is_mastered,
                }
            )
        })

        let obj = {
            learning_path_id: lpList[outerindex].id,
            learning_path_topic_details: TempArr,
            todays_learning_score: learningscore,
        }

        try {
            const response = await ApiConnection.post(`laerningpath/day-wise-workout-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
            }

        } catch (e) { }
    }

    const CheckboxHandle = async (lpid, tid, value, key, innerindex, outerindex) => {

        var arr = [...lpList]

        arr[outerindex].learning_path_topic_details_list[innerindex][key] = value.target.checked ? Number(1) : Number(0)
        setlpList(arr)

        var TempArr = []

        lpList[outerindex].learning_path_topic_details_list.forEach(elm => {
            TempArr.push(
                {
                    "topic_id": elm.topic_id,
                    "start_page_no": elm.start_page_no,
                    "end_page_no": elm.end_page_no,
                    "remarks": elm.remarks,
                    "is_completed": elm.is_completed,
                    "is_mastered": elm.is_mastered,
                }
            )
        })

        let obj = {
            learning_path_id: lpList[outerindex].id,
            learning_path_topic_details: TempArr,
            todays_learning_score: learningscore,
        }

        try {
            const response = await ApiConnection.post(`laerningpath/day-wise-workout-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
            }

        } catch (e) { }
    }

    const SingatureSubmit = async () => {

        if (instructorsign == '') {
            Swal.fire({
                icon: "error",
                text: 'Instructor Signature should be must',
            });
        } else if (notesforcd == '') {
            Swal.fire({
                icon: "error",
                text: 'Notes for Center Director should be must',
            });
        } else if (notesforstd == '') {
            Swal.fire({
                icon: "error",
                text: 'Notes for Student should be must',
            });
        } else if (sessiondate == '') {
            Swal.fire({
                icon: "error",
                text: 'Session Date should be must',
            });
        } else if (sessionstart == '') {
            Swal.fire({
                icon: "error",
                text: 'Session Start should be must',
            });
        } else if (sessionend == '') {
            Swal.fire({
                icon: "error",
                text: 'Session End should be must',
            });

        }
        else {

            Swal.fire({
                title: 'Warning !',
                text: "This Report Will be Emailed to Parents",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Accept'
            }).then(async (result) => {
                if (result.isConfirmed) {

                    let obj = {
                        "session_date": moment(sessiondate).format("YYYY-MM-DD"),
                        "session_start_time": sessionstart,
                        "session_end_time": sessionend,
                        "instructor_signature": instructorsign,
                        "notes_for_this_session": notesforstd,
                        "notes_for_center_director": notesforcd
                    }

                    try {
                        const response = await ApiConnection.post(`game_plan-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`, obj)
                        if (instructorsign != '') {
                            Swal.fire(
                                'Submitted!',
                                'Your Request has been Submitted.',
                                'success'
                            )
                        }
                    } catch (e) { }

                }

            })
        }
    }

    const GetSingatureDetials = async () => {
        try {
            const response = await ApiConnection.get(`game_plan-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`)
            if (response?.status == 200) {
                console.log("GetSingatureDetials", response);
                setsignnotesList(response?.data?.results[0])

                // setsessiondate(response.data.results[0].session_date);
                // setsessionstart(response.data.results[0].session_time);
                // setsessionend(response.data.results[0].session_time);
                setinstructorsign(response.data.results[0].instructor_signature);
                setnotesforcd(response.data.results[0].notes_for_center_director);
                setnotesforstd(response.data.results[0].notes_for_this_session);
            }
        } catch (e) { }

    }

    const GetDigitalRewadrd = async () => {

        try {
            const response = await ApiConnection.get(`student-reward-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`)
            if (response?.status == 200) {
                setRewardList(response?.data?.results)
            }

            // setcoinearned(response.data.results[0].coin_earned_is_session);
            setmoneyasoftoday(response.data.results[0].total_coin_as_today);
            setcoinasoftoday(response.data.results[0].total_money_as_today);
            // setcoinredeemed(response.data.results[0].coin_redeemed_in_session);
            setremainingcoin(response.data.results[0].remaining_coin);
            setremainingmoneyvalue(response.data.results[0].remaining_money_value);

        } catch (e) { }
    };

    useEffect(() => {
        let newTotalSum = 0;
        RewardList.forEach(elm => {
            newTotalSum += elm.total_pages_completed;
        });
        setpagescompleted(newTotalSum);
        setcoinearned(newTotalSum);

    }, [RewardList]);

    const AddDigitalReward = async () => {

        let obj = {
            "total_pages_completed": pagescompleted,
            "coin_earned_is_session": coinearned,
            "total_coin_as_today": coinasoftoday,
            "total_money_as_today": moneyasoftoday,
            "coin_redeemed_in_session": coinredeemed,
            "remaining_coin": remainingcoin,
            "remaining_money_value": remainingmoneyvalue,

        }

        try {
            const response = await ApiConnection.post(`student-reward-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
            }
        } catch (e) { }
    }

    const AddSessionRating = async () => {

        let obj = {
            "is_students_worked_on_assessment_today": 1,
            "internet_connectivity_rating": internetconnectivity,
            "putting_in_effort_score": effort,
            "mathitude_score": mathitude,
            "focus_and_accuracy_score": accuracy,
            "respectfulness_score": respectfulness,
            "confidence_and_speed_score": confidence,
            "curriculum_difficulty_level": difficulty
        }

        try {
            const response = await ApiConnection.post(`session-warmup/day-wise-workout-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`, obj)
            if (response?.status == 200) {
                // Swal.fire({
                //     icon: "success",
                //     text: response?.data?.msg,
                // });
            }
        } catch (e) { }
    }

    const AddSessionDetails = async () => {
        if (duration > 0) {
          Swal.fire({
            icon: 'error',
            text: 'Please wait until the countdown is completed.',
          });
          return;
        }
    
        let obj = {
          "start_schoolwork_after": schoolworkafter,
          "schoolwork_planned_duration": schoolworkplanned,
          "is_schoolwork_completed": isSchoolworkCompleted ? 1 : 0,
          "is_schoolwork_checked": isSchoolworkChecked ? 1 : 0,
          "is_concept_explained": isConceptExplained ? 1 : 0,
          "schoolwork_description": schoolworkdesc,
          "notes_student_schoolwork_today": "Student performed well.",
          "notes_concept_explain": "Concepts explained clearly."
        };
    
        try {
          const response = await ApiConnection.post(`session-workout-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`, obj);
          if (response?.status === 200) {
            Swal.fire({
              icon: "success",
              text: response?.data?.msg,
            });
          }
        } catch (e) {
          console.error(e);
        }
      };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter' && effort.trim() !== '') {
            // Trigger the function when Enter key is pressed
            AddSessionRating();
        }
    };

    const GetSessionDetails = async () => {
        try {
            const response = await ApiConnection.get(`session-workout-day-wise-crud/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`)
            if (response?.status == 200) {
                console.log(response);
                setSessionDetailsList(response?.data?.results[0])
            }

            setschoolworkafter(response.data.results[0].start_schoolwork_after);
            setschoolworkplanned(response.data.results[0].schoolwork_planned_duration);
            setschoolworkdesc(response.data.results[0].schoolwork_description);
            setIsSchoolworkCompleted(response.data.results[0].is_schoolwork_completed === 1);
            setIsSchoolworkChecked(response.data.results[0].is_schoolwork_checked === 1);
            setIsConceptExplained(response.data.results[0].is_concept_explained === 1);
        } catch (e) { }

    }

    const GetPrevSessionRating = async () => {
        try {
            const response = await ApiConnection.get(`session-warmup/prev-date/list/?server_type=production&student_id=${id}&attendance_date=${date}&attendance_time=${pros}`)
            if (response?.status == 200) {
                console.log(response);
                setSessionRatingList(response?.data?.results[0])
            }
        } catch (e) { }

    }

    const SessionStopwatch = () => {
        setInterval(() => {
            if (duration !== 0) {
                setduration(duration - 1);
            }
        }, 1000);
    };

    useEffect(() => {
        let timer;
        if (timerActive && duration > 0) {
          timer = setInterval(() => {
            setduration((prevDuration) => prevDuration - 1);
          }, 1000); // 1000 ms = 1 second
        } else if (duration === 0 && timerActive) {
          setTimerActive(false);
        }
        return () => clearInterval(timer);
      }, [timerActive, duration]);

    const startTimer = () => {
        setduration(schoolworkplanned * 60);
        setTimerActive(true);
    };

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3 className='mb-3'> Game Plan - {teachersList?.first_name} {teachersList?.last_name}</h3>
                    {/* <button className='formBtn1 ml-3' style={{ backgroundColor: '#5eaaaf' }}>Teacher : {teachersList?.teacher_name}</button> */}
                </div>

                <div className='d-flex'>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label >Session Date</label>
                            <DatePicker className='form-control' selected={sessiondate}
                                placeholderText="Session Date" onChange={(date) => setsessiondate(date)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-2'>
                        <div className='form-group'>
                            <label >Session Start</label>
                            <input type='time' className='form-control' placeholder='Start Time' value={sessionstart}
                                onChange={(e) => setsessionstart(e.target.value)} />
                        </div>
                    </div>

                    <div className='col-lg-2'>
                        <div className='form-group'>
                            <label >Session End</label>
                            <input type='time' className='form-control' placeholder='End Time ' value={sessionend}
                                onChange={(e) => setsessionend(e.target.value)} />
                        </div>
                    </div>

                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label >Instructor Signature</label>
                            <input type='text' className='form-control' placeholder='Instructor Sign' value={instructorsign}
                                onChange={(e) => setinstructorsign(e.target.value)} />
                        </div>
                    </div>

                    <div className='col-lg-2 mt-4'>
                        <div className='form-group'>
                            <button className='formBtn1' style={{ borderRadius: '2px', height: '40px', marginTop: '7px' }} onClick={SingatureSubmit}>Submit</button>
                        </div>
                    </div>

                </div>

                <hr></hr>

                <div className='col-lg-8 mb-2 ml-3'>
                    <div className='form-group'>
                        <div className='row align-items-right'>
                            <div className='mt-2' style={{ marginLeft: '16px' }}>
                                <ReactSwitch checked={checked2} onChange={handleChange2} />
                            </div>

                            <div className='col-lg-6 mt-2 mr-2'>
                                <label className='selectlabel ' style={{ fontSize: '16px', fontWeight: '500' }}>Notes <i className="fa fa-info-circle"></i>
                                    ({moment(SessionRatingList?.attendance_date).format('DD-MM-YYYY')})
                                </label>
                            </div>

                        </div>
                    </div>
                </div>

                {checked2 === true && (

                    <div className='ml-4 mb-2' style={{ border: '1px solid #c3c3c3', width: '95%', borderRadius: '5px' }}>

                        {/* <div className='col-lg-5 mt-2'>
                            <div className='form-group'>
                                <span className='d-block'>SchoolWork Description : Practice derivatives
                                </span>
                            </div>
                        </div> */}

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='d-block'>Additional Teacher Comment on SchoolWork :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={schoolworkdesc} placeholder='Additional Teacher Comments'
                                ></textarea>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className=''>
                                <span className='selectlabel'>1. Putting in Effort Score = {SessionRatingList?.putting_in_effort_score} / 10
                                </span>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className=''>
                                <span className='selectlabel'>2. Focus and Accuracy Score = {SessionRatingList?.focus_and_accuracy_score} / 10
                                </span>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className=''>
                                <span className='selectlabel'>3. Confidence and Speed Score = {SessionRatingList?.confidence_and_speed_score} / 10
                                </span>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className=''>
                                <span className='selectlabel'>4. Mathitude (Attitude) Score = {SessionRatingList?.mathitude_score} / 10
                                </span>
                            </div>
                        </div>

                        <div className='col-lg-5'>
                            <div className=''>
                                <span className='selectlabel'>5. Respectfulness Score = {SessionRatingList?.respectfulness_score} / 10
                                </span>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='d-block'>Student Notes :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={notesforstd} placeholder='Student Notes'
                                ></textarea>
                            </div>
                        </div>

                        <div className='col-lg-12'>
                            <div className='form-group'>
                                <label className='d-block'>Note from Center Director :
                                </label>
                                <textarea type='text' disabled className='form-control guidelinesnotes' value={notesforcd} placeholder='Center Director Notes'
                                ></textarea>
                            </div>
                        </div>

                    </div>
                )}

                <hr></hr>

                <div className='col-lg-8 mb-2 ml-3'>
                    <div className='form-group'>
                        <div className='row align-items-right'>
                            <div className='mt-2' style={{ marginLeft: '16px' }}>
                                <ReactSwitch checked={checked3} onChange={handleChange3} />
                            </div>
                            <div className='col-lg-6 mt-2 mr-2'>
                                <label className='selectlabel ' style={{ fontSize: '16px', fontWeight: '500' }}>Session Details </label>
                            </div>
                        </div>
                    </div>
                </div>

                {checked3 === true && (
                    <div className='view-page mt-2'>

                        <div className='ml-4 mt-2' style={{ border: '1px solid #c3c3c3', width: '95%', borderRadius: '5px' }}>

                            <div className='row mt-2'>
                                <div className='col-lg-6 '>
                                    <div className='form-group'>
                                        <div className='row align-items-left'>
                                            <div className='col-lg-6 mt-2'>
                                                <label className='d-block text-right' style={{ fontSize: '14px', fontWeight: '600' }}>Session Page Goals:</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <select className='form-control' style={{ width: '100px' }} value={pagegoal}
                                                    onChange={(e) => setpagegoal(e.target.value)}>
                                                    <option value="0">0</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                    <option value="8">8</option>
                                                    <option value="9">9</option>
                                                    <option value="10">10</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr></hr>

                                <div className='col-lg-8 mb-2 ml-3'>
                                    <div className='form-group'>
                                        <div className='row align-items-right'>
                                            <div className='mt-2' style={{ marginLeft: '16px' }}>
                                                <ReactSwitch checked={checked1} onChange={handleChange1} />
                                            </div>
                                            <div className='col-lg-6 mt-2 mr-5'>
                                                <label className='selectlabel mt-1' style={{ fontSize: '16px', fontWeight: '500' }}>Students Have Schoolwork Today</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {checked1 === true && (
                                    <div className='ml-4 mb-2' style={{ border: '1px solid #c3c3c3', width: '95%', borderRadius: '5px' }}>

                                        <div className='d-flex mt-2'>

                                            <div className='col-lg-6 '>
                                                <div className='form-group'>
                                                    <div className='row align-items-left'>
                                                        <div className='col-lg-6'>
                                                            <label className='d-block text-right mt-1'>Start SchoolWork After </label>
                                                        </div>
                                                        <div className='col-lg-6'>
                                                            <select className='form-control' style={{ width: '100px' }} value={schoolworkafter}
                                                                onChange={(e) => setschoolworkafter(e.target.value)}>
                                                                <option value="0">0</option>
                                                                <option value="5">5</option>
                                                                <option value="10">10</option>
                                                                <option value="15">15</option>
                                                                <option value="20">20</option>
                                                                <option value="25">25</option>
                                                                <option value="30">30</option>
                                                                <option value="35">35</option>
                                                                <option value="40">40</option>
                                                                <option value="45">45</option>
                                                                <option value="50">50</option>
                                                                <option value="55">55</option>
                                                                <option value="60">60</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 '>
                                                <div className='form-group'>
                                                    <div className='row align-items-left'>
                                                        <div className='col-lg-6'>
                                                            <label className='d-block text-right mt-1'>SchoolWork Planned Duration</label>
                                                        </div>
                                                        <div className='col-lg-4'>
                                                            <select className='form-control' style={{ width: '100px' }} value={schoolworkplanned}
                                                                onChange={(e) => setschoolworkplanned(e.target.value)} disabled={timerActive}
                                                            >
                                                                <option value="0">0</option>
                                                                <option value="15">15</option>
                                                                <option value="30">30</option>
                                                                <option value="45">45</option>
                                                                <option value="60">60</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='col-lg-12 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <button className='formBtn' onClick={startTimer} style={{ borderRadius: '10px', marginLeft: '85px' }} disabled={timerActive}>
                                                        Start SchoolWork
                                                    </button>
                                                    <span className='d-block text-right mt-2' style={{ marginLeft: '12.7rem', fontSize: '14px' }}>
                                                        Time Spend on SchoolWork : <b style={{ fontSize: '15px' }}>{formatTime(schoolworkplanned * 60 - duration)}</b> Minutes
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <div className='col-lg-12'>
        <span className='d-block text-right mt-2' style={{ marginLeft: '12.7rem', fontSize: '14px' }}>
          Countdown Timer: <b style={{ fontSize: '15px' }}>{formatTime(duration)}</b>
        </span>
      </div> */}

                                        <div className='d-flex mt-4'>
                                            <div className='col-lg-4 '>
                                                <div className='form-group'>
                                                    <div className='row align-items-left'>
                                                        <div style={{ marginLeft: '60px' }}>
                                                        <input type='checkbox' checked={isSchoolworkCompleted} onChange={(e) => setIsSchoolworkCompleted(e.target.checked)} />                                                        </div>
                                                        <div className='col-lg-8'>
                                                            <label className='d-block'>Schoolwork Completed</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-4 '>
                                                <div className='form-group'>
                                                    <div className='row align-items-left'>
                                                        <div style={{ marginLeft: '60px' }}>
                                                        <input type='checkbox' checked={isSchoolworkChecked} onChange={(e) => setIsSchoolworkChecked(e.target.checked)} />                                                        </div>
                                                        <div className='col-lg-8'>
                                                            <label className='d-block'>Schoolwork Checked</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 '>
                                                <div className='form-group'>
                                                    <div className='row align-items-right'>
                                                        <div style={{ marginLeft: '60px' }}>
                                                        <input className='mt-2' type='checkbox' checked={isConceptExplained} onChange={(e) => setIsConceptExplained(e.target.checked)} />                                                        </div>
                                                        <div className='col-lg-6 mt-1'>
                                                            <label className='d-block'>Concept Explained</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 
                                        <div className='col-lg-12'>
                                            <div className='form-group'>
                                                <label className='selectlabel'><b>Notes</b> :
                                                </label>
                                                <textarea type='text' className='form-control guidelinesnotes' placeholder='Notes For Concept Explained'
                                                ></textarea>
                                            </div>
                                        </div> */}

                                        <div className='col-lg-12 mt-4'>
                                            <div className='form-group'>
                                                <label className='selectlabel'><b>Additional Teacher Comment on SchoolWork</b> :
                                                </label>
                                                <textarea type='text' className='form-control' placeholder='Enter Additional Comments' value={schoolworkdesc}
                                                    onChange={(e) => setschoolworkdesc(e.target.value)}
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className='col-lg-12'>
                                            <div className='form-group'>
                                                <label className='selectlabel'><b>Notes</b> :
                                                </label>
                                                <textarea type='text' disabled className='form-control guidelinesnotes' placeholder='Notes For Students Have Schoolwork Today'
                                                ></textarea>
                                            </div>
                                        </div>
                                        <div className='col-lg-12 sessiondetails'>
                                            <button className='formBtn1' style={{ borderRadius: '6px', height: '38px' }} onClick={AddSessionDetails} >Ok </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                )}
                <hr></hr>

                <div className='col-lg-8 mt-4 ml-3'>
                    <div className='form-group'>
                        <div className='row align-items-right'>

                            <div className='col-lg-12'>
                                <div className='form-group d-flex align-item-center'>
                                    <ReactSwitch checked={checked} onChange={handleChange} />
                                    <label className='selectlabel ml-3 mt-1' style={{ fontSize: '16px', fontWeight: '500' }}>Learning Plan</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {checked === true && (
                    <Accordion defaultActiveKey="0" className='view-accordian'>
                        {/* {traininfo.map((item, index) => {
                        return ( */}
                        {lpList.map((item, index) => {
                            return (
                                <Accordion.Item eventKey={parseInt(index)} id={parseInt(index)}>
                                    <Accordion.Header style={{ backgroundColor: '#abe2fa', padding: '5px 10px 1px 10px', display: 'flex', alignItems: 'center' }} className='mt-2'><i className="fas fa-chalkboard-teacher"></i> <span className='ml-2'>{item?.test_name}</span></Accordion.Header>
                                    <Accordion.Body>
                                        <div className='mt-2 ml-4' style={{ border: '1px solid #c3c3c3', width: '95%' }}>
                                            {item?.learning_path_topic_details_list && item?.learning_path_topic_details_list.length > 0 ?
                                                <div className='table-responsive'>
                                                    <table className='custom-table'>
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: '30px' }}>Topic No.</th>
                                                                <th>Topic Name</th>
                                                                <th>Start Page No.</th>
                                                                <th>End Page No.</th>
                                                                <th>Remarks</th>
                                                                <th>Completed</th>
                                                                <th>Mastered</th>
                                                                <th>Documents</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {item?.learning_path_topic_details_list && item?.learning_path_topic_details_list.map((data, i) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td style={{ width: '30px' }}>{data?.topic_id}</td>
                                                                        <td>{data?.topic_name}</td>
                                                                        <td ><input type='number' style={{ width: '80px' }} value={data.start_page_no} onChange={(e) => SubmitResult(data?.learning_path_id, data?.topic_id, e.target.value, 'start_page_no', i, index)} /></td>
                                                                        <td><input type='number' style={{ width: '80px' }} value={data.end_page_no} onChange={(e) => SubmitResult(data?.learning_path_id, data?.topic_id, e.target.value, 'end_page_no', i, index)} /></td>
                                                                        <td><input type='text' style={{ width: '200px' }} value={data.remarks} onChange={(e) => SubmitResult(data?.learning_path_id, data?.topic_id, e.target.value, 'remarks', i, index)} /></td>
                                                                        <td><input type='checkbox' checked={data?.is_completed == 1 ? true : false} onChange={(e) => CheckboxHandle(data?.learning_path_id, data?.topic_id, e, 'is_completed', i, index)} /></td>
                                                                        <td><input type='checkbox' checked={data?.is_mastered == 1 ? true : false} onChange={(e) => CheckboxHandle(data?.learning_path_id, data?.topic_id, e, 'is_mastered', i, index)} /></td>
                                                                        <td>
                                                                            <button className="mr-2 text-info">Teacher' File</button>
                                                                            <button className="mr-2 text-info">Student's File</button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>

                                                    </table>

                                                </div>
                                                :
                                                <h6>No data found</h6>
                                            }
                                            <div className='col-lg-12 d-flex'>
                                                <div className='form-group'>
                                                    <div className='row align-items-left'>
                                                        <div className='col-lg-8'>
                                                            <label className='d-block mt-3 ml-5'>Todays Learning Score</label>
                                                        </div>
                                                        <div className='col-lg-4 mt-2'>
                                                            <select className='form-control' style={{ width: '100px' }} value={learningscore}
                                                                onChange={(e) => { setlearningscore(e.target.value) }}>
                                                                <option value="0">0</option>
                                                                <option value="1">1</option>
                                                                <option value="2">2</option>
                                                                <option value="3">3</option>
                                                                <option value="4">4</option>
                                                                <option value="5">5</option>
                                                                <option value="6">6</option>
                                                                <option value="7">7</option>
                                                                <option value="8">8</option>
                                                                <option value="9">9</option>
                                                                <option value="10">10</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className='button-part mt-2 ml-5'>
                                                    <ul style={{ float: 'right' }}>
                                                        <li>
                                                            <NavLink className='formBtn1' onClick={SubmitResult} >Save </NavLink>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        }
                        )}
                    </Accordion>
                )}

                <hr></hr>

                <div className='col-lg-8 mb-2 ml-3'>
                    <div className='form-group'>
                        <div className='row align-items-right'>
                            <div className='mt-2' style={{ marginLeft: '16px' }}>
                                <ReactSwitch checked={checked4} onChange={handleChange4} />
                            </div>
                            <div className='col-lg-6 mt-2 mr-2'>
                                <label className='selectlabel ' style={{ fontSize: '16px', fontWeight: '500' }}>Session Wrap-Up </label>
                            </div>
                        </div>
                    </div>
                </div>

                {checked4 === true && (

                    <div className='view-page mt-2'>

                        <div className='ml-4 mt-2' style={{ border: '1px solid #c3c3c3', width: '95%', borderRadius: '5px' }}>

                            <div className='col-lg-8 mb-2 ml-3'>
                                <div className='form-group'>
                                    <div className='row align-items-right'>
                                        <div className='mt-2'>
                                            {/* <ReactSwitch checked={checked2} onChange={handleChange2} /> */}
                                            <input type='checkbox' className='form-control' />
                                        </div>
                                        <div className='col-lg-10 mr-2' style={{ marginTop: '13px' }}>
                                            <label className='selectlabel mt-1' style={{ fontSize: '16px', fontWeight: '500' }}>Students Worked on Assessment Today</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* {checked2 === true && ( */}
                            <div>
                                <div className='row mt-2'>
                                    <div className="col-lg-8">
                                        <div className="form-group">
                                            <div className="row align-items-left">
                                                <div className="col-lg-6 mt-2">
                                                    <label
                                                        className="d-block text-right"
                                                        style={{ fontSize: '16px', fontWeight: '500' }}
                                                    >
                                                        Internet Connectivity Rating:
                                                    </label>
                                                </div>
                                                <div className="col-lg-6">
                                                    <select
                                                        className="form-control"
                                                        style={{ width: '100px' }}
                                                        value={internetconnectivity}
                                                        onChange={(e) => setinternetconnectivity(e.target.value)}
                                                        onKeyPress={handleKeyPress}
                                                    >
                                                        <option value="Great">Great</option>
                                                        <option value="Poor">Poor</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='d-flex mt-2'>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Putting in Effort Score </label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' value={effort}
                                                            onChange={(e) => { seteffort(e.target.value) }}
                                                            onKeyPress={handleKeyPress} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Mathitude Score </label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' value={mathitude}
                                                            onChange={(e) => { setmathitude(e.target.value) }}
                                                            onKeyPress={handleKeyPress} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex mt-2'>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Focus And Accuracy Score </label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' value={accuracy}
                                                            onChange={(e) => { setaccuracy(e.target.value) }}
                                                            onKeyPress={handleKeyPress} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Respectfulness Score </label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' value={respectfulness}
                                                            onChange={(e) => { setrespectfulness(e.target.value) }}
                                                            onKeyPress={handleKeyPress} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex mt-2'>
                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Confidence And Speed Score </label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' value={confidence}
                                                            onChange={(e) => { setconfidence(e.target.value) }}
                                                            onKeyPress={handleKeyPress} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Curriculum Difficulty Level </label>
                                                    </div>
                                                    <div className='col-lg-2'>
                                                        <select className='form-control' style={{ width: '100px' }} value={difficulty}
                                                            onChange={(e) => { setdifficulty(e.target.value) }}
                                                            onKeyPress={handleKeyPress} >
                                                            <option value="" disabled>Select Level</option>
                                                            <option value="1">Standard</option>
                                                            <option value="2">Medium</option>
                                                            <option value="3">Challenging</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='col-lg-2'>
                                        <button className='formBtn1' style={{ marginLeft: '-100px', borderRadius: '6px', height: '38px' }} onClick={AddSessionRating} >Ok </button>
                                    </div> */}
                                    </div>
                                </div>
                                {/* )} */}

                                <div className='ml-4 mb-2' style={{ border: '1px solid #c3c3c3', width: '95%', borderRadius: '5px', fontSize: '10px' }}>

                                    <div className='col-lg-12 mt-2 mb-2'>
                                        <div className=''>
                                            <span className='selectlabel'>Score Scale Above : less then 5 = needs work</span><br></br>
                                            <span className='selectlabel' style={{ marginLeft: '125px' }}>Greater then 5 - less then 7 = Meets Expectations</span><br></br>
                                            <span className='selectlabel' style={{ marginLeft: '125px' }}>Greater then 7 = Impressive</span>
                                            {/* <NavLink className='selectlabel' style={{ marginLeft: '10px', fontWeight: '500' }}>History</NavLink> */}
                                        </div>
                                    </div>
                                </div>

                                <div className='ml-4 mb-2' style={{ border: '1px solid #c3c3c3', width: '95%', borderRadius: '5px', fontSize: '10px' }}>

                                    <div className='d-flex mt-2'>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>No. of Pages Completed </label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' className='form-control' value={pagescompleted}
                                                            onChange={(e) => { setpagescompleted(e.target.value) }} placeholder='No. of Pages Completed' />                                                  </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Coin Redeemed in Session</label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' className='form-control' value={coinredeemed}
                                                            onChange={(e) => { setcoinredeemed(e.target.value) }} placeholder='Coins Redeemed in Session' />                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex'>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Coin Earned in Session </label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' className='form-control' value={coinearned}
                                                            onChange={(e) => { setcoinearned(e.target.value) }} placeholder='Coin Earned in Session' />                                                </div>
                                                    {/* <div className='col-lg-1'>
                                                    <i className="fa-solid fa-pen-to-square"></i>
                                                </div> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Remaining Coins</label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' className='form-control' value={remainingcoin}
                                                            onChange={(e) => { setremainingcoin(e.target.value) }} placeholder='Remaining Coins' />                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex'>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Total Coin as of Today</label>                                                        </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' className='form-control' value={coinasoftoday}
                                                            onChange={(e) => { setcoinasoftoday(e.target.value) }} placeholder='Total Coins as of Today' />                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Remaining Money Value</label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' className='form-control' value={remainingmoneyvalue}
                                                            onChange={(e) => { setremainingmoneyvalue(e.target.value) }} placeholder='Total Money as of Today' />                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className='d-flex'>

                                        <div className='col-lg-6 '>
                                            <div className='form-group'>
                                                <div className='row align-items-left'>
                                                    <div className='col-lg-6'>
                                                        <label className='d-block text-right mt-1'>Total Money as of Today</label>
                                                    </div>
                                                    <div className='col-lg-6'>
                                                        <input type='number' className='form-control' value={moneyasoftoday}
                                                            onChange={(e) => { setmoneyasoftoday(e.target.value) }} placeholder='Total Money as of Today' />                                                </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-lg-6'>
                                            <button className='formBtn1' style={{ borderRadius: '6px', height: '38px' }} onClick={AddDigitalReward} >Ok </button>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>

                )}

                <hr></hr>

                <div className='col-lg-12 mt-3'>
                    <div className='form-group'>
                        <label className='d-block'>Notes for this Session :
                        </label>
                        <textarea type='text' className='form-control' placeholder='Students Notes' value={notesforstd}
                            onChange={(e) => { setnotesforstd(e.target.value) }} ></textarea>
                    </div>
                </div>

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='d-block'>Notes for Center Director <span style={{ fontSize: '12px' }}>(Confidential Not for Parents)</span> :
                        </label>
                        <textarea type='text' className='form-control' placeholder='' value={notesforcd}
                            onChange={(e) => { setnotesforcd(e.target.value) }} ></textarea>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default Digitalworkout