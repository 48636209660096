import React, { useState } from 'react'
import { NavLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import Swal from "sweetalert2";
import Loader from '../utils/Loader';
import { useSelector, useDispatch } from 'react-redux'
import { CreatePasswordRequest } from '../redux/reducer/FetchAdminDataReducer';

const CreatePassword = () => {

    const [password, setPassword] = useState('');
    const [confirmpassword, setconfirmpassword] = useState('');
    const fetchReducer = useSelector((state) => state.FetchAdminDataReducer)

    console.log(fetchReducer)

    const dispatch = useDispatch()
      let navigate = useNavigate();

    let {id, base} = useParams()

    console.log(base, id)

    const LoginHandler = () =>{

        if(password == ''){
            Swal.fire({
                icon: "error",
                text: 'Password should be mandatory',
              });
        } if(password.length < 8){
            Swal.fire({
                icon: "error",
                text: 'Field has at least 8 characters',
              });
        } else if(password !== confirmpassword){
            Swal.fire({
                icon: "error",
                text: 'Password does not match',
              });
        } else {
            let obj = {
                "password":password,
                "password2": confirmpassword,
                "token": id,
                "uidb64": base,
             
             }

             dispatch(CreatePasswordRequest({obj:obj, navigate:navigate}))

        }   
        
    }



  return (
    <div className='login-layout'>
<Loader visible={fetchReducer.isloading}  />
    <div className='login-box'>
       
        <div className='form-group'>
            <label>New Password</label>
            <input type='password'
            value={password}
            onChange={(e)=>setPassword(e.target.value)}
            className='form-control' placeholder='******'  />
        </div>
        <div className='form-group mb-5'>
            <label>Re-Enter New Password</label>
            <input type='password'
            value={confirmpassword}
            onChange={(e)=>setconfirmpassword(e.target.value)}
            className='form-control' placeholder='******'  />
        </div>
        <div className='form-group'>
            <ul className='bottom-navigation'>
                <li> <NavLink to="/">Back to Login</NavLink></li>
                <li>  <button className='theme-button' onClick={LoginHandler}>Save</button></li>
            </ul>
            
          
        </div>
    </div>
</div>
  )
}

export default CreatePassword