import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { FetchTrainingRequest, DeleteTrainingRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const TrainingDetails = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var editdata = fetchacompanyReducer.FetchTrainingResponse?.results[0]

    console.log(fetchacompanyReducer.FetchTrainingResponse)
    console.log(editdata)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id } = useParams()

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchTrainingRequest(obj))
    }, [])


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Training Management</h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '920px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='view-page'>
                    <div className='form-group'>
                        <h6>Training Details</h6>
                    </div>
                    <div className='form-group'>
                        <hr />
                    </div>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Training Name:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.training_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Training Duration:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.training_duration}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Training Description:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.training_description}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Training Video Link:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.training_video_link}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Training Youtube Link:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.training_youtube_link}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Exam Id:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.exam_id}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Exam URL:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.exam_url}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Pass Marks:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.pass_mark}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Total Marks:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{editdata?.total_mark}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Uploaded Document:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        {editdata?.file_url ? (
                                            <b><a href={editdata.file_url} target='_blank'>Download</a></b>
                                        ) : (
                                            <b>N/A</b>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

            </div>

        </div>
    )
}

export default TrainingDetails