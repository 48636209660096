import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, GetEmployeeRequest, GetStudentListRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer, Views, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../utils/ApiConnection'
import ReactSwitch from 'react-switch';


const Scheduled_Documents = () => {
    const dispatch = useDispatch()
    const { id, pros } = useParams()
    console.log(id)

    const [search, setsearch] = useState("")
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [AvilScheduleList, setAvilScheduleList] = useState([])
    const [ScheduleDay, setScheduleDay] = useState(null)

    const [remainderdate1, setremainderdate1] = useState(null)
    const [communicationtype1, setcommunicationtype1] = useState("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const [FilterResult, setFilterResult] = useState([])

    let navigate = useNavigate()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var StudentActivityList = fetchacompanyReducer?.GetStudentActivityResponse?.results
    var StudentActivityView = fetchacompanyReducer?.FetchStudentActivityResponse?.results[0]
    console.log(fetchacompanyReducer.GetStudentActivityResponse)
    console.log("StudentId", id)

    const GetDocumentList = async () => {

        try {
            const response = await ApiConnection.get(`document_crud/?server_type=production&student_id=${id}`)
            // const response = await ApiConnection.get(`stripe-payment-list/`)
            if (response?.status == 200) {
                console.log("get DocumentList", response.data.results)
                setAvilScheduleList(response?.data?.results)
            }
        } catch (e) { }
    }

    useEffect(() => {
        GetDocumentList();
    }, [])


    return (
        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Documents </h3>
                </div>

                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th>Document Name</th>
                                <th>Created By</th>
                                <th>Last Modified</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {AvilScheduleList && AvilScheduleList.length > 0 ?

                            <tbody>
                                {AvilScheduleList && AvilScheduleList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.document_name}</td>
                                            <td>{item?.created_at_date_only}</td>
                                            <td>{item?.last_modified_date_only}</td>
                                            <td><a href={item?.file_url} target='_blank'><i className="fa-solid fa-eye"></i></a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            :
                            <td colSpan="4" className='text-center'>No data found</td>
                        }
                    </table>

                </div>


            </div>
        </div>
    )
}

export default Scheduled_Documents