import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetProspectiveRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const ComapnyDashboard = () => {

  const dispatch = useDispatch()
  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)

  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading}  />
        <div className='innerlayout p-3'>
               Company Home Page
        </div>
    </div>
  )
}

export default ComapnyDashboard