import { configureStore } from '@reduxjs/toolkit';
import  sidebarSlice  from './reducer/SidebarSlice';
import FetchAdminDataReducer from './reducer/FetchAdminDataReducer';
import createSagaMiddleware from 'redux-saga';
import RootSaga from './saga/RootSaga';
import FetchCompanyDataReducer from './reducer/FetchCompanyDataReducer';

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import  LocationSlice  from './reducer/LocationHandle';
import FetchStudentDataReducer from './reducer/FetchStudentDataReducer';

let sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const rootReducer = combineReducers({ 
//   FetchAdminDataReducer: FetchAdminDataReducer,
//   FetchCompanyDataReducer:FetchCompanyDataReducer,
//   LocationSlice: LocationSlice,
//   sidebarSlice:sidebarSlice
// })

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const Store = configureStore({
//   reducer: persistedReducer,
//   middleware
// })



//export const persistor = persistStore(Store)

export const Store = configureStore({

    reducer: {
      FetchAdminDataReducer: FetchAdminDataReducer,
      FetchCompanyDataReducer:FetchCompanyDataReducer,
      LocationSlice: LocationSlice,
      sidebarSlice:sidebarSlice,
      FetchStudentDataReducer:FetchStudentDataReducer,
    },
    middleware,
  });

  sagaMiddleware.run(RootSaga)