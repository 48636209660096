import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import { GetStudentAssesmentRequest, SubmitExamRequest, TakeexamRequest } from '../redux/reducer/FetchStudentDataReducer'
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'bootstrap';
import moment from "moment";
import ApiConnection from '../utils/ApiConnection'

const DocumentsList = () => {
    const [show, setShow] = useState(false);
    const [questionList, setQuestionList] = useState([])
    const [Index, setIndex] = useState(0)
    const [Newarry, setNewarry] = useState([])
    const [ChooseAns, setChooseAns] = useState("")
    const [testId, settestId] = useState("")
    const [duration, setduration] = useState(0);
    const [DocumentList, setDocumentList] = useState([])
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const handleClose = () => setShow(false);
    const handleShow1 = () => setShow1(true);

    const fetchstudentReducer = useSelector((state) => state.FetchStudentDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    const dispatch = useDispatch()
    const { id } = useParams()
    let navigate = useNavigate()

    useEffect(() => {
        dispatch(GetStudentAssesmentRequest())
    }, [])

    const GetDocumentList = async () => {
        let obj = {
            'employee_id': '',
            'student_id': userinfo?.user_id,
        }

        try {
            const response = await ApiConnection.get(`document_crud/?server_type=production&student_id=${userinfo?.user_id}`)
            // const response = await ApiConnection.get(`stripe-payment-list/`)
            if (response?.status == 200) {
                console.log("get DocumentList", response.data.results)
                setDocumentList(response?.data?.results)
            }
        } catch (e) { }
    }

    useEffect(() => {
        GetDocumentList();
    }, [])


    return (
        <div className='common-layout'>
            <Loader visible={fetchstudentReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Document List </h3>
                </div>

                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr className='text-center'>
                                <th>Document Name</th>
                                <th>Created By</th>
                                <th>Last Modified</th>
                                <th>Download</th>
                            </tr>
                        </thead>
                        {DocumentList && DocumentList.length > 0 ?

                            <tbody>
                                {DocumentList && DocumentList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.document_name}</td>
                                            <td>{item?.created_at_date_only}</td>
                                            <td>{item?.last_modified_date_only}</td>
                                            <td><a href={item?.file_url} target='_blank'><i className="fa-solid fa-eye"></i></a></td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                            :
                            <td colSpan="4" className='text-center'>No data found</td>
                        }
                    </table>
                </div>


            </div>
        </div>
    )
}

export default DocumentsList