import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isSidebarshow:false
  };

  export const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        sidebarAction: (state) => {
        state.isSidebarshow = !state.isSidebarshow;
    },
}
  })

  export const { sidebarAction } = sidebarSlice.actions

  export default sidebarSlice.reducer;