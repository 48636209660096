import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, NavLink, useLocation } from 'react-router-dom'
import { GetBackgroundRequest, DeleteBccRequest, FetchlocationRequest, GetlocationsRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import moment from "moment";

const ViewLocation = () => {
    const { id, pros } = useParams()
    const location = useLocation()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var viewdata = fetchacompanyReducer?.FetchlocationResponse?.results[0]

    console.log(fetchacompanyReducer.FetchlocationResponse)


    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        let obj = {
            id: id
        }
        dispatch(FetchlocationRequest(obj))
    }, [])


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Location Management :</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='view-page'>
                    <div className='form-group'>
                        <h6>Location Details :</h6>
                    </div>
                    <div className='form-group'>
                        <hr />
                    </div>
                    <div className='row'>
                    <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Location Name :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.location_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Location Email :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.email}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Location Phone No. :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.phone}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Opening Date :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                    <b>{moment(viewdata?.opening_date).format('DD-MM-YYYY')}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Status :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.status}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>URL :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.url}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Address 1 :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.street_address_1}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Address 2 :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.street_address_2}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Country :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.country_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>State :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.state_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>City :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.city_name}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Zipcode :</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{viewdata?.zipcode}</b>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}
export default ViewLocation