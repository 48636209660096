import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { DeletestudentRequest, GetGradeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../utils/ApiConnection'


const PendingRegistration = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var gradeList = fetchacompanyReducer?.GetGradeResponse?.results

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const GetPurchaseListService = async () => {
        try {
            const response = await ApiConnection.get(`purchase_reg_crud/?server_type=production`)
            if (response?.status == 200) {
            }
        } catch (e) { }
    }

    const GetPricing = async () => {
        try {
            const response = await ApiConnection.get(`pricing_crud/?server_type=production`)
            if (response?.status == 200) {
            }
        } catch (e) { }
    }  

    const GetStudent = async () => {
        try {
            const response = await ApiConnection.get(`student-crud/?server_type=production`)
            if (response?.status == 200) {
            }
        } catch (e) { }
    }


    useEffect(() => {
        GetPricing()
        GetPurchaseListService()
    }, [])

    const columns = [
        {
            name: 'Student Name',
            selector: row => row.student_name,
            sortable: true,
        },
        {
            name: 'Type',
            selector: row => row.subject_name,
            sortable: true,
        },
        {
          name: 'Status',
          selector: row => row.sublevel,
          sortable: true,
        },
      {
        name: 'Left Off On',
        selector: row => row.sessionpermnth,
        sortable: true,
    },
    {
        name: 'Open Date',
        selector: row => row.startdate,
        sortable: true,
    },
    {
        name: 'Close Date',
        selector: row => row.enddate,
        sortable: true,
    },
    {
        name: 'Todays Total',
        selector: row => row.recurring,
        sortable: true,
    },
    {
        name: 'Action',
        selector: (row) => (
            <>
            <div className='d-flex'>
            <NavLink className='formBtn studentassignbtn mr-1'><i className="fa-solid fa-pen-to-square"></i></NavLink>
            <NavLink className='formBtn studentassignbtn'>Launch</NavLink>
            </div>
            </>
          ),
    },
    {
        name: 'Link',
        selector: (row) => (
            <>
            <button className='formBtn1 studentassignbtn mr-2'>Link</button>
            </>
          ),
    },
    {
        name: 'Print',
        selector: (row) => (
            <>
            <button className='formBtn1 studentassignbtn'>Print</button>
            </>
          ),
    },
    {
        name: 'Location',
        selector: row => row.reg_fee,
        sortable: true,
    },
    ];

    useEffect(()=>{
        dispatch(GetGradeRequest())
    },[])

    useEffect(()=>{
      const result = gradeList && gradeList.filter(data =>{
        return data.grade_name.toLowerCase().match(search.toLowerCase())
      })
      setFilterResult(result)
    },[search])

    useEffect(()=>{

      setFilterResult(gradeList)
    },[fetchacompanyReducer?.GetGradeResponse])

    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Pending Registration List</h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-68px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <a id="Download" download></a>

     <div className='row form-style mb-4 align-items-center'>
          <div className='col-lg-3'>
              <div className='form-group'>
                  <label>Location</label>
                  <input type='text' className='form-control' placeholder='Search..'
                  />
              </div>
          </div>

     <div className='col-lg-3'>
              <div className='form-group'>
                  <label>Status</label>
                 <select className='form-control'  >
                 <option value="">All</option>
                 <option value="">New and Inprogress</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  
                 </select>
              </div>
          </div>
          <div className='col-lg-4'>
              <div className='form-group'>
                  <label className='d-block'>&nbsp;</label>
                  <div className='d-flex'>
                  <button className='btn btn-primary mr-3'>Search</button>
              </div>
              </div>
          </div>
      </div>
       
      <div className='table-responsive'>
          <DataTable
            columns={columns}
            data={FilterResult}
            pagination
            highlightOnHover
        />
      {/* <span style={{fontSize: '12px'}}>* Prices shown are card Prices. E-check payers get a discount.</span> */}
      </div>
            </div>

        </div>

        
    )
}

export default PendingRegistration