import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { DeletestudentRequest, ViewstudentRequest, GetAssignEmployeelocationRequest, GetStudentListRequest, GetSubjectRequest, GetTeacherRequest, AddServiceSubjectRequest, AddServicesRequest, AddPricingRequest, AddStaffRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../utils/ApiConnection'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'

const MembershipList = ({ id }) => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [show, setShow] = useState(false);
    const [location1, setlocation1] = useState("")
    const [startupfee, setstartupfee] = useState("")
    const [Assessmentfee, setAssessmentfee] = useState("")
    const [Cancellationfee, setCancellationfee] = useState("")
    const [Latefee, setLatefee] = useState("")
    const [text, settext] = useState("")
    const [subjectList, setsubjectList] = useState([])






    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var locationList = fetchacompanyReducer?.GetAssignEmployeelocationResponse?.results

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const [traininfo, settraininfo] = useState([{
        label: '',
        assigndate: null,
        duedate: null,
        completedate: null,
        isdisabled: false
    }]);

    useEffect(() => {

        let obj = {
            id: id
        }
        dispatch(GetAssignEmployeelocationRequest(id))

    }, [])

    const GetSubject = async () => {

        try {
            const response = await ApiConnection.get(`membership-setup-crud/?server_type=production`)
            if (response?.status == 200) {
                setsubjectList(response?.data?.results)
            }
        } catch (e) { }
    }

    const ServiceTermsDeleteHandle = async (id, Delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
            const response = await ApiConnection.put(`membership-setup-crud/?server_type=production&id=${id}&method=delete`)
            if (response?.status == 200) {
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                )
                GetSubject()
                //   setShow5(false)
            }
        }
    })
        } catch (e) { }
    }

    useEffect(() => {
        GetSubject()
    }, [])


    const editorConfiguration = {
        toolbar: ['bold', 'italic', 'heading', 'fontFamily', 'fontColor', 'fontBackgroundColor', 'highlight', 'fontSize', 'blockQuote', '|', 'bulletedList', 'numberedList', '|', 'imageUpload', 'insertTable', 'mediaEmbed', 'undo', 'redo']
    };

    const EditorHandle = (value, e, index) => {

        settext(e.target.value);
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Membership List </h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-68px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='col-lg-3 mb-2'>
                    <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/AddMembership`}><i className="fa-solid fa-plus"></i> Add Membership</NavLink>
                </div>

                {subjectList && subjectList.length > 0 ?
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Location Name</th>
                                    <th>Startup Fee</th>
                                    <th>Assessment Fee</th>
                                    <th>A/C Closing Fee</th>
                                    <th>Late Fee</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subjectList && subjectList.map((item, i) => {
                                    return (
                                        <tr>
                                            <td>{item?.location_name}</td>
                                            <td>{item?.startup_fee}</td>
                                            <td>{item?.assessment_fee}</td>
                                            <td>{item?.cancelation_fee}</td>
                                            <td>{item?.late_fee}</td>
                                            <td>
                                                {/* <NavLink to={``} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink> */}
                                                <NavLink to={`/${userinfo?.company_slug}/EditMembership/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                                <button onClick={() => ServiceTermsDeleteHandle(item?.id)}><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>

                        </table>
                    </div>
                    :
                    <h6>No data found</h6>
                }


            </div>
        </div>
    )
}

export default MembershipList