import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetModuleTypeRequest, DeleteModuleTypeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';

const ViewSchedule = () => {

    const [search, setsearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [teacherSub, setteacherSub] = useState([])
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var scheduleList = fetchacompanyReducer?.GetModuleTypeResponse?.results

    console.log(fetchacompanyReducer.GetModuleTypeResponse)

    const dispatch = useDispatch()

    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteModuleTypeRequest(id))

                if (fetchacompanyReducer?.DeleteModuleTypeResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }





    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Schedule Management</h3>
                </div>

                <div className='row form-style mb-4 mr-2 float-right'>
                    <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Test/Addschedule`}><i className="fa-solid fa-plus"></i> Add Schedule</NavLink>
                </div>

                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th>Schedule Name</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {teacherSub.length > 0 ? (
                            <tbody>
                                {teacherSub && teacherSub.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <button><i className="fa-solid fa-pen-to-square"></i></button>
                                                <button><i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        ) : (
                            <td colSpan="4" className='text-center'>No data found</td>
                        )}
                    </table>
                </div>
            </div>

        </div>
    )
}

export default ViewSchedule