import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetTeacherTrainingRequest, DeleteTeacherTrainingRequest, UpdateTeacherTrainingRequest, AddTeacherTrainingRequest, GetTrainingRequest, FetchTeacherTrainingRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const EmployeeTraining = ({id, etype}) => {

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [assigndate, setassigndate] = useState(null)
  const [assigndate1, setassigndate1] = useState(null)
  const [editrowId, seteditrowId] = useState(null)
  const [trainingmodule, settrainingmodule] = useState("")
  const [trainingmodule1, settrainingmodule1] = useState("")
  const [duedate, setduedate] = useState(null)
  const [datecompleted, setdatecompleted] = useState(null) 
  const [duedate1, setduedate1] = useState(null)
  const [datecompleted1, setdatecompleted1] = useState(null)

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const [FilterResult, setFilterResult] = useState([])

let navigate = useNavigate()


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var emptrainingList = fetchacompanyReducer?.GetTrainingResponse?.results
  var empteachertrainingList = fetchacompanyReducer?.GetTeacherTrainingResponse?.results
  var empteachertrainingView = fetchacompanyReducer?.FetchTeacherTrainingResponse?.results[0]

  
  console.log(fetchacompanyReducer?.FetchTeacherTrainingResponse)
  console.log("employee List",empteachertrainingList)
  console.log("employee id", id)

  const dispatch = useDispatch()

  useEffect(()=>{
      let obj ={
          id:id
      }
      dispatch(FetchTeacherTrainingRequest(obj))
  },[])
  
  useEffect(()=>{
    var editinfo = fetchacompanyReducer?.FetchTeacherTrainingResponse?.results[0]
    console.log(editinfo)
      settrainingmodule1(editinfo?.training_id)
      setduedate1(new Date(editinfo?.due_date == undefined? new Date(): new Date(editinfo?.due_date)))
      setdatecompleted1(new Date(editinfo?.completion_date == undefined? new Date(): new Date(editinfo?.completion_date)))
      setassigndate1(new Date(editinfo?.start_date == undefined? new Date(): new Date(editinfo?.start_date)))  
  },[fetchacompanyReducer?.FetchTeacherTrainingResponse])


  const columns = [
    {
      name: 'Training Name',
      selector: row => row.training_name,
      sortable: true,
    },
    {
      name: 'Date Assigned',
      selector: row => moment(row.start_date).format("YYYY-MM-DD"),
      sortable: true,
    },
    {
      name: 'Date Due',
      selector: row => moment(row.due_date).format("YYYY-MM-DD"),
    },
    {
      name: 'Date Completed',
      selector: row => moment(row.completion_date).format("YYYY-MM-DD"),
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink to={`/${userinfo?.company_slug}/Training/TrainingDetails/${row?.training_id}`} className='mr-2'><i className="fa-solid fa-eye"></i></NavLink>
          <button className='table-btn' onClick={()=>handleShow1(row?.id)}><i className="fa-solid fa-pen-to-square"></i></button>
          <button onClick={() => DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
        </>
      ),
    },
  ];

  useEffect(() => {
    const result = empteachertrainingList && empteachertrainingList.filter(data => {
      return data?.training_name.toLowerCase().match(search.toLowerCase())
    })
    setFilterResult(result)
  }, [search])

  useEffect(() => {
    dispatch(GetTeacherTrainingRequest(id))
  }, [fetchacompanyReducer?.DeleteTeacherTrainingResponse])

  const handleShow1 = (tid) =>{  
    seteditrowId(tid)
    setShow1(true)
    dispatch(FetchTeacherTrainingRequest({rowId:tid, id:id}))
};

useEffect(()=>{
  setShow1(false)
  let obj ={
      id:id
  }
dispatch(GetTeacherTrainingRequest(id))
},[fetchacompanyReducer?.UpdateTeacherTrainingResponse])

  useEffect(() => {

    setFilterResult(empteachertrainingList)
  }, [fetchacompanyReducer?.GetTeacherTrainingResponse])

  useEffect(() => {
    dispatch(GetTrainingRequest())
}, [])

useEffect(() => {
  dispatch(GetTeacherTrainingRequest(id))
}, [])

useEffect(() => {
  setShow(false)
  dispatch(GetTeacherTrainingRequest(id))
}, [fetchacompanyReducer?.AddTeacherTrainingResponse])

  const DeleteHandle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteTeacherTrainingRequest(id))

        if (fetchacompanyReducer?.DeleteTeacherTrainingResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const AddHandle = ()=>{

    if (trainingmodule == '') {
        Swal.fire({
          icon: "error",
          text: 'Training Module should be must',
        });
      } else if(assigndate == '') {
        Swal.fire({
            icon: "error",
            text: 'Assign Date should be must',
          });
        } else if(duedate == '') {
          Swal.fire({
              icon: "error",
              text: 'Due Date should be must',
            });
          } else if(datecompleted == '') {
            Swal.fire({
                icon: "error",
                text: 'Completed Date should be must',
              });
      } else {

    let obj = {
        "employee_id": Number(id),
        "training_id": trainingmodule,
        "start_date": assigndate== null ? '' : moment(assigndate).format("YYYY-MM-DD") ,
        "due_date":duedate== null ? '' : moment(duedate).format("YYYY-MM-DD") ,
        "completion_date":datecompleted== null ? '' : moment(datecompleted).format("YYYY-MM-DD")
    }

    dispatch(AddTeacherTrainingRequest({ obj: obj, navigate: navigate }))
}
  }

  const UpdateHandle = ()=>{

    if (trainingmodule1 == '') {
        Swal.fire({
          icon: "error",
          text: 'training Module should be must',
        });
      } else if(assigndate1 == '') {
        Swal.fire({
            icon: "error",
            text: 'Assigned Date should be must',
          });
        } else if(duedate1 == '') {
          Swal.fire({
              icon: "error",
              text: 'Due Date should be must',
            });
          } else if(datecompleted1 == '') {
            Swal.fire({
                icon: "error",
                text: 'Completed Date should be must',
              });
      } else {

    let obj = {
      "employee_id": Number(id),
      "training_id": trainingmodule1,
      "start_date":moment(assigndate1).format("YYYY-MM-DD") ,
      "due_date":moment(duedate1).format("YYYY-MM-DD") ,
      "completion_date":moment(datecompleted1).format("YYYY-MM-DD")
    }
    dispatch(UpdateTeacherTrainingRequest({obj: obj,employee_id :id,rowId:editrowId}))
}
  }

  useEffect(()=>{
 
    setFilterResult(empteachertrainingList)
  },[fetchacompanyReducer?.GetTeacherTrainingResponse])



  return (

    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Training</h5>


      <div className='row form-style mt-3'>
        <div className='col-lg-3'>
          <div className='form-group m-0'>
            <input type='text' className='form-control' placeholder='Training Name'
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </div>
        </div>
        <div className='col-lg-3'>
        <button className='formBtn' onClick={handleShow}>Add Training</button>
        {/* <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Training/AddTraining`}><i className="fa-solid fa-plus"></i> Add Training</NavLink> */}
        </div>
      </div>

      <div className='table-responsive'>
            <DataTable
                columns={columns}
                data={FilterResult}
                pagination
                highlightOnHover
                noDataComponent=""
            />
            {FilterResult?.length === 0 && (
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>{column.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>There are no records to display</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </div>
    

      <Modal show={show} onHide={handleClose}  size="lg">
        <Modal.Header >
          <Modal.Title>Add Training Information</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
        <div className='row form-style mb-4'>
        <div className='col-lg-6'>
         <div className='form-group'>
         <label className='selectlabel'>Select Training Module</label>
            <select className='form-control'  value={trainingmodule}
             onChange={(e)=>settrainingmodule(e.target.value)}>
              <option value="">--Select Training Module---</option>
              {emptrainingList&&emptrainingList.map((item, i)=>{
                  return (
                      <option key={i} value={item?.id}>{item?.training_name}</option>
                  )
              })}
            </select>
         </div>
     </div>

     <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Select Date Assigned</label>
           <DatePicker className='form-control' selected={assigndate} onChange={(assigndate) => setassigndate(assigndate)} 
           placeholderText="Scheduled Date"
           />
           </div>
       </div>    

       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Select Due Date</label>
           <DatePicker selected={duedate} onChange={(duedate) => setduedate(duedate)} className='form-control'
           placeholderText="Due Date"
           />
           </div>
       </div> 

       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Select Date Completed</label>
           <DatePicker selected={datecompleted} onChange={(datecompleted) => setdatecompleted(datecompleted)} className='form-control'
           placeholderText="Date Completed"
           />
           </div>
       </div> 
  </div>
        </Modal.Body>
        <Modal.Footer>
           <button className='formBtn' onClick={AddHandle}>Add</button>
       
        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1}  size="lg">
        <Modal.Header >
          <Modal.Title>Edit Training Information</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
        <div className='row form-style mb-4'>
        <div className='col-lg-6'>
         <div className='form-group'>
         <label className='selectlabel'>Select Training Module</label>
            <select className='form-control'  value={trainingmodule1}
             onChange={(e)=>settrainingmodule1(e.target.value)}>
              <option value="">--Select Training Module---</option>
              {emptrainingList&&emptrainingList.map((item, i)=>{
                  return (
                      <option key={i} value={item?.id}>{item?.training_name}</option>
                  )
              })}
            </select>
         </div>
     </div>
     <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Select Date Assigned</label>
           <DatePicker selected={assigndate1} onChange={(assigndate1) => setassigndate1(assigndate1)} className='form-control'
           placeholderText="Date Assigned"
           />
           </div>
       </div>  

       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Select Due Date</label>
           <DatePicker selected={duedate1} onChange={(duedate1) => setduedate1(duedate1)} className='form-control'
           placeholderText="Due Date"
           />
           </div>
       </div> 

       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Select Date Completed</label>
           <DatePicker selected={datecompleted1} onChange={(datecompleted1) => setdatecompleted1(datecompleted1)} className='form-control'
           placeholderText="Date Completed"
           />
           </div>
       </div>   
  </div>
        </Modal.Body>
        <Modal.Footer>
           <button className='formBtn' onClick={UpdateHandle}>Update</button>
       
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default EmployeeTraining