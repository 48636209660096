import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, GetGradeRequest, GetSubjectRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer, Views, DateLocalizer } from 'react-big-calendar';
import moment, { months } from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import ApiConnection from '../../utils/ApiConnection'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';
import { isAfter } from 'date-fns';

const TeacherSchedule = () => {

    const localizer = momentLocalizer(moment)
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var studentteacherList = fetchacompanyReducer?.GetTeacherResponse?.results
    var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
    // var SubjectList = fetchacompanyReducer?.GetSubjectResponse?.results
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var emptrainingList = fetchacompanyReducer?.GetTrainingResponse?.results
    var empteachertrainingList = fetchacompanyReducer?.GetTeacherTrainingResponse?.results
    var empteachertrainingView = fetchacompanyReducer?.FetchTeacherTrainingResponse?.results[0]

    console.log(fetchacompanyReducer.GetGradeResponse)

    const { id } = useParams()
    const dispatch = useDispatch()
    let navigate = useNavigate()

    // const DayList = [
    //     {
    //         id: "1",
    //         dayname: "sunday",
    //     },
    //     {
    //         id: "2",
    //         dayname: "monday",
    //     },
    //     {
    //         id: "3",
    //         dayname: "tuesday",
    //     },
    //     {
    //         id: "4",
    //         dayname: "wednesday",
    //     },
    //     {
    //         id: "5",
    //         dayname: "thrusday",
    //     },
    //     {
    //         id: "6",
    //         dayname: "friday",
    //     },
    //     {
    //         id: "7",
    //         dayname: "saturday",
    //     },
    // ]

    // console.log(DayList);

    const events = [
        {
            title: "",
            start: "", // May 20, 2024, 10:00 AM
            end: "", // May 20, 2024, 12:00 PM
        },
        {
            title: "",
            start: "", // May 20, 2024, 10:00 AM
            end: "", // May 20, 2024, 12:00 PM
        },
    ];

    const [grade, setgrade] = useState("")
    const [teacher, setteacher] = useState("")

    const [subjectList, setsubjectList] = useState([])
    const [time, settime] = useState(null)
    const [subject, setsubject] = useState("")
    const [schedulestartDate, setschedulestartDate] = useState(null)
    const [scheduleendDate, setscheduleendDate] = useState(null)
    const [starttime, setstarttime] = useState(null)
    const [endtime, setendtime] = useState(null)
    const [remarks, setremarks] = useState("")
    const [month, setmonth] = useState("")
    const [year, setyear] = useState("")
    const [dayname, setdayname] = useState("")
    const [show1, setShow1] = useState(false);
    const [FilterResult, setFilterResult] = useState([])
    const [search, setsearch] = useState("")
    const [value, onChange] = useState('10:00');
    const [selectedsubject, setselectedsubject] = useState([]);
    const [AvilScheduleList, setAvilScheduleList] = useState([])
    const [ScheduleDay, setScheduleDay] = useState(null)

    // const isValidTimeRange = isAfter(new Date(endtime), new Date(starttime));

    const handleShow = () => setShow1(true);

    // const [myEvents, setEvents] = useState(events)
    const [calendarEvents, setCalendarEvents] = useState(events);

    const startDate = new Date(events.start);
    const endDate = new Date(events.end);

    const chunks = [];
    const chunkLength = events.title * 60 * 60 * 1000; // Convert hours to milliseconds

    let currentChunkStart = startDate;
    while (currentChunkStart < endDate) {
        const currentChunkEnd = new Date(currentChunkStart.getTime() + chunkLength);
        const chunk = {
            start: currentChunkStart,
            end: currentChunkEnd <= endDate ? currentChunkEnd : endDate,
        };
        chunks.push(chunk);
        currentChunkStart = currentChunkEnd;
        console.log(currentChunkStart, currentChunkEnd, chunk)
    }

    // if (!isValidTimeRange) {
    //     // Show an error message or handle the invalid time range
    //     alert('End time must be after start time');
    //   }

    const columns = [
        {
            name: 'Training Name',
            selector: row => row.training_name,
            sortable: true,
        },
        {
            name: 'Date Assigned',
            selector: row => row.start_date,
            sortable: true,
        },
        {
            name: 'Date Due',
            selector: row => row.due_date,
        },
        {
            name: 'Date Completed',
            selector: row => row.completion_date,
        },
        {
            name: 'Action',
            selector: (row) => (
                <>
                    <NavLink to={`/${userinfo?.company_slug}/Training/TrainingDetails/${row?.id}`} className='mr-2'><i className="fa-solid fa-eye"></i></NavLink>
                    <button className='table-btn'><i className="fa-solid fa-pen-to-square"></i></button>
                    <button className='table-btn'><i className="fa-solid fa-trash"></i></button>
                </>
            ),
        },
    ];

    // const handleSelectSlot = useCallback(
    //     ({ start, end }) => {
    //         const title = setShow1(true)

    //         const ScheduleStartTime = moment(startDate).format("hh:mm")
    //         setstarttime(ScheduleStartTime)
    //         console.log(ScheduleStartTime)

    //         const ScheduleEndTime = moment(end).format("hh:mm")
    //         setendtime(ScheduleEndTime)
    //         console.log(ScheduleEndTime)

    //         // const ScheduleStartDate = moment(start).format("YYYY-MM-DD")
    //         // setendtime(ScheduleStartDate)
    //         // console.log(ScheduleStartDate)

    //         // const ScheduleEndDate = moment(end).format("YYYY-MM-DD")
    //         // setendtime(ScheduleEndDate)
    //         // console.log(ScheduleEndDate)

    //         if (title) {
    //             setCalendarEvents((prev) => [...prev, { start, end, title }])
    //         }
    //     },
    //     [setCalendarEvents]
    // )

    useEffect(() => {
        GetSchedule();
        GetScheduledetails();
    }, [])



    const GetScheduledetails = async () => {
        try {
            const response = await ApiConnection.get(`student-service-schedule-crud/?server_type=production`)
            if (response?.status == 200) {
                console.log(response)
            }
        } catch (e) { }
    }

    const GetSchedule = async (e) => {
        try {
            console.log(e);
            const val = e.target.value;
            setScheduleDay(val)
            const response = await ApiConnection.get(
                `employee-time-schedule-crud/?server_type=production&date=${val}&employee_id=${id}`
            );
            if (response?.status === 200) {
                setAvilScheduleList(response?.data?.results);
                console.log(response);
            }
        } catch (error) {
            console.error(error);
        }
    };

    

    const start_Date = new Date(schedulestartDate); // Make sure 'schedulestartDate' is defined
    const end_Date = new Date(scheduleendDate); // Make sure 'scheduleendDate' is defined
     
    // Function to get day names between two dates
    function getDayNamesBetweenDates(startDate, endDate) {
        const dayNames = [];
        const currentDate = new Date(startDate);
     
        while (currentDate <= endDate) {
            dayNames.push(currentDate.toLocaleString('en-us', { weekday: 'long' }));
            currentDate.setDate(currentDate.getDate() + 1);
        }
     
        return dayNames;
    }
     
    const dayNames = getDayNamesBetweenDates(start_Date, end_Date);
     
    const uniqueDayNames = [...new Set(dayNames)];
     
    const dayIdMap = {
        "sunday": 1,
        "monday": 2,
        "tuesday": 3,
        "wednesday": 4,
        "thursday": 5,
        "friday": 6,
        "saturday": 7,
    };
     
    const DayList = uniqueDayNames.map((dayName) => ({
        id: dayIdMap[dayName.toLowerCase()],
        dayname: dayName.toLowerCase(),
    }));
     
    console.log(DayList);

    

    const handleShow1 = (tid) => {
        setShow1(true)
    };

    const [traininfo, settraininfo] = useState([{
        starttime: '',
        endtime: '',
        dayname: '',
    }]);
    console.log(traininfo, selectedsubject)

    const DeleteHandle = (index) => {
        var newValue = [...traininfo];
        newValue.splice(index, 1);
        settraininfo(newValue);
    }

    const AddMore = () => {
        const rawinput = {
            starttime: '',
            endtime: '',
            dayname: '',
        }
        settraininfo([...traininfo, rawinput])
    }

    const AddMoreHandle = (value, key, index, _is) => {
        var newValue = [...traininfo];
        newValue[index][key] = value
        settraininfo(newValue);
    }

    const ModuleHandle = (e, data) => {
        console.log(data)
        const { name, checked } = e.target
        if (checked) {
            if (name === "allSelect") {
                setselectedsubject(DayList);
            } else {
                setselectedsubject([...selectedsubject, data]);
            }
        } else {
            if (name === "allSelect") {
                setselectedsubject([]);
            } else {
                let tempuser = DayList.filter((item) => item.id !== data.id);
                setselectedsubject(tempuser);
            }
        }
    }

    useEffect(() => {
        const result = empteachertrainingList && empteachertrainingList.filter(data => {
            return data?.training_name.toLowerCase().match(search.toLowerCase())
        })
        setFilterResult(result)
    }, [search])

    const addEvent = async (newEvent) => {
        // setCalendarEvents([...calendarEvents, newEvent]);
        // Call your API to save the new event / setdob(moment(editdata?.date_of_birth).format('YYYY-MM-DD'))
        var Temp = []
        selectedsubject && selectedsubject.map((item) => {
            var dataitem = Number(item.id);
            Temp.push(dataitem);
        })

        var arr = []
        traininfo.forEach((element, index) => {
            arr.push({
                day_name: Temp,
                start_time: element.starttime,
                end_time: element.endtime,
            })
        })

        let obj = {
            "schedule_details": arr[0].id !== '' ? arr : [],
            // year: new Date().getFullYear(),
            start_date: schedulestartDate,
            end_date: scheduleendDate,
        }
        console.log(obj)

        try {
            const response = await ApiConnection.post(`employee-time-schedule-crud/?server_type=production&employee_id=${id}`, obj)
            if (response?.status == 200) {
                Swal.fire({
                    icon: "success",
                    text: response?.data?.msg,
                });
                // GetService()
            }
        } catch (e) { }
    };

    const handleSelectEvent = () => {
        setShow1(true)

        // (event) => window.alert(event.title),
        // []

    }

    const handleClose = () => {
        setShow1(false);
    }

    const { defaultDate, scrollToTime } = useMemo(
        () => ({
            defaultDate: new Date(2015, 3, 12),
            scrollToTime: new Date(1970, 1, 1, 6),
        }),
        []
    )

    return (

        //     <div className='module_list'>
        //         <Loader visible={fetchacompanyReducer.isloading} />
        //         <h5>Schedule</h5>
        //         <hr></hr>

        //         <div className='row form-style mb-4'>

        //             <div className='ml-4' style={{ height: '500px', width: '95%' }}>
        //                 <Calendar
        //                     localizer={localizer}
        //                     startAccessor="start"
        //                     endAccessor="end"
        //                     style={{ margin: '20px' }}
        //                     defaultDate={defaultDate}
        //                     defaultView={Views.WEEK}
        //                     events={calendarEvents}
        //                     onSelectEvent={handleSelectEvent}
        //                     onSelectSlot={handleSelectSlot}
        //                     selectable
        //                     scrollToTime={scrollToTime}
        //                 />
        //             </div>

        //         </div>

        //         <Modal show={show1} size="lg">
        //     <Modal.Header >
        //       <Modal.Title>Add Avilability</Modal.Title>
        //       <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        //     </Modal.Header>
        //     <Modal.Body>
        //       <div className='row form-style mb-4'>

        //         <div className='col-lg-6'>
        //           <div className='form-group'>
        //             <label className='selectlabel'>Enter Title<span class="asterisk">*</span></label>
        //             <input type='text' className='form-control' placeholder='Start Time' value={remarks}
        //               onChange={(e) => setremarks(e.target.value)}
        //             />
        //           </div>
        //         </div>

        //       </div>
        //     </Modal.Body>
        //     <Modal.Footer>
        //       <button className='formBtn' onClick={addEvent}>Save</button>
        //     </Modal.Footer>
        //   </Modal>
        //     </div>
        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h5>Schedule</h5>


            <div className='row form-style'>
                <div className='col-lg-3'>
                    <div className='form-group'>
                        <label className='selectlabel'>Select Date<span class="asterisk">*</span></label>
                        <input type='date' value={ScheduleDay} onChange={(e) => GetSchedule(e)} className='form-control'
                            placeholderText="Date Scheduled"
                        />
                    </div>
                </div>
                <div className='col-lg-3'>
                    <button className='formBtn' style={{ marginTop: '25px' }} onClick={handleShow}>Add Schedule</button>
                </div>
            </div>

            {AvilScheduleList && AvilScheduleList.length > 0 ?
                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th>Schedule Date</th>
                                <th>Day Name</th>
                                <th>Schedule Time</th>
                                <th>Action</th>
                            </tr>
                        </thead>

                        <tbody>
    {AvilScheduleList && AvilScheduleList.map((item, i) => (
      <tr key={i}>
        <td>{moment(item?.start_date).format('YYYY-MM-DD')} - {moment(item?.end_date).format('YYYY-MM-DD')}</td>
        <td>
          {item?.day_list && item?.day_list.map((avilitem, j) => (
            <tr key={j}>{avilitem?.day_name}</tr>
          ))}
        </td>
        <td>
          {item?.day_list[0].time_details && item?.day_list[0].time_details.map((avilitem, k) => (
            <tr key={k}>{avilitem?.start_time} - {avilitem?.end_time}<br /></tr>
          ))}
        </td>
        <td>
          <NavLink><i className="fa-solid fa-pen-to-square"></i></NavLink>
          <button><i className="fa-solid fa-trash"></i></button>
        </td>
      </tr>
    ))}
  </tbody>

                    </table>

                </div>
                :
                <h6 className='text-center'>No data found (Please Select a date)</h6>
            }

            <Modal show={show1} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Schedule Details</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex'>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Start Date</label>
                                <input type='date' className='form-control' placeholder='Start Date'
                                    value={schedulestartDate}
                                    onChange={(e) => setschedulestartDate(e.target.value)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select End Date</label>
                                <input type='date' className='form-control' placeholder='End Date'
                                    value={scheduleendDate}
                                    onChange={(e) => setscheduleendDate(e.target.value)}
                                />

                            </div>
                        </div>
                    </div>

                    {traininfo.map((titem, index) => {
                        return (
                            <div className='row form-style mb-4' key={index}>


                                <div className='col-lg-3'>
                                    <div className='form-group'>
                                        <label className='selectlabel'>Select Start Time</label>

                                        <select className='form-control' value={titem?.starttime}
                                            onChange={(e) => AddMoreHandle(e.target.value, 'starttime', index)}>
                                            <option value="">--Start Time---</option>
                                            <option value="08:00">08:00 AM</option>
                                            <option value="08:30">08:30 AM</option>
                                            <option value="09:00">09:00 AM</option>
                                            <option value="09:30">09:30 AM</option>
                                            <option value="10:00">10:00 AM</option>
                                            <option value="10:30">10:30 AM</option>
                                            <option value="11:00">11:00 AM</option>
                                            <option value="11:30">11:30 AM</option>
                                            <option value="12:00">12:00 PM</option>
                                            <option value="12:30">12:30 PM</option>
                                            <option value="13:00">01:00 PM</option>
                                            <option value="13:30">01:30 PM</option>
                                            <option value="14:00">02:00 PM</option>
                                            <option value="14:30">02:30 PM</option>
                                            <option value="15:00">03:00 PM</option>
                                            <option value="15:30">03:30 PM</option>
                                            <option value="16:00">04:00 PM</option>
                                            <option value="16:30">04:30 PM</option>
                                            <option value="17:00">05:00 PM</option>
                                            <option value="17:30">05:30 PM</option>
                                            <option value="18:00">06:00 PM</option>
                                            <option value="18:30">06:30 PM</option>
                                            <option value="19:00">07:00 PM</option>
                                            <option value="19:30">07:30 PM</option>
                                            <option value="20:00">08:00 PM</option>
                                        </select>
                                    </div>
                                </div>

                                <div className='col-lg-3'>
                                    <div className='form-group'>
                                        <label className='selectlabel'>Select End Time</label>

                                        <select className='form-control' value={titem?.endtime}
                                            onChange={(e) => AddMoreHandle(e.target.value, 'endtime', index)}>
                                            <option value="">--End Time---</option>
                                            <option value="08:00">08:00 AM</option>
                                            <option value="08:30">08:30 AM</option>
                                            <option value="09:00">09:00 AM</option>
                                            <option value="09:30">09:30 AM</option>
                                            <option value="10:00">10:00 AM</option>
                                            <option value="10:30">10:30 AM</option>
                                            <option value="11:00">11:00 AM</option>
                                            <option value="11:30">11:30 AM</option>
                                            <option value="12:00">12:00 PM</option>
                                            <option value="12:30">12:30 PM</option>
                                            <option value="13:00">01:00 PM</option>
                                            <option value="13:30">01:30 PM</option>
                                            <option value="14:00">02:00 PM</option>
                                            <option value="14:30">02:30 PM</option>
                                            <option value="15:00">03:00 PM</option>
                                            <option value="15:30">03:30 PM</option>
                                            <option value="16:00">04:00 PM</option>
                                            <option value="16:30">04:30 PM</option>
                                            <option value="17:00">05:00 PM</option>
                                            <option value="17:30">05:30 PM</option>
                                            <option value="18:00">06:00 PM</option>
                                            <option value="18:30">06:30 PM</option>
                                            <option value="19:00">07:00 PM</option>
                                            <option value="19:30">07:30 PM</option>
                                            <option value="20:00">08:00 PM</option>
                                        </select>
                                    </div>
                                </div>

                                {/* <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <label className='selectlabel'>Select Day</label>
                                        <select className='form-control' value={titem?.dayname} onChange={(e) => AddMoreHandle(e.target.value, 'dayname', index)} >
                                            <option value="">Select Days</option>
                                            <option value="sunday">Sun</option>
                                            <option value="monday">Mon</option>
                                            <option value="tuesday">Tue</option>
                                            <option value="wednesday">Wed</option>
                                            <option value="thrusday">Thru</option>
                                            <option value="friday">Fri</option>
                                            <option value="saturday">Sat</option>
                                        </select>
                                    </div>
                                </div> */}

                                <div className='col-lg-4'>
                                    <div className='form-group'>
                                        <Dropdown className='custom-dropdown-checkbox' style={{ marginTop: '26px' }}>
                                            <Dropdown.Toggle variant="success">Select Days
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <ul>
                                                    <li>
                                                        <input type="checkbox" name="allSelect" checked={selectedsubject?.length === DayList?.length}
                                                            onChange={(e) => ModuleHandle(e, DayList, 'dayname', index)}
                                                        />
                                                        <label>All Select</label>
                                                    </li>
                                                    {DayList && DayList.map((list, i) => {
                                                        return (
                                                            <li>
                                                                <input type="checkbox" name={list?.dayname + i} id={i} onChange={(e) => ModuleHandle(e, list, 'dayname', index)}

                                                                />
                                                                <label htmlFor={i}>{list?.dayname}</label>
                                                            </li>
                                                        )
                                                    })}

                                                </ul>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </div>

                                <div className='col-lg-2' style={{ marginTop: '30px' }}>
                                    <button className='btn btn-primary' onClick={AddMore}><i className="fa-solid fa-plus"></i></button>
                                </div>

                                <div className='col-lg-1' style={{ marginTop: '30px', marginLeft: '-4.5rem' }}>
                                    {index > 0 &&
                                        <div className='form-group'>
                                            <button className='btn brn-sm btn-danger' onClick={() => DeleteHandle(index)}><i className="fa-solid fa-trash-can"></i></button>
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}

                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={addEvent}>Add</button>

                </Modal.Footer>
            </Modal>

        </div>

    )
}

export default TeacherSchedule