import axios from 'axios';
import Constant from './Constant';

export async function postApi(url, payload, header) {
    console.log('PostApi: ', `${Constant.BASE_URL}/${url}`);
    console.log('header: ', header);
    let headers = {};
    headers['Accept'] = header.Accept;
    headers['Content-Type'] = header.contenttype;
    if(header?.accesstoken != undefined && header?.accesstoken != '' && header?.accesstoken != null){
      headers['Authorization'] = `Bearer ${header.accesstoken}`
    }
    console.log('header: ', headers);
    return await axios.post(`${Constant.BASE_URL}/${url}`, payload, {
      headers: headers
      // {
      //   Accept: header.Accept,
      //   'Content-Type': header.contenttype,
      //   // 'x-access-token': header.accesstoken,
      //   'Authorization': `Bearer ${header.accesstoken ?? ''}`,
        
      // },
    });
  }

  export async function getApi(url, header) {
    console.log('GetApi: ', `${Constant.BASE_URL}/${url}`);
  
    return await axios.get(`${Constant.BASE_URL}/${url}`, {
      headers: {
        'Accept': header.Accept,
        'Content-Type': header.contenttype,
        'Authorization': `Bearer ${header.accesstoken ?? ''}`,
      },
    });
  }




  export async function putApi(url, payload, header) {
    console.log('PutApi: ', `${Constant.BASE_URL}/${url}`);
  
    return await axios.put(`${Constant.BASE_URL}/${url}`, payload, {
      headers: {
        'Accept': header.Accept,
        'Content-Type': header.contenttype,
        'Authorization': `Bearer ${header.accesstoken ?? ''}`,
      },
    });
  }

  export async function patchApi(url, payload, header) {
    console.log('PatchApi: ', `${Constant.BASE_URL}/${url}`);

    let headers = {};
    headers['Accept'] = header.Accept;
    headers['Content-Type'] = header.contenttype;
    if(header?.Authorization != undefined){
      headers['Authorization'] = `Bearer ${header.Authorization}`

    }
  
    return await axios.patch(`${Constant.BASE_URL}/${url}`, payload, {
      headers: headers
    });
  }


 

  export async function getApiWithParam(url, header) {
    console.log('getApiWithParam: ', `${Constant.BASE_URL}/${url}`);
  
    return await axios({
      method: 'PUT',
      url: `${Constant.BASE_URL}/${url}`,
      headers: {
        'Authorization': `Bearer ${header.accesstoken ?? ''}`,
      },
    });
  }