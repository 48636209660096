import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { UpdateBccRequest, FetchBccRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";

const EditBackgroundCheckCompany = () => {

    
    const [bccname, setbccname] = useState("")
    const [email, setemail] = useState("")
    const [contactname, setcontactname] = useState("")
    const [phone, setphone] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var editdata = fetchacompanyReducer.FetchBccResponse?.results[0]

    console.log(fetchacompanyReducer.FetchBccResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const {id} = useParams()

    useEffect(()=>{
        let obj ={
            id:id
        }
        dispatch(FetchBccRequest(obj))
    },[])

    useEffect(()=>{

        setbccname(editdata?.bcc_name)
        setemail(editdata?.email)
        setcontactname(editdata?.contact_name)
        setphone(editdata?.phone)


    },[fetchacompanyReducer?.FetchBccResponse])



    const SubmitHandle = () =>{
        if(bccname == ''){
            Swal.fire({
                icon: "error",
                text: 'Name should be must',
              });
            } else  if(email == ''){
                Swal.fire({
                    icon: "error",
                    text: 'Email should be must',
                  });
                } else  if(contactname == ''){
                    Swal.fire({
                        icon: "error",
                        text: 'Contact Person Name should be must',
                      });
                    } else  if(phone == ''){
                        Swal.fire({
                            icon: "error",
                            text: 'Phone should be must',
                          });
                        }  else {
    
                        let obj = {
                            "bcc_name": bccname,
                            "email": email,
                            "contact_name": contactname,
                            "phone": phone,
                        }
    
                        dispatch(UpdateBccRequest({obj:obj, id:id, navigate:navigate}))
    
                    }
    }


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
    <div className='innerlayout p-3'>
    <div className='mb-4'>
      <h3> Edit Background Check Company</h3>
      </div>
      <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
      <div className='row form-style mb-4'>
 
       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Background Check Company Name <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Background Check Company Name'
               value={bccname}
               onChange={(e)=>setbccname(e.target.value)}
               />
           </div>
       </div>

       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Email <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Email'
               value={email}
               onChange={(e)=>setemail(e.target.value)}
               />
           </div>
       </div>

       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Contact Person Name <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Contact Person Name'
               value={contactname}
               onChange={(e)=>setcontactname(e.target.value)}
               />
           </div>
       </div>

       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Phone Number <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Phone'
               value={phone}
               onChange={(e)=>setphone(e.target.value)}
               maxLength="10"
               minLength="10"
               onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
                }}}
               />
           </div>
       </div>
  
      
   </div>
   <button className='formBtn' onClick={SubmitHandle}>Save</button>
    </div>

  </div>
  )
}

export default EditBackgroundCheckCompany