import React from 'react'

const ApplicationDashboard = () => {

    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

  return (
    <div className='common-layout'>
  
      <div className='innerlayout p-3'>
            <div className='card'>
                <div className='card-body'>
                    <h3>Welcome {userinfo?.name}</h3>
                </div>
            </div>
      </div>
  </div>
  )
}

export default ApplicationDashboard