import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetTeacherActivityRequest, DeleteTeacherActivityRequest, UpdateTeacherActivityRequest, AddTeacherActivityRequest, FetchTeacherActivityRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { min } from "moment";
import ApiConnection from '../../utils/ApiConnection'


const ActivitiesReport = () => {

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [activityname1, setactivityname1] = useState("")
  const [starttime1, setstarttime1] = useState("")
  const [endtime1, setendtime1] = useState("")
  const [startdate1, setstartdate1] = useState("")
  const [enddate1, setenddate1] = useState("")
  const [remainderdate1, setremainderdate1] = useState(null)
  const [remaindertime1, setremaindertime1] = useState("")
  const [description1, setdescription1] = useState("")
  const [communicationtype1, setcommunicationtype1] = useState("")
  const [FilterResult, setFilterResult] = useState([])

  let navigate = useNavigate()
  const { id } = useParams()

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var empteachertrainingList = fetchacompanyReducer?.GetTeacherActivityResponse?.results
  var empteachertrainingView = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]


  console.log(fetchacompanyReducer?.FetchTeacherActivityResponse)
  console.log(empteachertrainingView)
  console.log("empteacherId", id)


  const totalsecond = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]?.start_time
  const hours = Math.floor(totalsecond / 3600);
  const minutes = Math.floor((totalsecond % 3600) / 60);

  const totalsecond1 = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]?.end_time
  const hours1 = Math.floor(totalsecond1 / 3600);
  const minutes1 = Math.floor((totalsecond1 % 3600) / 60);

  const totalsecond2 = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]?.remainder_time
  const hours2 = Math.floor(totalsecond2 / 3600);
  const minutes2 = Math.floor((totalsecond2 % 3600) / 60);

  const dispatch = useDispatch()

  useEffect(() => {
    var editinfo = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]
    console.log(editinfo)
    setactivityname1(editinfo?.title)
    const start_time = editinfo?.start_time;

    const hours = Math.floor(start_time / 3600);
    const minutes = Math.floor((start_time % 3600) / 60);

    const formattedStartTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    setstarttime1(formattedStartTime)


    setstartdate1(new Date(editinfo?.start_date == undefined ? new Date() : new Date(editinfo?.start_date)))

    const end_time = editinfo?.end_time;
    const hours1 = Math.floor(end_time / 3600);
    const minutes1 = Math.floor((end_time % 3600) / 60);

    const formattedEndTime = `${hours1.toString().padStart(2, '0')}:${minutes1.toString().padStart(2, '0')}`;
    setendtime1(formattedEndTime)

    setenddate1(new Date(editinfo?.end_date == undefined ? new Date() : new Date(editinfo?.end_date)))
    setremainderdate1(new Date(editinfo?.end_time == undefined ? new Date() : new Date(editinfo?.remainder_date)))

    const remainder_time = editinfo?.remainder_time;
    const hours2 = Math.floor(remainder_time / 3600);
    const minutes2 = Math.floor((remainder_time % 3600) / 60);

    const formattedremTime = `${hours2.toString().padStart(2, '0')}:${minutes2.toString().padStart(2, '0')}`;
    setremaindertime1(formattedremTime)

    setcommunicationtype1(editinfo?.comunication_type)
    setdescription1(editinfo?.description)


  }, [fetchacompanyReducer?.FetchTeacherActivityResponse])


  const columns = [
    {
      name: 'Activity Name',
      selector: row => row.title,
      sortable: true,
    },
    {
        name: 'Created By',
        selector: row => row.start_date,
        sortable: true,
    },
    {
      name: 'Created Date',
      selector: row => row.start_date,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
        </>
      ),
    },
  ];

//   useEffect(() => {
//     let TempArr = []
//     empteachertrainingList && empteachertrainingList.map(item => {

//       const totalsecond = item?.start_time
//       const hours = Math.floor(totalsecond / 3600);
//       const minutes = Math.floor((totalsecond % 3600) / 60);

//       const totalsecond1 = item?.end_time
//       const hours1 = Math.floor(totalsecond1 / 3600);
//       const minutes1 = Math.floor((totalsecond1 % 3600) / 60);

//       const totalsecond2 = item?.remainder_time
//       const hours2 = Math.floor(totalsecond2 / 3600);
//       const minutes2 = Math.floor((totalsecond2 % 3600) / 60);


//       return TempArr.push(
//         {
//           "title": item.title,
//           "start_time": hours + ':' + minutes,
//           "start_date": item.start_date,
//           "end_date": item.end_date,
//           "end_time": hours1 + ':' + minutes1,
//           "comunication_type": item.comunication_type,
//           "id": item.id
//         }
//       )
//     })

//     setFilterResult(TempArr)
//   }, [fetchacompanyReducer?.GetTeacherActivityResponse])

//   useEffect(() => {
//     let obj = {
//       id: id
//     }
//     dispatch(GetTeacherActivityRequest(obj))
//   }, [])


  return (

    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Activities Report</h5>


      <div className='row form-style mt-3'>
        <div className='col-lg-3'>
          <div className='form-group m-0'>
            <input type='text' className='form-control' placeholder='Activities Name'
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </div>
        </div>
        

      </div>

      <div className='table-responsive'>

        <DataTable
          columns={columns}
          data={FilterResult}
          pagination
          highlightOnHover
          noDataComponent=""
        />
        {FilterResult?.length === 0 && (
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>{column.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>There are no records to display</td>
                        </tr>
                    </tbody>
                </table>
            )}
      </div>

    </div>
  )
}

export default ActivitiesReport