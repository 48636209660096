import React from 'react'
import { NavLink } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux'

const Sidebar = () => {

  const sidebarReducer = useSelector((state) => state.sidebarSlice?.isSidebarshow)


  var DynmicClass = ""

  if(sidebarReducer === true)
  {
    DynmicClass = ""
  }
  if(sidebarReducer === false)
  {
    DynmicClass = "closes"
  }


  return (
    <div  className={`Sidebar-left ${DynmicClass}`}>
        <ul>
          <li>
            <NavLink to="/admin/dashboard"  className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
          </li>
          <li>
            <NavLink to="/admin/companyList"  className={({ isActive }) => (isActive ? "active" : undefined)}>Companies</NavLink>
          </li>
          <li>
            <NavLink to="/dsfd"  className={({ isActive }) => (isActive ? "active" : undefined)}>Employees</NavLink>
          </li>
          <li>
            <NavLink to="/ryt"  className={({ isActive }) => (isActive ? "active" : undefined)}>Control Panel</NavLink>
          </li>
        </ul>
    </div>
  )
}

export default Sidebar