import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetGroupRequest, DeleteGroupRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';

const GroupList = () => {
  const [search, setsearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var groupList = fetchacompanyReducer?.GetGroupResponse?.results

    console.log(fetchacompanyReducer.GetGroupResponse)

    const dispatch = useDispatch()

    const columns = [
      {
          name: 'Group Name',
          selector: row => row.name,
          sortable: true,
      },
      {
          name: 'Code',
          selector: row => row.code,
          sortable: true,
      },
      {
        name: 'Description',
        selector: row => row.description,
      },
      {
      name: 'Action',
      selector: (row) => (
        <>
        <NavLink to={`/${userinfo?.company_slug}/curriculum/group/edit-group/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
        <button onClick={()=>DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
        </>
      ),
  },
  ];

  useEffect(()=>{
    const result = groupList && groupList.filter(data =>{
      return data.name.toLowerCase().match(search.toLowerCase())
    })
    setFilterResult(result)
  },[search])

    useEffect(()=>{
        dispatch(GetGroupRequest())
    },[])

useEffect(()=>{
    dispatch(GetGroupRequest())
},[fetchacompanyReducer?.DeleteGroupResponse])

useEffect(()=>{

  setFilterResult(groupList)
},[fetchacompanyReducer?.GetGroupResponse])

    const DeleteHandle = (id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteGroupRequest(id))

                if(fetchacompanyReducer?.DeleteGroupResponse){
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      )
                }
             
            }
          })
    }



  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
      <div className='innerlayout p-3'>
      <div className='mb-4'>
        <h3> Group Management</h3>
        </div>

        <div className='module_list-top form-style mt-3'>
          <div className='form-group m-0 w-25'>
                <input type='text' className='form-control' placeholder='Enter group name'
                value={search}
                onChange={(e)=>setsearch(e.target.value)}
                />
            </div>
        
            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/curriculum/group/add-group`}><i className="fa-solid fa-plus"></i> Add Group</NavLink>
     </div>


   
      <div className='table-responsive custom-table'>
        <DataTable
            columns={columns}
            data={FilterResult}
            pagination
            highlightOnHover
            // subHeader
            // subHeaderComponent={
            //   <input type="text" 
            //   className='w-25 form-control' 
            //   placeholder='Search Here'
            //   value={search}
            //   onChange={(e)=>setsearch(e.target.value)}
            //   />
            // }
        />
      
      </div>
 


      </div>

  </div>
  )
}

export default GroupList