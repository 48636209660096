import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetTeacherActivityRequest, DeleteTeacherActivityRequest, UpdateTeacherActivityRequest, AddTeacherActivityRequest, FetchTeacherActivityRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';
import Loader from '../../utils/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment, { min } from "moment";
import ApiConnection from '../../utils/ApiConnection'


const EmployeeActivities = () => {

  const [search, setsearch] = useState("")
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [editrowId, seteditrowId] = useState(null)
  const [detailsrowId, setdetailsrowId] = useState(null)
  const [show2, setShow2] = useState(false);
  const [activityname, setactivityname] = useState("")
  const [activityname1, setactivityname1] = useState("")
  const [starttime, setstarttime] = useState("")
  const [endtime, setendtime] = useState("")
  const [startdate, setstartdate] = useState("")
  const [enddate, setenddate] = useState("")
  const [remainderdate, setremainderdate] = useState(null)
  const [remaindertime, setremaindertime] = useState("")
  const [description, setdescription] = useState("")
  const [communicationtype, setcommunicationtype] = useState("")
  const [starttime1, setstarttime1] = useState("")
  const [endtime1, setendtime1] = useState("")
  const [startdate1, setstartdate1] = useState("")
  const [enddate1, setenddate1] = useState("")
  const [remainderdate1, setremainderdate1] = useState(null)
  const [remaindertime1, setremaindertime1] = useState("")
  const [description1, setdescription1] = useState("")
  const [communicationtype1, setcommunicationtype1] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const [FilterResult, setFilterResult] = useState([])

  let navigate = useNavigate()
  const { id } = useParams()

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var empteachertrainingList = fetchacompanyReducer?.GetTeacherActivityResponse?.results
  var empteachertrainingView = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]


  console.log(fetchacompanyReducer?.FetchTeacherActivityResponse)
  console.log(empteachertrainingView)
  console.log("empteacherId", id)


  const totalsecond = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]?.start_time
  const hours = Math.floor(totalsecond / 3600);
  const minutes = Math.floor((totalsecond % 3600) / 60);

  const totalsecond1 = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]?.end_time
  const hours1 = Math.floor(totalsecond1 / 3600);
  const minutes1 = Math.floor((totalsecond1 % 3600) / 60);

  const totalsecond2 = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]?.remainder_time
  const hours2 = Math.floor(totalsecond2 / 3600);
  const minutes2 = Math.floor((totalsecond2 % 3600) / 60);

  const dispatch = useDispatch()

  useEffect(() => {
    let obj = {
      employee_id: id
    }
    dispatch(FetchTeacherActivityRequest(obj))
  }, [])

  useEffect(() => {
    var editinfo = fetchacompanyReducer?.FetchTeacherActivityResponse?.results[0]
    console.log(editinfo)
    setactivityname1(editinfo?.title)
    const start_time = editinfo?.start_time;

    const hours = Math.floor(start_time / 3600);
    const minutes = Math.floor((start_time % 3600) / 60);

    const formattedStartTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    setstarttime1(formattedStartTime)


    setstartdate1(new Date(editinfo?.start_date == undefined ? new Date() : new Date(editinfo?.start_date)))

    const end_time = editinfo?.end_time;
    const hours1 = Math.floor(end_time / 3600);
    const minutes1 = Math.floor((end_time % 3600) / 60);

    const formattedEndTime = `${hours1.toString().padStart(2, '0')}:${minutes1.toString().padStart(2, '0')}`;
    setendtime1(formattedEndTime)

    setenddate1(new Date(editinfo?.end_date == undefined ? new Date() : new Date(editinfo?.end_date)))
    setremainderdate1(new Date(editinfo?.end_time == undefined ? new Date() : new Date(editinfo?.remainder_date)))

    const remainder_time = editinfo?.remainder_time;
    const hours2 = Math.floor(remainder_time / 3600);
    const minutes2 = Math.floor((remainder_time % 3600) / 60);

    const formattedremTime = `${hours2.toString().padStart(2, '0')}:${minutes2.toString().padStart(2, '0')}`;
    setremaindertime1(formattedremTime)

    setcommunicationtype1(editinfo?.comunication_type)
    setdescription1(editinfo?.description)


  }, [fetchacompanyReducer?.FetchTeacherActivityResponse])


  const columns = [
    {
      name: 'Task Name',
      selector: row => row.title,
      sortable: true,
    },
    {
      name: 'Start Date',
      selector: row => row.start_date,
      sortable: true,
    },
    {
      name: 'Start Time',
      selector: row => row.start_time,
      // <b>{`${hours2}:${minutes2}`}</b>

      sortable: true,
    },
    {
      name: 'End Date',
      selector: row => row.end_date,
    },
    {
      name: 'End Time',
      selector: row => row.end_time,
    },
    // {
    //   name: 'Remainder Date',
    //   selector: row => '20/12/2023',
    // },
    // {
    //   name: 'Remainder Time',
    //   selector: row => '03:00',
    // },
    {
      name: 'Communication Type',
      selector: row => row.comunication_type,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink onClick={() => handleShow2(row?.id)} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
          <NavLink onClick={() => handleShow1(row?.id)}><i className="fa-solid fa-pen-to-square"></i></NavLink>
          <button onClick={() => DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
        </>
      ),
    },
  ];

  // useEffect(() => {
  //   const result = empteachertrainingList && empteachertrainingList.filter(data => {
  //     return data?.title.toLowerCase().match(search.toLowerCase())
  //   })
  //   setFilterResult(result)
  // }, [search])

  const handleShow1 = (tid) => {
    seteditrowId(tid)
    setShow1(true)
    let obj = {
      employee_id: id
    }
    dispatch(FetchTeacherActivityRequest({ obj: obj, rowId: tid, employee_id: id }))
  };

  const handleShow2 = (tid) => {
    console.log("Emp Activities RowId", tid)
    setdetailsrowId(tid)
    setShow2(true)
    let obj = {
      employee_id: id
    }
    dispatch(FetchTeacherActivityRequest({ obj: obj, rowId: tid, employee_id: id }))
  };

  useEffect(() => {
    setShow1(false)
    let obj = {
      id: id,
      employee_id: id
    }
    dispatch(GetTeacherActivityRequest(obj))
  }, [fetchacompanyReducer?.UpdateTeacherActivityResponse])

  useEffect(() => {


    let TempArr = []
    empteachertrainingList && empteachertrainingList.map(item => {

      const totalsecond = item?.start_time
      const hours = Math.floor(totalsecond / 3600);
      const minutes = Math.floor((totalsecond % 3600) / 60);

      const totalsecond1 = item?.end_time
      const hours1 = Math.floor(totalsecond1 / 3600);
      const minutes1 = Math.floor((totalsecond1 % 3600) / 60);

      const totalsecond2 = item?.remainder_time
      const hours2 = Math.floor(totalsecond2 / 3600);
      const minutes2 = Math.floor((totalsecond2 % 3600) / 60);


      return TempArr.push(
        {
          "title": item.title,
          "start_time": hours + ':' + minutes,
          "start_date": item.start_date,
          "end_date": item.end_date,
          "end_time": hours1 + ':' + minutes1,
          "comunication_type": item.comunication_type,
          "id": item.id
        }
      )
    })

    setFilterResult(TempArr)
    //setFilterResult(empteachertrainingList)
  }, [fetchacompanyReducer?.GetTeacherActivityResponse])

  useEffect(() => {
    let obj = {
      id: id
    }
    dispatch(GetTeacherActivityRequest(obj))
  }, [])

  useEffect(() => {
    let obj = {
      id: id
    }
    dispatch(GetTeacherActivityRequest(obj))
  }, [fetchacompanyReducer?.DeleteTeacherActivityResponse])

  useEffect(() => {
    let obj = {
      id: id
    }
    dispatch(GetTeacherActivityRequest(obj))
    setShow(false)
  }, [fetchacompanyReducer?.AddTeacherActivityResponse])


  const DeleteHandle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteTeacherActivityRequest(id))

        if (fetchacompanyReducer?.DeleteTeacherActivityResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }

  const AddHandle = () => {

    if (startdate == '') {
      Swal.fire({
        icon: "error",
        text: 'Start Date should be must',
      });
    } else if (starttime == '') {
      Swal.fire({
        icon: "error",
        text: 'Start Time should be must',
      });
    // } else if (enddate == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'End Date should be must',
    //   });
    // } else if (endtime == '') {
    //   Swal.fire({
    //     icon: "error",
    //     text: 'End Time should be must',
    //   });
    } else {

      let obj = {
        "employee_id": Number(id),
        "prospect_id": "",
        "guardian_id": "",
        "student_id": "",
        "title": activityname,
        "start_time": starttime,
        // "end_time": endtime,
        "start_date": moment(startdate).format("YYYY-MM-DD"),
        // "end_date": moment(enddate).format("YYYY-MM-DD"),
        "remainder_time": remaindertime,
        "remainder_date": moment(remainderdate).format("YYYY-MM-DD"),
        "description": description,
        "communication_type": communicationtype
      }

      dispatch(AddTeacherActivityRequest({ obj: obj, navigate: navigate }))
    }
  }

  const UpdateHandle = () => {

    if (startdate1 == '') {
      Swal.fire({
        icon: "error",
        text: 'Start Date should be must',
      });
    } else if (starttime1 == '') {
      Swal.fire({
        icon: "error",
        text: 'Start Time should be must',
      });
    } else if (enddate1 == '') {
      Swal.fire({
        icon: "error",
        text: 'End Date should be must',
      });
    } else if (endtime1 == '') {
      Swal.fire({
        icon: "error",
        text: 'End Time should be must',
      });
    } else {

      let obj = {
        "employee_id": Number(id),
        "prospect_id": "",
        "guardian_id": "",
        "student_id": "",
        "title": activityname1,
        "start_time": starttime1,
        "end_time": endtime1,
        "start_date": moment(startdate1).format("YYYY-MM-DD"),
        "end_date": moment(enddate1).format("YYYY-MM-DD"),
        "remainder_time": remaindertime1,
        "remainder_date": moment(remainderdate1).format("YYYY-MM-DD"),
        "description": description1,
        "communication_type": communicationtype1
      }

      dispatch(UpdateTeacherActivityRequest({ obj: obj, employee_id: id, rowId: editrowId }))
    }
  }





  return (

    <div className='module_list'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <h5>Tasks</h5>


      <div className='row form-style mt-3'>
        <div className='col-lg-3'>
          <div className='form-group m-0'>
            <input type='text' className='form-control' placeholder='Task Name'
              value={search}
              onChange={(e) => setsearch(e.target.value)}
            />
          </div>
        </div>
        <div className='col-lg-3'>
          <button className='formBtn' onClick={handleShow}>Add Task</button>
          {/* <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Training/AddTraining`}><i className="fa-solid fa-plus"></i> Add Training</NavLink> */}
        </div>
      </div>

      <div className='table-responsive'>

        <DataTable
          columns={columns}
          data={FilterResult}
          pagination
          highlightOnHover
          noDataComponent=""
        />
        {FilterResult?.length === 0 && (
                <table className='custom-table'>
                    <thead>
                        <tr>
                            {columns.map((column, index) => (
                                <th key={index}>{column.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td colSpan={columns.length} style={{ textAlign: 'center' }}>There are no records to display</td>
                        </tr>
                    </tbody>
                </table>
            )}
      </div>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>Add Task</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Task Name <span class="asterisk">*</span></label>
                <input type='text' className='form-control' placeholder='Task Name'
                  value={activityname}
                  onChange={(e) => { setactivityname(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Start Date <span class="asterisk">*</span></label>
                <DatePicker selected={startdate} onChange={(date) => { setstartdate(date) }} className='form-control'
                  placeholderText="Start Date"
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Start Time <span class="asterisk">*</span></label>
                <input type='time' className='form-control' placeholder='Start Time'
                  value={starttime}
                  onChange={(e) => setstarttime(e.target.value)} />
              </div>
            </div>

            {/* <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select End Date <span class="asterisk">*</span></label>
                <DatePicker selected={enddate} onChange={(date) => { setenddate(date) }} className='form-control'
                  placeholderText="End Date"
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select End Time <span class="asterisk">*</span></label>
                <input type='time' className='form-control' placeholder='End Time'
                  value={endtime} onChange={(e) => setendtime(e.target.value)}
                />
              </div>
            </div> */}

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Remainder Date <span class="asterisk">*</span></label>
                <DatePicker selected={remainderdate} onChange={(date) => { setremainderdate(date) }} className='form-control'
                  placeholderText="Remainder Date"
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Remainder Time <span class="asterisk">*</span></label>
                <input type='time' className='form-control' placeholder='Remainder Time'
                  value={remaindertime}
                  onChange={(e) => { setremaindertime(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Communication Type <span class="asterisk">*</span></label>
                <select className='form-control' value={communicationtype}
                  onChange={(e) => setcommunicationtype(e.target.value)}>
                  <option value="">--Select Communication Type---</option>
                  <option value="Follow Up Call">Follow Up Call</option>
                  <option value="Follow Up Email">Follow Up Email</option>
                  <option value="Follow Up SMS">Follow Up SMS</option>
                  <option value="Follow Up In Person">Follow Up In Person</option>
                  <option value="Internal">Internal</option>
                </select>
              </div>
            </div>

            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='selectlabel'>Select Description <span class="asterisk">*</span></label>
                <textarea type='text' className='form-control' placeholder='Description'
                  value={description} onChange={(e) => setdescription(e.target.value)}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={AddHandle}>Add</button>

        </Modal.Footer>
      </Modal>

      <Modal show={show1} onHide={handleClose1} size="lg">
        <Modal.Header >
          <Modal.Title>Edit Task</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Task Name <span class="asterisk">*</span></label>
                <input type='text' className='form-control' placeholder='Task Name'
                  value={activityname1}
                  onChange={(e) => { setactivityname1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Start Date <span class="asterisk">*</span></label>
                <DatePicker selected={startdate1} onChange={(date) => { setstartdate1(date) }} className='form-control'
                  placeholderText="Start Date"
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Start Time <span class="asterisk">*</span></label>
                <input type='time' className='form-control' placeholderText="Start Time"
                  value={starttime1}
                  onChange={(e) => setstarttime1(e.target.value)}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select End Date <span class="asterisk">*</span></label>
                <DatePicker selected={enddate1} onChange={(date) => { setenddate1(date) }} className='form-control'
                  placeholderText="End Date"
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select End Time <span class="asterisk">*</span></label>
                <input type='time' className='form-control' placeholderText='End Time'
                  value={endtime1} onChange={(e) => setendtime1(e.target.value)}
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Remainder Date <span class="asterisk">*</span></label>
                <DatePicker selected={remainderdate1} onChange={(date) => { setremainderdate1(date) }} className='form-control'
                  placeholderText="Remainder Date"
                />
              </div>
            </div>

            <div className='col-lg-4'>
              <div className='form-group'>
                <label className='selectlabel'>Select Remainder Time <span class="asterisk">*</span></label>
                <input type='time' className='form-control' placeholderText='Remainder Time'
                  value={remaindertime1}
                  onChange={(e) => { setremaindertime1(e.target.value) }}
                />
              </div>
            </div>

            <div className='col-lg-6'>
              <div className='form-group'>
                <label className='selectlabel'>Select Communication Type <span class="asterisk">*</span></label>
                <select className='form-control' value={communicationtype1}
                  onChange={(e) => setcommunicationtype1(e.target.value)}>
                  <option value="">--Select Communication Type---</option>
                  <option value="Follow Up Call">Follow Up Call</option>
                  <option value="Follow Up Email">Follow Up Email</option>
                  <option value="Follow Up SMS">Follow Up SMS</option>
                  <option value="Follow Up In Person">Follow Up In Person</option>
                  <option value="Internal">Internal</option>
                </select>
              </div>
            </div>

            <div className='col-lg-12'>
              <div className='form-group'>
                <label className='selectlabel'>Select Description <span class="asterisk">*</span></label>
                <textarea type='text' className='form-control' placeholder='Description'
                  value={description1} onChange={(e) => setdescription1(e.target.value)}
                />
              </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='formBtn' onClick={UpdateHandle}>Update</button>

        </Modal.Footer>
      </Modal>

      <Modal show={show2} onHide={handleClose2} size="lg">
        <Modal.Header >
          <Modal.Title>Tasks details</Modal.Title>
          <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
          <div className='row form-style mb-4'>

            <div className='view-page'>

              <div className='row'>
                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Task Name :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empteachertrainingView?.title}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Start Date :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empteachertrainingView?.start_date}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Start Time :</label>
                      </div>
                      <div className='col-lg-6'>
                        {/* <b>{empteachertrainingView?.start_time}</b> */}
                        <b>{`${hours}:${minutes}`}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>End Date :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empteachertrainingView?.end_date}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>End Time :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{`${hours1}:${minutes1}`}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Remainder Date :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empteachertrainingView?.remainder_date}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Remainder Time :</label>
                      </div>
                      <div className='col-lg-6'>
                        {/* <b>{empteachertrainingView?.remainder_time}</b> */}
                        <b>{`${hours2}:${minutes2}`}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Communication Type :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empteachertrainingView?.comunication_type}</b>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='col-lg-6'>
                  <div className='form-group'>
                    <div className='row align-items-center'>
                      <div className='col-lg-6'>
                        <label className='d-block text-right'>Description :</label>
                      </div>
                      <div className='col-lg-6'>
                        <b>{empteachertrainingView?.description}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/students`}><i className="fa-solid fa-angle-left"></i> Back to Activities</NavLink>
                        </li>

                    </ul>
                </div> */}


          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default EmployeeActivities