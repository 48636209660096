import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, GetEmployeeRequest, GetStudentListRequest } from '../redux/reducer/FetchCompanyDataReducer'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { Calendar, momentLocalizer, Views, DateLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import globalize from 'globalize'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../utils/ApiConnection'
import ReactSwitch from 'react-switch';


const Student_Teachers = () => {
    const dispatch = useDispatch()
    const { id, pros } = useParams()
    console.log(id)

    const [search, setsearch] = useState("")
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [AvilScheduleList, setAvilScheduleList] = useState([])
    const [ScheduleDay, setScheduleDay] = useState(null)

    const [remainderdate1, setremainderdate1] = useState(null)
    const [communicationtype1, setcommunicationtype1] = useState("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
    const [FilterResult, setFilterResult] = useState([])

    let navigate = useNavigate()

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var StudentActivityList = fetchacompanyReducer?.GetStudentActivityResponse?.results
    var StudentActivityView = fetchacompanyReducer?.FetchStudentActivityResponse?.results[0]
    console.log(fetchacompanyReducer.GetStudentActivityResponse)
    console.log("StudentId", id)

    const GetSchedule = async (e) => {
        try {
            const response = await ApiConnection.get(
                `student-service-schedule-crud/?server_type=production&student_id=${id}`
            );
            if (response?.status === 200) {
                console.log('student-service-schedule-crud',response);
                setAvilScheduleList(response?.data?.results);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        GetSchedule()

    }, [])

    const DeleteHandle = async (id, Delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Cancel it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`employee-time-schedule-crud/?server_type=production&id=${id}&schedule_id=${Delid}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Cancelled!',
                            'Schedule has been Cancelled.',
                            'success'
                        )
                        // GetService()
                        // setShow5(false)
                    }
                }
            })
        } catch (e) { }
    }


    return (
        <div className='module_list'>
        <Loader visible={fetchacompanyReducer.isloading} />
            <div className='mb-4'>
          <h3> Teachers </h3>
        </div>

            <div className='table-responsive'>
                <table className='custom-table'>
                    <thead>
                        <tr>
                            <th>Teacher Name</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    {AvilScheduleList && AvilScheduleList.length > 0 ?

                    <tbody>
                        {AvilScheduleList && AvilScheduleList.map((item, i) => {
                            return (
                                <tr>
                                    <td>{item?.day_name}</td>
                                    <td>
                                        {/* <NavLink><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                        <button><i className="fa-solid fa-trash"></i></button> */}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                                      :
                                      <h6 className='text-center'>There are no records to display</h6>
                                  }  
                </table>
            </div>
        </div>
    )
}

export default Student_Teachers