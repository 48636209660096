import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { UpdateGroupRequest, FetchGroupRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";

export const EditGroup = () => {

    const [gname, setgname] = useState("")
    const [code, setcode] = useState("")
    const [description, setdescription] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var editdata = fetchacompanyReducer.FetchGroupResponse?.results[0]

    console.log(fetchacompanyReducer.FetchGroupResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const {id} = useParams()

    useEffect(()=>{
        let obj ={
            id:id
        }
        dispatch(FetchGroupRequest(obj))
    },[])

    useEffect(()=>{

        setgname(editdata?.name)
        setcode(editdata?.code)
        setdescription(editdata?.description)

    },[fetchacompanyReducer?.FetchGroupResponse])



    const SubmitHandle = () =>{
        if(gname == ''){
            Swal.fire({
                icon: "error",
                text: 'Group name should be must',
              });
            } else  if(code == ''){
                Swal.fire({
                    icon: "error",
                    text: 'Code should be must',
                  });
                } else  if(description == ''){
                    Swal.fire({
                        icon: "error",
                        text: 'Description should be must',
                      });
                    } else {
    
                        let obj = {
                            "name": gname,
                            "code": code,
                            "description": description
                        }
    
                        dispatch(UpdateGroupRequest({obj:obj, id:id, navigate:navigate}))
    
                    }
    }


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
    <div className='innerlayout p-3'>
    <div className='mb-4'>
      <h3> Edit Group</h3>
      </div>
      <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
      <div className='row form-style mb-4'>
 
       <div className='col-lg-6'>
           <div className='form-group'>
               <input type='text' className='form-control' placeholder='Group Name'
               value={gname}
               onChange={(e)=>setgname(e.target.value)}
               />
           </div>
       </div>
       <div className='col-lg-6'>
           <div className='form-group'>
               <input type='text' className='form-control' placeholder='Code'
               value={code}
               onChange={(e)=>setcode(e.target.value)}
               />
           </div>
       </div>

      <div className='col-lg-12'>
           <div className='form-group'>
              <textarea className='form-control' placeholder='Write Description'
              value={description}
              onChange={(e)=>setdescription(e.target.value)}
              >

              </textarea>
           </div>
       </div>
  
      
   </div>
   <button className='formBtn' onClick={SubmitHandle}>Save</button>
    </div>

  </div>
  )
}
