import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, NavLink } from 'react-router-dom'
import { DeletestudentRequest, ViewstudentRequest, ShuffleLearningPathRequest, GetLearningPlanListsRequest, DownloadLearningPlanRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
const column = [
    {
        "id": 1,
        "display_order": 1,
        "plan_name": "First_Name",
        "question": false,
        'order': 2,
        'topic': 'lorem ipsum'
    },
    {
        "id": 2,
        "display_order": 2,
        "plan_name": "First_Name",
        "question": false,
        'order': 3,
        'topic': 'lorem ipsum'
    },
    {
        "id": 3,
        "display_order": 3,
        "plan_name": "First_Name",
        "question": false,
        'order': 2,
        'topic': 'lorem ipsum'
    },
    {
        "id": 4,
        "display_order": 4,
        "plan_name": "First_Name",
        "question": false,
        'order': 2,
        'topic': 'lorem ipsum'
    },
    {
        "id": 5,
        "display_order": 5,
        "plan_name": "First_Name",
        "question": false,
        'order': 2,
        'topic': 'lorem ipsum'
    },
    {
        "id": 6,
        "display_order": 6,
        "plan_name": "First_Name",
        "question": false,
        'order': 2,
        'topic': 'lorem ipsum'
    },

]

const columns = [
    {
        name: 'Select',
        selector: row => (
            <>
                <input type='checkbox' />
            </>
        )
    },
    {
        name: 'Learning Plan Name',
        selector: row => 'Algebra',
    },
    {
        name: 'Questions',
        selector: row => '2+2',
    },
    {
        name: 'Order',
        selector: row => '2',
    },
    {
        name: 'Intial Sort Order',
        selector: row => '2',
    },
    {
        name: 'Global Sort Order',
        selector: row => '240',
    },

    {
        name: 'Topic',
        selector: row => 'Ratio',
    },
    {
        name: 'Checkup origination',
        selector: row => 'CU:AR',
    },
    {
        name: 'Desctiption',
        selector: row => 'Test Description',
    },
    {
        name: 'Document Link',
        selector: row => (
            <>
                <div className='d-flex'>
                    <button className='formBtn studentassignbtn' >Print</button>
                </div>
            </>
        ),
    },
    {
        name: 'Date Assigned',
        selector: row => '12-01-2024',
    },
    {
        name: 'Date Completed',
        selector: row => '01-02-2024',
    },
    {
        name: 'Last Printed',
        selector: row => '01-02-2024',
    },
    {
        name: 'Actions',
        selector: (row) => (
            <>
                <div className='d-flex'>
                    <button className='formBtn studentassignbtn mr-2' >Re-Add</button>
                    <button className='formBtn studentassignbtn' >Related</button>
                </div>
            </>
        ),
    },
    {
        name: 'Mastered',
        selector: row => (
            <>
                <input type='checkbox' />
            </>
        )
    },
];

const LearningPathManager = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState(columns)
    const [show, setShow] = useState(false);
    const [learningList, setlearningList] = useState([])

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var LearningPlanList = fetchacompanyReducer?.GetLearningPlanListsResponse?.results
    console.log(fetchacompanyReducer.GetLearningPlanListsResponse)


    const dispatch = useDispatch()
    let navigate = useNavigate()

    const { id, pros, lpid } = useParams()

    const handleClose = () => setShow(false);

    useEffect(() => {
        let obj = {
            id: id,
            student_id: pros,
            learning_path_id: lpid
        }
        dispatch(GetLearningPlanListsRequest(obj))
    }, [])

    useEffect(() => {
        var learningList = fetchacompanyReducer?.GetLearningPlanListsResponse?.results
        console.log("acctual", learningList)
        setlearningList(learningList)
    }, [fetchacompanyReducer?.GetLearningPlanListsResponse])

    // useEffect(() => {
    //     let obj = {
    //         id: id,
    //         student_id: pros,
    //         learning_path_id: lpid
    //     }
    //     dispatch(GetLearningPlanListsRequest(obj))
    // }, [fetchacompanyReducer?.ShuffleLearningPathResponse])


    const HandleonDrag = (result) => {
        const items = Array.from(learningList)
        const [saveItem] = items.splice(result.source.index, 1)
        items.splice(result.destination.index, 0, saveItem)

        setlearningList(items)

        dispatch(ShuffleLearningPathRequest({obj:{"results":items}, test_id:id, student_id:pros }))

        console.log("achanges", items)
    }

    const DownloadHandle = (testId,studentId,topicId)=>{
        console.log("DownloadLearningPlanRequest",testId,studentId,topicId)
        dispatch(DownloadLearningPlanRequest({testId:testId,studentId:studentId,topicId:topicId}))
    }



    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Learning Plan Manager</h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-70px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='row form-style mb-4 align-items-center'>
                   
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Learning Plan</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Type</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label>Learning Path View</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-3'>
                        <div className='form-group'>
                            <label className='d-block'>&nbsp;</label>
                            <div className='d-flex'>
                                <button className='btn btn-primary mr-3'>Search</button>
                            </div>
                        </div>
                    </div>

                </div>

                <div className='button-part mb-4'>
                    <ul>
                        <li>
                            <button className='btn btn-primary mr-1'> Export </button>
                        </li>
                        <li>
                            <button className='btn btn-primary mr-1'> Add New </button>
                        </li>
                        
                        <li>
                            <button className='btn btn-primary mr-1'> Print </button>
                        </li>
                        <li>
                            <button className='btn btn-primary mr-1'> Deactivate </button>
                        </li>
                        <li>
                            <button className='btn btn-primary mr-1'> Recommend Extensions </button>
                        </li>
                        
                        {/* <li>
                            <button className='formBtn1'> Report </button>
                        </li>
                        <li>
                            <button className='formBtn1'> Reorder Assigned </button>
                        </li> */}
                    </ul>
                </div>
                <a id="DownloadQuestion" target='_blank'></a>

                <div className='table-responsive lp-drop-table mb-4'>
                    <ul className='q-table-head'>
                        <li>
                            <h2 style={{width:'100px',flex:'inherit'}}>Select</h2>
                            <h2>Learning Plan Name</h2>
                            <h2 style={{width:'100px',flex:'inherit'}}>Question No.</h2>
                            <h2 style={{width:'100px',flex:'inherit'}}>Order</h2>
                            <h2 style={{width:'100px',flex:'inherit'}}>Intial Sort Order</h2>
                            <h2 style={{width:'100px',flex:'inherit'}}>Global Sort Order</h2>
                            <h2>Module</h2>
                            <h2>Grade Source</h2>
                            <h2>Topic</h2>
                            <h2>Document Link</h2>
                            <h2>Date Assigned</h2>
                            <h2>Date Completed</h2>
                            <h2>Last Printed</h2>
                            <h2>Actions</h2>
                            <h2 style={{width:'100px',flex:'inherit'}}>Mastered</h2>

                        </li>
                    </ul>
                    <DragDropContext onDragEnd={HandleonDrag}>
                        <Droppable droppableId='characters'>
                            {(provided) => (
                                <ul {...provided.droppableProps} ref={provided.innerRef} className='q-table-body'>
                                    {learningList && learningList.map(({ id, display_order, plan_name, grade_name, questions_list, module_name, date_assigned, date_completed, date_last_printed, test_name, topic_name, question_name, test_id, student_id, topic_id, topic_number }, index) => {
                                        return (
                                            <Draggable key={id} draggableId={(display_order).toString()} index={index}>
                                                {(provided) => (
                                                    <li {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                                        <h3 style={{width:'100px',flex:'inherit'}}><span><i className="fa-solid fa-arrows-up-down-left-right"></i></span> {display_order}</h3>

                                                        <h3>{test_name}</h3>
                                                        <h3 style={{width:'100px',flex:'inherit'}}>{questions_list}</h3>
                                                        <h3 style={{width:'100px',flex:'inherit'}}>{index + 1}</h3>
                                                        <h3 style={{width:'100px',flex:'inherit'}}>{display_order}</h3>
                                                        <h3 style={{width:'100px',flex:'inherit'}}>{topic_number}</h3>
                                                        <h3>{module_name}</h3>
                                                        <h3>{grade_name}</h3>
                                                        <h3>{topic_name}</h3>
                                                        <h3>
                                                            <>
                                                                <div className='d-flex'>
                                                                    <button className='formBtn studentassignbtn' onClick={() => DownloadHandle(test_id,student_id,topic_id)}>Print</button>
                                                                </div>
                                                            </>
                                                        </h3>
                                                        <h3>{moment(date_assigned).format('DD-MM-YYYY')}</h3>
                                                        <h3>{moment(date_completed).format('DD-MM-YYYY')}</h3>
                                                        <h3>{moment(date_last_printed).format('DD-MM-YYYY')}</h3>
                                                        <h3>
                                                            <>
                                                                <div className='d-flex'>
                                                                    <button className='formBtn studentassignbtn mr-2' >Re-Add</button>
                                                                    <button className='formBtn studentassignbtn' >Related</button>
                                                                </div>
                                                            </>
                                                        </h3>
                                                        <h3 style={{width:'100px',flex:'inherit'}}>
                                                            <>
                                                                <input type='checkbox' />
                                                            </>
                                                        </h3>
                                                    </li>
                                                )}

                                            </Draggable>
                                        )
                                    })}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>

                {/* <div className='table-responsive custom-table mt-3'>
                    <DataTable
                        columns={columns}
                        data={FilterResult}
                        pagination
                        highlightOnHover
                    // subHeader
                    // subHeaderComponent={
                    //   <input type="text" 
                    //   className='w-25 form-control' 
                    //   placeholder='Search Here'
                    //   value={search}
                    //   onChange={(e)=>setsearch(e.target.value)}
                    //   />
                    // }
                    />

                </div> */}



            </div>

        </div>
    )
}

export default LearningPathManager