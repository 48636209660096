import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { GetExistingTestRequest, GetStudentTestListRequest, GetExistingQuestionRequest, AssignScheduleRequest, GetStudenttestRequest, AddStudentEnrollRequest, GetStateRequest, GetcityRequest, FetchstudentRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../../utils/ApiConnection'
import { IMAGE } from '../../../src/utils/Theme'

const Student_Enrollments = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [holdduration, setholdduration] = useState("")
    const [remarks, setremarks] = useState("")
    const [enrolltype, setenrolltype] = useState("")
    const [enrolltypesession, setenrolltypesession] = useState("")
    const [enrollmonthlyamount, setenrollmonthlyamount] = useState("")
    const [enrolltotalsession, setenrolltotalsession] = useState("")
    const [enrollremainings, setenrollremainings] = useState("")
    const [enrollholdlength, setenrollholdlength] = useState("")
    const [firstname, setfirstname] = useState("")
    const [lastname, setlastname] = useState("")
    const [gender, setgender] = useState("")
    const [dob, setdob] = useState("")
    const [studentemail, setstudentemail] = useState("")
    const [stateList, setstateList] = useState([])
    const [cityList, setcityList] = useState([])
    const [grade, setgrade] = useState("")
    const [schoolyear, setschoolyear] = useState("")
    const [schoolyear1, setschoolyear1] = useState("")
    const [school, setschool] = useState("")
    const [teacher, setteacher] = useState("")
    const [Prospectfirstname, setProspectfirstname] = useState("")
    const [Prospectlastname, setProspectlastname] = useState("")
    const [ProspectDOB, setProspectDOB] = useState("")
    const [Prospectemail, setProspectemail] = useState("")
    const [Prospectphone, setProspectphone] = useState("")
    const [Prospectadd1, setProspectadd1] = useState("")
    const [Prospectadd2, setProspectadd2] = useState("")
    const [Prospectcountry, setProspectcountry] = useState("")
    const [Prospectstate, setProspectstate] = useState("")
    const [Prospectcity, setProspectcity] = useState("")
    const [Prospectpincode, setProspectpincode] = useState("")
    const [Billingfirstname, setBillingfirstname] = useState("")
    const [Billinglastname, setBillinglastname] = useState("")
    const [Billingemail, setBillingemail] = useState("")
    const [Billingphone, setBillingphone] = useState("")
    const [Billingadd1, setBillingadd1] = useState("")
    const [Billingadd2, setBillingadd2] = useState("")
    const [Billingcountry, setBillingcountry] = useState("")
    const [Billingcity, setBillingcity] = useState("")
    const [Billingstate, setBillingstate] = useState("")
    const [Billingpincode, setBillingpincode] = useState("")
    const [enrolldelivery, setenrolldelivery] = useState("")
    const [enrollstartdate, setenrollstartdate] = useState(null)
    const [enrolloed, setenrolloed] = useState("")
    const [enrollinactivedate, setenrollinactivedate] = useState(null)
    const [enrollrecurring, setenrollrecurring] = useState("")
    const [servicename, setservicename] = useState("");
    const [discount, setdiscount] = useState("");
    const [discountAmount, setdiscountAmount] = useState("");
    const [discountPercentage, setdiscountPercentage] = useState("");
    const [achdiscount, setachdiscount] = useState("");
    const [achdiscountamount, setachdiscountamount] = useState("");
    const [name1, setname1] = useState("");
    const [price1, setprice1] = useState("");
    const [session1, setsession1] = useState("");
    const [recurring1, setrecurring1] = useState("");
    const [subjectList, setsubjectList] = useState([])
    const [discountList, setdiscountList] = useState([])
    const [serviceList, setserviceList] = useState([])
    const [EnrollmentList, setEnrollmentList] = useState([])
    const [inpersonsign, setinpersonsign] = useState("");
    const [onlinesign, setonlinesign] = useState("");
    const [mediasign, setmediasign] = useState("");
    const [teachersign, setteachersign] = useState("");
    const [leavesign, setleavesign] = useState("");
    const [scheduleduration, setscheduleduration] = useState("");
    const [inpersonchk, setinpersonchk] = useState("");
    const [onlinechk, setonlinechk] = useState("");
    const [mediachk, setmediachk] = useState("");
    const [teacherchk, setteacherchk] = useState("");
    const [leavechk, setleavechk] = useState("");
    const [inpersontext, setinpersontext] = useState("");
    const [onlinetext, setonlinetext] = useState("");
    const [mediatext, setmediatext] = useState("");
    const [teachertext, setteachertext] = useState("");
    const [leavetext, setleavetext] = useState("");
    const [inpersonnotes, setinpersonnotes] = useState("");
    const [onlinenotes, setonlinenotes] = useState("");
    const [medianotes, setmedianotes] = useState("");
    const [teachernotes, setteachernotes] = useState("");
    const [leavenotes, setleavenotes] = useState("");
    const [enrollmanualfee, setenrollmanualfee] = useState("");
    const [contactstartdate, setcontactstartdate] = useState("");
    const [contactenddate, setcontactenddate] = useState("");
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [schoolname, setschoolname] = useState("")
    const [schoolphone, setschoolphone] = useState("")
    const [schoolemail, setschoolemail] = useState("")
    const [schoolcontactperson, setschoolcontactperson] = useState("")
    const [schooladdress, setschooladdress] = useState("")
    const [schoolwebsite, setschoolwebsite] = useState("")
    const [content1, setContent1] = useState(true);
    const [content2, setContent2] = useState(false);
    const [content3, setContent3] = useState(false);
    const [content4, setContent4] = useState(false);
    const [content5, setContent5] = useState(false);
    const [content6, setContent6] = useState(false);
    const [subject, setsubject] = useState("");
    const [scheduleemail, setscheduleemail] = useState("");
    const [scheduledate, setscheduledate] = useState("");
    const [totalamount, settotalamount] = useState("");
    const [pricename, setpricename] = useState("");
    const [priceList, setpriceList] = useState([]);
    const [achdiscountamountprice, setachdiscountamountprice] = useState("");
    const [Amount1, setAmount1] = useState("");
    const [Amount2, setAmount2] = useState("");
    const [TodaysAmount, setTodaysAmount] = useState("");
    const [enrollregfee, setenrollregfee] = useState("");
    const [enrollassessmentfee, setenrollassessmentfee] = useState("");
    const [paymentmethod, setpaymentmethod] = useState("");
    const [companyid, setcompanyid] = useState("");


    const [startdate, setstartdate] = useState(null)
    const [enddate, setenddate] = useState(null)
    const [show, setShow] = useState(false);
    const [editrowId, seteditrowId] = useState(null)
    const [TestId, setTestId] = useState("");
    const handleClose = () => setShow(false);
    const [show1, setShow1] = useState(false);
    const [selectedUser, setSelectedUser] = useState([]);
    const [StudentList, setStudentList] = useState([])
    const handleClose1 = () => setShow1(false);
    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var testList = fetchacompanyReducer?.GetStudenttestResponse?.results
    var ExistingTest = fetchacompanyReducer?.GetStudentTestListResponse?.results

    console.log(fetchacompanyReducer.GetTitleResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { id } = useParams()

    const columns = [
        {
            name: 'Start date',
            selector: row => row.contract_start_date,
            sortable: true,
        },
        {
            name: 'Service Name',
            selector: row => 'test',
        },
        {
            name: 'InActive Date',
            selector: row => '12/01/2024',
        },
        {
            name: 'Recurring',
            selector: row => 'test',
        },
        {
            name: 'Type',
            selector: row => 'Stable',
        },
        {
            name: 'Monthly Amount',
            selector: row => '10,000',
        },
        {
            name: 'Total Sessions',
            selector: row => '4',
        },
        {
            name: 'Remainings',
            selector: row => '2',
        },
        {
            name: 'Holds Length',
            selector: row => '1 Month',
        },
        {
            name: 'Delivery',
            selector: row => 'test',
        },
        {
            name: 'Delivery',
            selector: row => 'Paid',
        },
        {
            name: 'Actions',
            selector: (row) => (
                <>
                    <NavLink ><i className="fa-solid fa-pen-to-square"></i></NavLink>
                    <button className='table-btn'><i className="fa-solid fa-trash"></i></button>
                </>
            ),
        },
    ];

    useEffect(() => {

        var editdata = fetchacompanyReducer?.FetchstudentResponse?.results[0]
        setfirstname(editdata?.first_name)
        setlastname(editdata?.last_name)
        setgender(editdata?.gender)
        setgrade(editdata?.grade_id)
        setschool(editdata?.school_id)
        setteacher(editdata?.school_teacher_id)
        setschoolyear(editdata?.school_year)
        setdob(moment(editdata?.date_of_birth).format('YYYY-MM-DD'))
        setstudentemail(editdata?.email)

        setProspectfirstname(editdata?.prospect_first_name)
        setProspectlastname(editdata?.prospect_last_name)
        setProspectemail(editdata?.prospect_email)
        setProspectphone(editdata?.prospect_mobile_phone)
        setProspectadd1(editdata?.prospect_street_address_1)
        setProspectadd2(editdata?.prospect_street_address_2)
        setProspectcountry(editdata?.prospect_country_id)
        setProspectcity(editdata?.prospect_city_id)
        setProspectstate(editdata?.prospect_state_id)
        setProspectpincode(editdata?.prospect_zipcode)

        if (editdata && editdata?.leave_unescorted_consent_form) {
            const enrollmentConfirmation = editdata.leave_unescorted_consent_form;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setleavetext(formattedText);
        }

        if (editdata && editdata?.teacher_consent_form) {
            const enrollmentConfirmation = editdata.teacher_consent_form;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setteachertext(formattedText);
        }

        if (editdata && editdata?.media_release_form) {
            const enrollmentConfirmation = editdata.media_release_form;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setmediatext(formattedText);
        }

        if (editdata && editdata?.learning_center_guidelines) {
            const enrollmentConfirmation = editdata.learning_center_guidelines;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setinpersontext(formattedText);
        }

        if (editdata && editdata?.home_learning_center_guidelines) {
            const enrollmentConfirmation = editdata.home_learning_center_guidelines;
            function decodeHTMLEntities(text) {
                var textArea = document.createElement('textarea');
                textArea.innerHTML = text;
                return textArea.value;
            }
            const formattedText = decodeHTMLEntities(enrollmentConfirmation.replace(/<\/?[^>]+(>|$)/g, ""));
            setonlinetext(formattedText);
        }

        let obj = {
            id: editdata?.prospect_country_id,
        };
        dispatch(GetStateRequest(obj));

        let obj1 = {
            id: editdata?.prospect_state_id,
        };
        dispatch(GetcityRequest(obj1));

        setBillingfirstname(editdata?.prospect_first_name)
        setBillinglastname(editdata?.prospect_last_name)
        setBillingemail(editdata?.prospect_email)
        setBillingphone(editdata?.prospect_mobile_phone)
        setBillingadd1(editdata?.prospect_street_address_1)
        setBillingadd2(editdata?.prospect_street_address_2)
        setBillingcountry(editdata?.prospect_country_id)
        setBillingstate(editdata?.prospect_state_id)
        setBillingcity(editdata?.prospect_city_id)
        setBillingpincode(editdata?.prospect_zipcode)
        setcompanyid(editdata?.company_id)

        setenrollstartdate(editdata?.startup_fee)
        setenrollinactivedate(editdata?.assessment_fee)
        setenrollrecurring()
        setenrolltype()
        setenrolltotalsession()
        setenrollmonthlyamount(editdata?.cancelation_fee)
        setenrolldelivery()
        setenrollremainings(editdata?.late_fee)
        setenrolltotalsession()
        setenrollmanualfee(editdata?.manual_payment_fee)
        setachdiscountamount(editdata?.ach_discount)
        setachdiscount(editdata?.ach_discount)
        setscheduleduration(editdata?.service_durations)

        // setenrollstartdate(editdata?.startup_fee)
        // setenrollinactivedate(editdata?.assessment_fee)
        // setenrollrecurring()
        // setenrolltype()
        // setenrollregfee(editdata?.startup_fee)
        // setenrolltotalsession()
        // setenrollassessmentfee(editdata?.assessment_fee)
        // setenrollmonthlyamount(editdata?.cancelation_fee)
        // setenrolldelivery()
        // setenrollremainings(editdata?.late_fee)
        // setenrolltotalsession()
        // setenrollmanualfee(editdata?.manual_payment_fee)
        // setachdiscountamount(editdata?.ach_discount)

        setenrollstartdate(editdata?.startup_fee)
        setenrollinactivedate(editdata?.assessment_fee)
        setenrollrecurring()
        setenrolltype()
        setenrollregfee(editdata?.startup_fee)
        setenrolltotalsession()
        setenrollassessmentfee(editdata?.assessment_fee)
        setenrollmonthlyamount(editdata?.cancelation_fee)
        setenrolldelivery()
        setenrollremainings(editdata?.late_fee)
        setenrolltotalsession()
        setenrollmanualfee(editdata?.manual_payment_fee)
        setachdiscountamount(editdata?.ach_discount)

        setinpersonnotes(editdata?.learning_center_guidelines_notes)
        setonlinenotes(editdata?.online_learning_guidelines_notes)
        setmedianotes(editdata?.media_release_form_notes)
        setteachernotes(editdata?.teacher_consent_form_notes)
        setleavenotes(editdata?.leave_unescorted_consent_form_notes)

    }, [fetchacompanyReducer?.FetchstudentResponse])

    useEffect(() => {
        const result = EnrollmentList && EnrollmentList.filter(data => {
            return data.product?.toLowerCase().match(Namesearch?.toLowerCase())
        })
        setFilterResult(result)
    }, [Namesearch])

    const GetEnrollmentList = async () => {

        try {
            // const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${id}`)
            const response = await ApiConnection.get(`stripe-payment-list/?student_id=${id}`)
            if (response?.status == 200) {
                console.log("get Enrollment", response.data.results)
                setEnrollmentList(response?.data?.results)
            }
        } catch (e) { }
    }

    // const ProratedamountHandle = (value) => {
    //     setenrolltotalsession(value)
    //     const newAmount = Math.floor(price1 / session1 * value);
    //     setenrolltype(newAmount);
    //     const newAmount1 = Math.floor(newAmount + enrollregfee + enrollassessmentfee);
    //     setAmount1(newAmount1);
    //     const discounttotal = Math.floor(newAmount1 - discountAmount);
    //     setAmount2(discounttotal)
    //     const AchdiscountPrice = Math.floor(TodaysAmount * achdiscountamount) / 100;
    //     setachdiscountamountprice(AchdiscountPrice)
    //     const Achdiscounttotal = Math.floor(discounttotal - AchdiscountPrice);
    //     setTodaysAmount(Achdiscounttotal)
    // }

    const ProratedamountHandle = (value) => {
        setenrolltotalsession(value);
        const newAmount = Math.floor(price1 / session1 * value);
        setenrolltype(newAmount);
      
        const newAmount1 = Math.floor(newAmount + parseFloat(enrollregfee) + parseFloat(enrollassessmentfee));
        setAmount1(newAmount1);
      
        const discounttotal = Math.floor(newAmount1 - parseFloat(discountAmount));
        setAmount2(discounttotal);
      
        const AchdiscountPrice = Math.floor((discounttotal * parseFloat(achdiscountamount)) / 100);
        setachdiscountamountprice(AchdiscountPrice);
      
        const Achdiscounttotal = Math.floor(discounttotal - AchdiscountPrice);
        setTodaysAmount(Achdiscounttotal);
      };

    const TotalamountHandle = (value) => {
        setenrollmanualfee(value)
        const totalAmount = Math.floor(enrolltype + value);
        console.log(totalAmount)
    }

    const DiscountPercentageAmount = (value) => {
        setdiscountPercentage(value);
        const totalAmount = parseFloat(enrolldelivery) / value;
        const calculatedAmount = enrolldelivery - totalAmount;
        setachdiscountamount(calculatedAmount);
      };

    const TodaysTotalamountHandle = (value) => {
        // setenrolltotalsession(value)  var tomorrow = moment(today).add(1, 'days');
        const newAmount = Math.floor(price1 / session1 * value);
        setenrolltype(newAmount);
    }

    useEffect(() => {
        GetSubject();
        GetService();
        GetEnrollmentList();
        GetDiscount();
    }, [])

    const GetDiscount = async (e) => {

        try {
            const response = await ApiConnection.get(`membership-discount-crud/?server_type=production`)
            if (response?.status == 200) {
                setdiscountList(response?.data?.results)

            }
        } catch (e) { }

    }

    const SubjectHandle = async (e) => {
        var val = e.target.value
        setsubject(val)
        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production&subject_id=${val}`,)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
                setname1("");
                setsession1("");
                setprice1("");
                setrecurring1("");
            }
        } catch (e) { }
    }

    const ServiceHandle = async (e) => {
        console.log("ServiceHandle", e)
        var val = e.target.value
        setservicename(val)
        try {
            const response = await ApiConnection.get(`pricing_crud/?server_type=production&service_id=${val}`)
            console.log(response.data.results[0].name);
            setpriceList(response?.data?.results)
            console.log("priceList", priceList)

            setsession1("");
            setprice1("");
            setrecurring1("");


        } catch (e) { }
    }

    const DiscountHandle = async (e) => {
        console.log("ServiceHandle", e)
        var val = e.target.value
        setdiscount(val)
        try {
            const response = await ApiConnection.get(`membership-discount-crud/?server_type=production&id=${val}`)
            console.log(response.data.results[0].name);

            setdiscountAmount(response.data.results[0].amount);
            setdiscountPercentage(response.data.results[0].is_percentage);

        } catch (e) { }

    }

    const GetSubject = async () => {
        let obj = {
            name: '',
            group_id: ''
        }
        try {
            const response = await ApiConnection.get(`subjects-crud/?server_type=production&name=${obj.name}&group_id=${obj.group_id}`)
            if (response?.status == 200) {
                console.log("get subject", response.data)
                setsubjectList(response?.data?.results)
            }
        } catch (e) { }
    }

    const PriceHandle = async (e) => {
        console.log("PriceHandle", e)
        var val = e.target.value
        setpricename(val)
        console.log("PriceHandleVal", val)

        try {
            const response = await ApiConnection.get(`pricing_crud/?server_type=production&id=${val}`)
            console.log(response);

            setsession1(response.data.results[0].total_session);
            setprice1(response.data.results[0].price);
            setrecurring1(response.data.results[0].recurring);


        } catch (e) { }
    }

    const GetService = async () => {
        try {
            const response = await ApiConnection.get(`service_crud/?server_type=production`)
            if (response?.status == 200) {
                setserviceList(response?.data?.results)
            }
        } catch (e) { }
    }

    const handleShow = () => {
        // setaddrowId(tid)
        setShow(true)
        let obj = {
            id: id
        }
        dispatch(FetchstudentRequest(obj))
    };

    const handleShow1 = () => {
        setShow1(true)
    };


    const serviceinfoSubmit = async () => {
        try {

            if (subject == '') {
                Swal.fire({
                    icon: "error",
                    text: 'subject should be must',
                });
            } else if (servicename == '') {
                Swal.fire({
                    icon: "error",
                    text: 'service name should be must',
                });
            } else if (pricename == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Price name should be must',
                });
            } else if (session1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'Total session should be must',
                });
            } else if (price1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'price should be must',
                });
            } else if (recurring1 == '') {
                Swal.fire({
                    icon: "error",
                    text: 'recurring should be must',
                });
            } else if (contactstartdate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'recurring start date should be must',
                });
            } else if (enrollstartdate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll start date should be must',
                });
            } else if (enrollinactivedate == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll inactive date should be must',
                });
            } else if (enrollrecurring == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll recurring should be must',
                });
                // } else if (enrolltype == '') {
                //     Swal.fire({
                //         icon: "error",
                //         text: 'Prorated First Month Price should be must',
                //     });
            } else if (enrolltotalsession == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll total session should be must',
                });
            } else if (enrollmanualfee == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll manual fee should be must',
                });
            } else if (enrollremainings == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll remainings should be must',
                });
            } else if (enrolldelivery == '') {
                Swal.fire({
                    icon: "error",
                    text: 'enroll delivery should be must',
                });
            } else {
                let obj = {
                    // "student_id": id,
                    "company_id": companyid,
                    "subject_id": subject,
                    "service_id": servicename,
                    "pricing_id": pricename,
                    "total_session": session1,
                    "price": price1,
                    "recurring": recurring1,
                    "startup_fee": enrollstartdate,
                    "registration_fee": price1 === 0 ? '' : enrollregfee,
                    "assessment_fee": enrollassessmentfee,
                    "monthly_recurring_fee": price1,
                    "contract_start_date": contactstartdate,
                    "contract_end_date": contactenddate,
                    "discount_id": discount,
                    "dicount_amount": discountAmount,
                    "discount_percentage": discountPercentage,
                    "prorated_first_month": enrolltype,
                    "prorated_first_session": enrolltotalsession,
                    "account_closing_fee": enrollmonthlyamount,
                    "manual_fee": enrollmanualfee,
                    "ach_discount": achdiscountamount,
                    "late_payment_fee": enrollremainings,
                    "todays_total_fee": TodaysAmount,
                    "service_durations": scheduleduration

                }

                const response = await ApiConnection.post(`admission/service-info/add/?server_type=production&student_id=${id}`, obj);
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                    if (obj.price !== "0") {
                        navigate(`/${userinfo?.company_slug}/students/PaymentLink/${obj.service_id}/${obj.company_id}/${id}`)
                    } else {
                        setShow(false)
                        setShow3(true)
                        // navigate(`/${userinfo?.company_slug}/students/PaymentLink/${obj.service_id}/${obj.company_id}/${id}`)
                    }
                }
                // setContent(5);
            }
        } catch (e) { }
    }

    const ScheduleHandle = async () => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (scheduledate == '') {
            Swal.fire({
                icon: "error",
                text: 'Schedule Date should be must',
            });
        } else if (totalamount == '') {
            Swal.fire({
                icon: "error",
                text: 'Total Amount should be must',
            });
        }

        else {

            let obj = {
                // "schedule_email": scheduleemail,
                //                 "service_id": "1",
                //   "schedule_date": "2024-12-12",
                //   "amount": "340"
                "schedule_date": scheduledate,
                "total_amount": totalamount,
            }

            try {
                const response = await ApiConnection.post(`schedule-payment/?server_type=production&student_id=${id}`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                }
            } catch (e) { }
        }
    }

    const HoldHandle = async (id) => {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (startdate == '') {
            Swal.fire({
                icon: "error",
                text: 'Start Date should be must',
            });
        } else if (enddate == '') {
            Swal.fire({
                icon: "error",
                text: 'End Date should be must',
            });
        }

        else {

            let obj = {
                // "schedule_email": scheduleemail,
                "start_date": startdate,
                "end_date": enddate,
                "hold_duration": holdduration,
                "remarks": remarks,
                "status": "hold"
            }

            try {
                const response = await ApiConnection.post(`membership-discount-cruds/?server_type=production`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                }
            } catch (e) { }
        }
    }


    return (
        <div className='module_list'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <h5>Subscriptions</h5>
            <hr></hr>

            <div className='col-lg-12'>
                <div className='d-flex'>
                    <NavLink className='btn btn-primary mr-2 mb-1' style={{ width: '20%', fontSize: '13px' }} to={`/${userinfo?.company_slug}/StudentEnrollmentDetails/${id}`}>Enrollment Process</NavLink>
                    <button className='btn btn-primary mr-2 mb-1' style={{ width: '20%', fontSize: '13px' }} onClick={handleShow}>Add Service</button>
                    {/* <button className='btn btn-primary mr-2 mb-1' style={{ width: '20%', fontSize: '13px' }} onClick={handleShow1}>Hold</button> */}
                    <NavLink className='btn btn-primary mr-2 mb-1' style={{ width: '20%', fontSize: '13px' }} to={`/${userinfo?.company_slug}/students/ActiveServicesList/${id}`}>Active Services</NavLink>
                </div>
            </div>

            {EnrollmentList && EnrollmentList.length > 0 ?
                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th>Service Name</th>
                                <th>Start date</th>
                                <th>InActive Date</th>
                                <th>Recurring</th>
                                {/* <th>Type</th> */}
                                <th>Amount</th>
                                {/* <th>Holds Length</th> */}
                                <th>Allowed Sessions</th>
                                <th>Booked Sessions</th>
                                {/* <th>Delivery</th> */}
                                <th>Payment</th>
                                {/* <th>Link</th>
                                <th>Payment Status</th> */}
                                <th>Payment Date</th>
                                {/* <th>canceled Date</th> */}
                                <th>Next Payment Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {EnrollmentList && EnrollmentList.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item?.product}</td>
                                        <td>{moment(item?.current_period_start).format("YYYY-MM-DD")}</td>
                                        <td>{moment(item?.current_period_end).format("YYYY-MM-DD")}</td>
                                        <td>{item?.recurring}</td>
                                        {/* <td>{item?.training_youtube_link}</td> */}
                                        <td>{item?.amount}</td>
                                        {/* <td></td> */}
                                        <td>{item?.exam_url}</td>
                                        {/* <td>{item?.pass_mark}</td> */}
                                        <td>{item?.total_mark}</td>
                                        {/* <td><button className='formBtn assignbtn'>Pay Now</button></td> */}
                                        {/* <td><NavLink to={`/${userinfo?.company_slug}/students/PaymentLink/${item?.id}/${item?.company_id}/${id}`}>Pay Now</NavLink></td>
                                        <td><NavLink>Share</NavLink></td> */}
                                        <td style={{ color: item?.status === 'past_due' ? "red" : 'green', fontWeight: '500', fontSize: '14px', textAlign: 'center' }}>{item?.status}</td>
                                        <td>{moment(item?.created_time).format("YYYY-MM-DD")}</td>
                                        {/* <td>{moment(item?.canceled_at).format("YYYY-MM-DD")}</td> */}
                                        <td>{moment(item?.next_payment_date).format("YYYY-MM-DD")}</td>
                                        <td>
                                            <NavLink><i className="fa-solid fa-pen-to-square"></i></NavLink>
                                            <button ><i className="fa-solid fa-trash"></i></button>
                                        </td>
                                    </tr>
                                )
                            })}

                        </tbody>

                    </table>
                </div>
                :
                <h6>No data found</h6>
            }


            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header >
                    <Modal.Title>Add Service</Modal.Title>
                    <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Subject<span class="asterisk">*</span></label>
                                <select className='form-control' value={subject}
                                    onChange={SubjectHandle}>
                                    <option value="">--Select Subject---</option>
                                    {subjectList && subjectList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.subject_name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Service<span class="asterisk">*</span></label>
                                <select className='form-control' value={servicename}
                                    onChange={(e) => ServiceHandle(e)}>
                                    <option value="">--Select Services---</option>
                                    {serviceList && serviceList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Pricing<span class="asterisk">*</span></label>
                                <select className='form-control' value={pricename}
                                    onChange={(e) => PriceHandle(e)}>
                                    <option value="">--Select Price---</option>
                                    {priceList && priceList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )

                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Total Session<span class="asterisk">*</span></label>
                                <input type='text' disabled className='form-control' placeholder='Total Session' value={session1}
                                    onChange={(e) => { setsession1(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Schedule Duration<span class="asterisk">*</span></label>
                                {/* <input type='text' className='form-control' placeholder='Schedule Duration' value={scheduleduration}
                                    onChange={(e) => { setscheduleduration(e.target.value) }}
                                /> */}
                                <select className='form-control' value={scheduleduration}
                                    onChange={(e) => { setscheduleduration(e.target.value) }}>
                                    <option value="">--Select Duration---</option>
                                    <option value="30">30 min.</option>
                                    <option value="60">60 min.</option>
                                    <option value="90">90 min.</option>
                                    <option value="120">120 min.</option>
                                    <option value="150">150 min.</option>
                                    <option value="180">180 min.</option>
                                    <option value="210">210 min.</option>
                                    <option value="240">240 min.</option>


                                    {/* {priceList && priceList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )

                                    })} */}
                                </select>
                            </div>
                        </div>


                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Price' value={price1}
                                    onChange={(e) => { setprice1(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Contract Start Date<span class="asterisk">*</span> </label>
                                <input type='date' className='form-control' placeholder='Contract Start Date' value={contactstartdate}
                                    onChange={(e) => { setcontactstartdate(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Contract End Date<span class="asterisk">*</span> </label>
                                <input type='date' className='form-control' placeholder='Contract End Date' value={contactenddate}
                                    onChange={(e) => { setcontactenddate(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Recurring Type<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Recurring Type' value={recurring1}
                                    onChange={(e) => { setrecurring1(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Registration Fee<span class="asterisk">*</span></label>
                                <input type='text' className='form-control' placeholder='Registration Fee '
                                    value={enrollstartdate} onChange={(e) => { setenrollstartdate(e.target.value);
                                        ProratedamountHandle(enrolltotalsession);
                                    }} />
                            </div>
                        </div>

                        <hr></hr>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Prorated First Month Session<span class="asterisk">*</span> </label>
                                <input type='text' className='form-control' placeholder='Prorated First Session '
                                    value={enrolltotalsession} onChange={(e) => ProratedamountHandle(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Prorated First Month Price<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Prorated First Month '
                                    value={enrolltype} onChange={(e) => setenrolltype(e.target.value)} />

                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Select Discount</label>
                                <select className='form-control' value={discount}
                                    onChange={(e) => DiscountHandle(e)}>
                                    <option value="">--Select Discount---</option>
                                    {discountList && discountList.map((item, i) => {
                                        return (
                                            <option key={i} value={item?.id} >{item?.name}</option>
                                        )
                                    })}
                                </select>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Assessment Fee<span class="asterisk">*</span> </label>
                                <input type='text' className='form-control' placeholder='Assessment Fee '
                                    value={enrollinactivedate} onChange={(e) => setenrollinactivedate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount Amount </label>
                                <input type='text' disabled className='form-control' placeholder='Amount' value={discountAmount}
                                    onChange={(e) => { setdiscountAmount(e.target.value) }}
                                />
                            </div>
                        </div>


                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Sub Total 1<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Sub Total 1'
                                    value={Amount1} onChange={(e) => setAmount1(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount Percentage </label>
                                <input type='text' disabled className='form-control' placeholder='Percentage' value={discountPercentage}
                                    onChange={(e) => { DiscountPercentageAmount(e.target.value) }}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount</label>
                                <input type='text' disabled className='form-control' value={discountAmount}
                                    onChange={(e) => setenrollstartdate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group' style={{ marginTop: '40px', fontWeight: '600' }}>
                                <label className='selectlabel'> <u>Monthly Recurring Details</u> </label>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>

                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Price</label>
                                <input type='text' disabled className='form-control' value={price1}
                                    onChange={(e) => setenrollstartdate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Sub Total 2<span class="asterisk">*</span> </label>
                                <input type='text' disabled className='form-control' placeholder='Sub Total 2'
                                    value={Amount2} onChange={(e) => setAmount2(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Discount </label>
                                <input type='text' disabled className='form-control' placeholder='Discount' value={discountAmount}
                                    onChange={(e) => { setdiscountAmount(e.target.value) }}
                                />
                            </div>
                        </div>

                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>ACH Discount</label>
                                <input type='text' className='form-control' value={achdiscount}
                                    onChange={(e) => setenrollstartdate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>ACH Discount <input disabled style={{ width: '12%' }} value={achdiscount} /> %</label>
                                <input type='text' className='form-control' placeholder='ACH Discount' value={achdiscountamount}
                                    onChange={(e) => { setachdiscountamount(e.target.value) }}
                                />
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Payment Method</label>
                                <select className='form-control' value={paymentmethod}
                                    onChange={(e) => setpaymentmethod(e.target.value)}>
                                    <option value="" disabled>Payment Method</option>
                                    <option value="1">Card</option>
                                    <option value="2">Cash</option>
                                    <option value="3">Check</option>
                                    <option value="4">ACH</option>
                                </select>
                            </div>
                        </div>

                        {paymentmethod === '4' && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>ACH Discount</label>
                                    <input type='text' disabled className='form-control' value={achdiscountamountprice}
                                        onChange={(e) => setenrollstartdate(e.target.value)} />
                                </div>
                            </div>
                        )}

                        {paymentmethod === '4' && (
                            <div className='col-lg-6'>
                                <div className='form-group'>
                                    <label className='selectlabel'>ACH Discount <input disabled style={{ width: '12%' }} value={achdiscountamount} /> %</label>
                                    <input type='text' disabled className='form-control' placeholder='ACH Discount' value={achdiscountamountprice}
                                        onChange={(e) => { setachdiscountamountprice(e.target.value) }}
                                    />
                                </div>
                            </div>
                        )}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Total (Fututre Recuuring Months)</label>
                                <input type='text' disabled className='form-control' value={price1}
                                    onChange={(e) => setprice1(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>Today's Total<span class="asterisk">*</span> </label>
                                <input type='text' className='form-control' placeholder='Todays Total '
                                    value={TodaysAmount} onChange={(e) => setTodaysAmount(e.target.value)} />
                            </div>
                        </div>



                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label className='selectlabel'>ACH Discount <input disabled style={{width: '12%'}} value={achdiscount}/> %</label>
                                <input type='text' className='form-control' placeholder='ACH Discount' value={achdiscount}
                                    onChange={(e) => { setachdiscount(e.target.value) }}
                                />
                            </div>
                        </div> */}

                        <div className='col-lg-6 erolldetails' style={{ maxWidth: '47%' }}>
                            <span className='selectlabel'>Notes : </span><br></br>

                            <div className='form-group'>
                                <label className='selectlabel'>A/C Closing Fee <input disabled value={enrollmonthlyamount} onChange={(e) => setenrollmonthlyamount(e.target.value)} /></label><br></br>
                                <label className='selectlabel'>Late Fee <input disabled value={enrollremainings} onChange={(e) => setenrollremainings(e.target.value)} /></label><br></br>
                                <label className='selectlabel'>Manual Fee <input disabled value={enrollmanualfee} onChange={(e) => setenrollmanualfee(e.target.value)} /></label><br></br>
                            </div>
                        </div>




                        <div className='col-lg-6'>
                            <div className='form-group'>

                            </div>
                        </div>



                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={serviceinfoSubmit}>Add</button>
                </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Holds</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Start Date <span class="asterisk">*</span></label>
                                <input type='date' className='form-control' placeholder='Hold Start Date '
                                    value={startdate} onChange={(e) => setstartdate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>End Date <span class="asterisk">*</span></label>
                                <input type='date' className='form-control' placeholder='Hold End Date '
                                    value={enddate} onChange={(e) => setenddate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Total Hold Duration <span class="asterisk">*</span></label>
                                <input type='number' className='form-control' placeholder='Hold Duration '
                                    value={holdduration} onChange={(e) => setholdduration(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Remarks </label>
                                <input type='text' className='form-control' placeholder='Enter Remarks '
                                    value={remarks} onChange={(e) => setremarks(e.target.value)} />
                            </div>
                        </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={HoldHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2} size="lg">
                <Modal.Header >
                    <Modal.Title>Schedule Payments</Modal.Title>
                    <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>


                        {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Email Id <span class="asterisk">*</span></label>
                                <input type='email' className='form-control' placeholder='Email Id '
                                    value={scheduleemail} onChange={(e) => setscheduleemail(e.target.value)} />
                            </div>
                        </div> */}

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Schedule Date <span class="asterisk">*</span></label>
                                <input type='date' className='form-control' placeholder='Schedule Date '
                                    value={scheduledate} onChange={(e) => setscheduledate(e.target.value)} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Total Amount <span class="asterisk">*</span></label>
                                <input type='number' className='form-control' placeholder='Total Amount '
                                    value={totalamount} onChange={(e) => settotalamount(e.target.value)} />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={ScheduleHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show3} onHide={handleClose3} size="lg">
                <Modal.Header >
                    <Modal.Title>Trail Schedule</Modal.Title>
                    <button onClick={handleClose3} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                    <div className='col-lg-12 mr-4' style={{marginLeft: '225px'}}>
                    <img src={IMAGE.success_logo} alt="Image" />
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row mt-4'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Name :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b></b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row mt-4'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Service Name :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b></b>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Billing Address :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b></b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Recurring :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b></b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <label className='d-block'>Today's Payment :</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b></b>
                                </div>
                            </div>
                        </div>
                    </div>


                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='formBtn' onClick={HoldHandle}>Add</button> */}

                </Modal.Footer>
            </Modal>

        </div>

    )
}

export default Student_Enrollments