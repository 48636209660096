import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import { GetStudentAssesmentRequest, SubmitExamRequest, TakeexamRequest } from '../redux/reducer/FetchStudentDataReducer'
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'bootstrap';


const data = [
    {
        id:1,
        question:"Question one",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_a"
    },
    {
        id:2,
        question:"Question two",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_c"
    },
    {
        id:3,
        question:"Question two",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_c"
    },
    {
        id:4,
        question:"Question two",
        answer_a: "answer one",
        answer_b: "answer two",
        answer_c: "answer three",
        answer_d: "answer four",
        correct_answer: "answer_c"
    },

]

const Homework_List = () => {

    const [show, setShow] = useState(false);
    const [questionList, setQuestionList] = useState([])
    const [Index, setIndex] = useState(0)
    const [Newarry, setNewarry ] = useState([])
    const [ChooseAns, setChooseAns] = useState("")
    const [testId, settestId] = useState("")
    const [duration, setduration] = useState(0);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

  const fetchstudentReducer = useSelector((state) => state.FetchStudentDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var AssessmentList = fetchstudentReducer?.GetStudentAssesmentResponse?.results
  var QuestionListreducer = fetchstudentReducer?.TakeexamResponse?.results

  console.log(Newarry)

  const dispatch = useDispatch()

  useEffect(() => {
    
      dispatch(GetStudentAssesmentRequest())
  }, [])


  useEffect(() => {
    if (duration > 0) {
      const timerId = setInterval(() => {
        setduration((prevTime) => prevTime - 1);
      }, 60000);

      return () => clearInterval(timerId);
    }
  }, [duration]);


  const NextHandle = (index, qid) => {

    if(ChooseAns == ''){
        alert('Please select any one')
    } else {
        setIndex(index + 1)

        var TempArr = [...Newarry];
        TempArr[Index] = {answer: ChooseAns, question_number: QuestionListreducer[Index]?.question_no};
       setChooseAns('');
        setNewarry(TempArr); 
    }



}

const SkipHandle = (index) => {
    if(Index == QuestionListreducer.length - 1) {
        alert("Please select") 
  }  else {
    setIndex(index + 1)
    
    var TempArr = [...Newarry];
    TempArr[Index] = {answer: '', question_number: QuestionListreducer[Index]?.question_no};
    setChooseAns('');
    setNewarry(TempArr); 
}
} 
const BackHandle = (index) => {
    setIndex(index - 1)
    setChooseAns(Newarry[index - 1]?.answer)
    // var TempArr = [...Newarry];
    // TempArr[Index] = '';
    // setChooseAns('');
    // setNewarry(TempArr); 

}
const RadioHandle = (value, index, key) => {
    console.log(value)
    console.log(key);
    setChooseAns(key)
    var TempArr = [...Newarry];
    TempArr[Index] = {answer: key, question_number: QuestionListreducer[Index]?.question_no};
    setNewarry(TempArr); 
}   

const ModalHandler = (id, time) =>{
    setduration(Number(time))
    dispatch(TakeexamRequest(id))
    setShow(true);
    settestId(id)
}

// useEffect(()=>{
 
//     setQuestionList(QuestionListreducer)
// },[fetchstudentReducer?.TakeexamResponse])

const SubmitExam = () =>{
    if (ChooseAns == '') {
        alert("Please Attempt All Questions") 
    } else {
        let obj = {
            exam_questions_list:Newarry
        }
        dispatch(SubmitExamRequest({test_id:testId,student_id:userinfo?.user_id,obj:obj }))
    }
}

  const goToQuestion = (index) => {
    console.log("goToQuestion", index)
    if(Index == QuestionListreducer.length - 1) {
        alert("Please select") 
  }  else {
    setIndex(index + 1)
    
    var TempArr = [...Newarry];
    TempArr[Index] = {answer: '', question_number: QuestionListreducer[Index]?.question_no};
    setChooseAns('');
    setNewarry(TempArr); 
}
  }

useEffect(()=>{
    setShow(false);
    setduration(0)
    dispatch(GetStudentAssesmentRequest())
},[fetchstudentReducer?.SubmitExamResponse])

 
 





  return (
        <div className='common-layout'>
            <Loader visible={fetchstudentReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Homework List </h3>
                </div>

                {AssessmentList && AssessmentList.length > 0 ?
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Homework Name</th>
                                    <th>Date</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {AssessmentList && AssessmentList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                {/* {item?.is_exam_taken == 0 ?
                                                <button className="btn btn-sm btn-primary m-0" onClick={()=>ModalHandler(item?.test_id, item?.time )}>Take Test</button>
                                                :
                                                <>
                                                <NavLink to={`/${userinfo?.company_slug}/application/student/reportcard/${item?.test_id}`}>(View Result)</NavLink>
                                                </>
                                                 } */}
                                          
                                                </td>
                                        </tr>
                                    )
                                })}





                            </tbody>

                        </table>
                    </div>
                    :
                    <h6>No data found</h6>
                }


            </div>
        </div>
  )
}

export default Homework_List