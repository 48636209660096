import {takeEvery,takeLatest, call, put, select } from 'redux-saga/effects'
import { getApi, postApi, getApiWithParam, putApi, patchApi } from '../../utils/ApiRequest';
import Swal from "sweetalert2";


import {
    GetStudentAssesmentSuccess,
    GetStudentAssesmentFailure,
    TakeexamSuccess,
    TakeexamFailure,
    GetProgressReportSuccess,
    GetProgressReportFailure,
    SubmitExamSuccess,
    SubmitExamFailure,
    GetAnswerSuccess,
    GetAnswerFailure,
    GetAssessmentAnswerSuccess,
    GetAssessmentAnswerFailure,

    
  } from '../reducer/FetchStudentDataReducer';
  
  const token = sessionStorage.getItem('mtb_access_token');
  

  
  function* GetStudentAssesmentRequest() {
  
    let header = {
        Accept: 'application/json',
        contenttype: 'application/json',
        accesstoken:token
      };
  
      try {
  
        let response = yield call(getApi, 'assigned-student-test-lists/?server_type=production', header);
  
        if(response.status === 200) {
          yield put(GetStudentAssesmentSuccess(response.data))
        } else {
          yield put(GetStudentAssesmentFailure(response.data))
        }
  
      } catch(err){
        
      }
  
    
  }

  function* TakeexamRequest(action) {
  
    let header = {
        Accept: 'application/json',
        contenttype: 'application/json',
        accesstoken:token
      };
  
      try {
  
        let response = yield call(getApi, `questions-list/?server_type=production&test_id=${action.payload}`, header);
  
        if(response.status === 200) {
          yield put(TakeexamSuccess(response.data))
        } else {
          yield put(TakeexamFailure(response.data))
        }
  
      } catch(err){
        
      }
  
    
  }

  function* SubmitExamRequest(action) {
  
    let header = {
        Accept: 'application/json',
        contenttype: 'application/json',
        accesstoken:token
      };
  
      try {
  
        let response = yield call(postApi, `exam-create/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}`, action.payload.obj, header);
  
        if(response.status === 200) {
          yield put(SubmitExamSuccess(response.data))
          Swal.fire({
            icon: "success",
            text: response?.data?.msg,
          });
        } else {
          yield put(SubmitExamFailure(response.data))
          Swal.fire({
            icon: "error",
            text: response.data?.message,
          });
        }
  
      } catch(err){
        yield put(SubmitExamFailure(err?.response?.data))
      }
  
    
  }



  function* GetProgressReportRequest(action) {
    console.log("GetProgressReportRequest", action)
  
    let header = {
        Accept: 'application/json',
        contenttype: 'application/json',
        accesstoken:token
      };
  
      try {
  
        let response = yield call(getApi, `exam-reslut-details/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}`, header);
  
        if(response.status === 200) {
          yield put(GetProgressReportSuccess(response.data))
        } else {
          yield put(GetProgressReportFailure(response.data))
        }
  
      } catch(err){
        
      }
    
  }

  function* GetAnswerRequest(action) {
    console.log("GetAnswerRequest", action)
  
    let header = {
        Accept: 'application/json',
        contenttype: 'application/json',
        accesstoken:token
      };
  
      try {
  
        let response = yield call(getApi, `exam-details/?server_type=production&test_id=${action.payload.test_id}&student_id=${action.payload.student_id}`, header);
  
        if(response.status === 200) {
          yield put(GetAnswerSuccess(response.data))
        } else {
          yield put(GetAnswerFailure(response.data))
        }
  
      } catch(err){
        
      }
    
  }

  function* GetAssessmentAnswerRequest(action) {

    let header = {
      Accept: 'application/json',
      contenttype: 'application/json',
      accesstoken: token
    };
  
    try {
  
      let response = yield call(getApi, `questions-list-with-answer/?server_type=production&test_id=${action.payload.test_id}`, header);
  
      if (response.status === 200) {
        yield put(GetAssessmentAnswerSuccess(response.data))
      } else {
        yield put(GetAssessmentAnswerFailure(response.data))
      }
  
    } catch (err) {
      yield put(GetAssessmentAnswerFailure(err.data))
    }
  
  }
  
  
  const watchFunction = [

    (function* () {
      yield takeLatest('FetchStudentDataReducer/SubmitExamRequest', SubmitExamRequest)
    })(),
   
    (function* () {
      yield takeLatest('FetchStudentDataReducer/TakeexamRequest', TakeexamRequest)
    })(),
  
    (function* () {
      yield takeLatest('FetchStudentDataReducer/GetStudentAssesmentRequest', GetStudentAssesmentRequest)
    })(),

    (function* () {
      yield takeLatest('FetchStudentDataReducer/GetProgressReportRequest', GetProgressReportRequest)
    })(),
   
    (function* () {
      yield takeLatest('FetchStudentDataReducer/GetAnswerRequest', GetAnswerRequest)
    })(),
  
    (function* () {
      yield takeLatest('FetchStudentDataReducer/GetAssessmentAnswerRequest', GetAssessmentAnswerRequest)
    })(),
  
    ];
  
  
  
  
  export default watchFunction;