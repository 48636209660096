import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import { GetStudentAssesmentRequest, SubmitExamRequest, TakeexamRequest } from '../redux/reducer/FetchStudentDataReducer'
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'bootstrap';
import moment from "moment";
import ApiConnection from '../utils/ApiConnection'

const ServiceList = () => {
    const [show, setShow] = useState(false);
    const [questionList, setQuestionList] = useState([])
    const [Index, setIndex] = useState(0)
    const [Newarry, setNewarry ] = useState([])
    const [ChooseAns, setChooseAns] = useState("")
    const [testId, settestId] = useState("")
    const [duration, setduration] = useState(0);
    const [EnrollmentList, setEnrollmentList] = useState([])
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const handleClose = () => setShow(false);
    const handleShow1 = () => setShow1(true);

  const fetchstudentReducer = useSelector((state) => state.FetchStudentDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))

  const dispatch = useDispatch()
  const { id } = useParams()
  let navigate = useNavigate()

  useEffect(() => {
      dispatch(GetStudentAssesmentRequest())
  }, [])

  const GetEnrollmentList = async () => {

    try {
        const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${userinfo?.user_id}`)
        // const response = await ApiConnection.get(`stripe-payment-list/`)
        if (response?.status == 200) {
            console.log("get Enrollment", response.data.results)
            setEnrollmentList(response?.data?.results)
        }
    } catch (e) { }
}

useEffect(() => {
    GetEnrollmentList();
}, [])

  const CancelHandle = async (srvid) => {
    Swal.fire({
       title: 'Are you sure?',
       text: "You want to cancel this Service!",
       icon: 'warning',
       showCancelButton: true,
       confirmButtonColor: '#3085d6',
       cancelButtonColor: '#d33',
       confirmButtonText: 'Yes, Cancel it!'
   }).then(async (result) => {
       if (result.isConfirmed) {
           const response = await ApiConnection.post(`service-subscription-cancel/?server_type=production&student_id=${id}&service_id=${srvid}`)
           console.log(srvid, id)
           if (response?.status == 200) {
               Swal.fire(
                   'Cancelled!',
                   'Your Service has been Cancelled.',
                   'success'
               )
               GetEnrollmentList();
           }

       }
   })
};



  return (
        <div className='common-layout'>
            <Loader visible={fetchstudentReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Service Management </h3>
                </div>

                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th>Service Name</th>
                                <th>Start date</th>
                                <th>End Date</th>
                                <th>Recurring</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Allowed Sessions</th>
                                <th>Delivery</th>
                                {/* <th>Action</th> */}
                            </tr>
                        </thead>
                        {EnrollmentList && EnrollmentList.length > 0 ?
                        <tbody>
                            {EnrollmentList && EnrollmentList.map((item, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{item?.service_name}</td>
                                        <td>{moment(item?.contract_start_date).format("YYYY-MM-DD")}</td>
                                        <td>{moment(item?.contract_end_date).format("YYYY-MM-DD")}</td>
                                        <td>{item?.recurring}</td>
                                        <td>{item?.todays_total_fee}</td>
                                        <td></td>
                                        <td>{item?.exam_url}</td>
                                        <td>{item?.total_mark}</td>
                                        {/* <td>
                                            <button className='formBtn text-info assignbtn' onClick={() => handleShow1(item.id)}>Hold</button>
                                            <button className='formBtn assignbtn'onClick={() => CancelHandle(item.id)}>Cancel</button>
                                        </td> */}
                                    </tr>
                                )
                            })}

                        </tbody>
                                        :
                                        <td colSpan="8" className='text-center'>No data found</td>
                                    }  

                    </table>
                </div>


            </div>
        </div>
  )
}

export default ServiceList