import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetProspectiveRequest, GetLocationRequest, ExportProspectiveRequest, DeleteprospectsRequest, ViewprospectsRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import moment from "moment";

const ProspectsList = () => {
  const column = [
    {
      "key": "First Name",
      "value":"First_Name",
      "ischecked":false
    },
    {
      "key": "Last Name",
      "value":"Last_Name",
      "ischecked":false
    },
    {
      "key": "Status",
      "value":"Status",
      "ischecked":false
    },
    {
      "key": "Mobile Phone",
      "value":"Mobile_Phone",
      "ischecked":false
    },
    {
      "key": "Other Phone",
      "value":"Other_Phone",
      "ischecked":false
    },
    {
      "key": "Email",
      "value":"Email",
      "ischecked":false
    },
    {
      "key": "Address",
      "value":"Street_Address_1",
      "ischecked":false
    },
    {
      "key": "Description",
      "value":"Description",
      "ischecked":false
    },
    {
      "key": "Customer Description",
      "value":"Customer_Description",
      "ischecked":false
    },
    {
      "key": "Propect Rating",
      "value":"Propect_Rating",
      "ischecked":false
    },
    {
      "key": "Country",
      "value":"Country_Name",
      "ischecked":false
    },
    {
      "key": "State",
      "value":"State_Name",
      "ischecked":false
    },
    {
      "key": "City",
      "value":"City_Name",
      "ischecked":false
    },
    
    
  ]
  const [numberpagination, setnumberpagination] = useState([])
  const [active, setactive] = useState(0)
  const [location, setlocation] = useState("")
  const [name, setname] = useState("")
  const [status, setstatus] = useState("")
  const [show, setShow] = useState(false);
  const [columnName, setColumnName] = useState(column)

  const handleClose = () => setShow(false);
 

  const dispatch = useDispatch()
  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var propectList = fetchacompanyReducer?.GetProspectiveResponse?.results
  var locationList = fetchacompanyReducer?.GetLocationResponse?.results
 
  const handleShow = () => {
    setShow(true)
    setColumnName(column)
      const link = document.getElementById("Download");
      link.removeAttribute("href");
  };

  useEffect( ()=>{

    let obj= {
      "page_size":10,
      'page':1,
      'name':'',
      'status':'',
      'location_id':'',
      'employee_id': userinfo.user_id
    }

    dispatch(GetProspectiveRequest(obj))
    dispatch(GetLocationRequest())

  },[])
function isEmpty(item){
  if(item == '' || item == null ||item == undefined) return true; return false;
}
  useEffect(()=>{
    if(!isEmpty(fetchacompanyReducer?.GetProspectiveResponse)){

      if(fetchacompanyReducer?.GetProspectiveResponse?.total_stats && fetchacompanyReducer?.GetProspectiveResponse?.total_stats.length > 0 )

      var totalLength = fetchacompanyReducer?.GetProspectiveResponse?.total_stats[0]?.total_count

      console.log(totalLength)
  
      var count = totalLength / 10
      var roundof = Math.ceil(count)
      let pagArr = []
  
      for(let i=0; i<roundof; i++){
        pagArr.push(i)
      }
      setnumberpagination(pagArr)
    }
  },[fetchacompanyReducer?.GetProspectiveResponse])

 

  const paginateHandle = (number) =>{
    setactive(number - 1)
    console.log(number)
    let obj= {
      "page_size":10,
      'page':number,
      'name':'',
      'status':'',
      'location_id':'',
      'employee_id': userinfo.user_id
    }

    dispatch(GetProspectiveRequest(obj))
  }

  const SearchHandle = ()=>{
    let obj= {
      "page_size":10,
      'page':1,
      'name':name,
      'status':status,
      'location_id':location,
      'employee_id': userinfo.user_id
    }

    dispatch(GetProspectiveRequest(obj))
  }

  const ResetHandle = ()=>{
    setname("")
    setstatus("")
    setlocation("")
    let obj= {
      "page_size":10,
      'page':1,
      'name':'',
      'status':'',
      'location_id':'',
      'employee_id': userinfo.user_id
    }

    dispatch(GetProspectiveRequest(obj))
  }


  const CheckboxHandle = (index, e)=>{
    const { value, checked } = e.target;
      let raw = [...columnName]
      if(checked){
        raw[index].ischecked = true
      } else {
        raw[index].ischecked = false
      }
      setColumnName(raw)
  }

  const ExportHandle = ()=>{

    let Arr = []

    columnName.forEach(element => {

      if(element.ischecked){
        Arr.push(element.value)
      }
    
    });

    let obj = {
      "column_selector":Arr
    }

    if(obj.column_selector.length > 1){
      dispatch(ExportProspectiveRequest(obj))
      setShow(false)
    } else {
      Swal.fire({
        icon: "error",
        text: 'Choose atleast two columns',
      });
    }


  }

//   useEffect(()=>{
//     let obj= {
//       "page_size":10,
//       'page':1,
//       'name':'',
//       'status':'',
//       'location_id':''
//     }

//     dispatch(ViewprospectsRequest(obj))
// },[fetchacompanyReducer?.MakePrimaryResponse])

  useEffect(()=>{
    let obj= {
      "page_size":10,
      'page':1,
      'name':'',
      'status':'',
      'location_id':'',
      'employee_id': userinfo.user_id
    }

    dispatch(GetProspectiveRequest(obj))
},[fetchacompanyReducer?.DeleteprospectsResponse])
 

  const DeleteHandle = (id)=>{
    Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.isConfirmed) {
            dispatch(DeleteprospectsRequest(id))

            if(fetchacompanyReducer?.DeleteprospectsResponse){
                Swal.fire(
                    'Deleted!',
                    'Your file has been deleted.',
                    'success'
                  )
            }
         
        }
      })
}
  


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading}  />
        <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3>Prospect Management</h3>
          </div>
          <div className='row form-style mb-4 align-items-center'>
            <div className='col-lg-3'>
                <div className='form-group'>
                    <label>Name</label>
                    <input type='text' className='form-control' placeholder='Search..'
                    value={name}
                    onChange={(e)=>setname(e.target.value)}
                    />
                </div>
            </div>
           
            <div className='col-lg-3'>
           <div className='form-group'>
           <label>Location</label>
              <select className='form-control'  value={location}
               onChange={(e)=>setlocation(e.target.value)}>
                <option value="">--Location---</option>
                {locationList&&locationList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.location_name}</option>
                    )
                })}
              </select>
           </div>
       </div>
       <div className='col-lg-2'>
                <div className='form-group'>
                    <label>Status</label>
                   <select className='form-control' value={status}
                    onChange={(e)=>setstatus(e.target.value)}>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    
                   </select>
                </div>
            </div>
            <div className='col-lg-4'>
                <div className='form-group'>
                    <label className='d-block'>&nbsp;</label>
                    <div className='d-flex'>
                    <button className='btn btn-primary mr-3' onClick={SearchHandle}>Search</button>
                    <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
                </div>
                </div>
            </div>
        </div>
        <div className='button-part mb-4'>
            <ul>
              <li>
                <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/prospects/add-prospects`}><i className="fa-solid fa-plus"></i> Add Prospects</NavLink>
              </li>
              <li>
                  <button className='formBtn1'  onClick={handleShow}><i className="fa-solid fa-file-excel"></i> Export </button>
              </li>
            </ul>
        </div>
        <a id="Download" download></a>
      {propectList&&propectList.length > 0  ?
        <div className='table-responsive'>
            <table className='custom-table'>
              <thead>
                <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Student Name</th>
                <th>Mobile no</th>
                <th>Email</th>
                <th>Created Date</th>
                <th>Status</th>
                <th>Rating</th>
                <th>Last Contacted</th>
                <th>Last Assessment</th>
                <th>Last Consultation</th>
                <th>E-Register Sent On</th>
                <th>Location</th>
                <th>Action</th>
                </tr>
              </thead>
               <tbody>
               {propectList&&propectList.map((item, index)=>{
                return (
                  <tr key={index}>
                  <td>{item?.first_name}</td>
                  <td>{item?.last_name}</td>
                  <td>{item?.student_list?.[0]?.student_name}</td>
                  <td>{item?.mobile_phone}</td>
                  <td>{item?.email}</td>
                  <td>{moment(item?.created_at).format('YYYY-MM-DD')}</td>
                  <td>{item?.propect_status}</td>
                  <td>{item?.propect_rating}</td>
                  <td>{item?.last_contacted}</td>
                  <td>{item?.last_assessment}</td>
                  <td>{item?.last_consultation}</td>
                  <td>{item?.e_register_date}</td>
                  <td>{item?.location_name}</td>

                  <td>
                  <NavLink to={`/${userinfo?.company_slug}/prospects/view/${item?.id}/${item?.user_id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                  
                  <NavLink to={`/${userinfo?.company_slug}/prospects/edit/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                   <button onClick={()=>DeleteHandle(item?.id)}><i className="fa-solid fa-trash"></i></button>
                  </td>
              </tr>
                )
               })}  
               
               

               </tbody>

            </table>
        </div>
        :
        <h6>No data found</h6>
}
{numberpagination.length > 10 &&
<ul className='pagination'>
  {numberpagination&&numberpagination.map((number, i)=>{
    return (
      <li key={i}><button className={active == number ? 'active':null} onClick={()=>paginateHandle(number + 1)}>{number + 1}</button></li>
    )
  })}
 
</ul>
}

        </div>
        <Modal show={show} onHide={handleClose}  size="lg">
        <Modal.Header >
          <Modal.Title>Export CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className='mb-3'>Select Column</h6>
          <ul className='columnlist'>
            {columnName.map((column, index)=>{
              return (
                <li key={index}>
                <input type="checkbox" name="column"  id={`column${index}`} value={column.value} onChange={(e)=>CheckboxHandle(index,e)} />
                <label htmlFor={`column${index}`}>{column.key}</label>
            </li>
              )
            
            })}
            
          </ul>
          
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-warning' onClick={handleClose}>
            Close
          </button>
          <button className='btn btn-primary' onClick={ExportHandle}>
            Export Now
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ProspectsList