import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddSyllabusRequest, GetSubjectChangeRequest, GetAllGroupListRequest, GetGradeRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";

const AddSyllabus = () => {
    const [gname, setgname] = useState("")
    const [grade, setgrade] = useState("")
    const [subject, setsubject] = useState("")
    const [code, setcode] = useState("")
    const [sname, setsname] = useState("")
    const [sno, setsno] = useState("")
    const [tags, settags] = useState("")
    const [description, setdescription] = useState("")

    


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const groupList = fetchacompanyReducer?.GetAllGroupListResponse?.results
    var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
    var subjectList = fetchacompanyReducer?.GetSubjectChangeResponse?.results

    console.log(fetchacompanyReducer?.GetAllGroupListResponse)


   useEffect(()=>{
    dispatch(GetAllGroupListRequest())
   },[]) 

   useEffect(()=>{
    dispatch(GetAllGroupListRequest())
   },[]) 

   useEffect(()=>{
    dispatch(GetGradeRequest())
   },[]) 

    console.log(subjectList)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const SubjectHandle = (e) => {
        setgname(e.target.value)
        let obj = {
            id: e.target.value
        }
        dispatch(GetSubjectChangeRequest(obj))
    }

const SubmitHandle = () =>{
    if(gname == ''){
        Swal.fire({
            icon: "error",
            text: 'Grade name should be must',
          });
        } else  if(code == ''){
            Swal.fire({
                icon: "error",
                text: 'Code should be must',
              });
            } else  if(description == ''){
                Swal.fire({
                    icon: "error",
                    text: 'Description should be must',
                  });
            } else  if(grade == ''){
                    Swal.fire({
                        icon: "error",
                        text: 'Grade should be must',
                      });
                    } else  if(subject == ''){
                        Swal.fire({
                            icon: "error",
                            text: 'Subject type should be must',
                          });
                        }
                        else  if(sname == ''){
                            Swal.fire({
                                icon: "error",
                                text: 'Subject name should be must',
                              });
                            }
                            else  if(sno == ''){
                                Swal.fire({
                                    icon: "error",
                                    text: 'Subject no should be must',
                                  });
                                }
                                else  if(tags == ''){
                                    Swal.fire({
                                        icon: "error",
                                        text: 'Tags should be must',
                                      });
                                    }
                else {

                    let obj = {
                        "grade_id": grade,
                        "group_id": gname,
                        "subject_id": subject,
                        "code": code,
                        "name": sname,
                        "number": sno,
                        "status": "active",
                        "tags": tags,
                        "description": description
                    }

                    dispatch(AddSyllabusRequest({obj:obj, navigate:navigate}))

                }
}


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
    <div className='innerlayout p-3'>
    <div className='mb-4'>
      <h3> Add Syllabus</h3>
      </div>
      <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
      <div className='row form-style mb-4'>
 
      <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Select Grade <span class="asterisk">*</span></label>
              <select className='form-control'  value={grade}
               onChange={(e)=>setgrade(e.target.value)}>
                <option value="">--Grade Name---</option>
                {gradeList&&gradeList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.grade_name}</option>
                    )
                })}
              </select>
           </div>
       </div>
       
      <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Select Group <span class="asterisk">*</span></label>
              <select className='form-control'  value={gname}
               onChange={SubjectHandle}>
                <option value="">--Group Name---</option>
                {groupList&&groupList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.name}</option>
                    )
                })}
              </select>
           </div>
       </div>

       <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Select Subject <span class="asterisk">*</span></label>
              <select className='form-control'  value={subject}
               onChange={(e)=>setsubject(e.target.value)}>
                <option value="">--Subject Type---</option>
                {subjectList&&subjectList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.subject_name}</option>
                    )
                })}
              </select>
           </div>
       </div>
       <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Syllabus Name <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Syllabus Name'
               value={sname}
               onChange={(e)=>setsname(e.target.value)}
               />
           </div>
       </div>
       <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Syllabus Code <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Syllabus Code'
               value={code}
               onChange={(e)=>setcode(e.target.value)}
               />
           </div>
       </div>
       <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Syllabus No. <span class="asterisk">*</span></label>
               <input type='number' className='form-control' placeholder='Syllabus No'
               value={sno}
               onChange={(e)=>setsno(e.target.value)}
               />
           </div>
       </div>
      
       <div className='col-lg-4'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Tags <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Tags'
               value={tags}
               onChange={(e)=>settags(e.target.value)}
               />
           </div>
       </div>
       <div className='col-lg-12'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Description <span class="asterisk">*</span></label>
              <textarea className='form-control' placeholder='Write Description'
              value={description}
              onChange={(e)=>setdescription(e.target.value)}
              >

              </textarea>
           </div>
       </div>
   </div>
   <button className='formBtn' onClick={SubmitHandle}>Save</button>
    </div>

  </div>
  )
}

export default AddSyllabus