import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddTitleRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";

const AddTitle_CP = () => {
    const [tname, settname] = useState("")



    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetTitleResponse       )

    const dispatch = useDispatch()
    let navigate = useNavigate()

const SubmitHandle = () =>{
    if(tname == ''){
        Swal.fire({
            icon: "error",
            text: 'Name should be must',
          });
        } else {

                    let obj = {
                        "name": tname,
                    }

                    dispatch(AddTitleRequest({obj:obj, navigate:navigate}))

                }
}


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
    <div className='innerlayout p-3'>
    <div className='mb-4'>
      <h3> Add Title</h3>
      </div>
      <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-50px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
      <div className='row form-style mb-4'>
 
       <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Title Name <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Title Name'
               value={tname}
               onChange={(e)=>settname(e.target.value)}
               />
           </div>
       </div>
  
      
   </div>
   <button className='formBtn' onClick={SubmitHandle}>Save</button>
    </div>

  </div>
  )
}

export default AddTitle_CP