import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { FetchSyllabusRequest, GetModuleRequest, AddModuleRequest, GetTypeRequest, FetchModuleRequest, UpdateModuleRequest, DeleteModuleRequest, GetAllGroupListRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import Modal from 'react-bootstrap/Modal';
import DataTable from 'react-data-table-component';

const ViewSyllabus = () => {
    const [show, setShow] = useState(false);
    const [gname, setgname] = useState("")
    const [tags, settags] = useState("")
    const [description, setdescription] = useState("")
    const [code, setcode] = useState("")
    const [mno, setmno] = useState("")
    const [mname, setmname] = useState("")
    const [tname, settname] = useState("")
    const [show1, setShow1] = useState(false);

    const [gname1, setgname1] = useState("")
    const [tags1, settags1] = useState("")
    const [description1, setdescription1] = useState("")
    const [code1, setcode1] = useState("")
    const [mno1, setmno1] = useState("")
    const [mname1, setmname1] = useState("")
    const [tname1, settname1] = useState("")
    const [moduleId, setmoduleId] = useState(null)
    const [sgroup, setsgroup] = useState("")
    const [search, setsearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose1 = () => setShow1(false);
   
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    const Viewdata = fetchacompanyReducer?.FetchSyllabusResponse?.results[0]
    const moduleList = fetchacompanyReducer?.GetModuleResponse?.results
    const groupList = fetchacompanyReducer?.GetAllGroupListResponse?.results
    const TypeList = fetchacompanyReducer?.GetTypeResponse?.results



    console.log(fetchacompanyReducer?.FetchModuleResponse)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const columns = [
        {
            name: 'Module Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Module Code',
            selector: row => row.code,
            sortable: true,
        },
        {
          name: 'Module Number',
          selector: row => row.number,
          sortable: true,
      },
      {
        name: 'Group Name',
        selector: row => row.group_name,
        sortable: true,
    },

      {
        name: 'Action',
        selector: (row) => (
          <>
           
          <NavLink to={`/${userinfo?.company_slug}/curriculum/view-module/${row?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                        <button className='table-btn' onClick={()=>handleShow1(row?.id)}><i className="fa-solid fa-pen-to-square"></i></button>
                       
                         <button className='table-btn' onClick={()=>DeleteHandle(row?.id)}><i className="fa-solid fa-trash"></i></button>
         
          </>
        ),
    },
    ];
  
    useEffect(()=>{
      const result = moduleList && moduleList.filter(data =>{
        return data.name.toLowerCase().match(search.toLowerCase())
      })
      setFilterResult(result)
    },[search])

    const {id} = useParams()

   useEffect(()=>{

        let obj ={
            id:id
        }

        let obj1 = {
            'name':'',
            'group_id':'',
            "syllabus_id": Number(id),
        }

        dispatch(FetchSyllabusRequest(obj))

        dispatch(GetModuleRequest(obj1))

        dispatch(GetTypeRequest())

        dispatch(GetAllGroupListRequest())

    },[])

    useEffect(()=>{
        let obj1 = {
            'name':'',
            'group_id':'',
            "syllabus_id": Number(id),
        }
        dispatch(GetModuleRequest(obj1))
        setShow(false)
    },[fetchacompanyReducer?.AddModuleResponse])


    const AddModule = ()=>{
        if(gname == ''){
            Swal.fire({
                icon: "error",
                text: 'Group name should be must',
              });
            } else if(code == ''){
                Swal.fire({
                    icon: "error",
                    text: 'Module code should be must',
                  });
                } else if(mname == ''){
                    Swal.fire({
                        icon: "error",
                        text: 'Module name should be must',
                      });
                    }  else if(mno == ''){
                        Swal.fire({
                            icon: "error",
                            text: 'Module no should be must',
                          });
                        }  else if(tags == ''){
                            Swal.fire({
                                icon: "error",
                                text: 'Tags should be must',
                              });
                            } else {
                                let obj = {
                                    "syllabus_id": Number(id),
                                    "group_id": Number(gname),
                                    "code": code,
                                    "name": mname,
                                    "number": Number(mno),
                                    "status": "active",
                                    "tags": tags,
                                    "type_id": Number(tname),
                                    "description": description
                                }


                                dispatch(AddModuleRequest(obj))

                            }
    }

    const handleShow1 = (id) =>{
        setmoduleId(id)
        dispatch(FetchModuleRequest(id))
        setShow1(true)
    };

    useEffect(()=>{
        var editinfo = fetchacompanyReducer?.FetchModuleResponse?.results[0]
        setgname1(editinfo?.group_id)
        settags1(editinfo?.tags)
        setdescription1(editinfo?.description)
        setcode1(editinfo?.code)
        setmno1(editinfo?.number)
        setmname1(editinfo?.name)
        settname1(editinfo?.type_id)
    },[fetchacompanyReducer?.FetchModuleResponse])

    useEffect(()=>{
        const Viewdata = fetchacompanyReducer?.FetchSyllabusResponse?.results[0]
        setgname(Viewdata?.group_id)

    },[fetchacompanyReducer?.FetchSyllabusResponse])

    const UpdateModule = ()=>{
        if(gname1 == ''){
            Swal.fire({
                icon: "error",
                text: 'Group name should be must',
              });
            } else if(code1 == ''){
                Swal.fire({
                    icon: "error",
                    text: 'Module code should be must',
                  });
                } else if(mname1 == ''){
                    Swal.fire({
                        icon: "error",
                        text: 'Module name should be must',
                      });
                    }  else if(mno1 == ''){
                        Swal.fire({
                            icon: "error",
                            text: 'Module no should be must',
                          });
                        }  else if(tags1 == ''){
                            Swal.fire({
                                icon: "error",
                                text: 'Tags should be must',
                              });
                            } else {
                                let obj = {
                                    "syllabus_id": Number(id),
                                    "group_id": Number(gname1),
                                    "code": code1,
                                    "name": mname1,
                                    "number": Number(mno1),
                                    "status": "active",
                                    "tags": tags1,
                                    "type_id": Number(tname1),
                                    "description": description1
                                }


                                dispatch(UpdateModuleRequest({obj:obj, id:moduleId}))

                            }
    }

    useEffect(()=>{
        let obj1 = {
            'name':'',
            'group_id':'',
            "syllabus_id": Number(id),
        }
        dispatch(GetModuleRequest(obj1))
        setShow1(false)
    },[fetchacompanyReducer?.UpdateModulResponse])

    const DeleteHandle = (id)=>{
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteModuleRequest(id))

                if(fetchacompanyReducer?.DeleteModuleResponse){
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                      )
                }
             
            }
          })
    }

    useEffect(()=>{
        let obj1 = {
            'name':'',
            'group_id':'',
            "syllabus_id": Number(id),
        }
        dispatch(GetModuleRequest(obj1))
    },[fetchacompanyReducer?.DeleteModuleResponse])
    

  
      const ResetHandle = ()=>{
        setsearch("")
        setsgroup("")
        let obj1 = {
            'name':'',
            'group_id':'',
            "syllabus_id": Number(id),
        }
    
        dispatch(GetModuleRequest(obj1))
      }


      const GroupHandle = (e)=>{
        setsgroup(e.target.value)
        var val = e.target.value
  
        let obj1 = {
            'name':search,
            'group_id':val,
            "syllabus_id": Number(id),
        }
    
        dispatch(GetModuleRequest(obj1))
      }
    useEffect(()=>{
  
      setFilterResult(moduleList)
    },[fetchacompanyReducer?.GetModuleResponse])


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
    <div className='innerlayout p-3'>
    <div className='mb-4'>
      <h3> View Syllabus</h3>
      </div>
      <div className='button-part mb-4' style={{marginLeft:'910px', marginTop:'-50px'}}>
                    <ul>
                        <li>
                        <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>

                    </ul>
                </div>

      <div className='row view-page'>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Syllabus Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{Viewdata?.name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Subject Code:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{Viewdata?.code}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Group Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{Viewdata?.group_name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Grade Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{Viewdata?.grade_name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Subject Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{Viewdata?.subject_name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Tags:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{Viewdata?.tags}</b>
                         </div>
                    </div>
               </div>
          </div>
      </div>
      <div className='module_list'>
      <h5>Module</h5>
      <div className='row form-style mt-3'>
        <div className='col-lg-3'>
        <div className='form-group m-0'>
                <label>Module Name</label>
            <input type='text' className='form-control' placeholder='Enter Module'
            value={search}
            onChange={(e)=>setsearch(e.target.value)}
            />
                </div>
        </div>
     
                {/* <div className='col-lg-3'>
           <div className='form-group'>
           <label>Select Group</label>
              <select className='form-control'  value={sgroup}
               onChange={(e)=>GroupHandle(e)}>
                <option value="">--All---</option>
                {groupList&&groupList.map((item, i)=>{
                    return (
                        <option key={i} value={item?.id}>{item?.name}</option>
                    )
                })}
              </select>
           </div>
       </div> */}
       <div className='col-lg-4'>
                <div className='form-group'>
                <label>&nbsp;</label>
                    <div className='d-flex'>
                    
                    <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
                </div>
                </div>
            </div>
        
     </div>
     <div className='row mb-3'>
                <div className='col-lg-3'>
                <button className='formBtn' onClick={handleShow}>Add Module</button>
                </div>
        </div>
 
      <div className='table-responsive'>

<DataTable
        columns={columns}
        data={FilterResult}
        pagination
        highlightOnHover
       />

      </div>
  
</div>
    </div>
    <Modal show={show} onHide={handleClose}  size="lg">
        <Modal.Header >
          <Modal.Title>Add Module</Modal.Title>
          <button onClick={handleClose} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
        </Modal.Header>
        <Modal.Body>
        <div className='row form-style mb-4'>
        
            {/* <div className='col-lg-6'>
                <div className='form-group'>
                    <select className='form-control'  value={gname}
                    onChange={(e)=>setgname(e.target.value)}>
                    <option value="">--Group Name---</option>
                    {groupList&&groupList.map((item, i)=>{
                        return (
                            <option key={i} value={item?.id}>{item?.name}</option>
                        )
                    })}
                    </select>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <select className='form-control'  value={tname}
                    onChange={(e)=>settname(e.target.value)}>
                    <option value="">--Type List---</option>
                    {TypeList&&TypeList.map((item, i)=>{
                        return (
                            <option key={i} value={item?.id}>{item?.name}</option>
                        )
                    })}
                    </select>
                </div>
            </div> */}
            <div className='col-lg-6'>
                <div className='form-group'>
                <label className='selectlabel'>Enter Module Name <span class="asterisk">*</span></label>
                    <input type='text' className='form-control' placeholder='Module Name'
                    value={mname}
                    onChange={(e)=>setmname(e.target.value)}
                    />
                </div>
         </div>
         <div className='col-lg-6'>
                <div className='form-group'>
                <label className='selectlabel'>Enter Module Code <span class="asterisk">*</span></label>
                    <input type='text' className='form-control' placeholder='Module Code'
                    value={code}
                    onChange={(e)=>setcode(e.target.value)}
                    />
                </div>
         </div>
         <div className='col-lg-6'>
                <div className='form-group'>
                <label className='selectlabel'>Enter Module No. <span class="asterisk">*</span></label>
                    <input type='text' className='form-control' placeholder='Module No'
                    value={mno}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    onChange={(e)=>setmno(e.target.value)}
                    />
                </div>
         </div>
         <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Tags <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Tags'
               value={tags}
               onChange={(e)=>settags(e.target.value)}
               />
           </div>
       </div>
       <div className='col-lg-12'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Description</label>
              <textarea className='form-control' placeholder='Write Description'
              value={description}
              onChange={(e)=>setdescription(e.target.value)}
              >

              </textarea>
           </div>
       </div>
  </div>
        </Modal.Body>
        <Modal.Footer>
           <button className='formBtn' onClick={AddModule}>Add Module</button>
       
        </Modal.Footer>
      </Modal>
      <Modal show={show1} onHide={handleClose1}  size="lg">
        <Modal.Header>
          <Modal.Title>Edit Module</Modal.Title>
          <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>

        </Modal.Header>
        <Modal.Body>
        <div className='row form-style mb-4'>
        
            {/* <div className='col-lg-6'>
                <div className='form-group'>
                    <select className='form-control'  value={gname1}
                    onChange={(e)=>setgname1(e.target.value)}>
                    <option value="">--Group Name---</option>
                    {groupList&&groupList.map((item, i)=>{
                        return (
                            <option key={i} value={item?.id}>{item?.name}</option>
                        )
                    })}
                    </select>
                </div>
            </div>
            <div className='col-lg-6'>
                <div className='form-group'>
                    <select className='form-control'  value={tname1}
                    onChange={(e)=>settname1(e.target.value)}>
                    <option value="">--Type List---</option>
                    {TypeList&&TypeList.map((item, i)=>{
                        return (
                            <option key={i} value={item?.id}>{item?.name}</option>
                        )
                    })}
                    </select>
                </div>
            </div> */}
            <div className='col-lg-6'>
                <div className='form-group'>
                <label className='selectlabel'>Enter Module Name <span class="asterisk">*</span></label>
                    <input type='text' className='form-control' placeholder='Module Name'
                    value={mname1}
                    onChange={(e)=>setmname1(e.target.value)}
                    />
                </div>
         </div>
         <div className='col-lg-6'>
                <div className='form-group'>
                <label className='selectlabel'>Enter Module Code <span class="asterisk">*</span></label>
                    <input type='text' className='form-control' placeholder='Module Code'
                    value={code1}
                    onChange={(e)=>setcode1(e.target.value)}
                    />
                </div>
         </div>
         <div className='col-lg-6'>
                <div className='form-group'>
                <label className='selectlabel'>Enter Module No. <span class="asterisk">*</span></label>
                    <input type='text' className='form-control' placeholder='Module No'
                    value={mno1}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    onChange={(e)=>setmno1(e.target.value)}
                    />
                </div>
         </div>
         <div className='col-lg-6'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Tags <span class="asterisk">*</span></label>
               <input type='text' className='form-control' placeholder='Tags'
               value={tags1}
               onChange={(e)=>settags1(e.target.value)}
               />
           </div>
       </div>
       <div className='col-lg-12'>
           <div className='form-group'>
           <label className='selectlabel'>Enter Description</label>
              <textarea className='form-control' placeholder='Write Description'
              value={description1}
              onChange={(e)=>setdescription1(e.target.value)}
              >

              </textarea>
           </div>
       </div>
  </div>
        </Modal.Body>
        <Modal.Footer>
           <button className='formBtn' onClick={UpdateModule}>Update Module</button>
       
        </Modal.Footer>
      </Modal>
  </div>
  )
}

export default ViewSyllabus