import React, { useState } from 'react'
import { NavLink,useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Loader from '../utils/Loader';
import { useSelector, useDispatch } from 'react-redux'
import { SendEmaillinkRequest } from '../redux/reducer/FetchAdminDataReducer';

const ForgotPassword = () => {

    const [email, setemail] = useState('')
    const fetchReducer = useSelector((state) => state.FetchAdminDataReducer)

    const dispatch = useDispatch()
      let navigate = useNavigate();

    const LoginHandler = () =>{

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (reg.test(email) === false) {
            Swal.fire({
                icon: "error",
                text: 'Email should be proper',
              });
        }  else {
            let obj = {"email" : email}

            dispatch(SendEmaillinkRequest({obj:obj, navigate:navigate}))

        }
        
    }



  return (
    <div className='login-layout'>
 <Loader visible={fetchReducer.isloading}  />
    <div className='login-box'>
    <div className='form-group text-center'>
    <h4>Forgot Password</h4>
    </div>
       
        <div className='form-group mb-5'>
            <label>Email</label>
            <input type='email'
            value={email}
            onChange={(e)=>setemail(e.target.value)}
            className='form-control' placeholder='Enter mail-id'  />
            <span><i className="fa-solid fa-envelope"></i></span>
            <p className='text-primary'>Password link will be sent to you email address </p>
        </div>
        
        <div className='form-group'>
            <ul className='bottom-navigation'>
                <li> <NavLink to="/">Go Back</NavLink></li>
                <li>  <button className='theme-button' onClick={LoginHandler}>Submit</button></li>
            </ul>
           
          
        </div>
    </div>
</div>
  )
}

export default ForgotPassword