import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetModuleTypeRequest, DeleteModuleTypeRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';

const ViewModule = () => {

    const [search, setsearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var groupList = fetchacompanyReducer?.GetModuleTypeResponse?.results

    console.log(fetchacompanyReducer.GetModuleTypeResponse)

    const dispatch = useDispatch()

    const columns = [
        {
            name: 'Lead Interest',
            selector: row => row.name,
            sortable: true,
        },
      {
        name: 'Action',
        selector: (row) => (
          <>
             {/* <NavLink to={`/${userinfo?.company_slug}/curriculum/grade/edit-grade/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink> */}
          <button disabled onClick={()=>DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
          </>
        ),
    },
    ];

    useEffect(() => {
        dispatch(GetModuleTypeRequest())
    }, [])

    useEffect(()=>{
        const result = groupList && groupList.filter(data =>{
          return data.name.toLowerCase().match(search.toLowerCase())
        })
        setFilterResult(result)
      },[search])

    useEffect(() => {
        dispatch(GetModuleTypeRequest())
    }, [fetchacompanyReducer?.DeleteModuleTypeResponse])

    useEffect(()=>{

        setFilterResult(groupList)
      },[fetchacompanyReducer?.GetModuleTypeResponse])


    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteModuleTypeRequest(id))

                if (fetchacompanyReducer?.DeleteModuleTypeResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }





    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Lead Interest Management</h3>
                </div>

                <div className='module_list-top form-style mt-3'>
          <div className='form-group m-0 w-25'>
                <input type='text' className='form-control' placeholder='Enter Lead Interest'
                value={search}
                onChange={(e)=>setsearch(e.target.value)}
                />
            </div>
        
            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/settings/Module_Type/AddModule_Type`}><i className="fa-solid fa-plus"></i> Add Lead Interest</NavLink>
     </div>

<div className='table-responsive'>
          <DataTable
            columns={columns}
            data={FilterResult}
            pagination
            highlightOnHover
        />


      </div>


            </div>

        </div>
    )
}

export default ViewModule