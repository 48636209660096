import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink , useParams, useNavigate} from 'react-router-dom'
import { FetchQuestionRequest } from '../../redux/reducer/FetchCompanyDataReducer'

import Swal from "sweetalert2";
import Loader from '../../utils/Loader';

const QuestionDetails = () => {

    const dispatch = useDispatch()
    const {id , pros} = useParams()
    const navigate = useNavigate();

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    var viewdata = fetchacompanyReducer?.FetchQuestionResponse?.results?.[0]

    console.log(viewdata)


    useEffect(()=>{

        let obj = {
            test_id:id,
            id:pros
        }

        dispatch(FetchQuestionRequest(obj))

    },[])


  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
      <div className='innerlayout p-3'>
      <div className='mb-4'>
        <h3> View Question</h3>
        </div>
      
      <div className='view-page'>
     
      <div className='row'>

      
      <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Assessment Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.assesment_name}</b>
                         </div>
                    </div>
               </div>
          </div>
      <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Topic Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.topic_name}</b>
                         </div>
                    </div>
               </div>
          </div>
     
       
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Module Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.module_name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Question Hints:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.question_hints}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Question Marks:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.question_marks}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-12'>
               <div className='form-group'> 
        <div className='question-details'>
        <b className='d-block mb-2'>Question:</b>
                <div
                    dangerouslySetInnerHTML={{__html: viewdata?.question}}
                    />
              <b className='d-block mb-2'>Answer:</b>
            <ul>
                <li className={viewdata?.answer == "1" ? "correct" : ''}><span>{viewdata?.answer == "1" ? <i className="fa-solid fa-square-check"></i> : <i className="fa-regular fa-square"></i>}</span>  {viewdata?.option_1}</li>
                <li className={viewdata?.answer == "2" ? "correct" : ''}><span>{viewdata?.answer == "2" ? <i className="fa-solid fa-square-check"></i> :<i className="fa-regular fa-square"></i>}</span> {viewdata?.option_2}</li>
                <li className={viewdata?.answer == "3" ? "correct" : ''}><span>{viewdata?.answer == "3" ? <i className="fa-solid fa-square-check"></i> : <i className="fa-regular fa-square"></i>}</span> {viewdata?.option_3}</li>
                <li className={viewdata?.answer == "4" ? "correct" : ''}><span>{viewdata?.answer == "4" ? <i className="fa-solid fa-square-check"></i> : <i className="fa-regular fa-square"></i>}</span> {viewdata?.option_4}</li>
            </ul>
        </div>
        </div> 
               </div>
</div>
           
        
      </div>

      <div className='button-part mb-4'>
          <ul>
            <li>
            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back to question</button>
              {/* <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/curriculum/syllabus`}><i className="fa-solid fa-angle-left"></i> Back to syllabus</NavLink> */}
            </li>
           
          </ul>
      </div>
 
      </div>
  </div>
  )
}

export default QuestionDetails