import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  token: null,
  status: '',
  isloading: false,
  data: {},
  error: {},
  GetProspectiveResponse: '',
  GetLocationResponse: {},
  GetInterestResponse: {},
  GetstateResponse: [],
  GetcityResponse: [],
  AddpropectsResponse: {},
  ExportProspectiveResponse: {},
  UpdatepropectsResponse: {},
  FetchSubjectResponse: [],
  AddlocationResponse: {}
}

const FetchCompanyDataReducer = createSlice({
  name: 'FetchCompanyDataReducer',
  initialState,
  reducers: {

    //login
    LoginCompanyRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    SignInCompanySuccess(state, action) {
      state.SignInResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    SignInCompanyFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Prospective list
    GetProspectiveRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetProspectiveSuccess(state, action) {
      state.GetProspectiveResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetProspectiveFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Country list
    GetCountryRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetcountrySuccess(state, action) {
      state.GetcountryResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetcountryFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //State list
    GetStateRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetstateSuccess(state, action) {
      state.GetstateResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetstateFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //city list
    GetcityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetcitySuccess(state, action) {
      state.GetcityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetcityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Location list
    GetLocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetLocationSuccess(state, action) {
      state.GetLocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetLocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Interest list
    GetInterestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetInterestSuccess(state, action) {
      state.GetInterestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetInterestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add propects
    AddpropectsRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddpropectsSuccess(state, action) {
      state.AddpropectsResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddpropectsFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //View prospects
    ViewprospectsRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ViewprospectsSuccess(state, action) {
      state.ViewprospectsResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ViewprospectsFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Export prospects
    ExportProspectiveRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ExportProspectiveSuccess(state, action) {
      state.ExportProspectiveResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ExportProspectiveFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Update prospects
    UpdatepropectsRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdatepropectsSuccess(state, action) {
      state.UpdatepropectsResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdatepropectsFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Employee list
    GetEmployeeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetEmployeeSuccess(state, action) {
      state.GetEmployeeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetEmployeeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Employee Add
    AddemployeeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddemployeeSuccess(state, action) {
      state.AddemployeeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddemployeeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list title
    GetTitleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTitleSuccess(state, action) {
      state.GetTitleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTitleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //list manager
    GetManagerRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetManagerSuccess(state, action) {
      state.GetManagerResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetManagerFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list background
    GetBackgroundRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetBackgroundSuccess(state, action) {
      state.GetBackgroundResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetBackgroundFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list background Dropdown Items
    GetBackgroundListRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetBackgroundListSuccess(state, action) {
      state.GetBackgroundListResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetBackgroundListFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Training Dropdown Items
    GetTrainingListRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTrainingListSuccess(state, action) {
      state.GetTrainingListResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTrainingListFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Training
    GetTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTrainingSuccess(state, action) {
      state.GetTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list group
    GetGroupRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetGroupSuccess(state, action) {
      state.GetGroupResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetGroupFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add group
    AddGroupRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddGroupSuccess(state, action) {
      state.AddGroupResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddGroupFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch group
    FetchGroupRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchGroupSuccess(state, action) {
      state.FetchGroupResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchGroupFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //update group
    UpdateGroupRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateGroupSuccess(state, action) {
      state.UpdateGroupResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateGroupFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //del group
    DeleteGroupRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteGroupSuccess(state, action) {
      state.DeleteGroupResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteGroupFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list grade
    GetGradeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetGradeSuccess(state, action) {
      state.GetGradeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetGradeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Grade
    AddGradeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddGradeSuccess(state, action) {
      state.AddGradeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddGradeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Grade
    FetchGradeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchGradeSuccess(state, action) {
      state.FetchGradeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchGradeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Grade
    UpdateGradeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateGradeSuccess(state, action) {
      state.UpdateGradeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateGradeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Delete Grade
    DeleteGradeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteGradeSuccess(state, action) {
      state.DeleteGradeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteGradeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list subject
    GetSubjectRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetSubjectSuccess(state, action) {
      state.GetSubjectResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetSubjectFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list all group
    GetAllGroupListRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetAllGroupListSuccess(state, action) {
      state.GetAllGroupListResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetAllGroupListFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list all group
    AddSubjectRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddSubjectSuccess(state, action) {
      state.AddSubjectResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddSubjectFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Fetch subject
    FetchSubjectRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchSubjectSuccess(state, action) {
      state.FetchSubjectResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchSubjectFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update subject
    UpdateSubjectRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateSubjectSuccess(state, action) {
      state.UpdateSubjectResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateSubjectFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete subject
    DeleteSubjectRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteSubjectSuccess(state, action) {
      state.DeleteSubjectResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteSubjectFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //View Employee
    ViewEmployeeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ViewEmployeeSuccess(state, action) {
      state.ViewEmployeeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ViewEmployeeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Employee
    UpdateEmployeeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateEmployeeSuccess(state, action) {
      state.UpdateEmployeeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateEmployeeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Syllabus List
    GetSyllabusRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetSyllabusSuccess(state, action) {
      state.GetSyllabusResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetSyllabusFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Syllabus Add
    AddSyllabusRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddSyllabusSuccess(state, action) {
      state.AddSyllabusResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddSyllabusFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Syllabus fetch
    FetchSyllabusRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchSyllabusSuccess(state, action) {
      state.FetchSyllabusResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchSyllabusFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Get Module
    GetModuleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetModuleSuccess(state, action) {
      state.GetModuleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetModuleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Module
    AddModuleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddModuleSuccess(state, action) {
      state.AddModuleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddModuleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get type
    GetTypeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTypeSuccess(state, action) {
      state.GetTypeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTypeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Syllabus
    UpdateSyllabusRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateSyllabusSuccess(state, action) {
      state.UpdateSyllabusResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateSyllabusFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch module
    FetchModuleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchModuleSuccess(state, action) {
      state.FetchModuleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchModuleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Update module
    UpdateModuleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateModuleSuccess(state, action) {
      state.UpdateModulResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateModuleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete module
    DeleteModuleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteModuleSuccess(state, action) {
      state.DeleteModuleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteModuleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Syllabus
    DeleteSyllabusRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteSyllabusSuccess(state, action) {
      state.DeleteSyllabusResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteSyllabusFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Employee
    DeleteEmployeeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteEmployeeSuccess(state, action) {
      state.DeleteEmployeeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteEmployeeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete prospects
    DeleteprospectsRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteprospectsSuccess(state, action) {
      state.DeleteprospectsResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteprospectsFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get Topic
    GetTopicRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTopicSuccess(state, action) {
      state.GetTopicResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTopicFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Topic
    AddTopicRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddTopicSuccess(state, action) {
      state.AddTopicResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddTopicFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Fetch Topic
    FetchTopicRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchTopicSuccess(state, action) {
      state.FetchTopicResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchTopicFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Del Topic
    DeleteTopicRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteTopicSuccess(state, action) {
      state.DeleteTopicResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteTopicFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Topic
    UpdateTopicRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateTopicSuccess(state, action) {
      state.UpdateTopicResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateTopicFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Add Title
    AddTitleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddTitleSuccess(state, action) {
      state.AddTitleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddTitleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Title
    FetchTitleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchTitleSuccess(state, action) {
      state.FetchTitleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchTitleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Title
    UpdateTitleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateTitleSuccess(state, action) {
      state.UpdateTitleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateTitleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Title
    DeleteTitleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteTitleSuccess(state, action) {
      state.DeleteTitleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteTitleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add BackgroundCheckCompany
    AddBackgroundRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddBackgroundSuccess(state, action) {
      state.AddBackgroundResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddBackgroundFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Bcc
    FetchBccRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchBccSuccess(state, action) {
      state.FetchBccResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchBccFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Bcc
    UpdateBccRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateBccSuccess(state, action) {
      state.UpdateBccResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateBccFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //del Bcc
    DeleteBccRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteBccSuccess(state, action) {
      state.DeleteBccResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteBccFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Training
    GetTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTrainingSuccess(state, action) {
      state.GetTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Training
    AddTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddTrainingSuccess(state, action) {
      state.AddSubjectResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Training
    FetchTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchTrainingSuccess(state, action) {
      state.FetchTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Training
    UpdateTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateTrainingSuccess(state, action) {
      state.UpdateTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //del Training
    DeleteTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteTrainingSuccess(state, action) {
      state.DeleteTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Guardian
    AddguardianRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddguardianSuccess(state, action) {
      state.AddguardianResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddguardianFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //View Guardian
    ViewguardianRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ViewguardianSuccess(state, action) {
      state.ViewguardianResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ViewguardianFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Export Guardian
    ExportguardianRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ExportguardianSuccess(state, action) {
      state.ExportguardianResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ExportguardianFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Update Guardian
    UpdateguardianRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateguardianSuccess(state, action) {
      state.UpdateguardianResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateguardianFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Guardian
    DeleteguardianRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteguardianSuccess(state, action) {
      state.DeleteguardianResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteguardianFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Add Location
    AddlocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddlocationSuccess(state, action) {
      state.AddlocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddlocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //View Location
    ViewlocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ViewlocationSuccess(state, action) {
      state.ViewlocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ViewlocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Export Location
    ExportlocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ExportlocationSuccess(state, action) {
      state.ExportlocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ExportlocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Update Location
    UpdatelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdatelocationSuccess(state, action) {
      state.UpdatelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdatelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Location

    DeletelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },


    DeletelocationSuccess(state, action) {
      state.DeletelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeletelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Location
    FetchlocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;

    },

    FetchlocationSuccess(state, action) {
      state.FetchlocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    FetchlocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch crud Location
    GetCrudlocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;

    },

    GetCrudlocationSuccess(state, action) {
      state.GetCrudlocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    GetCrudlocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },



    //list Locations
    GetlocationsRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetlocationsSuccess(state, action) {
      state.GetlocationsResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetlocationsFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

      //Add Student Enroll
      AddStudentEnrollRequest(state, action) {
        state.status = action.type;
        state.isloading = true;
      },
  
      AddStudentEnrollSuccess(state, action) {
        state.AddStudentEnrollResponse = action.payload;
        state.status = action.type;
        state.isloading = false;
      },
      AddStudentEnrollFailure(state, action) {
        state.error = action.payload;
        state.status = action.type;
        state.isloading = false;
      },

    //Get Guardian
    GetGuardianRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetGuardianSuccess(state, action) {
      state.GetGuardianResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetGuardianFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Make Primary
    MakePrimaryRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    MakePrimarySuccess(state, action) {
      state.MakePrimaryResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    MakePrimaryFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get employee location
    GetEmployeelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetEmployeelocationSuccess(state, action) {
      state.GetEmployeelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetEmployeelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get employee location
    AddEmployeelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    AddEmployeelocationSuccess(state, action) {
      state.AddEmployeelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddEmployeelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Fetch employee location
    FetchEmployeelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    FetchEmployeelocationSuccess(state, action) {
      state.FetchEmployeelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchEmployeelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //del employee location
    DeleteEmployeelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    DeleteEmployeelocationSuccess(state, action) {
      state.DeleteEmployeelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteEmployeelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update employee location
    UpdateEmployeelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    UpdateEmployeelocationSuccess(state, action) {
      state.UpdateEmployeelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateEmployeelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //primary employee location
    MakeLocationPrimaryRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    MakeLocationPrimarySuccess(state, action) {
      state.MakeLocationPrimaryResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    MakeLocationPrimaryFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Add Student
    AddstudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddstudentSuccess(state, action) {
      state.AddstudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddstudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //View Student
    ViewstudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ViewstudentSuccess(state, action) {
      state.ViewstudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ViewstudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Export Student
    ExportstudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ExportstudentSuccess(state, action) {
      state.ExportstudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ExportstudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Update Student
    UpdatestudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdatestudentSuccess(state, action) {
      state.UpdatestudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdatestudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Student

    DeletestudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },


    DeletestudentSuccess(state, action) {
      state.DeletestudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeletestudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Student
    FetchstudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;

    },

    FetchstudentSuccess(state, action) {
      state.FetchstudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    FetchstudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Student
    GetstudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetstudentSuccess(state, action) {
      state.GetstudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetstudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //All Students Lists
    GetStudentListRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetStudentListSuccess(state, action) {
      state.GetStudentListResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetStudentListFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Default locations
    GetDefaultlocationsRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetDefaultlocationsSuccess(state, action) {
      state.GetDefaultlocationsResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetDefaultlocationsFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //list Assign employee locations
    GetAssignEmployeelocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetAssignEmployeelocationSuccess(state, action) {
      state.GetAssignEmployeelocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetAssignEmployeelocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Module Type
    GetModuleTypeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetModuleTypeSuccess(state, action) {
      state.GetModuleTypeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetModuleTypeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Module Type
    AddModuleTypeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddModuleTypeSuccess(state, action) {
      state.AddModuleTypeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddModuleTypeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete module type
    DeleteModuleTypeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteModuleTypeSuccess(state, action) {
      state.DeleteModuleTypeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteModuleTypeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Test
    AddtestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddtestSuccess(state, action) {
      state.AddtestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddtestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Test
    UpdatetestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdatetestSuccess(state, action) {
      state.UpdatetestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdatetestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //list test
    GettestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GettestSuccess(state, action) {
      state.GettestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GettestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Fetch test
    FetchtestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchtestSuccess(state, action) {
      state.FetchtestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchtestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete test type
    DeletetestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeletetestSuccess(state, action) {
      state.DeletetestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeletetestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Questions
    AddquesRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddquesSuccess(state, action) {
      state.AddquesResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddquesFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get Questions
    GetquesRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetquesSuccess(state, action) {
      state.GetquesResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetquesFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Teacher Training
    GetTeacherTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTeacherTrainingSuccess(state, action) {
      state.GetTeacherTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTeacherTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Teacher Training
    AddTeacherTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddTeacherTrainingSuccess(state, action) {
      state.AddTeacherTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddTeacherTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Teacher Training
    UpdateTeacherTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateTeacherTrainingSuccess(state, action) {
      state.UpdateTeacherTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateTeacherTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Teacher Training
    DeleteTeacherTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteTeacherTrainingSuccess(state, action) {
      state.DeleteTeacherTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteTeacherTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Subject Change
    GetSubjectChangeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetSubjectChangeSuccess(state, action) {
      state.GetSubjectChangeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetSubjectChangeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //View Profile
    GetProfileRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetProfileSuccess(state, action) {
      state.GetProfileResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetProfileFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Profile
    UpdateProfileRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateProfileSuccess(state, action) {
      state.UpdateProfileResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateProfileFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Fetch Teacher Training
    FetchTeacherTrainingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchTeacherTrainingSuccess(state, action) {
      state.FetchTeacherTrainingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchTeacherTrainingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Topic Change
    GetTopicChangeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTopicChangeSuccess(state, action) {
      state.GetTopicChangeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTopicChangeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Module Change
    GetModuleChangeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetModuleChangeSuccess(state, action) {
      state.GetModuleChangeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetModuleChangeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Syllabus Change
    GetSyllabusChangeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetSyllabusChangeSuccess(state, action) {
      state.GetSyllabusChangeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetSyllabusChangeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Order list
    UpdateorderListRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateorderListSuccess(state, action) {
      state.UpdateorderListResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateorderListFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Fetch Question Details
    FetchQuestionRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchQuestionSuccess(state, action) {
      state.FetchQuestionResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchQuestionFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list Syllabus Topic Change
    GetSyllabusTopicRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetSyllabusTopicSuccess(state, action) {
      state.GetSyllabusTopicResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetSyllabusTopicFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Download Question
    DownloadQuestionRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DownloadQuestionSuccess(state, action) {
      state.DownloadQuestionResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DownloadQuestionFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Individual Assessment
    AssignScheduleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AssignScheduleSuccess(state, action) {
      state.AssignScheduleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AssignScheduleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Group Assessment
    AssignGroupScheduleRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AssignGroupScheduleSuccess(state, action) {
      state.AssignGroupScheduleResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AssignGroupScheduleFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //View Question
    ViewQuestionRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ViewQuestionSuccess(state, action) {
      state.ViewQuestionResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ViewQuestionFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Question
    UpdateQuestionRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateQuestionSuccess(state, action) {
      state.UpdateQuestionSuccessResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateQuestionFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //del Question
    DeleteQuestionRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteQuestionSuccess(state, action) {
      state.DeleteQuestionResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteQuestionFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //List Teacher Activity
    GetTeacherActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTeacherActivitySuccess(state, action) {
      state.GetTeacherActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTeacherActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Teacher Activity
    AddTeacherActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddTeacherActivitySuccess(state, action) {
      state.AddTeacherActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddTeacherActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Teacher Activity
    FetchTeacherActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchTeacherActivitySuccess(state, action) {
      state.FetchTeacherActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchTeacherActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Teacher Activity
    UpdateTeacherActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateTeacherActivitySuccess(state, action) {
      state.UpdateTeacherActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateTeacherActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Delete Teacher Activity
    DeleteTeacherActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteTeacherActivitySuccess(state, action) {
      state.DeleteTeacherActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteTeacherActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //list grade Student
    GetGradeStudentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetGradeStudentSuccess(state, action) {
      state.GetGradeStudentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetGradeStudentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Export Employee
    ExportEmployeeRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ExportEmployeeSuccess(state, action) {
      state.ExportEmployeeResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    ExportEmployeeFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Document
    AddDocumentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddDocumentSuccess(state, action) {
      state.AddDocumentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddDocumentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Document
    FetchDocumentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchDocumentSuccess(state, action) {
      state.FetchDocumentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchDocumentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Document
    UpdateDocumentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateDocumentSuccess(state, action) {
      state.UpdateDocumentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateDocumentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get Document
    GetDocumentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetDocumentSuccess(state, action) {
      state.GetDocumentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetDocumentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Document
    DeleteDocumentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteDocumentSuccess(state, action) {
      state.DeleteDocumentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteDocumentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Add attendance
    AddattendanceRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddattendanceSuccess(state, action) {
      state.AddattendanceResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddattendanceFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch attendance
    FetchattendanceRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchattendanceSuccess(state, action) {
      state.FetchattendanceResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchattendanceFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update attendance
    UpdateattendanceRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateattendanceSuccess(state, action) {
      state.UpdateattendanceResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateattendanceFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get attendance
    GetattendanceRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetattendanceSuccess(state, action) {
      state.GetattendanceResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetattendanceFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete attendance
    DeleteattendanceRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteattendanceSuccess(state, action) {
      state.DeleteattendanceResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteattendanceFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //get Test filter
    GettestfilterRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GettestfilterSuccess(state, action) {
      state.GettestfilterResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GettestfilterFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get virtual employee location
    GetEmployeevirtuallocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetEmployeevirtuallocationSuccess(state, action) {
      state.GetEmployeevirtuallocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetEmployeevirtuallocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get employee location
    AddEmployeevirtuallocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    AddEmployeevirtuallocationSuccess(state, action) {
      state.AddEmployeevirtuallocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddEmployeevirtuallocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Fetch employee location
    FetchEmployeevirtuallocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    FetchEmployeevirtuallocationSuccess(state, action) {
      state.FetchEmployeevirtuallocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchEmployeevirtuallocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //del employee location
    DeleteEmployeevirtuallocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    DeleteEmployeevirtuallocationSuccess(state, action) {
      state.DeleteEmployeevirtuallocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteEmployeevirtuallocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update employee location
    UpdateEmployeevirtuallocationRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    UpdateEmployeevirtuallocationSuccess(state, action) {
      state.UpdateEmployeevirtuallocationResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateEmployeevirtuallocationFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Gt Existing Question
    GetExistingQuestionRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetExistingSuccess(state, action) {
      state.GetExistingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetExistingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get Existing test
    GetExistingTestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetExistingTestSuccess(state, action) {
      state.GetExistingTestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetExistingTestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Add Existing test
    AddExistingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    AddExistingSuccess(state, action) {
      state.AddExistingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddExistingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get Attendence status
    GetAttendenceRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetAttendenceSuccess(state, action) {
      state.GetAttendenceResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetAttendenceFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Punch status
    AddpunchRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    AddpunchSuccess(state, action) {
      state.AddpunchResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddpunchFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //List Teacher Student
    GetTeacherRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetTeacherSuccess(state, action) {
      state.GetTeacherResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetTeacherFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Teacher Student
    AddTeacherRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddTeacherSuccess(state, action) {
      state.AddTeacherResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddTeacherFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Teacher Student
    FetchTeacherRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchTeacherSuccess(state, action) {
      state.FetchTeacherResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchTeacherFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Teacher Student
    UpdateTeacherRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateTeacherSuccess(state, action) {
      state.UpdateTeacherResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateTeacherFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete Teacher Student
    DeleteTeacherRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteTeacherSuccess(state, action) {
      state.DeleteTeacherResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteTeacherFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //List School
    GetSchoolRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetSchoolSuccess(state, action) {
      state.GetSchoolResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetSchoolFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add School
    AddSchoolRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddSchoolSuccess(state, action) {
      state.AddSchoolResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddSchoolFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch School
    FetchSchoolRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchSchoolSuccess(state, action) {
      state.FetchSchoolResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchSchoolFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update School
    UpdateSchoolRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateSchoolSuccess(state, action) {
      state.UpdateSchoolResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateSchoolFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Delete School
    DeleteSchoolRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteSchoolSuccess(state, action) {
      state.DeleteSchoolResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteSchoolFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    ////////////////////

    //List Student Activity
    GetStudentActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetStudentActivitySuccess(state, action) {
      state.GetStudentActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetStudentActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Student Activity
    AddStudentActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddStudentActivitySuccess(state, action) {
      state.AddStudentActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddStudentActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //fetch Student Activity
    FetchStudentActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    FetchStudentActivitySuccess(state, action) {
      state.FetchStudentActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    FetchStudentActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Student Activity
    UpdateStudentActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateStudentActivitySuccess(state, action) {
      state.UpdateStudentActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateStudentActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Delete Student Activity
    DeleteStudentActivityRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DeleteStudentActivitySuccess(state, action) {
      state.DeleteStudentActivityResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DeleteStudentActivityFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Student Assessment List
    GetStudenttestRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetStudenttestSuccess(state, action) {
      state.GetStudenttestResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetStudenttestFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //Add Student Assessment
    AddStudentAssessmentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddStudentAssessmentSuccess(state, action) {
      state.AddStudentAssessmentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    AddStudentAssessmentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Student Assessment Filter List
    GetStudentTestListRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetStudentTestListSuccess(state, action) {
      state.GetStudentTestListResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetStudentTestListFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get Assessment Answer Details
    GetAssessmentAnswerRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetAssessmentAnswerSuccess(state, action) {
      state.GetAssessmentAnswerResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetAssessmentAnswerFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get Assessment Result List
    GetAssessmentResultRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetAssessmentResultSuccess(state, action) {
      state.GetAssessmentResultResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetAssessmentResultFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get Result List
    GetResultListRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetResultListSuccess(state, action) {
      state.GetResultListResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetResultListFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

        //get Compare Chart List
        GetCompareChartRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
        GetCompareChartSuccess(state, action) {
          state.GetCompareChartResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        GetCompareChartFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },


    //get chart List
    GetResultChartRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetResultChartSuccess(state, action) {
      state.GetResultChartResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetResultChartFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Update Assessment Status
    UpdateAssessmentResultRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    UpdateAssessmentResultSuccess(state, action) {
      state.UpdateAssessmentResultResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    UpdateAssessmentResultFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //List Leraning Plan Student
    GetLearningPlanRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetLearningPlanSuccess(state, action) {
      state.GetLearningPlanResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetLearningPlanFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

        //View Leraning Plan Student
        ViewLearningPlanRequest(state, action) {
          state.status = action.type;
          state.isloading = true;
        },
    
        ViewLearningPlanSuccess(state, action) {
          state.ViewLearningPlanResponse = action.payload;
          state.status = action.type;
          state.isloading = false;
        },
        ViewLearningPlanFailure(state, action) {
          state.error = action.payload;
          state.status = action.type;
          state.isloading = false;
        },

    //List Leraning Path Main
    GetLearningPathMainRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetLearningPathMainSuccess(state, action) {
      state.GetLearningPathMainResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetLearningPathMainFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Learning Path
    GetLearningPlanListsRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetLearningPlanListsSuccess(state, action) {
      state.GetLearningPlanListsResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetLearningPlanListsFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Download Learning Plan
    DownloadLearningPlanRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    DownloadLearningPlanSuccess(state, action) {
      state.DownloadLearningPlanResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    DownloadLearningPlanFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Shuffle Learning Plan
    ShuffleLearningPathRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    ShuffleLearningPathSuccess(state, action) {
      state.ShuffleLearningPathResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    ShuffleLearningPathFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get Progress Report
    GetProgressReportSuccess(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetProgressReportSuccess(state, action) {
      state.GetProgressReportResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    GetProgressReportSuccess(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Progress Check
    AddProgressCheckRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddProgressCheckSuccess(state, action) {
      state.AddProgressCheckResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    AddProgressCheckFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Get Progress Check List
    GetProgressCheckRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetProgressCheckSuccess(state, action) {
      state.GetProgressCheckResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    GetProgressCheckFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Service Subject
    AddServiceSubjectRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddServiceSubjectSuccess(state, action) {
      state.AddServiceSubjectResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    AddServiceSubjectFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Service
    AddServicesRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddServicesSuccess(state, action) {
      state.AddServicesResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    AddServicesFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Staff
    AddStaffRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddStaffSuccess(state, action) {
      state.AddStaffResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    AddStaffFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //Add Pricing
    AddPricingRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    AddPricingSuccess(state, action) {
      state.AddPricingResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    AddPricingFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

  }


});


export const {

  GetResultChartRequest,
  GetResultChartSuccess,
  GetResultChartFailure,

  AddpunchRequest,
  AddpunchSuccess,
  AddpunchFailure,

  GetAttendenceRequest,
  GetAttendenceSuccess,
  GetAttendenceFailure,

  AddExistingRequest,
  AddExistingSuccess,
  AddExistingFailure,

  GetExistingTestRequest,
  GetExistingTestSuccess,
  GetExistingTestFailure,

  GetExistingQuestionRequest,
  GetExistingSuccess,
  GetExistingFailure,

  DeleteQuestionRequest,
  DeleteQuestionSuccess,
  DeleteQuestionFailure,

  UpdateQuestionRequest,
  UpdateQuestionSuccess,
  UpdateQuestionFailure,

  ViewQuestionRequest,
  ViewQuestionSuccess,
  ViewQuestionFailure,

  DownloadQuestionRequest,
  DownloadQuestionSuccess,
  DownloadQuestionFailure,

  FetchQuestionRequest,
  FetchQuestionSuccess,
  FetchQuestionFailure,

  UpdateorderListRequest,
  UpdateorderListSuccess,
  UpdateorderListFailure,

  GetAssignEmployeelocationRequest,
  GetAssignEmployeelocationSuccess,
  GetAssignEmployeelocationFailure,

  GetDefaultlocationsRequest,
  GetDefaultlocationsSuccess,
  GetDefaultlocationsFailure,

  MakeLocationPrimaryRequest,
  MakeLocationPrimarySuccess,
  MakeLocationPrimaryFailure,

  UpdateEmployeelocationRequest,
  UpdateEmployeelocationSuccess,
  UpdateEmployeelocationFailure,

  DeleteEmployeelocationRequest,
  DeleteEmployeelocationSuccess,
  DeleteEmployeelocationFailure,

  FetchEmployeelocationRequest,
  FetchEmployeelocationSuccess,
  FetchEmployeelocationFailure,

  AddEmployeelocationRequest,
  AddEmployeelocationSuccess,
  AddEmployeelocationFailure,


  GetEmployeelocationRequest,
  GetEmployeelocationSuccess,
  GetEmployeelocationFailure,

  MakePrimaryRequest,
  MakePrimarySuccess,
  MakePrimaryFailure,

  GetCrudlocationRequest,
  GetCrudlocationSuccess,
  GetCrudlocationFailure,

  GetGuardianRequest,
  GetGuardianSuccess,
  GetGuardianFailure,

  UpdateTopicRequest,
  UpdateTopicSuccess,
  UpdateTopicFailure,

  DeleteTopicRequest,
  DeleteTopicSuccess,
  DeleteTopicFailure,

  FetchTopicRequest,
  FetchTopicSuccess,
  FetchTopicFailure,

  AddTopicRequest,
  AddTopicSuccess,
  AddTopicFailure,

  GetTopicRequest,
  GetTopicSuccess,
  GetTopicFailure,

  DeleteprospectsRequest,
  DeleteprospectsSuccess,
  DeleteprospectsFailure,

  DeleteSyllabusRequest,
  DeleteSyllabusSuccess,
  DeleteSyllabusFailure,

  DeleteModuleRequest,
  DeleteModuleSuccess,
  DeleteModuleFailure,

  UpdateModuleRequest,
  UpdateModuleSuccess,
  UpdateModuleFailure,

  FetchModuleRequest,
  FetchModuleSuccess,
  FetchModuleFailure,

  UpdateSyllabusRequest,
  UpdateSyllabusSuccess,
  UpdateSyllabusFailure,

  GetTypeRequest,
  GetTypeSuccess,
  GetTypeFailure,

  AddModuleRequest,
  AddModuleSuccess,
  AddModuleFailure,

  GetModuleRequest,
  GetModuleSuccess,
  GetModuleFailure,

  FetchSyllabusRequest,
  FetchSyllabusSuccess,
  FetchSyllabusFailure,

  AddSyllabusRequest,
  AddSyllabusSuccess,
  AddSyllabusFailure,

  GetSyllabusRequest,
  GetSyllabusSuccess,
  GetSyllabusFailure,

  LoginCompanyRequest,
  SignInCompanySuccess,
  SignInCompanyFailure,

  GetProspectiveRequest,
  GetProspectiveSuccess,
  GetProspectiveFailure,

  GetCountryRequest,
  GetcountrySuccess,
  GetcountryFailure,

  GetLocationRequest,
  GetLocationSuccess,
  GetLocationFailure,

  GetInterestRequest,
  GetInterestSuccess,
  GetInterestFailure,

  GetStateRequest,
  GetstateSuccess,
  GetstateFailure,

  GetcityRequest,
  GetcitySuccess,
  GetcityFailure,

  AddpropectsRequest,
  AddpropectsSuccess,
  AddpropectsFailure,

  ViewprospectsRequest,
  ViewprospectsSuccess,
  ViewprospectsFailure,

  ExportProspectiveRequest,
  ExportProspectiveSuccess,
  ExportProspectiveFailure,

  UpdatepropectsRequest,
  UpdatepropectsSuccess,
  UpdatepropectsFailure,

  GetEmployeeRequest,
  GetEmployeeSuccess,
  GetEmployeeFailure,

  AddemployeeRequest,
  AddemployeeSuccess,
  AddemployeeFailure,

  GetTitleRequest,
  GetTitleSuccess,
  GetTitleFailure,

  GetManagerRequest,
  GetManagerSuccess,
  GetManagerFailure,

  GetBackgroundRequest,
  GetBackgroundSuccess,
  GetBackgroundFailure,

  GetBackgroundListRequest,
  GetBackgroundListSuccess,
  GetBackgroundListFailure,

  GetTrainingRequest,
  GetTrainingSuccess,
  GetTrainingFailure,

  GetTrainingListRequest,
  GetTrainingListSuccess,
  GetTrainingListFailure,

  GetGroupRequest,
  GetGroupSuccess,
  GetGroupFailure,

  AddGroupRequest,
  AddGroupSuccess,
  AddGroupFailure,

  FetchGroupRequest,
  FetchGroupSuccess,
  FetchGroupFailure,

  DeleteGroupRequest,
  DeleteGroupSuccess,
  DeleteGroupFailure,

  UpdateGroupRequest,
  UpdateGroupSuccess,
  UpdateGroupFailure,

  GetGradeRequest,
  GetGradeSuccess,
  GetGradeFailure,

  AddGradeRequest,
  AddGradeSuccess,
  AddGradeFailure,

  FetchGradeRequest,
  FetchGradeSuccess,
  FetchGradeFailure,

  UpdateGradeRequest,
  UpdateGradeSuccess,
  UpdateGradeFailure,

  DeleteGradeRequest,
  DeleteGradeSuccess,
  DeleteGradeFailure,

  GetSubjectRequest,
  GetSubjectSuccess,
  GetSubjectFailure,

  GetAllGroupListRequest,
  GetAllGroupListSuccess,
  GetAllGroupListFailure,

  AddSubjectRequest,
  AddSubjectSuccess,
  AddSubjectFailure,

  FetchSubjectRequest,
  FetchSubjectSuccess,
  FetchSubjectFailure,

  UpdateSubjectRequest,
  UpdateSubjectSuccess,
  UpdateSubjectFailure,

  DeleteSubjectRequest,
  DeleteSubjectSuccess,
  DeleteSubjectFailure,

  ViewEmployeeRequest,
  ViewEmployeeSuccess,
  ViewEmployeeFailure,

  UpdateEmployeeRequest,
  UpdateEmployeeSuccess,
  UpdateEmployeeFailure,

  DeleteEmployeeRequest,
  DeleteEmployeeSuccess,
  DeleteEmployeeFailure,

  FetchBccRequest,
  FetchBccSuccess,
  FetchBccFailure,

  AddBackgroundRequest,
  AddBackgroundSuccess,
  AddBackgroundFailure,

  UpdateBccRequest,
  UpdateBccSuccess,
  UpdateBccFailure,

  DeleteBccRequest,
  DeleteBccSuccess,
  DeleteBccFailure,


  AddTitleRequest,
  AddTitleSuccess,
  AddTitleFailure,

  FetchTitleRequest,
  FetchTitleSuccess,
  FetchTitleFailure,

  UpdateTitleRequest,
  UpdateTitleSuccess,
  UpdateTitleFailure,

  DeleteTitleRequest,
  DeleteTitleSuccess,
  DeleteTitleFailure,

  AddTrainingRequest,
  AddTrainingSuccess,
  AddTrainingFailure,

  DeleteTrainingRequest,
  DeleteTrainingSuccess,
  DeleteTrainingFailure,

  FetchTrainingRequest,
  FetchTrainingSuccess,
  FetchTrainingFailure,

  UpdateTrainingRequest,
  UpdateTrainingSuccess,
  UpdateTrainingFailure,

  AddguardianRequest,
  AddguardianSuccess,
  AddguardianFailure,

  ViewguardianRequest,
  ViewguardianSuccess,
  ViewguardianFailure,

  ExportguardianRequest,
  ExportguardianSuccess,
  ExportguardianFailure,

  UpdateguardianRequest,
  UpdateguardianSuccess,
  UpdateguardianFailure,

  DeleteguardianRequest,
  DeleteguardianSuccess,
  DeleteguardianFailure,

  AddlocationRequest,
  AddlocationSuccess,
  AddlocationFailure,

  ViewlocationRequest,
  ViewlocationSuccess,
  ViewlocationFailure,

  ExportlocationRequest,
  ExportlocationSuccess,
  ExportlocationFailure,

  UpdatelocationRequest,
  UpdatelocationSuccess,
  UpdatelocationFailure,

  DeletelocationRequest,
  DeletelocationSuccess,
  DeletelocationFailure,

  FetchlocationRequest,
  FetchlocationSuccess,
  FetchlocationFailure,

  GetlocationsRequest,
  GetlocationsSuccess,
  GetlocationsFailure,

  AddstudentRequest,
  AddstudentSuccess,
  AddstudentFailure,

  ViewstudentRequest,
  ViewstudentSuccess,
  ViewstudentFailure,

  ExportstudentRequest,
  ExportstudentSuccess,
  ExportstudentFailure,

  UpdatestudentRequest,
  UpdatestudentSuccess,
  UpdatestudentFailure,

  DeletestudentRequest,
  DeletestudentSuccess,
  DeletestudentFailure,

  FetchstudentRequest,
  FetchstudentSuccess,
  FetchstudentFailure,

  GetstudentRequest,
  GetstudentSuccess,
  GetstudentFailure,

  GetModuleTypeRequest,
  GetModuleTypeSuccess,
  GetModuleTypeFailure,

  AddModuleTypeRequest,
  AddModuleTypeSuccess,
  AddModuleTypeFailure,

  DeleteModuleTypeRequest,
  DeleteModuleTypeSuccess,
  DeleteModuleTypeFailure,

  AddtestRequest,
  AddtestSuccess,
  AddtestFailure,

  GettestRequest,
  GettestSuccess,
  GettestFailure,

  UpdatetestRequest,
  UpdatetestSuccess,
  UpdatetestFailure,

  FetchtestRequest,
  FetchtestSuccess,
  FetchtestFailure,

  DeletetestRequest,
  DeletetestSuccess,
  DeletetestFailure,

  AddquesRequest,
  AddquesSuccess,
  AddquesFailure,

  GetquesRequest,
  GetquesSuccess,
  GetquesFailure,

  UpdateTeacherTrainingRequest,
  UpdateTeacherTrainingSuccess,
  UpdateTeacherTrainingFailure,

  DeleteTeacherTrainingRequest,
  DeleteTeacherTrainingSuccess,
  DeleteTeacherTrainingFailure,

  AddTeacherTrainingRequest,
  AddTeacherTrainingSuccess,
  AddTeacherTrainingFailure,

  GetTeacherTrainingRequest,
  GetTeacherTrainingSuccess,
  GetTeacherTrainingFailure,

  GetSubjectChangeRequest,
  GetSubjectChangeSuccess,
  GetSubjectChangeFailure,

  GetProfileRequest,
  GetProfileSuccess,
  GetProfileFailure,

  UpdateProfileRequest,
  UpdateProfileSuccess,
  UpdateProfileFailure,

  FetchTeacherTrainingRequest,
  FetchTeacherTrainingSuccess,
  FetchTeacherTrainingFailure,

  GetTopicChangeRequest,
  GetTopicChangeSuccess,
  GetTopicChangeFailure,

  GetModuleChangeRequest,
  GetModuleChangeSuccess,
  GetModuleChangeFailure,

  GetSyllabusChangeRequest,
  GetSyllabusChangeSuccess,
  GetSyllabusChangeFailure,

  GetSyllabusTopicRequest,
  GetSyllabusTopicSuccess,
  GetSyllabusTopicFailure,

  AssignScheduleRequest,
  AssignScheduleSuccess,
  AssignScheduleFailure,

  AssignGroupScheduleRequest,
  AssignGroupScheduleSuccess,
  AssignGroupScheduleFailure,

  GetGradeStudentRequest,
  GetGradeStudentSuccess,
  GetGradeStudentFailure,

  GetTeacherActivityRequest,
  GetTeacherActivitySuccess,
  GetTeacherActivityFailure,

  AddTeacherActivityRequest,
  AddTeacherActivitySuccess,
  AddTeacherActivityFailure,

  FetchTeacherActivityRequest,
  FetchTeacherActivitySuccess,
  FetchTeacherActivityFailure,

  UpdateTeacherActivityRequest,
  UpdateTeacherActivitySuccess,
  UpdateTeacherActivityFailure,

  DeleteTeacherActivityRequest,
  DeleteTeacherActivitySuccess,
  DeleteTeacherActivityFailure,

  ExportEmployeeRequest,
  ExportEmployeeSuccess,
  ExportEmployeeFailure,

  AddDocumentRequest,
  AddDocumentSuccess,
  AddDocumentFailure,

  GetDocumentRequest,
  GetDocumentSuccess,
  GetDocumentFailure,

  UpdateDocumentRequest,
  UpdateDocumentSuccess,
  UpdateDocumentFailure,

  FetchDocumentRequest,
  FetchDocumentSuccess,
  FetchDocumentFailure,

  DeleteDocumentRequest,
  DeleteDocumentSuccess,
  DeleteDocumentFailure,

  AddattendanceRequest,
  AddattendanceSuccess,
  AddattendanceFailure,

  GetattendanceRequest,
  GetattendanceSuccess,
  GetattendanceFailure,

  UpdateattendanceRequest,
  UpdateattendanceSuccess,
  UpdateattendanceFailure,

  FetchattendanceRequest,
  FetchattendanceSuccess,
  FetchattendanceFailure,

  DeleteattendanceRequest,
  DeleteattendanceSuccess,
  DeleteattendanceFailure,

  UpdateEmployeevirtuallocationRequest,
  UpdateEmployeevirtuallocationSuccess,
  UpdateEmployeevirtuallocationFailure,

  DeleteEmployeevirtuallocationRequest,
  DeleteEmployeevirtuallocationSuccess,
  DeleteEmployeevirtuallocationFailure,

  FetchEmployeevirtuallocationRequest,
  FetchEmployeevirtuallocationSuccess,
  FetchEmployeevirtuallocationFailure,

  AddEmployeevirtuallocationRequest,
  AddEmployeevirtuallocationSuccess,
  AddEmployeevirtuallocationFailure,

  GetEmployeevirtuallocationRequest,
  GetEmployeevirtuallocationSuccess,
  GetEmployeevirtuallocationFailure,

  GettestfilterRequest,
  GettestfilterSuccess,
  GettestfilterFailure,

  GetTeacherRequest,
  GetTeacherSuccess,
  GetTeacherFailure,

  AddTeacherRequest,
  AddTeacherSuccess,
  AddTeacherFailure,

  FetchTeacherRequest,
  FetchTeacherSuccess,
  FetchTeacherFailure,

  UpdateTeacherRequest,
  UpdateTeacherSuccess,
  UpdateTeacherFailure,

  DeleteTeacherRequest,
  DeleteTeacherSuccess,
  DeleteTeacherFailure,

  GetSchoolRequest,
  GetSchoolSuccess,
  GetSchoolFailure,

  AddSchoolRequest,
  AddSchoolSuccess,
  AddSchoolFailure,

  FetchSchoolRequest,
  FetchSchoolSuccess,
  FetchSchoolFailure,

  UpdateSchoolRequest,
  UpdateSchoolSuccess,
  UpdateSchoolFailure,

  DeleteSchoolRequest,
  DeleteSchoolSuccess,
  DeleteSchoolFailure,

  GetStudentActivityRequest,
  GetStudentActivitySuccess,
  GetStudentActivityFailure,

  AddStudentActivityRequest,
  AddStudentActivitySuccess,
  AddStudentActivityFailure,

  FetchStudentActivityRequest,
  FetchStudentActivitySuccess,
  FetchStudentActivityFailure,

  UpdateStudentActivityRequest,
  UpdateStudentActivitySuccess,
  UpdateStudentActivityFailure,

  DeleteStudentActivityRequest,
  DeleteStudentActivitySuccess,
  DeleteStudentActivityFailure,

  GetStudenttestRequest,
  GetStudenttestSuccess,
  GetStudenttestFailure,

  AddStudentAssessmentRequest,
  AddStudentAssessmentSuccess,
  AddStudentAssessmentFailure,

  GetStudentTestListRequest,
  GetStudentTestListSuccess,
  GetStudentTestListFailure,

  GetAssessmentAnswerRequest,
  GetAssessmentAnswerSuccess,
  GetAssessmentAnswerFailure,

  GetAssessmentResultRequest,
  GetAssessmentResultSuccess,
  GetAssessmentResultFailure,

  GetResultListRequest,
  GetResultListSuccess,
  GetResultListFailure,

  GetCompareChartRequest,
  GetCompareChartSuccess,
  GetCompareChartFailure,

  UpdateAssessmentResultRequest,
  UpdateAssessmentResultSuccess,
  UpdateAssessmentResultFailure,

  GetLearningPlanRequest,
  GetLearningPlanSuccess,
  GetLearningPlanFailure,

  ViewLearningPlanRequest,
  ViewLearningPlanSuccess,
  ViewLearningPlanFailure,

  GetLearningPlanListsRequest,
  GetLearningPlanListsSuccess,
  GetLearningPlanListsFailure,

  DownloadLearningPlanRequest,
  DownloadLearningPlanSuccess,
  DownloadLearningPlanFailure,

  ShuffleLearningPathRequest,
  ShuffleLearningPathSuccess,
  ShuffleLearningPathFailure,

  GetStudentListRequest,
  GetStudentListSuccess,
  GetStudentListFailure,

  GetProgressCheckRequest,
  GetProgressCheckSuccess,
  GetProgressCheckFailure,

  AddProgressCheckRequest,
  AddProgressCheckSuccess,
  AddProgressCheckFailure,

  GetProgressReportRequest,
  GetProgressReportSuccess,
  GetProgressReportFailure,

  GetLearningPathMainRequest,
  GetLearningPathMainSuccess,
  GetLearningPathMainFailure,

  AddServicesRequest,
  AddServicesSuccess,
  AddServicesFailure,

  AddPricingRequest,
  AddPricingSuccess,
  AddPricingFailure,

  AddStaffRequest,
  AddStaffSuccess,
  AddStaffFailure,

  AddServiceSubjectRequest,
  AddServiceSubjectSuccess,
  AddServiceSubjectFailure,

  AddStudentEnrollRequest,
  AddStudentEnrollSuccess,
  AddStudentEnrollFailure,

} = FetchCompanyDataReducer.actions;

export default FetchCompanyDataReducer.reducer;