import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import { GetProgressReportRequest } from '../redux/reducer/FetchStudentDataReducer'
import Modal from 'react-bootstrap/Modal';

const ReportCard = () => {

    const fetchstudentReducer = useSelector((state) => state.FetchStudentDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var ProgressReportList = fetchstudentReducer?.GetProgressReportResponse?.results[0]

    console.log(ProgressReportList)

    const dispatch = useDispatch()
    const { id } = useParams()
    let navigate = useNavigate()

    useEffect(() => {
        console.log("Report Card", id)
        let obj = {
            'test_id': id,
            'student_id': userinfo?.user_id,
        }
        dispatch(GetProgressReportRequest(obj))
        console.log("Report Card OBJ", obj)
    }, [])

    return (
        <div className='common-layout'>
            <Loader visible={fetchstudentReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Student Progress Report </h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='form-group'>
                    <hr />
                </div>

                <div className='mb-4'>
                    <h4 className='text-center'> <u>Report Card</u> </h4>
                </div>

                <div className='row'>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Student Name:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.student_first_name}  {ProgressReportList?.student_last_name}</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>School Name:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.company_name} </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Class:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.grade_name} </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Group:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.group_name} </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Subject:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.subject_name} </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Test Name:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.test_name}</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Total Marks:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.fullmarks} </b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Marks Obtained:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.total_percantege_obtained} %</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Pass Marks:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.passmarks} %</b>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className='col-lg-6'>
                        <div className='form-group'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <label className='d-block text-right'>Status:</label>
                                </div>
                                <div className='col-lg-6'>
                                    <b>{ProgressReportList?.result_status} </b>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    {/* <div className='col-lg-6'>
                            <div className='form-group'>
                                <div className='row align-items-center'>
                                    <div className='col-lg-6'>
                                        <label className='d-block text-right'>Total Answer:</label>
                                    </div>
                                    <div className='col-lg-6'>
                                        <b>{ProgressReportList?.total_answer_value}</b>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                </div>
            </div>
        </div>

    )
}

export default ReportCard