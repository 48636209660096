import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
  token: null,
  status: '',
  isloading: false,
  data: {},
  error: {},
}

const FetchStudentDataReducer = createSlice({
  name: 'FetchStudentDataReducer',
  initialState,
  reducers: {



    //get company list
    GetStudentAssesmentRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetStudentAssesmentSuccess(state, action) {
      state.GetStudentAssesmentResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetStudentAssesmentFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },


    //take exam list
    TakeexamRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    TakeexamSuccess(state, action) {
      state.TakeexamResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    TakeexamFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get Progress Report list
    GetProgressReportRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetProgressReportSuccess(state, action) {
      state.GetProgressReportResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetProgressReportFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //submit exam list
    SubmitExamRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    SubmitExamSuccess(state, action) {
      state.SubmitExamResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    SubmitExamFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    // get Answer Details
    GetAnswerRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },

    GetAnswerSuccess(state, action) {
      state.GetAnswerResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetAnswerFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

    //get Assessment Answer Details
    GetAssessmentAnswerRequest(state, action) {
      state.status = action.type;
      state.isloading = true;
    },
    GetAssessmentAnswerSuccess(state, action) {
      state.GetAssessmentAnswerResponse = action.payload;
      state.status = action.type;
      state.isloading = false;
    },
    GetAssessmentAnswerFailure(state, action) {
      state.error = action.payload;
      state.status = action.type;
      state.isloading = false;
    },

  },
});


export const {

  SubmitExamRequest,
  SubmitExamSuccess,
  SubmitExamFailure,

  TakeexamRequest,
  TakeexamSuccess,
  TakeexamFailure,

  GetStudentAssesmentRequest,
  GetStudentAssesmentSuccess,
  GetStudentAssesmentFailure,

  GetProgressReportRequest,
  GetProgressReportSuccess,
  GetProgressReportFailure,

  GetAnswerRequest,
  GetAnswerSuccess,
  GetAnswerFailure,

  GetAssessmentAnswerRequest,
  GetAssessmentAnswerSuccess,
  GetAssessmentAnswerFailure,


} = FetchStudentDataReducer.actions;

export default FetchStudentDataReducer.reducer;