import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddBackgroundRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const GuidelineDetails = () => {
    const [bccname, setbccname] = useState("")
    const [email, setemail] = useState("")
    const [contactname, setcontactname] = useState("")
    const [phone, setphone] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetBackgroundResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const SubmitHandle = () => {
        if (bccname == '') {
            Swal.fire({
                icon: "error",
                text: 'Name should be must',
            });
        } else if (email == '') {
            Swal.fire({
                icon: "error",
                text: 'Email should be must',
            });
        } else if (contactname == '') {
            Swal.fire({
                icon: "error",
                text: 'Contact Name should be must',
            });
        } else if (phone == '') {
            Swal.fire({
                icon: "error",
                text: 'Phone should be must',
            });
        } else {

            let obj = {
                "bcc_name": bccname,
                "email": email,
                "contact_name": contactname,
                "phone": phone,

            }

            dispatch(AddBackgroundRequest({ obj: obj, navigate: navigate }))

        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Enrollment Details</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <label className='selectlabel' style={{ fontSize: '16px' }}>Guidelines Terms and Consent Forms</label>

                <div className='row form-style mb-4'>

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Learning Center Guidelines</label>
                            <textarea type='text' disabled className='form-control' placeholder='Learning Center Guidelines'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    <div className='d-flex mb-5 ml-5'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                    </div>

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Mathnasium @home Learning Center Guidelines</label>
                            <textarea type='text formBtn' disabled className='form-control' placeholder='Mathnasium @home Learning Center Guidelines'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    <div className='d-flex mb-5 ml-5'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                    </div>

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Media Release Form</label>
                            <textarea type='text' disabled className='form-control' placeholder='Media Release Form'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    <div className='d-flex mb-5 ml-5'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                    </div>

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Teacher Consent Form</label>
                            <textarea type='text' disabled className='form-control' placeholder='Teacher Consent Form'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    <div className='d-flex mb-5 ml-5'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                    </div>

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Leave Unescorted Consent Form</label>
                            <textarea type='text' disabled className='form-control' placeholder='Leave Unescorted Consent Form'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    <div className='d-flex mb-5 ml-5'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                    </div>

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Remote Digital Enrollment Email Text</label>
                            <textarea type='text' disabled className='form-control' placeholder='Remote Digital Enrollment Email Text'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4 mb-5'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    {/* <div className='d-flex mb-5'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                        </div> */}

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Change Payment Method Request Email</label>
                            <textarea type='text' disabled className='form-control' placeholder='Change Payment Method Request Email'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4 mb-5'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    {/* <div className='d-flex mb-5'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                        </div> */}

                    <div className='col-lg-12'>
                        <div className='form-group'>
                            <label className='selectlabel'>Enrollment Confirmation</label>
                            <textarea type='text' disabled className='form-control' placeholder='Enrollment Confirmation'
                            ></textarea>
                        </div>
                    </div>
                    <div className='ml-4 mb-5'>
                        <input type='checkbox' /> I Accept All the Terms & Conditions
                    </div>
                    {/* <div className='d-flex mb-2'>
                        <button className='mr-2 formBtn'>Opt In</button>
                        <button className='formBtn'>Opt Out</button>
                        </div> */}

                </div>
                {/* <button className='formBtn float-right mb-4' onClick={SubmitHandle}>Next</button> */}
                <NavLink className='btn btn-primary mr-3 mb-4 float-right' to={`/${userinfo?.company_slug}/EnrollmentCost`}>Next</NavLink>
            </div>

        </div>
    )
}

export default GuidelineDetails