import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetTitleRequest, DeleteTitleRequest, GetStudentListRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import ApiConnection from '../../utils/ApiConnection'
import Dropdown from 'react-bootstrap/Dropdown';
import DataTable from 'react-data-table-component';

const ReportList = () => {

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var studentList = fetchacompanyReducer?.GetStudentListResponse?.results

    console.log(fetchacompanyReducer.GetTitleResponse)

    const [showMembersInfoBox, setshowMembersInfoBox] = useState(false);
    const [showProspectInfoBox, setshowProspectInfoBox] = useState(false);
    const [showSalesInfoBox, setshowSalesInfoBox] = useState(false);
    const [showEmployessPaymentInfoBox, setshowEmployessPaymentInfoBox] = useState(false);
    const [showOthersInfoBox, setshowOthersInfoBox] = useState(false);
    const [showPaymentInfoBox, setshowPaymentInfoBox] = useState(false);
    const [FilterResult, setFilterResult] = useState([])
    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")

    const dispatch = useDispatch()

    const columns = [
        {
            name: 'Student Name',
            selector: row => row.first_name + ' ' + row.last_name,
            sortable: true,
        },
        {
            name: 'Grade',
            selector: row => row.grade_name,
            sortable: true,
        },
        {
            name: 'School',
            selector: row => row.school_name,
            sortable: true,
        },
        {
            name: 'School Year',
            selector: row => row.school_year,
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row.virtual_loocation,
            sortable: true,
        },
        {
            name: 'Action',
            selector: (row) => (
                <>
                    <NavLink to={`/${userinfo?.company_slug}/students/view-students/${row?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                    {/* <NavLink to={`/${userinfo?.company_slug}/students/edit-students/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink> */}
                    {/* <button onClick={() => DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button> */}
                </>
            ),
        },
    ];

    useEffect(() => {
        const result = studentList && studentList.filter(data => {
            return data.first_name?.toLowerCase().match(Namesearch?.toLowerCase())
        })

        setFilterResult(result)
    }, [Namesearch])

    useEffect(()=>{

        setFilterResult(studentList)
      },[fetchacompanyReducer?.GetStudentListResponse])

    useEffect(() => {
        dispatch(GetStudentListRequest())
    }, [])

    const handleMembersClick = () => {
        setshowMembersInfoBox(!showMembersInfoBox);
    };

    const handleProspectClick = () => {
        setshowProspectInfoBox(!showProspectInfoBox);
    };

    const handleEmployeeClick = () => {
        setshowEmployessPaymentInfoBox(!showEmployessPaymentInfoBox);
    };

    const handleSalesClick = () => {
        setshowSalesInfoBox(!showSalesInfoBox);
    };

    const handlePaymentClick = () => {
        setshowPaymentInfoBox(!showPaymentInfoBox);
    };

    const handleOthersClick = () => {
        setshowOthersInfoBox(!showOthersInfoBox);
    };



    return (

        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Reports </h3>
                </div>

                <div className='d-flex'>
                <div className='col-lg-3'>

                <Card style={{ width: '14rem' }}>
                    <ListGroup className="list-group-flush" style={{gap: '6px'}}>
                        <ListGroup.Item style={{ height: '58px', backgroundColor:!showMembersInfoBox ? '#e7e6e6' : 'rgb(206 235 235)'}} onClick={handleMembersClick}> <i className="fa fa-users"></i>  Members</ListGroup.Item>
                        <ListGroup.Item style={{ height: '58px', backgroundColor:!showProspectInfoBox ? '#e7e6e6' : 'rgb(206 235 235)'}} onClick={handleProspectClick}> <i class="fa-solid fa-user"></i>  Prospects</ListGroup.Item>
                        <ListGroup.Item style={{ height: '58px', backgroundColor:!showSalesInfoBox ? '#e7e6e6' : 'rgb(206 235 235)'}} onClick={handleSalesClick}> <i class="fa fa-shopping-cart"></i>  Sales</ListGroup.Item>
                        <ListGroup.Item style={{ height: '58px', backgroundColor:!showPaymentInfoBox ? '#e7e6e6' : 'rgb(206 235 235)'}} onClick={handlePaymentClick}> <i class="fa fa-credit-card"></i>  Payment Process</ListGroup.Item>
                        <ListGroup.Item style={{ height: '58px', backgroundColor:!showEmployessPaymentInfoBox ? '#e7e6e6' : 'rgb(206 235 235)'}} onClick={handleEmployeeClick}> <i class="fa fa-suitcase"></i>  Employees</ListGroup.Item>
                        <ListGroup.Item style={{ height: '58px', backgroundColor:!showOthersInfoBox ? '#e7e6e6' : 'rgb(206 235 235)'}} onClick={handleOthersClick}> <i class="fa fa-list-alt"></i>  Others</ListGroup.Item>
                    </ListGroup>
                </Card>
                </div>



                {showMembersInfoBox && (
                    <div className='col-lg-9 common-layout pt-2 infoBox'>

                        <div style={{ marginLeft: '-155px' }}>
                            <h6 style={{ fontWeight: "500", fontSize: "18px" }}>Members</h6>
                            <ul>
                                <span style={{ fontWeight: "400", fontSize: "14px" }}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                </span>
                            </ul>
                        </div>

                        <div style={{ marginLeft: '-155px' }}>
                        <Accordion defaultActiveKey="0" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-user-friends"></i>  Members</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                        <hr></hr>
                                        <div className='table-responsive custom-table'>
                    <DataTable
                        columns={columns}
                        data={FilterResult}
                        pagination
                        highlightOnHover
                    />
                </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="1" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-calendar-alt"></i>  Attendance</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="2" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-book-reader"></i>  Assessment</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="3" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-chalkboard-teacher"></i>  Enrollment</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="4" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-user-tie"></i>  Virtual / In-Person</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="5" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-graduation-cap"></i>  Schools</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="6" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-birthday-cake"></i>  Birthdays</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="7" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa fa-calendar"></i>  Session Report</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="8" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-envelope"></i>  Mailing List</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="9" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-hand-holding-usd"></i>  Account Balances</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="10" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa fa-phone"></i>  Contact Logs</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="11" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa fa-address-card"></i>  Entry Logs</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="12" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-money-check-alt"></i>  Unpaid Visit</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="13" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa fa-sign-out"></i>  Last Visit</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="14" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="far fa-address-book"></i>  Visit Remaining</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="15" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa-solid fa-image"></i>  Schedule at a Glance</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="16" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fas fa-money-bill-wave-alt"></i>  No - Return</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="17" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa-solid fa-image"></i>  No - Shows</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="18" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa-solid fa-image"></i>  Client Arrivals</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        <Accordion defaultActiveKey="19" className='view-accordian mt-4' style={{ width: '45rem' }}>
                            <Accordion.Item className='service_accordian mb-3'>
                                <Accordion.Header>
                                    &nbsp;
                                </Accordion.Header>
                                <div className='d-flex justify-content-between pr-5'>
                                    <h6><i className="fa-solid fa-image"></i>  Referrers</h6>
                                    <h6 className='detailreport'>Detail Report</h6>
                                </div>
                                <Accordion.Body>
                                    <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                        <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                        </span>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>

                        </div>

                    </div>
                )}

                {showProspectInfoBox && (
                    <div className='col-lg-9 common-layout pt-2 infoBox'>

                    <div style={{ marginLeft: '-155px' }}>
                        <h6 style={{ fontWeight: "500", fontSize: "18px" }}>Prospects</h6>
                        <ul>
                            <span style={{ fontWeight: "400", fontSize: "14px" }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </span>
                        </ul>
                    </div>

                    <div style={{ marginLeft: '-155px' }}>
                    <Accordion defaultActiveKey="0" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6><i class="fa fa-map-marker"></i>  Tracking</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="1" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6><i class="fa fa-star"></i>  Roster</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="2" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6><i class="fa fa-star"></i>  Conversion</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="3" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6><i class="fa fa-star"></i>  Referrals</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    </div>

                </div>
                )}

                {showSalesInfoBox && (
                    <div className='col-lg-9 common-layout pt-2 infoBox'>

                    <div style={{ marginLeft: '-155px' }}>
                        <h6 style={{ fontWeight: "500", fontSize: "18px" }}>Sales</h6>
                        <ul>
                            <span style={{ fontWeight: "400", fontSize: "14px" }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </span>
                        </ul>
                    </div>

                    <div style={{ marginLeft: '-155px' }}>
                    <Accordion defaultActiveKey="0" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Sales</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="1" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Daily Closeout</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="2" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Sales by Service</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="3" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Sales by Category</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="4" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Cash Drawer</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="5" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Attendance with Revenue</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="6" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Sales by Product</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="7" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Sales by Rep</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="8" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Gift Cards</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    </div>

                </div>
                )}

                {showPaymentInfoBox && (
                    <div className='col-lg-9 common-layout pt-2 infoBox'>

                    <div style={{ marginLeft: '-155px' }}>
                        <h6 style={{ fontWeight: "500", fontSize: "18px" }}>Payment Process</h6>
                        <ul>
                            <span style={{ fontWeight: "400", fontSize: "14px" }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </span>
                        </ul>
                    </div>

                    <div style={{ marginLeft: '-155px' }}>
                    <Accordion defaultActiveKey="0" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Approved Transactions</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="1" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Autopay Details</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="2" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Settled Transactions</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="3" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Autopay Summary</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="4" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Pending Transactions</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="5" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Voided/Rejected Transactions</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="6" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Autopay Expirations</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="7" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                            <h6>Autopay CC Expirations</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    </div>

                </div>
                )}

                {showEmployessPaymentInfoBox && (
                    <div className='col-lg-9 common-layout pt-2 infoBox'>

                    <div style={{ marginLeft: '-155px' }}>
                        <h6 style={{ fontWeight: "500", fontSize: "18px" }}>Employees</h6>
                        <ul>
                            <span style={{ fontWeight: "400", fontSize: "14px" }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </span>
                        </ul>
                    </div>

                    <div style={{ marginLeft: '-155px' }}>
                    <Accordion defaultActiveKey="0" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Payroll</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="1" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Schedule at a Glance</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="2" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Time Clock</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="3" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                            <h6>Staff Schedule</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="4" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                            <h6>Phone Book</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="5" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                            <h6>Pay Rates</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Accordion defaultActiveKey="6" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Rating and Reviews</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    </div>

                </div>
                )}

                {showOthersInfoBox && (
                    <div className='col-lg-9 common-layout pt-2 infoBox' style={{borderBottomLeftRadius: '0px'}}>

                    <div style={{ marginLeft: '-155px' }}>
                        <h6 style={{ fontWeight: "500", fontSize: "18px" }}>Others</h6>
                        <ul>
                            <span style={{ fontWeight: "400", fontSize: "14px" }}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                            </span>
                        </ul>
                    </div>

                    {/* <div style={{ marginLeft: '-155px' }}>
                    <Accordion defaultActiveKey="0" className='view-accordian mt-4' style={{ width: '45rem' }}>
                        <Accordion.Item className='service_accordian mb-3'>
                            <Accordion.Header>
                                &nbsp;
                            </Accordion.Header>
                            <div className='d-flex justify-content-between pr-5'>
                                <h6>Others</h6>
                                <h6 className='detailreport'>Detail Report</h6>
                            </div>
                            <Accordion.Body>
                                <div style={{ border: "1px solid #dfe0eb", borderRadius: "4px", padding: '10px', marginTop: '5px' }}>
                                    <span style={{ fontWeight: "400", fontSize: "14px" }}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                    </span>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    </div> */}

                </div>
                )}
                </div>


            </div>
        </div>

    )
}

export default ReportList