import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddTitleRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const ViewCustomers = () => {
    const [tname, settname] = useState("")



    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetTitleResponse       )

    const dispatch = useDispatch()
    let navigate = useNavigate()

const SubmitHandle = () =>{
    if(tname == ''){
        Swal.fire({
            icon: "error",
            text: 'Name should be must',
          });
        } else {

                    let obj = {
                        "name": tname,
                    }

                    dispatch(AddTitleRequest({obj:obj, navigate:navigate}))

                }
}


  return (
    <div className='common-layout'>
        <h3>View Customers</h3>

  </div>
  )
}

export default ViewCustomers