import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import { DeletestudentRequest, ViewstudentRequest, ExportstudentRequest, GetStudentListRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DataTable from 'react-data-table-component';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
import Dropdown from 'react-bootstrap/Dropdown';
import ApiConnection from '../../utils/ApiConnection'



const ActiveServicesList = () => {

    const [search, setsearch] = useState("")
    const [Namesearch, setNamesearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const [show, setShow] = useState(false);
    const [EnrollmentList, setEnrollmentList] = useState([])
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const [scheduledate, setscheduledate] = useState("");
    const [totalamount, settotalamount] = useState("");
    const [startdate, setstartdate] = useState("")
    const [enddate, setenddate] = useState("")
    const [remarks, setremarks] = useState("")
    const [holdduration, setholdduration] = useState("")
    const [hId, sethId] = useState(null);
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    const dispatch = useDispatch()
    const { id } = useParams()
    let navigate = useNavigate()

    const handleClose = () => setShow(false);

    const GetEnrollmentList = async () => {

        try {
            const response = await ApiConnection.get(`student-admission-crud/?server_type=production&student_id=${id}`)
            // const response = await ApiConnection.get(`stripe-payment-list/`)
            if (response?.status == 200) {
                console.log("get Enrollment", response.data.results)
                setEnrollmentList(response?.data?.results)
            }
        } catch (e) { }
    }

    useEffect(() => {
        GetEnrollmentList();
    }, [])

    const handleShow1 = (hid) => {
        console.log(hid)
        sethId(hid)

        setShow1(true)
    };

    const handleShow2 = () => {
        // console.log(hid)
        // sethId(hid)

        setShow2(true)
    };

    const CancelHandle = async (srvid) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You want to cancel this Service!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!'
        }).then(async (result) => {
            if (result.isConfirmed) {
                const response = await ApiConnection.post(`service-subscription-cancel/?server_type=production&student_id=${id}&service_id=${srvid}`)
                console.log(srvid, id)
                if (response?.status == 200) {
                    Swal.fire(
                        'Cancelled!',
                        'Your Service has been Cancelled.',
                        'success'
                    )
                    GetEnrollmentList();
                }

            }
        })
    };

    const calculateHoldDuration = (e) => {
        setenddate(e.target.value);
        const startDate = new Date(startdate);
        const endDate = new Date(e.target.value);
        const differenceInTime = endDate.getTime() - startDate.getTime();
        const differenceInDays = differenceInTime / (1000 * 3600 * 24) + 1;
        setholdduration(differenceInDays);
    };

    const HoldHandle = async () => {

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

        if (startdate == '') {
            Swal.fire({
                icon: "error",
                text: 'Start Date should be must',
            });
        } else if (enddate == '') {
            Swal.fire({
                icon: "error",
                text: 'End Date should be must',
            });
        }

        else {

            let obj = {
                "start_date": startdate,
                "end_date": enddate,
                "hold_duration": holdduration,
                "remarks": remarks,
                "status": "hold"
            }

            try {
                const response = await ApiConnection.post(`service-subscription-hold/?server_type=production&service_id=${hId}&student_id=${id}`, obj)
                if (response?.status == 200) {
                    Swal.fire({
                        icon: "success",
                        text: response?.data?.msg,
                    });
                }
            } catch (e) { }
        }
    }



    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Active Services</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                {EnrollmentList && EnrollmentList.length > 0 ?
                    <div className='table-responsive'>
                        <table className='custom-table'>
                            <thead>
                                <tr>
                                    <th>Service Name</th>
                                    <th>Start date</th>
                                    <th>End Date</th>
                                    <th>Recurring</th>
                                    <th>Payment Date</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Allowed Sessions</th>
                                    {/* <th>Booked Sessions</th> */}
                                    <th>Delivery</th>
                                    {/* <th>Payment</th>
                                <th>Link</th>
                                <th>Payment Status</th> */}
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {EnrollmentList && EnrollmentList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item?.service_name}</td>
                                            <td>{moment(item?.contract_start_date).format("YYYY-MM-DD")}</td>
                                            <td>{moment(item?.contract_end_date).format("YYYY-MM-DD")}</td>
                                            <td>{item?.recurring}</td>
                                            <td></td>
                                            <td>{item?.todays_total_fee}</td>
                                            {/* <td><button className='formBtn text-info assignbtn' onClick={() => handleShow2(item.id)}>Hold</button></td> */}
                                            <td><button className='formBtn text-info assignbtn'></button></td>
                                            <td>{item?.exam_url}</td>
                                            {/* <td>{item?.pass_mark}</td> */}
                                            <td>{item?.total_mark}</td>
                                            {/* <td><button className='formBtn assignbtn'>Pay Now</button></td> */}
                                            {/* <td><NavLink to={`/${userinfo?.company_slug}/students/PaymentLink/${item?.id}/${item?.company_id}/${id}`}>Pay Now</NavLink></td> */}
                                            {/* <td><NavLink>Share</NavLink></td>
                                        <td style={{ color: item?.status === 'active' ? "green" : 'red', fontWeight: '500', fontSize: '14px', textAlign: 'center' }}>{item?.status}</td> */}
                                            <td>
                                                <button className='formBtn text-info assignbtn' onClick={() => handleShow1(item.id)}>Hold</button>
                                                <button className='formBtn assignbtn' onClick={() => CancelHandle(item.id)}>Cancel</button>
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>

                        </table>
                    </div>
                    :
                    <h6>No data found</h6>
                }
            </div>

            <Modal show={show1} onHide={handleClose1} size="lg">
                <Modal.Header >
                    <Modal.Title>Holds</Modal.Title>
                    <button onClick={handleClose1} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Start Date <span className="asterisk">*</span></label>
                                <input type='date' className='form-control' placeholder='Hold Start Date '
                                    value={startdate} onChange={(e) => {
                                        setstartdate(e.target.value);
                                        calculateHoldDuration(e)
                                    }} />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>End Date <span className="asterisk">*</span></label>
                                <input
                                    type='date'
                                    className='form-control'
                                    placeholder='Hold End Date'
                                    value={enddate}
                                    onChange={(e) => calculateHoldDuration(e)}
                                />
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Total Hold Duration <span className="asterisk">*</span></label>
                                <input type='number' className='form-control' placeholder='Hold Duration '
                                    value={holdduration} readOnly={true} />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='form-group'>
                                <label>Remarks </label>
                                <input type='text' className='form-control' placeholder='Enter Remarks '
                                    value={remarks} onChange={(e) => setremarks(e.target.value)} />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='formBtn' onClick={HoldHandle}>Add</button>

                </Modal.Footer>
            </Modal>

            <Modal show={show2} onHide={handleClose2} size="lg">
                <Modal.Header >
                    <Modal.Title>Holds</Modal.Title>
                    <button onClick={handleClose2} className='btn btn-sm btn-warning'><i class="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row form-style mb-4'>

                        <div className='view-page'>

                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='form-group'>
                                        <div className='row align-items-center'>
                                            <div className='col-lg-6'>
                                                <label className='d-block text-right'>Hold Start Date :</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <b></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='form-group'>
                                        <div className='row align-items-center'>
                                            <div className='col-lg-6'>
                                                <label className='d-block text-right'>Hold End Date :</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <b></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='form-group'>
                                        <div className='row align-items-center'>
                                            <div className='col-lg-6'>
                                                <label className='d-block text-right'>Hold Duration :</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <b></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6'>
                                    <div className='form-group'>
                                        <div className='row align-items-center'>
                                            <div className='col-lg-6'>
                                                <label className='d-block text-right'>Remarks :</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <b></b>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                {/* <div className='col-lg-6'>
                                    <div className='form-group'>
                                        <div className='row align-items-center'>
                                            <div className='col-lg-6'>
                                                <label className='d-block text-right'>Remarks :</label>
                                            </div>
                                            <div className='col-lg-6'>
                                                <b></b>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {/* <button className='formBtn' onClick={HoldHandle}>Add</button> */}

                </Modal.Footer>
            </Modal>

        </div>


    )
}

export default ActiveServicesList