import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddBackgroundRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const StudentDetails = () => {
    const [bccname, setbccname] = useState("")
    const [email, setemail] = useState("")
    const [contactname, setcontactname] = useState("")
    const [phone, setphone] = useState("")

    const [content1, setContent1] = useState(true);
    const [content2, setContent2] = useState(false);
    const [content3, setContent3] = useState(false);
    const [content4, setContent4] = useState(false);
    const [content5, setContent5] = useState(false);


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetBackgroundResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const SubmitHandle = () => {
        if (bccname == '') {
            Swal.fire({
                icon: "error",
                text: 'Name should be must',
            });
        } else if (email == '') {
            Swal.fire({
                icon: "error",
                text: 'Email should be must',
            });
        } else if (contactname == '') {
            Swal.fire({
                icon: "error",
                text: 'Contact Name should be must',
            });
        } else if (phone == '') {
            Swal.fire({
                icon: "error",
                text: 'Phone should be must',
            });
        } else {

            let obj = {
                "bcc_name": bccname,
                "email": email,
                "contact_name": contactname,
                "phone": phone,

            }

            dispatch(AddBackgroundRequest({ obj: obj, navigate: navigate }))

        }
    }

    const Content_1 =
        "Content 1" ;

    const Content_2 =
        "Content 2" ;

    const Content_3 =
        "Content 3" ;

        const Content_4 =
        "Content 4" ;

        const Content_5 =
        "Content 5" ;

    function setContent(id) {
        setContent1(id === 1);
        setContent2(id === 2);
        setContent3(id === 3);
        setContent4(id === 4);
        setContent5(id === 5);
    }


    return (



        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />

            {content1 &&
                <div className='innerlayout p-3'>
                    <div className='mb-4'>
                        <h3> Enrollment Details</h3>
                    </div>
                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                        <ul>
                            <li>
                                <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                            </li>
                        </ul>
                    </div>

                    <label className='selectlabel' style={{ fontSize: '16px' }}>Student Information</label>

                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>First Name</label>
                                <input type='text' className='form-control' placeholder='First Name'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>last Name</label>
                                <input type='text' className='form-control' placeholder='Last Name'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Gender</label>
                                <input type='text' className='form-control' placeholder='Gender'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>DOB</label>
                                <input type='date' className='form-control' placeholder='DOB'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Grade</label>
                                <input type='text' className='form-control' placeholder='Grade'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>School Year</label>
                                <input type='text' className='form-control' placeholder='School Year'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>School</label>
                                <input type='text' className='form-control' placeholder='School Name'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Teacher</label>
                                <input type='text' className='form-control' placeholder='Teacher Name'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 1</label>
                                <input type='text' className='form-control' placeholder='Address 1'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 2</label>
                                <input type='text' className='form-control' placeholder='Address 2'
                                />
                            </div>
                        </div>                    <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Country</label>
                                <input type='text' className='form-control' placeholder='Country'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>State</label>
                                <input type='text' className='form-control' placeholder='State'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>City</label>
                                <input type='text' className='form-control' placeholder='City'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Pincode</label>
                                <input type='text' className='form-control' placeholder='Pincode'
                                />
                            </div>
                        </div>

                    </div>
                    <button className='formBtn mr-3 mb-4 float-right' onClick={() => setContent(2)}>Next</button>
                    {/* <NavLink className='btn btn-primary mr-3 mb-4 float-right' to={`/${userinfo?.company_slug}/GuardianDetails`}>Next</NavLink> */}
                </div>
            }

            {content2 &&
                <div className='innerlayout p-3'>
                    <div className='mb-4'>
                        <h3> Enrollment Details</h3>
                    </div>
                    <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                        <ul>
                            <li>
                                <button className='formBtn1' onClick={() => setContent(1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                            </li>
                        </ul>
                    </div>
                    <label className='selectlabel' style={{ fontSize: '16px' }}>Guardian Information</label>

                    <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>First Name</label>
                                <input type='text' className='form-control' placeholder='First Name'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Last Name</label>
                                <input type='text' className='form-control' placeholder='Last Name'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Gender</label>
                                <input type='text' className='form-control' placeholder='Gender'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>DOB</label>
                                <input type='date' className='form-control' placeholder='DOB'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Email</label>
                                <input type='text' className='form-control' placeholder='Email'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Phone</label>
                                <input type='text' className='form-control' placeholder='Phone No.'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 1</label>
                                <input type='text' className='form-control' placeholder='Address 1'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Address 2</label>
                                <input type='text' className='form-control' placeholder='Address 2'
                                />
                            </div>
                        </div>                    <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Country</label>
                                <input type='text' className='form-control' placeholder='Country'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>State</label>
                                <input type='text' className='form-control' placeholder='State'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>City</label>
                                <input type='text' className='form-control' placeholder='City'
                                />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Pincode</label>
                                <input type='text' className='form-control' placeholder='Pincode'
                                />
                            </div>
                        </div>

                    </div>
                    <button className='formBtn mr-3 mb-4 float-right' onClick={() => setContent(3)}>Next</button>
                    {/* <NavLink className='btn btn-primary mr-3 mb-4 float-right' to={`/${userinfo?.company_slug}/BillingDetails`}>Next</NavLink> */}
                </div>
            }

{content3 &&
            <div className='innerlayout p-3'>
            <div className='mb-4'>
                <h3> Enrollment Details</h3>
            </div>
            <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                <ul>
                    <li>
                        <button className='formBtn1' onClick={() => setContent(2)}><i className="fa-solid fa-angle-left"></i> Back</button>
                    </li>
                </ul>
            </div>
            <label className='selectlabel' style={{fontSize: '16px'}}>Billing Information</label>

            <div className='row form-style mb-4'>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>First Name</label>
                            <input type='text' className='form-control' placeholder='First Name'
                                />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>last Name</label>
                            <input type='text' className='form-control' placeholder='Last Name'
                                />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Email</label>
                            <input type='text' className='form-control' placeholder='Email'
                                />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Phone</label>
                            <input type='text' className='form-control' placeholder='Phone'
                                />
                        </div>
                    </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Address 1</label>
                        <input type='text' className='form-control' placeholder='Address 1'
                        />
                    </div>
                </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Address 2</label>
                        <input type='text' className='form-control' placeholder='Address 2'
                        />
                    </div>
                </div>                    <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Country</label>
                        <input type='text' className='form-control' placeholder='Country'
                        />
                    </div>
                </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>State</label>
                        <input type='text' className='form-control' placeholder='State'
                        />
                    </div>
                </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>City</label>
                        <input type='text' className='form-control' placeholder='City'
                        />
                    </div>
                </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Pincode</label>
                        <input type='text' className='form-control' placeholder='Pincode'
                        />
                    </div>
                </div>


            </div>
            <button className='formBtn mr-3 mb-4 float-right' onClick={() => setContent(4)}>Next</button>
            {/* <NavLink className='btn btn-primary mr-3 mb-4 float-right' to={`/${userinfo?.company_slug}/GuidelineDetails`}>Next</NavLink> */}
        </div>
}

{content4 &&
            <div className='innerlayout p-3'>
            <div className='mb-4'>
                <h3> Enrollment Details</h3>
            </div>
            <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                <ul>
                    <li>
                        <button className='formBtn1' onClick={() => setContent(3)}><i className="fa-solid fa-angle-left"></i> Back</button>
                    </li>
                </ul>
            </div>

            <label className='selectlabel' style={{ fontSize: '16px' }}>Guidelines Terms and Consent Forms</label>

            <div className='row form-style mb-4'>

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Learning Center Guidelines</label>
                        <textarea type='text' disabled className='form-control' placeholder='Learning Center Guidelines'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                <div className='d-flex mb-5 ml-5'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                </div>

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Mathnasium @home Learning Center Guidelines</label>
                        <textarea type='text formBtn' disabled className='form-control' placeholder='Mathnasium @home Learning Center Guidelines'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                <div className='d-flex mb-5 ml-5'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                </div>

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Media Release Form</label>
                        <textarea type='text' disabled className='form-control' placeholder='Media Release Form'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                <div className='d-flex mb-5 ml-5'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                </div>

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Teacher Consent Form</label>
                        <textarea type='text' disabled className='form-control' placeholder='Teacher Consent Form'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                <div className='d-flex mb-5 ml-5'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                </div>

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Leave Unescorted Consent Form</label>
                        <textarea type='text' disabled className='form-control' placeholder='Leave Unescorted Consent Form'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                <div className='d-flex mb-5 ml-5'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                </div>

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Remote Digital Enrollment Email Text</label>
                        <textarea type='text' disabled className='form-control' placeholder='Remote Digital Enrollment Email Text'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4 mb-5'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                {/* <div className='d-flex mb-5'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                    </div> */}

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Change Payment Method Request Email</label>
                        <textarea type='text' disabled className='form-control' placeholder='Change Payment Method Request Email'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4 mb-5'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                {/* <div className='d-flex mb-5'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                    </div> */}

                <div className='col-lg-12'>
                    <div className='form-group'>
                        <label className='selectlabel'>Enrollment Confirmation</label>
                        <textarea type='text' disabled className='form-control' placeholder='Enrollment Confirmation'
                        ></textarea>
                    </div>
                </div>
                <div className='ml-4 mb-5'>
                    <input type='checkbox' /> I Accept All the Terms & Conditions
                </div>
                {/* <div className='d-flex mb-2'>
                    <button className='mr-2 formBtn'>Opt In</button>
                    <button className='formBtn'>Opt Out</button>
                    </div> */}

            </div>
            <button className='formBtn mr-3 mb-4 float-right' onClick={() => setContent(5)}>Next</button>
            {/* <NavLink className='btn btn-primary mr-3 mb-4 float-right' to={`/${userinfo?.company_slug}/EnrollmentCost`}>Next</NavLink> */}
        </div>
}

{content5 &&
            <div className='innerlayout p-3'>
            <div className='mb-4'>
                <h3> Enrollment Details</h3>
            </div>
            <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                <ul>
                    <li>
                        <button className='formBtn1' onClick={() => setContent(4)}><i className="fa-solid fa-angle-left"></i> Back</button>
                    </li>
                </ul>
            </div>

            <div className='row form-style mb-4'>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Delivery Method</label>
                            <input type='text' className='form-control' placeholder='Delivery Method'
                                />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Contract Start Date</label>
                            <input type='date' className='form-control' placeholder='Contract Start Date'
                                />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                        <label className='selectlabel'>Contract End Date</label>
                            <input type='date' className='form-control' placeholder='Contract End Date'
                                />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Membership Type</label>
                            <input type='text' className='form-control' placeholder='Membership Type'
                                />
                        </div>
                    </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Session Count </label>
                        <input type='text' className='form-control' placeholder='Session Count'
                        />
                    </div>
                </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Recurs After</label>
                        <input type='text' className='form-control' placeholder='Recurs After'
                        />
                    </div>
                </div>                    <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Contract Length</label>
                        <input type='text' className='form-control' placeholder='Contract Length'
                        />
                    </div>
                </div>

                <div className='col-lg-4'>
                    <div className='form-group'>
                        <label className='selectlabel'>Contract End</label>
                        <input type='text' className='form-control' placeholder='Contract End'
                        />
                    </div>
                </div>

            </div>
            <button className='btn btn-primary mr-3 mb-4 float-right' onClick={SubmitHandle}>Confirm</button>
        </div>
}
        </div>
    )
}

export default StudentDetails