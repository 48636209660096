import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GettestRequest, DeleteModuleTypeRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';

const Viewtest = () => {

    const [search, setsearch] = useState("")
    const [FilterResult, setFilterResult] = useState([])
    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var testList = fetchacompanyReducer?.GettestResponse?.results

    console.log(fetchacompanyReducer.GettestResponse)

    const dispatch = useDispatch()

    const columns = [
        {
            name: 'Grade',
            selector: row => row.grade_name,
            sortable: true,
        },        {
            name: 'Subject',
            selector: row => row.subject_name,
            sortable: true,
        },        {
            name: 'Test Name',
            selector: row => row.name,
            sortable: true,
        },
      {
        name: 'Action',
        selector: (row) => (
          <>
             {/* <NavLink to={`/${userinfo?.company_slug}/Test/Editschedule/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink> */}
          <button disabled onClick={()=>DeleteHandle(row.id)} className='table-btn'><i className="fa-solid fa-trash"></i></button>
          </>
        ),
    },
    ];

    useEffect(() => {
        dispatch(GettestRequest())
    }, [])

    useEffect(()=>{
        const result = testList && testList.filter(data =>{
          return data.grade_name.toLowerCase().match(search.toLowerCase())
        })
        setFilterResult(result)
      },[search])

    useEffect(() => {
        dispatch(GettestRequest())
    }, [fetchacompanyReducer?.DeleteModuleTypeResponse])

    useEffect(()=>{

        setFilterResult(testList)
      },[fetchacompanyReducer?.GettestResponse])


    const DeleteHandle = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(DeleteModuleTypeRequest(id))

                if (fetchacompanyReducer?.DeleteModuleTypeResponse) {
                    Swal.fire(
                        'Deleted!',
                        'Your file has been deleted.',
                        'success'
                    )
                }

            }
        })
    }





    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3>Test Managemenet</h3>
                </div>
                {/* <NavLink className='formBtn1 testadd' to={`/${userinfo?.company_slug}/Test/Viewtest`}><i className="fa-solid fa-plus"></i> Test</NavLink> */}


                <div className='row form-style mb-4 align-items-center'>

            <div className='col-lg-4'>
                        <div className='form-group'>
                            <label>Select Grade</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label>Select Subject</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label>Select Test</label>
                            <select className='form-control' value={search}
                                onChange={(e) => setsearch(e.target.value)}>
                                <option value="">All</option>
                                {/* <option value="active">Active</option>
                                <option value="inactive">In-Active</option> */}
                            </select>
                        </div>
                    </div>

                    <div className='col-lg-4'>
                <input type='text' className='form-control' placeholder='Module'
                value={search}
                onChange={(e)=>setsearch(e.target.value)}
                />
            </div>

            <div className='col-lg-4'>
                <input type='text' className='form-control' placeholder='Assign Students'
                value={search}
                onChange={(e)=>setsearch(e.target.value)}
                />
            </div>
        
            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/Test/Addtest`}><i className="fa-solid fa-plus"></i> Create Test</NavLink>
     </div>

<div className='table-responsive'>
          <DataTable
            columns={columns}
            data={FilterResult}
            pagination
            highlightOnHover
        />

      </div>
            </div>
        </div>
    )
}

export default Viewtest