import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { GetSyllabusRequest, DeleteSyllabusRequest, GetGroupRequest, GetSubjectChangeRequest, GetGradeRequest, GetSubjectRequest, GetTopicRequest, GetModuleRequest, GetSyllabusTopicRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import DataTable from 'react-data-table-component';

const Syllabus = () => {
  const [sname, setsname] = useState("")
  const [subname, setsubname] = useState("")
  const [sgroup, setsgroup] = useState("")
  const [sgrade, setsgrade] = useState("")
  const [modulename, setmodulename] = useState("")
  const [topicname, settopicname] = useState("")
  const [search, setsearch] = useState("")
  const [FilterResult, setFilterResult] = useState([])
  const [show, setshow] = useState();
  const ShowHideHandle = (i) => {
    setshow(i)
}


  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  var syllabusList = fetchacompanyReducer?.GetSyllabusResponse?.results
  var groupList = fetchacompanyReducer?.GetGroupResponse?.results
  var gradeList = fetchacompanyReducer?.GetGradeResponse?.results
  var subList = fetchacompanyReducer?.GetSubjectChangeResponse?.results
  var ModuleList = fetchacompanyReducer?.GetModuleResponse?.results
  var TopicList = fetchacompanyReducer?.GetSyllabusTopicResponse?.results
  console.log(fetchacompanyReducer?.GetSyllabusTopicResponse?.results)

  const dispatch = useDispatch()

  const columns = [
    {
      name: 'Syllabus Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Code',
      selector: row => row.code,
      sortable: true,
    },
    {
      name: 'Group Name',
      selector: row => row.group_name,
      sortable: true,
    },
    {
      name: 'Grade Name',
      selector: row => row.grade_name,
      sortable: true,
    },
    {
      name: 'Subject Name',
      selector: row => row.subject_name,
      sortable: true,
    },
    {
      name: 'Action',
      selector: (row) => (
        <>
          <NavLink to={`/${userinfo?.company_slug}/curriculum/view-syllabus/${row?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
          <NavLink to={`/${userinfo?.company_slug}/curriculum/edit-syllabus/${row?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
          <button className='table-btn' onClick={() => DeleteHandle(row.id)}><i className="fa-solid fa-trash"></i></button>

        </>
      ),
    },
  ];

  // useEffect(() => {
  //   const result = syllabusList && syllabusList.filter(data => {
  //     return data.name.toLowerCase().match(search.toLowerCase())
  //   })
  //   setFilterResult(result)
  // }, [search])



  useEffect(() => {
    let obj = {
      'name': '',
      'grade_id': '',
      'group_id': '',
      'subject_id': ''
    }
    let obj1 = {
      'name': '',
      'group_id': '',
    }
    let obj2 = {
      'name': '',
      'group_id': '',
      'syllabus_id': '',
      'module_id': '',
    }
    dispatch(GetSyllabusRequest(obj))
    dispatch(GetGroupRequest())
    dispatch(GetGradeRequest())
    dispatch(GetSubjectRequest(obj1))
    dispatch(GetModuleRequest(obj1))
    dispatch(GetTopicRequest(obj2))
  }, [])



  useEffect(() => {
    let obj = {
      'name': '',
      'grade_id': '',
      'group_id': '',
      'subject_id': ''
    }
    dispatch(GetSyllabusRequest(obj))
  }, [fetchacompanyReducer?.DeleteSyllabusResponse])


  const DeleteHandle = (id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteSyllabusRequest(id))

        if (fetchacompanyReducer?.DeleteSyllabusResponse) {
          Swal.fire(
            'Deleted!',
            'Your file has been deleted.',
            'success'
          )
        }

      }
    })
  }


  const ResetHandle = () => {
    setsname("")
    setsgrade("")
    setsgroup("")
    setsubname("")
    let obj = {
      'name': '',
      'grade_id': '',
      'group_id': '',
      'subject_id': '',
      'module_id': '',
      'topic_id': '',
    }

    dispatch(GetSyllabusRequest(obj))
  }
  const GroupHandle = (e) => {
    var val = e.target.value
    setsgroup(val)

    let obj = {
      id: e.target.value,
      'name': search,
      'grade_id': sgrade,
      'group_id': val,
      'subject_id': subname,
      'module_id': modulename,
      'topic_id': topicname,
    }

    dispatch(GetSyllabusRequest(obj))
    dispatch(GetSubjectChangeRequest(obj))
    dispatch(GetModuleRequest(obj))
    dispatch(GetSyllabusTopicRequest(obj))
  }
  const SubjectHandle = (e) => {
    var val = e.target.value
    setsubname(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': sgrade,
      'group_id': sgroup,
      'subject_id': val,
      'module_id': modulename,
      'topic_id': topicname,
    }

    dispatch(GetSyllabusRequest(obj))
    dispatch(GetModuleRequest(obj))
    dispatch(GetSyllabusTopicRequest(obj))
  }

  const GradeHandle = (e) => {
    var val = e.target.value
    setsgrade(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': val,
      'group_id': sgroup,
      'subject_id': subname,
      'module_id': modulename,
      'topic_id': topicname,
    }

    dispatch(GetSyllabusRequest(obj))
  }

  const ModuleHandle = (e) => {
    var val = e.target.value
    setmodulename(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': sgrade,
      'group_id': sgroup,
      'subject_id': subname,
      'topic_id': topicname,
      'module_id': val
    }

    dispatch(GetSyllabusRequest(obj))
    dispatch(GetSyllabusTopicRequest(obj))
  }

  const TopicHandle = (e) => {
    var val = e.target.value
    settopicname(val)
    console.log(val)

    let obj = {
      'name': search,
      'grade_id': sgrade,
      'group_id': sgroup,
      'group_id': sgroup,
      'subject_id': subname,
      'module_id': modulename,
      'topic_id': val
    }

    dispatch(GetSyllabusRequest(obj))
  }





  useEffect(() => {

    setFilterResult(syllabusList)
  }, [fetchacompanyReducer?.GetSyllabusResponse])



  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3> Syllabus Management</h3>
        </div>



        <div className='row form-style mb-4 align-items-center'>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Syllabus Name</label>
              <input type='text' className='form-control' placeholder='Syllabus Name'
                value={search}
                onChange={(e) => setsearch(e.target.value)}
              />
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Grade</label>
              <select className='form-control' value={sgrade}
                onChange={(e) => GradeHandle(e)}>
                <option value="">--Grade---</option>
                {gradeList && gradeList.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.grade_name}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Group</label>
              <select className='form-control' value={sgroup}
                onChange={(e) => GroupHandle(e)}>
                <option value="">--All---</option>
                {groupList && groupList.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Subject</label>
              <select className='form-control' value={subname}
                onChange={(e) => SubjectHandle(e)}>
                <option value="">--All---</option>
                {subList && subList.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.subject_name}</option>
                  )
                })}
              </select>
            </div>
          </div>

          {/* <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Module</label>
              <select className='form-control' value={modulename}
                onChange={(e) => ModuleHandle(e)}>
                <option value="">--All---</option>
                {ModuleList && ModuleList.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.name}</option>
                  )
                })}
              </select>
            </div>
          </div> */}

          <div className='col-lg-3'>
            <div className='form-group'>
              <label>Select Topic</label>
              {/* <select className='form-control' value={topicname}
                onChange={(e) => TopicHandle(e)}>
                <option value="">--All---</option>
                {TopicList && TopicList.map((item, i) => {
                  return (
                    <option key={i} value={item?.id}>{item?.name}</option>
                  )
                })}
              </select> */}
               <input type='text' className='form-control' placeholder='Topic Name'
                value={topicname}
                onChange={(e) => settopicname(e.target.value)}
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>

              <div className='d-flex'>
                <button className='btn btn-warning' onClick={ResetHandle}>Reset</button>
              </div>
            </div>
          </div>
        </div>
        <div className='row mb-3'>
          <div className='col-lg-3'>
            <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/curriculum/add-syllabus`}><i className="fa-solid fa-plus"></i> Add Syllabus</NavLink>
          </div>
        </div>

        <div className='accordian-table-container custom-table'>

    <ul className='admin-ern-table'>
         
            <li><b>Syllabus Name</b></li>
            <li><b>Syllabus Code</b></li>
            <li><b>Group Name</b></li>
            <li><b>Grade Name</b></li>
            <li><b>Subject Name</b></li>
            <li><b>Action</b></li>

    </ul>

    {syllabusList&&syllabusList.map((item, index) =>{
        return ( 
            <>
            <ul key={index} className='admin-ern-table' onClick={()=>ShowHideHandle(index)}>
                 <span className='arrow-acc'><i className="fa-solid fa-angle-right"></i></span>
                <li>{item?.name}</li>
                <li>{item?.code}</li>
                <li>{item?.group_name}</li>
                <li>{item?.grade_name}</li>
                <li>{item.subject_name}</li>
                <li>
                <NavLink to={`/${userinfo?.company_slug}/curriculum/view-syllabus/${item?.id}`} className="mr-2"><i className="fa-solid fa-eye"></i></NavLink>
                            <NavLink to={`/${userinfo?.company_slug}/curriculum/edit-syllabus/${item?.id}`}><i className="fa-solid fa-pen-to-square"></i></NavLink>
                            <button className='table-btn' onClick={() => DeleteHandle(item.id)}><i className="fa-solid fa-trash"></i></button>
                </li>
            </ul>
           
            {show == index &&
    <>
            <table className='admin-ern-table-inner-table'>
               <thead>
                    <th>Module Name</th>
                    {/* <th>Code</th>
                    <th>Description</th> */}
               </thead>
               <tbody>
               {item?.modules.length > 0 ? 

               item?.modules.map((row, i)=>{
        return (
                <tr>

                    <td>{row?.name}</td>
                    {/* <td>{row?.code}</td>
                    <td>{row?.description}</td> */}
                </tr>
            )
        })
      :
      <tr>
        <td colSpan="3">No Record found</td>
      </tr>
      }
               </tbody>
            </table>

            <table className='admin-ern-table-inner-table'>
               <thead>
                    <th>Topic Name</th>
                    {/* <th>Code</th>
                    <th>Description</th> */}
               </thead>
               <tbody>
               {item?.topic_list.length > 0 ? 

               item?.topic_list.map((row, i)=>{
        return (
                <tr>

                    <td>{row?.name}</td>
                    {/* <td>{row?.code}</td>
                    <td>{row?.description}</td> */}
                </tr>
            )
        })
      :
      <tr>
        <td colSpan="3">No Record found</td>
      </tr>
      }
               </tbody>
            </table>

      </>
            }
   
            </>
        )
    })}

 
</div>

   

      </div>

    </div>
  )
}

export default Syllabus
