import { Outlet, Navigate } from 'react-router-dom'

const PrivateRoute = () => {
    const token = sessionStorage.getItem('mtb_access_token');
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))


    return(
        token ? <Outlet/> : <Navigate to={userinfo?.user_type == "ADMIN"  ?  "/" : `/${userinfo?.company_slug}/login`}  />
    )
}

export default PrivateRoute