import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, NavLink , useLocation} from 'react-router-dom'
import { ViewguardianRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const ViewGuardians = () => {
    const [tname, settname] = useState("")

    const {id, pros} = useParams()

    console.log(pros)

    const location = useLocation()


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var viewdata = fetchacompanyReducer?.ViewguardianResponse?.results[0]

    console.log(fetchacompanyReducer.ViewguardianResponse  )

    const dispatch = useDispatch()
    let navigate = useNavigate()

        useEffect(()=>{
        let obj ={
            id:id
        }
        dispatch(ViewguardianRequest(obj))
    },[])



  return (
    <div className='common-layout'>
    <Loader visible={fetchacompanyReducer.isloading}  />
      <div className='innerlayout p-3'>
      <div className='mb-4'>
        <h3> View Guardian</h3>
        </div>
      
      <div className='view-page'>
      <div className='form-group'>
        <h6>Guardian information</h6>
      </div>
      <div className='form-group'>
        <hr />
      </div> 
      <div className='row'>
      <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Salutation:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.salutation}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.first_name} {viewdata?.last_name}</b>
                         <NavLink to={`/${userinfo?.company_slug}/prospects/edit-guardians/${id}`} className="ml-2"><i className="fa-solid fa-pen-to-square"></i></NavLink>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Prospect Name:</label>
                         </div>
                         <div className='col-lg-6'>
                         {/* <NavLink to={`/${userinfo?.company_slug}/prospects/view/${location.state.prosId}`} >  </NavLink> */}
                         <b>{viewdata?.prospect_first_name} {viewdata?.prospect_last_name}</b>
                         <NavLink to={`/${userinfo?.company_slug}/prospects/view/${pros}/${id}`} className="ml-2"><i className="fa-solid fa-eye"></i></NavLink>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Email:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.email}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Mobile:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.mobile_phone}</b>
                         </div>
                    </div>
               </div>
          </div>
      
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Other Phone:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.other_phone}</b>
                         </div>
                    </div>
               </div>
          </div>

          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Guardian status:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.gurdain_status}</b>
                         </div>
                    </div>
               </div>
          </div>
          
          <div className='col-lg-12'>
            <div className='form-group'> 
            <h6>Address information</h6>
                  <hr/>
            </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Country:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.country_name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>State:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.state_name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>City:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.city_name}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Address 1:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.street_address_1}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Address 2:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.street_address_2}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Zipcode:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.zipcode}</b>
                         </div>
                    </div>
               </div>
          </div>
          <div className='col-lg-6'>
               <div className='form-group'> 
                    <div className='row align-items-center'>
                         <div className='col-lg-6'>
                         <label className='d-block text-right'>Description:</label>
                         </div>
                         <div className='col-lg-6'>
                         <b>{viewdata?.description}</b>
                         </div>
                    </div>
               </div>
          </div>
          
      </div>

   
      </div>

      <div className='button-part mb-4'>
          <ul>
            <li>
              <NavLink className='formBtn1' to={`/${userinfo?.company_slug}/guardians`}><i className="fa-solid fa-angle-left"></i> Back Guardian List</NavLink>
            </li>
           
          </ul>
      </div>
 
      </div>
  </div>
  )
}

export default ViewGuardians