import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Loader from '../utils/Loader'
import Swal from "sweetalert2";
import { GetStudentAssesmentRequest, SubmitExamRequest, TakeexamRequest } from '../redux/reducer/FetchStudentDataReducer'
import Modal from 'react-bootstrap/Modal';
import { Alert } from 'bootstrap';
import moment from "moment";
import ApiConnection from '../utils/ApiConnection'

const AttendanceList = () => {
    const [show, setShow] = useState(false);
    const [questionList, setQuestionList] = useState([])
    const [Index, setIndex] = useState(0)
    const [Newarry, setNewarry] = useState([])
    const [ChooseAns, setChooseAns] = useState("")
    const [testId, settestId] = useState("")
    const [duration, setduration] = useState(0);
    const [EnrollmentList, setEnrollmentList] = useState([])
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);

    const handleClose = () => setShow(false);
    const handleShow1 = () => setShow1(true);

    const fetchstudentReducer = useSelector((state) => state.FetchStudentDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    const dispatch = useDispatch()
    const { id } = useParams()
    let navigate = useNavigate()

    const [AvilScheduleList, setAvilScheduleList] = useState([])

    const GetSchedule = async (e) => {
        try {
            const response = await ApiConnection.get(
                `attendance-crud/?server_type=production&student_id=${userinfo?.user_id}`
            );
            if (response?.status === 200) {
                setAvilScheduleList(response?.data?.results[0]);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        GetSchedule()

    }, [])


    return (
        <div className='common-layout'>
            <Loader visible={fetchstudentReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Attendance List </h3>
                </div>

                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr className='text-center'>
                                <th>Date</th>
                                <th>Clock In</th>
                                <th>Clock Out</th>
                                <th>Remarks</th>
                                {userinfo?.user_type == "COMPANY" &&
                                    <th>Action</th>
                                }
                            </tr>
                        </thead>
                        {AvilScheduleList && AvilScheduleList.length > 0 ?

                            <tbody>
                                {AvilScheduleList && AvilScheduleList.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{moment(item?.attendance_date).format("YYYY-MM-DD")}</td>
                                            <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                                                return (
                                                    <span className='timeseparate'>{list?.login_time}</span>
                                                )
                                            })}</td>
                                            <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                                                return (
                                                    <span className='timeseparate'>{list?.logout_time}</span>
                                                )
                                            })}</td>
                                            <td className='p-0 text-center'>{item?.time && item?.time.map((list, index) => {
                                                return (
                                                    <span className='timeseparate'>{list?.remarks}</span>
                                                )
                                            })}</td>
                                            {/* <td>
                                            <button className='formBtn text-info assignbtn' onClick={() => handleShow1(item.id)}>Hold</button>
                                            <button className='formBtn assignbtn'onClick={() => CancelHandle(item.id)}>Cancel</button>
                                        </td> */}
                                        </tr>
                                    )
                                })}

                            </tbody>
                            :
                            <td colSpan="4" className='text-center'>No data found</td>
                        }
                    </table>
                </div>


            </div>
        </div>
    )
}

export default AttendanceList