import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { AddBackgroundRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";

const BillingDetails = () => {
    const [bccname, setbccname] = useState("")
    const [email, setemail] = useState("")
    const [contactname, setcontactname] = useState("")
    const [phone, setphone] = useState("")


    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))

    console.log(fetchacompanyReducer.GetBackgroundResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    const SubmitHandle = () => {
        if (bccname == '') {
            Swal.fire({
                icon: "error",
                text: 'Name should be must',
            });
        } else if (email == '') {
            Swal.fire({
                icon: "error",
                text: 'Email should be must',
            });
        } else if (contactname == '') {
            Swal.fire({
                icon: "error",
                text: 'Contact Name should be must',
            });
        } else if (phone == '') {
            Swal.fire({
                icon: "error",
                text: 'Phone should be must',
            });
        } else {

            let obj = {
                "bcc_name": bccname,
                "email": email,
                "contact_name": contactname,
                "phone": phone,

            }

            dispatch(AddBackgroundRequest({ obj: obj, navigate: navigate }))

        }
    }


    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Enrollment Details</h3>
                </div>
                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>
                <label className='selectlabel' style={{fontSize: '16px'}}>Billing Information</label>

                <div className='row form-style mb-4'>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>First Name</label>
                                <input type='text' className='form-control' placeholder='First Name'
                                    />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>last Name</label>
                                <input type='text' className='form-control' placeholder='Last Name'
                                    />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Email</label>
                                <input type='text' className='form-control' placeholder='Email'
                                    />
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='form-group'>
                                <label className='selectlabel'>Phone</label>
                                <input type='text' className='form-control' placeholder='Phone'
                                    />
                            </div>
                        </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Address 1</label>
                            <input type='text' className='form-control' placeholder='Address 1'
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Address 2</label>
                            <input type='text' className='form-control' placeholder='Address 2'
                            />
                        </div>
                    </div>                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Country</label>
                            <input type='text' className='form-control' placeholder='Country'
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>State</label>
                            <input type='text' className='form-control' placeholder='State'
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>City</label>
                            <input type='text' className='form-control' placeholder='City'
                            />
                        </div>
                    </div>

                    <div className='col-lg-4'>
                        <div className='form-group'>
                            <label className='selectlabel'>Pincode</label>
                            <input type='text' className='form-control' placeholder='Pincode'
                            />
                        </div>
                    </div>


                </div>
                {/* <button className='formBtn' onClick={SubmitHandle}>Next</button> */}
                <NavLink className='btn btn-primary mr-3 mb-4 float-right' to={`/${userinfo?.company_slug}/GuidelineDetails`}>Next</NavLink>
            </div>

        </div>
    )
}

export default BillingDetails