import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, NavLink, useParams } from 'react-router-dom'
import { AddstudentRequest, ViewguardianRequest } from '../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../utils/Loader'
import Swal from "sweetalert2";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import moment from "moment";

const EditSchedule = () => {

  const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
  var gradeList = fetchacompanyReducer?.GetGradeResponse?.results

  console.log(fetchacompanyReducer.GetGradeResponse)

  const { id } = useParams()
  const dispatch = useDispatch()
  let navigate = useNavigate()

  const [grade, setgrade] = useState("")
  const [subject, setsubject] = useState("")
  const [test, settest] = useState("")
  const [student, setstudent] = useState("")
  const [modulename, setmodulename] = useState("")
  const [date, setdate] = useState(null)
  const [time, settime] = useState(null)


  useEffect(()=>{
      let obj ={
          id:id
      }
      dispatch(ViewguardianRequest(obj))
  },[])

//   useEffect(()=>{
  
//     var editdata = fetchacompanyReducer?.ViewguardianResponse?.results[0]
//     setlocation(editdata?.location_id)
//     setprospect(editdata?.prospect_id)
//     setsalution(editdata?.salutation)
//     setfname(editdata?.first_name)
//     setlname(editdata?.last_name)
//     setphone(editdata?.phone)
//     setmobile(editdata?.mobile_phone)
  
//   },[fetchacompanyReducer?.ViewguardianResponse])

  const SubmitHandle = () => {
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

    if (grade == '') {
      Swal.fire({
        icon: "error",
        text: 'Grade should be must',
      });
    }
    else if (subject == '') {
      Swal.fire({
        icon: "error",
        text: 'Subject no should be must',
      });
    } else if (test == '') {
      Swal.fire({
        icon: "error",
        text: 'test Name should be must',
      });
    } else if (student == '') {
      Swal.fire({
        icon: "error",
        text: 'Student should be must',
      });
    } else if (modulename == '') {
      Swal.fire({
        icon: "error",
        text: 'Module Name should be must',
      });
    } else if (date == '') {
      Swal.fire({
        icon: "error",
        text: 'Date should be must',
      });
    } else if (time == '') {
      Swal.fire({
        icon: "error",
        text: 'Time should be must',
      });
    }

    else {
      let obj = {
        "prospect_id": 1,
        "grade": grade,
        "subject": subject,
        "test": test,
        "student": student,
        "date": moment(date).format("YYYY-MM-DD"),
        "time": time,
      }

      dispatch(AddstudentRequest({ obj: obj, id: id, navigate: navigate }))
    }
  }


  return (
    <div className='common-layout'>
      <Loader visible={fetchacompanyReducer.isloading} />
      <div className='innerlayout p-3'>
        <div className='mb-4'>
          <h3>Edit Schedule</h3>
        </div>

        <div className='row form-style mb-4'>
          <div className='col-lg-12 mb-2'>
            <h6>Edit Schedule Details</h6>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <select className='form-control' value={grade}
                onChange={(e) => setgrade(e.target.value)}>
                <option value="">--Select Grade---</option>
                <option value="A">Class I</option>
                <option value="B">Class II</option>
                <option value="C">Class IV</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <select className='form-control' value={subject}
                onChange={(e) => setsubject(e.target.value)}>
                <option value="">-- Select Subject --</option>
                <option>History</option>
                <option>Physics</option>
                <option>Math</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <select className='form-control' value={test}
                onChange={(e) => settest(e.target.value)}>
                <option value="">-- Select Test --</option>
                <option>Test 1</option>
                <option>Test 2</option>
                <option>Test 4</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <select className='form-control' value={student}
                onChange={(e) => setstudent(e.target.value)}>
                <option value="">-- Assign Student --</option>
                <option value="1">Ram</option>
                <option value="1">Rittick</option>
                <option value="1">Suman</option>
              </select>
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <input type='text' className='form-control' placeholder='Module'
                value={modulename}
                onChange={(e) => setmodulename(e.target.value)}
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <div className='form-group'>
              <DatePicker selected={date} onChange={(date) => setdate(date)} className='form-control'
                placeholderText="Date (mm/dd/yy)"
              />
            </div>
          </div>

          <div className='col-lg-4'>
            <TimePicker onChange={settime} className='form-control'
              placeholder="Time" value={time}
            />
          </div>


        </div>
        <button className='formBtn' onClick={SubmitHandle}>Update</button>

      </div>
    </div>
  )
}

export default EditSchedule